import styles from './StatsPeriodSelector.module.css';

import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { CustomProvider } from 'rsuite';
import ruRU from 'rsuite/locales/ru_RU';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import endOfYear from 'date-fns/endOfYear';

import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { useStatsBlock } from '../StatsBlock/useStatsBlock';
import { useTranslation } from 'react-i18next';

export function StatsPeriodSelector() {
  const { t } = useTranslation();
  const predefinedRanges = [
    {
      label: t('last_7_days'),
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left',
    },
    {
      label: t('last_30_days'),
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left',
    },
    {
      label: t('current_month'),
      value: [startOfMonth(new Date()), endOfMonth(addMonths(new Date(), 0))],
      placement: 'left',
    },
    {
      label: t('last_month'),
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: 'left',
    },
  
    {
      label: t('last_year'),
      value: [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear(), 0, 0),
      ],
      placement: 'left',
    },
    {
      label: t('all_time'),
      value: [new Date(new Date().getFullYear() - 2, 0, 1), new Date()],
      placement: 'left',
    },
  ];
  let { calendarHandler, period } = useStatsBlock();

  return (
    <>
      <div className={styles.stats_period_selector_container}>
        <h3 className={styles.title} style={{ color: '#282828' }}>
          {t('statistics')}
        </h3>
        <CustomProvider locale={ruRU}>
          <DateRangePicker
            format="dd/MM/yyyy"
            placement="auto"
            showOneCalendar={window.innerWidth < 1024 ? true : false}
            locale={ruRU}
            isoWeek="8601"
            placeholder={t('choose_time_slot')}
            ranges={
              window.innerWidth < 1024
                ? predefinedRanges.map((range) => ({
                    ...range,
                    placement: 'bottom',
                  }))
                : predefinedRanges
            }
            value={period}
            onChange={calendarHandler}
          />
        </CustomProvider>
      </div>
    </>
  );
}
