export const LanguageEnum = {
  En: 'en',
  Uk: 'uk',
  De: 'de',
  Ru: 'ru',
  Cn: 'cn',
  Jp: 'jp',
  Us: 'us',
  As: 'as',
  Ca: 'Ca',
  Au: 'aus',
}

export const CurrencyEnum = {
  [LanguageEnum.Uk]: 'грн',
  [LanguageEnum.En]: 'usd',
  [LanguageEnum.De]: 'eur',
  [LanguageEnum.Ru]: 'руб',
  [LanguageEnum.Cn]: 'cny',
  [LanguageEnum.Jp]: 'jpy',
  [LanguageEnum.Us]: 'gbp',
}