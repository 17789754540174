import React, { useState, useLayoutEffect } from 'react';
import styles from './Step3.module.css';
import {
  Background,
  Button,
  Input,
  InputsTitle,
} from '../../Components/BasicComponents/BasicComponents';
import Imgs from '../../Imgs/Imgs';
import ProfilePhoto from '../../Components/ProfilePhoto/ProfilePhoto';
import { useNavigate } from 'react-router-dom';
import { checkAccess } from '../../access';
import { useTranslation } from 'react-i18next';

function Step3() {
  let navigate = useNavigate();
  const { t } = useTranslation()

  let specialistId = checkAccess();

  //Адаптивный дизайн
  let [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function updateWidth() {
    setWindowWidth(window.innerWidth);
  }
  useLayoutEffect(() => {
    window.addEventListener('resize', updateWidth);

    if (windowWidth < 1025) {
      document.body.style.backgroundColor = '#F4F7FE';
    }
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  //! Рендеринг

  return (
    <>
      <Background style={{ backgroundColor: '#F4F7FE' }}>
        <div className={styles.outer_container}>
          <div className={styles.form_container}>
            {windowWidth > 600 ? (
              <Imgs img={'planguinLogo'} />
            ) : (
              <div className={styles.logo_container}>
                {' '}
                <Imgs img={'planguinLogo'} planguinLogoWidth={'200px'} />
              </div>
            )}
            <div className={styles.inner_form_container}>
              <div className={styles.photo_container}>
                <ProfilePhoto t={'test'} imgWidth={'250px'} />
              </div>

              <div className={styles.title}></div>
              <InputsTitle title={`${t('add_photo_or_logo')} 👆`} />
            </div>

            <div className={styles.buttons_container}>
              <Button
                name={t('next')}
                onClick={() => {
                  navigate('/step4');
                }}
              />
              <Button
                name={t('skip')}
                style={{ backgroundColor: '#DADADA',  marginTop: '15px' }}
                onClick={() => {
                  navigate('/step4');
                }}
              />
            </div>
          </div>
        </div>
      </Background>
    </>
  );
}

export default Step3;
