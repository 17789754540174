import React, { useEffect, useState } from 'react';
import { checkAccess } from '../../access';
import Header, { MobileHeader } from '../../Components/Header/Header';
import {
  Button,
  Input,
} from '../../Components/BasicComponents/BasicComponents';
import stylesD from './PaymentsD.module.css';
import stylesM from './PaymentsM.module.css';

import sms from '../../Imgs/sms.png';
import {
  usePlan1Store,
  usePlanBlock,
  useSMSTopUpStore,
  useTransactions,
} from '../../store';
import Imgs from '../../Imgs/Imgs';
import moment from 'moment/moment';
import { useDB } from 'utils/DB';
import { useTranslation } from 'react-i18next';
import { useCurrency } from 'hooks/useCurrency';
import { LanguageEnum } from 'utils/enums';

function PlanBlock(props) {
  const { t } = useTranslation();
  let { plan, expiresIn, getData } = usePlanBlock();
    
  useEffect(() => {
    async function af() {
      getData();
    }
    af();
  }, []);

  if (props.type == 'mobile') {
    return (
      <>
        <div className={stylesM.plan_block_container}>
          <div className={stylesM.plan}>
            {plan == 'trial' ? t('trial') : t('basic')}
          </div>
          <div className={stylesM.time_left}>
            {t('valid_until')}: {moment(expiresIn).format('DD.MM.YYYY')}
          </div>
          {/* {Date.now()<expiresIn?<></>:<Button style={{backgroundColor:'black',height:'46px',fontWeight:'16px',cursor:'pointer'}} name={'Обрати тариф'} onClick={props.onClick}/>} */}
          <Button
            style={{
              backgroundColor: 'black',
              height: '46px',
              fontWeight: '16px',
              cursor: 'pointer',
            }}
            name={t('select_tariff')}
            onClick={props.onClick}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={stylesD.plan_block_container}>
        <div className={stylesD.plan}>
          {plan == 'trial' ? t('trial') : t('basic')}
        </div>
        <div className={stylesD.time_left}>
          {t('valid_until')}: {moment(expiresIn).format('DD.MM.YYYY')}
        </div>
        {/* {Date.now()<expiresIn?<></>:<Button style={{width:'231px',backgroundColor:'black',height:'46px',fontWeight:'16px',cursor:'pointer'}} name={'Обрати тариф'} onClick={props.onClick}/>} */}
        <Button
          style={{
            width: '231px',
            backgroundColor: 'black',
            height: '46px',
            fontWeight: '16px',
            cursor: 'pointer',
          }}
          name={t('select_tariff')}
          onClick={props.onClick}
        />
      </div>
    </>
  );
}

function SMSTopUp(props) {
  const { t, i18n } = useTranslation();
  const currentName = i18n.language === LanguageEnum.Uk ? 'грн' : 'UAH' 
  
  let {
    balance,
    getSMSbalance,
    topUpSMS,
    incrementPackage,
    decrementPackage,
    priceForSelectedAmountOfSMS,
    fee,
    sumToPay,
    createInvoice,
  } = useSMSTopUpStore();

  useEffect(() => {
    async function af() {
      getSMSbalance();
    }
    af();
  }, []);

  if (props.type == 'mobile') {
    return (
      <>
        <div className={stylesM.sms_topup_container}>
          <div className={stylesM.sms_topup_line1}>
            <div className={stylesM.sms_topup_img_container}>
              <img src={sms} alt="" />
            </div>
            <div className={stylesM.sms_topup_right_side}>
              <div className={stylesM.sms_topup_title}>SMS {t('notifications')}</div>
              <div className={stylesM.sms_topup_subtitle}>
                {t('top_up_balance_and_send_sms')}
              </div>
              <div className={stylesM.sms_topup_status}>{t('connected')}</div>
            </div>
          </div>

          <div className={stylesM.sms_topup_balance}>
            {t('your_balance')}: <span style={{ color: 'coral' }}>{balance} SMS</span>{' '}
          </div>

          <div className={stylesM.sms_topup_topup}>
            <div className={stylesM.sms_topup_topup_right_side}>
              <div className={stylesM.sms_topup_topup_right_side_title}>
                {t('top_up_balance')}
              </div>
              <div className={stylesM.sms_topup_topup_right_side_subtitle}>
                {t('enter_amount')} SMS
              </div>
            </div>

            <Input
              value={topUpSMS}
              onChange={() => false}
              style={{ width: '80px', fontWeight: 'bold', color: 'grey', padding: 0, textAlign: 'center' }}
            />

            <button className={stylesD.sms_topup_icn} type='button' onClick={incrementPackage}>+</button>
            <button className={stylesD.sms_topup_dec} type='button' onClick={decrementPackage}>-</button>
          </div>

          <div className={stylesM.sms_topup_info}>
            <div className={stylesM.sms_topup_info_cell}>
              {t('price_per')} {topUpSMS} SMS
            </div>
            <div className={stylesM.sms_topup_info_cell}>
              {priceForSelectedAmountOfSMS} {currentName}
            </div>
            <div className={stylesM.sms_topup_info_cell}>
              {t('bank_fee')}: 1.3%
            </div>
            <div className={stylesM.sms_topup_info_cell}>{fee} {currentName}</div>
            {/* <div className={stylesD.sms_topup_info_cell}>ПДВ (0 %):</div>
<div className={stylesD.sms_topup_info_cell}>0 грн</div> */}
            <div
              style={{ fontWeight: 700, fontSize: '16px', marginTop: '10px' }}
              className={stylesM.sms_topup_info_cell}
            >
              {t('amount_due')}:
            </div>
            <div
              style={{ fontWeight: 700, fontSize: '16px', marginTop: '10px' }}
              className={stylesM.sms_topup_info_cell}
            >
              {sumToPay} {currentName}
            </div>
          </div>

          <Button name={t('top_up')} onClick={createInvoice} />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={stylesD.sms_topup_container}>
        <div className={stylesD.sms_topup_line1}>
          <div className={stylesD.sms_topup_img_container}>
            <img src={sms} alt="" />
          </div>
          <div className={stylesD.sms_topup_right_side}>
            <div className={stylesD.sms_topup_title}>SMS {t('notifications')}</div>
            <div className={stylesD.sms_topup_subtitle}>
              {t('top_up_balance_and_send_sms')}
            </div>
            <div className={stylesD.sms_topup_status}>{t('connected')}</div>
          </div>
        </div>

        <div className={stylesD.sms_topup_balance}>
          {t('your_balance')}: <span style={{ color: 'coral' }}>{balance} SMS</span>{' '}
        </div>

        <div className={stylesD.sms_topup_topup}>
          <div className={stylesD.sms_topup_topup_right_side}>
            <div className={stylesD.sms_topup_topup_right_side_title}>
              {t('top_up_balance')}
            </div>
            <div className={stylesD.sms_topup_topup_right_side_subtitle}>
              {t('enter_amount')} SMS
            </div>
          </div>

          <Input
            value={topUpSMS}
            onChange={() => false}
            style={{ width: '80px', fontWeight: 'bold', color: 'grey', padding: 0, textAlign: 'center' }}
          />

          <button className={stylesD.sms_topup_icn} type='button' onClick={incrementPackage}>+</button>
          <button className={stylesD.sms_topup_dec} type='button' onClick={decrementPackage}>-</button>
        </div>

        <div className={stylesD.sms_topup_info}>
          <div className={stylesD.sms_topup_info_cell}>
            {t('price_per')} {topUpSMS} SMS
          </div>
          <div className={stylesD.sms_topup_info_cell}>
            {priceForSelectedAmountOfSMS} {currentName} 
          </div>
          <div className={stylesD.sms_topup_info_cell}>{t('bank_fee')}: 3%</div>
          <div className={stylesD.sms_topup_info_cell}>{fee} {currentName}</div>
          {/* <div className={stylesD.sms_topup_info_cell}>ПДВ (0 %):</div>
          <div className={stylesD.sms_topup_info_cell}>0 грн</div> */}

          <div
            style={{ fontWeight: 700, fontSize: '16px', marginTop: '10px' }}
            className={stylesD.sms_topup_info_cell}
          >
            {t('amount_due')}:
          </div>
          <div
            style={{ fontWeight: 700, fontSize: '16px', marginTop: '10px' }}
            className={stylesD.sms_topup_info_cell}
          >
            {sumToPay} {currentName}
          </div>
        </div>

        <Button name={t('top_up')} onClick={createInvoice} />
      </div>
    </>
  );
}

function Transactions(props) {
  const { t } = useTranslation();
  let { transactions, getTransactions } = useTransactions();

  useEffect(() => {
    async function af() {
      getTransactions();
    }
    af();
  }, []);

  if (props.type == 'mobile') {
    return (
      <>
        <div className={stylesM.transactions_container}>
          <div className={stylesM.transactions_title}>{t('payment_history')}</div>
          <div className={stylesM.transactions_data}>
            <div>{t('date')}</div>
            <div>{t('type')}</div>
            <div>{t('amount')}</div>
          </div>

          {transactions.map((el) => {
            return (
              <Transaction_Raw
                date={moment(el.created).format(`DD.MM.YYYY HH:mm`)}
                type={el.nameOfProduct}
                sum={el.sum / 100}
              />
            );
          })}
        </div>
      </>
    );
  }

  return (
    <>
      <div className={stylesD.transactions_container}>
        <div className={stylesD.transactions_title}>{t('payment_history')}</div>
        <div className={stylesD.transactions_data}>
          <div>{t('date')}</div>
          <div>{t('type')}</div>
          <div>{t('amount')}</div>
        </div>

        {transactions.map((el) => {
          return (
            <Transaction_Raw
              date={moment(el.created).format(`DD.MM.YYYY HH:mm`)}
              type={el.nameOfProduct}
              sum={el.sum / 100}
            />
          );
        })}
      </div>
    </>
  );
}

function Transaction_Raw(props) {
  const { i18n } = useTranslation();
  const currentName = i18n.language === LanguageEnum.Uk ? 'грн' : 'UAH' 

  return (
    <>
      <div className={stylesD.transactions_row}>
        <div>{props.date}</div>
        <div>{props.type}</div>
        <div>{props.sum} {currentName}</div>
      </div>
    </>
  );
}

export function Notification(props) {
  const { t } = useTranslation();
  let { specialist } = useDB();
  let { expiresIn, getData } = usePlanBlock();

  useEffect(() => {
    async function af() {
      getData();
    }
    af();
  }, [specialist]);

  if (new URL(window.location.href).pathname == '/payments') {
    return;
  }

  if (expiresIn < Date.now()) {
    if (props.type == 'mobile') {
      return (
        <>
          <div className={stylesM.notification_container}>
            {t('renew_license')} &nbsp;&nbsp;
            <span>
              {' '}
              <Button
                onClick={() => {
                  window.location.href = '/payments';
                }}
                style={{
                  backgroundColor: 'white',
                  color: 'coral',
                  height: '40px',
                  width: '150px',
                  fontSize: '16px',
                }}
                name={t('renew')}
              />
            </span>
          </div>
        </>
      );
    }

    return (
      <>
        <div className={stylesD.notification_container}>
          {t('renew_license')} &nbsp;&nbsp;
          <span>
            {' '}
            <Button
              onClick={() => {
                window.location.href = '/payments';
              }}
              style={{
                backgroundColor: 'white',
                color: 'coral',
                height: '40px',
                width: '150px',
                fontSize: '16px',
              }}
              name={t('renew')}
            />
          </span>
        </div>
      </>
    );
  }
}

function Plan1(props) {
  const { t } = useTranslation();

  if (props.type == 'mobile') {
    return (
      <>
        <div className={stylesM.plan_container}>
          <div className={stylesM.plan_title}>{t('basic_license')}</div>
          <div
            className={stylesM.options}
            style={{ fonSize: '18px', color: 'grey' }}
          >
            {' '}
            <span style={{ fontSize: '35px', fontWeight: 500, color: 'black' }}>
              170
            </span>
            {` ${t('uah_per_month')}`}
          </div>
          <div className={stylesM.options}>
            · {t('basic_functionality')} <br />
            <br />
            · {t('online_link_booking')} <br />
            <br />
            · {t('calendar_journal')} <br />
            <br />
            · {t('telegram_email_notifications')} <br />
            <br />
            · {t('client_sms_reminders')} <br />
            <br />
            · {t('technical_support')} Telegram/ <br />
            &nbsp; Email {t('available_24_7')}
            <br />
          </div>
          <Button
            type={4}
            name={t('activate')}
            style={{ width: '100%', height: '60px', marginTop: '15px' }}
            onClick={props.onClick}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={stylesD.plan_container}>
        <div className={stylesD.plan_title}>{t('basic_license')}</div>
        <div
          className={stylesD.options}
          style={{ fonSize: '18px', color: 'grey' }}
        >
          {' '}
          <span style={{ fontSize: '35px', fontWeight: 500, color: 'black' }}>
            170
          </span>
          {` ${t('uah_per_month')}`}
        </div>
        <div className={stylesD.options}>
          · {t('basic_functionality')} <br />
          <br />
          · {t('online_link_booking')}<br />
          <br />
          · {t('calendar_journal')} <br />
          <br />
          · {t('telegram_email_notifications')} <br />
          <br />
          · {t('client_sms_reminders')} <br />
          <br />
          · {t('technical_support')} Telegram/ <br />
          &nbsp; Email {t('available_24_7')}
          <br />
        </div>
        <Button
          type={4}
          name={t('activate')}
          style={{ width: '100%', height: '60px', marginTop: '15px' }}
          onClick={props.onClick}
        />
      </div>
    </>
  );
}

function Plan2(props) {
  const { t } = useTranslation();
  if (props.type == 'mobile') {
    return (
      <>
        <div className={stylesM.plan_container}>
          <div className={stylesM.plan_title}>{t('extended_license')}</div>
          <div
            className={stylesM.options}
            style={{ fonSize: '18px', color: 'grey' }}
          >
            {' '}
            <span style={{ fontSize: '35px', fontWeight: 500, color: 'black' }}>
              ~ 200
            </span>{' '}
            {` ${t('uah_per_month')}`}
          </div>
          <div className={stylesM.options}>
            · {t('all_features_basic_license')} <br />
            <br />
            · {t('client_database_and_management')} <br />
            <br />
            · {t('notification_customization')} <br />
            <br />
            · {t('online_client_payment')} <br />
            <br />
            · {t('bonus_system')} <br />
            <br />· {t('latest_client_tools_and_more')}
            {t('interesting')} <br />
          </div>
          <Button
            onClick={props.onClick}
            type={5}
            name={t('in_development')}
            style={{
              width: '100%',
              height: '60px',
              marginTop: '15px',
              backgroundColor: 'grey',
            }}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={stylesD.plan_container}>
        <div className={stylesD.plan_title}>{t('extended_license')}</div>
        <div
          className={stylesD.options}
          style={{ fonSize: '18px', color: 'grey' }}
        >
          {' '}
          <span style={{ fontSize: '35px', fontWeight: 500, color: 'black' }}>
            ~ 200
          </span>
          {` ${t('uah_per_month')}`}
        </div>
        <div className={stylesD.options}>
          · {t('all_features_basic_license')} <br />
          <br />
          · {t('client_database_and_management')} <br />
          <br />
          · {t('notification_customization')} <br />
          <br />
          · {t('online_client_payment')} <br />
          <br />
          · {t('bonus_system')} <br />
          <br />· {`${t('latest_client_tools_and_more')} `}
          <br />
        </div>
        <Button
          onClick={props.onClick}
          type={5}
          name={t('in_development')}
          style={{
            width: '100%',
            height: '60px',
            marginTop: '15px',
            backgroundColor: 'grey',
          }}
        />
      </div>
    </>
  );
}

function Payments() {
  const { t, i18n } = useTranslation();
  const {currency} = useCurrency()
  let [page, setPage] = useState('main');
  let specialistId = checkAccess();
  let {
    selected,
    setSelected,
    planPrice,
    sumWithoutDiscount,
    discount,
    discountInUAH,
    total,
    createInvoice,
  } = usePlan1Store();

  const currentName = i18n.language === LanguageEnum.Uk ? 'грн' : 'UAH' 

  document.body.style.backgroundColor = '#F4F7FE';

  if (window.innerWidth < 1025) {
    if (page == 'main') {
      return (
        <>
          <MobileHeader title={t('payment')} type={2} />

          <div className={stylesM.main_container}>
            {/* <div className={stylesM.page_title}>Оплата</div> */}
            <PlanBlock
              type={'mobile'}
              onClick={() => {
                setPage('plans');
              }}
            />
            <SMSTopUp type={'mobile'} />
            <Transactions type={'mobile'} />
          </div>
        </>
      );
    }

    if (page == 'plans') {
      return (
        <>
          <Header platform={'mobile'} specialistId={specialistId} />
          <div className={stylesM.main_container}>
            <div className={stylesM.back_button_container}>
              <Imgs
                onClickB={() => {
                  setPage('main');
                }}
                img={'back_arrow'}
              />
            </div>

            <Plan1
              type={'mobile'}
              onClick={() => {
                setPage('options');
              }}
            />
            <Plan2 type={'mobile'} />
          </div>
        </>
      );
    }

    if (page == 'options') {
      return (
        <>
          <Header platform={'mobile'} specialistId={specialistId} />
          <div className={stylesM.main_container}>
            <div className={stylesM.options_outer_container}>
              <div className={stylesM.options_container}>
                <div className={stylesM.options_title}>
                  <Imgs
                    img={'back_arrow'}
                    onClickB={() => {
                      setPage('plans');
                    }}
                  />
                  {` ${t('basic_license')}`}
                </div>

                <div className={stylesM.options_block}>
                  <div className={stylesM.options_block_title}>
                    {t('payment_period')}
                  </div>

                  <div className={stylesM.option}>
                    {' '}
                    <input
                      name={'period'}
                      defaultChecked
                      value={selected}
                      onClick={setSelected}
                      id={1}
                      type="radio"
                    />
                    1 {`${t('month')} `}
                  </div>
                  <div className={stylesM.option}>
                    {' '}
                    <input
                      name={'period'}
                      value={selected}
                      onClick={setSelected}
                      id={3}
                      type="radio"
                    />
                    3 {`${t('months')} `}
                    <span className={stylesD.discount}>
                      {' '}
                      &nbsp;&nbsp;&nbsp; {t('discount')} 5%
                    </span>{' '}
                  </div>
                  <div className={stylesM.option}>
                    {' '}
                    <input
                      name={'period'}
                      value={selected}
                      onClick={setSelected}
                      id={6}
                      type="radio"
                    />
                    6 {`${t('months_plural')} `}
                    <span className={stylesD.discount}>
                      {' '}
                      &nbsp;&nbsp;&nbsp;{t('discount')} 10%
                    </span>{' '}
                  </div>
                  <div className={stylesM.option}>
                    {' '}
                    <input
                      name={'period'}
                      value={selected}
                      onClick={setSelected}
                      id={12}
                      type="radio"
                    />
                    12 {`${t('months_plural')} `}
                    <span className={stylesD.discount}>&nbsp;{t('discount')} 20%</span>{' '}
                  </div>
                </div>

                <div className={stylesM.options_block}>
                  <div className={stylesM.options_block_title}>
                    {t('basic_license')}
                  </div>

                  <div className={stylesM.row}>
                    <div>
                      {planPrice} {currency} х {selected} {t('months_short')}
                    </div>
                    <div style={{ fontWeight: 'bold' }}>
                      {sumWithoutDiscount} {currency}
                    </div>
                  </div>

                  <div className={stylesM.discount}>{t('discount')}</div>

                  <div className={stylesM.row}>
                    <div>
                      {discount} % х {selected} {t('months_plural')} 
                    </div>
                    <div style={{ fontWeight: 'bold' }}>
                      -{discountInUAH} {currency}
                    </div>
                  </div>

                  <div className={stylesM.row}>
                    <div style={{ fontWeight: 'bold' }}>{t('total_amount_due')}:</div>
                    <div style={{ fontWeight: 'bold' }}>
                      {' '}
                      <span
                        style={{
                          color: '#FF8A35',
                          textDecoration: 'line-through',
                          fontWeight: '300',
                        }}
                      >
                        {sumWithoutDiscount === 170 ? 0 : sumWithoutDiscount} {t('currency')}
                      </span>{' '}
                      &nbsp; {total} {currency}
                    </div>
                  </div>
                </div>

                <Button name={t('pay')} onClick={createInvoice} />

                <div className={stylesM.conditions}>
                  {`${t('i_agree_with')} `}
                  <span
                    onClick={() => {
                      window.open(`https://www.planguin.co/terms`);
                    }}
                    style={{ textDecoration: 'underline' }}
                  >
                    {t('terms_of_service')}
                  </span>{' '}
                  {`${t('and')} `}
                  <span
                    onClick={() => {
                      window.open(`https://www.planguin.co/privacy-policy`);
                    }}
                    style={{ textDecoration: 'underline' }}
                  >
                    {`${t('privacy_policy_repeated.0')} ${t('privacy_policy_repeated.1')}`}
                  </span>
                  {` ${t('subscription_starts_today')}`}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }

  if (page == 'main') {
    return (
      <>
        <Header platform={'desktop'} specialistId={specialistId} />

        <div className={stylesD.container}>
          <div className={stylesD.left_side}>
            <PlanBlock
              onClick={() => {
                setPage('plans');
              }}
            />
            <Transactions />
          </div>
          <div className={stylesD.right}>
            <SMSTopUp />
          </div>
        </div>
      </>
    );
  }

  if (page == 'plans') {
    return (
      <>
        <Header platform={'desktop'} specialistId={specialistId} />
        <div className={stylesD.back_button_container}>
          <div style={{ width: '837px' }}>
            <Imgs
              onClickB={() => {
                setPage('main');
              }}
              img={'back_arrow'}
            />
          </div>
        </div>
        <div className={stylesD.container}>
          <Plan1
            onClick={() => {
              setPage('options');
            }}
          />
          <Plan2 />
        </div>
      </>
    );
  }

  if (page == 'options') {
    return (
      <>
        <Header platform={'desktop'} specialistId={specialistId} />

        <div className={stylesD.options_outer_container}>
          <div className={stylesD.options_container}>
            <div className={stylesD.options_title}>
              <Imgs
                img={'back_arrow'}
                onClickB={() => {
                  setPage('plans');
                }}
              />{' '}
              {t('basic_license')}
            </div>

            <div className={stylesD.options_block}>
              <div className={stylesD.options_block_title}>{t('payment_period')}</div>

              <div className={stylesD.option}>
                {' '}
                <input
                  name={'period'}
                  defaultChecked
                  value={selected}
                  onClick={setSelected}
                  id={1}
                  type="radio"
                />
                1 {`${t('month')} `}
              </div>
              <div className={stylesD.option}>
                {' '}
                <input
                  name={'period'}
                  value={selected}
                  onClick={setSelected}
                  id={3}
                  type="radio"
                />
                3 {`${t('months')} `}
                <span className={stylesD.discount}>
                  {' '}
                  &nbsp;&nbsp;&nbsp; {t('discount')} 5%
                </span>{' '}
              </div>
              <div className={stylesD.option}>
                {' '}
                <input
                  name={'period'}
                  value={selected}
                  onClick={setSelected}
                  id={6}
                  type="radio"
                />
                  6 {`${t('months_plural')} `}
                <span className={stylesD.discount}>
                  {' '}
                  &nbsp;&nbsp;&nbsp;{t('discount')} 10%
                </span>{' '}
              </div>
              <div className={stylesD.option}>
                {' '}
                <input
                  name={'period'}
                  value={selected}
                  onClick={setSelected}
                  id={12}
                  type="radio"
                />
                12 {`${t('months_plural')} `}
                <span className={stylesD.discount}>&nbsp;{t('discount')} 20%</span>{' '}
              </div>
            </div>

            <div className={stylesD.options_block}>
              <div className={stylesD.options_block_title}>{t('basic_license')}</div>

              <div className={stylesD.row}>
                <div>
                  {planPrice} {currentName} х {selected} {t('months_short')}
                </div>
                <div style={{ fontWeight: 'bold' }}>
                  {sumWithoutDiscount} {currentName}
                </div>
              </div>

              <div className={stylesD.discount}>{t('discount')}</div>

              <div className={stylesD.row}>
                <div>
                  {discount} % х {selected} {t('months_plural')}
                </div>
                <div style={{ fontWeight: 'bold' }}>-{discountInUAH} {currentName}</div>
              </div>

              <div className={stylesD.row}>
                <div style={{ fontWeight: 'bold' }}>{t('total_amount_due')}:</div>
                <div style={{ fontWeight: 'bold' }}>
                  {' '}
                  <span
                    style={{
                      color: '#FF8A35',
                      textDecoration: 'line-through',
                      fontWeight: '300',
                    }}
                  >
                    {sumWithoutDiscount === 170 ? 0 : sumWithoutDiscount} {t('currency')}
                  </span>{' '}
                  &nbsp; {total} {currentName}
                </div>
              </div>
            </div>

            <Button name={t('pay')} onClick={createInvoice} />

            <div className={stylesD.conditions}>
              {`${t('i_agree_with')} `}
              <span
                onClick={() => {
                  window.open(`https://www.planguin.co/terms`);
                }}
                style={{ textDecoration: 'underline' }}
              >
                {t('terms_of_service')}
              </span>{' '}
              {`${t('and')} `}
              <span
                onClick={() => {
                  window.open(`https://www.planguin.co/privacy-policy`);
                }}
                style={{ textDecoration: 'underline' }}
              >
                {`${t('privacy_policy_repeated.0')} ${t('privacy_policy_repeated.1')}`}
              </span>{' '}
              {t('subscription_starts_today')}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Payments;
