import React, { useState, useEffect } from 'react';
import styles from './CalendarWeekChanger.module.css';
import moment from 'moment';
import 'moment/locale/uk';
import { useCalendarWeekChangerStore } from './CalendarWeekChangerStore';
import { WeekSelector2 } from '../WeekSelector/WeekSelector';
import { useWeekSelectorStore } from '../WeekSelector/WeekSelectorStore';
import i18n from 'i18n';

export function CalendarWeekChanger(props) {
  const [scrollDown, setScrollDown] = useState(false);

  let { month, getMonthAndYearFromCurrentWeek } = useCalendarWeekChangerStore();
  let { currentWeek } = useWeekSelectorStore();

  useEffect(() => {
    getMonthAndYearFromCurrentWeek(currentWeek);

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrollDown(true);
      } else {
        setScrollDown(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [currentWeek, i18n.language]);

  return (
    <>
      {window.innerWidth < 1025 && scrollDown == false ? (
        <div style={{ height: '60px' }}></div>
      ) : (
        <></>
      )}

      <div
        style={
          scrollDown && window.innerWidth < 1025 ? { display: 'none' } : {}
        }
        id={'container'}
        className={styles.weekChanger_container}
      >
        <div className={styles.month}>
          {month.month} {month.year}
        </div>
        <WeekSelector2 />
      </div>
    </>
  );
}
