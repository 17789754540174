import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Booking from './Pages/Booking/Booking';
import Calendar from './Pages/Calendar/Calendar';
import ChangePassword from './Pages/ChangePassword/ChangePassword';
import Clients from './Pages/Clients/Clients';
import ConfirmEmail from './Pages/ConfirmEmail/ConfirmEmail';
import DeleteAccount from './Pages/DeleteAccount/DeleteAccount';
import EmailConfirmed from './Pages/EmailConfirmed/EmailConfirmed';
import EmailConfirmaed from './Pages/EmailConfirmed/EmailConfirmed';
import InactiveAccount from './Pages/InactiveAccount/InactiveAccount';
import About from './Pages/Landing/About/About';
import License from './Pages/Landing/Components/License/License';
import PrivacyPolicy from './Pages/Landing/Components/PrivacyPolicy/PrivacyPolicy';
import Terms from './Pages/Landing/Components/Terms/Terms';
import Landing from './Pages/Landing/Landing';
import Login from './Pages/Login/Login';
import More from './Pages/More/More';
import Notifications from './Pages/Notifications/Notifications';
import PaymentResult from './Pages/PaymentResult/PaymentResult';
import Payments from './Pages/Payments/Payments';
import RegistrationSuccessful from './Pages/RegistrationSuccessful/RegistrationSuccessful';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import SandBox from './Pages/SandBox/SandBox';
import Services from './Pages/Services/Services';
import SignUp from './Pages/SignUp/SignUp';
import SpecialistProfile from './Pages/SpecialistProfile/SpecialistProfile';
import Step1 from './Pages/Step1/Step1';
import Step2 from './Pages/Step2/Step2';
import Step3 from './Pages/Step3/Step3';
import Step4 from './Pages/Step4/Step4';
import Help from 'Components/Help/Help';
import Stats from 'Pages/Stats/Stats';
import 'rsuite/dist/rsuite.min.css';
import { ToastContainer } from 'react-toastify';
import DeleteAccountWeb from 'Pages/Landing/Components/Delete Account/DeleteAccount';
import Support from 'Pages/Support/Support';
import NotificationsMessages from 'Pages/NotificationsMessages/NotificationsMessages';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n';
import Settings from 'Pages/Settings/Settings';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>
);

function App() {
  return (
    <>
      <ToastContainer />
      <Help />
      <BrowserRouter>
        <Routes>
          <Route path="/sandbox" element={<SandBox />} />
          <Route path="/" element={<Landing />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/license" element={<License />} />
          <Route path="/deleteAccount" element={<DeleteAccountWeb />} />

          <Route path="/*" element={<Booking />} />

          <Route path="/login" element={<Login />} />
          <Route path="/support" element={<Support />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/email_confirmation/" element={<ConfirmEmail />} />
          <Route path="/email_confirmed" element={<EmailConfirmed />} />

          <Route path="/reset_password" element={<ResetPassword />} />
          <Route path="/change_password" element={<ChangePassword />} />
          <Route
            path="/registration_successful"
            element={<RegistrationSuccessful />}
          />

          <Route path="/step1" element={<Step1 />} />
          <Route path="/step2" element={<Step2 />} />
          <Route path="/step3" element={<Step3 />} />
          <Route path="/step4" element={<Step4 />} />

          <Route path="/profile/specialist" element={<SpecialistProfile />} />
          <Route path="/profile/settings" element={<Settings />} />
          <Route path="/specialist/services" element={<Services />} />

          <Route path="/booking" element={<Booking />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/notifications_messages" element={<NotificationsMessages />} />
          <Route path="/about" element={<About />} />
          <Route path="/payment_result" element={<PaymentResult />} />
          <Route path="/inactive_account" element={<InactiveAccount />} />
          <Route path="/more" element={<More />} />
          <Route path="/delete_account" element={<DeleteAccount />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/stats" element={<Stats />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
