import React, { useState, useEffect } from 'react';
import { checkAccess } from '../../access';
import { Button } from '../../Components/BasicComponents/BasicComponents';
import Header, { MobileHeader } from '../../Components/Header/Header';
import { useNotificationsStore, usePopUpTelegramStore } from '../../store';
import stylesD from './NotificationsD.module.css';
import stylesM from './NotificationsM.module.css';

import Imgs from '../../Imgs/Imgs';
import telegram from '../../Imgs/Others/telegram.png';
import { Client } from 'Pages/Clients/Components/BasicComponents/BasicComponents';
import ClientNotification from './Components/ClientNotification/ClientNotification';
import { useTranslation } from 'react-i18next';

function PopUpTelegram(props) {
  const { t } = useTranslation();
  let { visiable, closePopUpTelegram, screen, changeScreen } =
    usePopUpTelegramStore();
  let { changeTelegramStatus } = useNotificationsStore();

  if (visiable) {
    if (screen == 1) {
      return (
        <>
          <div className={stylesD.popup_background}>
            <div className={stylesD.popup_window}>
              <div className={stylesD.title_container}>
                <div className={stylesD.title}>{t('new_record_notification')}</div>
                <Imgs onClick={closePopUpTelegram} img={'close'} />
              </div>

              <div className={stylesD.img_container}>
                <img src={telegram} alt="" />
              </div>

              <div className={stylesD.description}>
                {t('telegram_notifications_required')}
              </div>

              <Button
                name={t('next')}
                color={'black'}
                style={{ marginTop: '50px' }}
                onClick={() => {
                  changeScreen(2);
                }}
              />
            </div>
          </div>
        </>
      );
    }

    if (screen == 2) {
      return (
        <>
          <div className={stylesD.popup_background}>
            <div style={{ height: '315px' }} className={stylesD.popup_window}>
              <div className={stylesD.title_container}>
                <div className={stylesD.title}>{t('how_to_connect_notifications')}</div>
                <Imgs onClick={closePopUpTelegram} img={'close'} />
              </div>

              <div className={stylesD.step_container}>
                {/* <div className={stylesD.subtitle}>Як підключити сповіщення телеграм ?</div> */}
                <div className={stylesD.step}>
                  1.{`${t('go_to_telegram_bot')} `}
                  <span
                    onClick={() => {
                      window.open('https://t.me/planguin_dev_bot', '_self');
                    }}
                    className={stylesD.link}
                  >
                    {t('go_to')}
                  </span>
                </div>
                <div className={stylesD.step}>2.{t('start_bot')}</div>
                <div className={stylesD.step}>3.{t('enter_your_email')}</div>
              </div>
              <Button
                name={t('notifications_connected')}
                color={'black'}
                style={{ marginTop: '25px' }}
                onClick={() => {
                  changeTelegramStatus();
                  closePopUpTelegram();
                }}
              />
              <div
                onClick={() => {
                  window.open('https://t.me/+380937520309', '_blank');
                }}
                className={stylesD.support}
              >
                {t('need_help')}
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

function Channel(props) {
  const { t } = useTranslation();
  document.body.style.backgroundColor = '#F4F7FE';

  return (
    <div className={stylesD.channel_container}>
      <div className={stylesD.channel_title}>{props.title}</div>
      <div className={stylesD.cahnnel_subtitle}>{props.subtitle}</div>
      <div className={stylesD.channel_button_container}>
        {props.status ? (
          <Button
            onClick={props.onClick}
            style={{
              width: '190px',
              color: 'white',
              border: '1px solid black',
              backgroundColor: 'black',
              cursor: 'pointer',
            }}
            name={t('turn_off')}
          />
        ) : (
          <Button
            onClick={props.onClick}
            style={{
              width: '190px',
              color: 'black',
              border: '1px solid coral',
              backgroundColor: 'white',
              cursor: 'pointer',
            }}
            name={t('connect')}
          />
        )}
      </div>
    </div>
  );
}

function Notifications() {
  const { t } = useTranslation();
  let specialistId = checkAccess();

  let {
    notificationsStatus,
    changeTelegramStatus,
    changeEmailStatus,
    getNotificationsStatus,
    changePushStatus,
    changeSmsStatus,
    savePushToken,
    clientSMSNotifications,
    clientSMSNotificationsHandler,
    saveClientSMSNotificationsSettings,
  } = useNotificationsStore();

  //Android
  document.addEventListener('message', (message) => {
    if (message.data.includes('ExponentPushToken')) {
      savePushToken(message.data);
    }
  });

  //IOS
  window.addEventListener('message', (message) => {
    if (message.data.includes('ExponentPushToken')) {
      savePushToken(message.data);
    }
  });

  useEffect(() => {
    async function af() {
      getNotificationsStatus(specialistId);
    }
    af();
  }, []);

  if (window.innerWidth < 1025) {
    return (
      <>
        <MobileHeader title={t('notifications')} type={2} />
        <div className={stylesM.channels_container}>
          <PopUpTelegram />
          <Channel
            status={notificationsStatus.telegram}
            onClick={changeTelegramStatus}
            title={'Telegram'}
            subtitle={t('telegram_notifications')}
          />

          <Channel
            status={notificationsStatus.email}
            onClick={changeEmailStatus}
            title={t('email_notifications')}
            subtitle={t('email_notifications_description')}
          />

          <Channel
            status={notificationsStatus.push}
            onClick={changePushStatus}
            title={t('push_notifications')}
            subtitle={t('push_notifications_description')}
          />

          <div style={{ color: 'grey' }} className={stylesD.line_title}>
            {t('client_notifications')}
          </div>

          <div className={stylesM.line2}>
            {/* <Channel
    status={notificationsStatus.sms}
    onClick={changeSmsStatus} 
    title={'SMS нагадування '} 
    subtitle={'Інформуйте клієнтів автоматичними SMS повідомленнями. Сповіщення за 2 години до запису.'}/> */}

            <ClientNotification
              value={clientSMSNotifications?.during}
              title={t('sms_on_booking')}
              onChange={(v) => {
                clientSMSNotificationsHandler('during', v);
              }}
              onSave={saveClientSMSNotificationsSettings}
            />

            <ClientNotification
              value={clientSMSNotifications?.before}
              title={t('sms_before_booking')}
              picker
              pickerText={t('remind_client')}
              pickerValue={clientSMSNotifications?.before?.minutes}
              onChange={(v) => {
                clientSMSNotificationsHandler('before', v);
              }}
              onSave={saveClientSMSNotificationsSettings}
            />

            <ClientNotification
              value={clientSMSNotifications?.after}
              title={t('sms_after_booking')}
              picker
              pickerText={t('remind_client_after')}
              pickerValue={clientSMSNotifications?.after?.minutes}
              onChange={(v) => {
                clientSMSNotificationsHandler('after', v);
              }}
              onSave={saveClientSMSNotificationsSettings}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header platform={'desktop'} specialistId={specialistId} />

      <div className={stylesD.outer_container}>
        <div className={stylesD.container}>
          <div className={stylesD.line_title}>{t('my_notifications')}</div>
          <div className={stylesD.line1}>
            <PopUpTelegram />

            <Channel
              status={notificationsStatus.telegram}
              onClick={changeTelegramStatus}
              title={'Telegram'}
              subtitle={t('telegram_notifications')}
            />

            <Channel
              status={notificationsStatus.email}
              onClick={changeEmailStatus}
              title={t('email_notifications')}
              subtitle={t('email_notifications_description')}
            />

            <Channel
              status={notificationsStatus.push}
              onClick={changePushStatus}
              title={t('push_notifications')}
              subtitle={t('push_notifications_description')}
            />
          </div>
          <div className={stylesD.line_title}>{t('client_notifications')}</div>

          <div className={stylesD.line2}>
            {/* <Channel
    status={notificationsStatus.sms}
    onClick={changeSmsStatus} 

title={'SMS нагадування '} 
subtitle={'Інформуйте клієнтів автоматичними SMS повідомленнями. Сповіщення за 2 години до запису.'}/> */}

            <ClientNotification
              value={clientSMSNotifications?.during}
              title={t('sms_on_booking')}
              onChange={(v) => {
                clientSMSNotificationsHandler('during', v);
              }}
              onSave={saveClientSMSNotificationsSettings}
            />

            <ClientNotification
              value={clientSMSNotifications?.before}
              title={t('sms_before_booking')}
              picker
              pickerText={t('remind_client')}
              pickerValue={clientSMSNotifications?.before?.minutes}
              onChange={(v) => {
                clientSMSNotificationsHandler('before', v);
              }}
              onSave={saveClientSMSNotificationsSettings}
            />

            <ClientNotification
              value={clientSMSNotifications?.after}
              title={t('sms_after_booking')}
              pickerText={t('remind_client_after')}
              picker
              pickerValue={clientSMSNotifications?.after?.minutes}
              onChange={(v) => {
                clientSMSNotificationsHandler('after', v);
              }}
              onSave={saveClientSMSNotificationsSettings}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Notifications;
