import React from 'react';
import Header from '../Header/Header';
import styles from './Terms.module.css';

function Terms() {
  return (
    <>
      <div className={styles.container}>
        {window.innerWidth < 600 ? (
          <Header type={'mobile2'} />
        ) : (
          <Header type={'desktop2'} />
        )}

        <div className={styles.text_container}>
          <p>
            <b style={{ fontSize: '24px', color: 'coral' }}>
              Угода про користування програмою Planguin
            </b>
          </p>
          <br />
          <p>
            <span style={{ fontWeight: 400 }}>
              Програма Planguin надає свої функціональні можливості на платній
              основі. Тарифи можна знайти на сайті:{' '}
            </span>
            <a href="https://www.planguin.co">
              <span style={{ fontWeight: 400 }}>https://www.planguin.co</span>
            </a>
            <span style={{ fontWeight: 400 }}>
              . Розпочавши використовувати Planguin, ви погоджуєтеся з правилами
              викладеними в Угоді про користування програмою.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Цей документ (далі &ndash; &ldquo;Угода про користування
              програмою&rdquo;) є пропозицією команди Planguin укласти Угоду про
              використання програми Planguin&nbsp; на умовах, визначених нижче.
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
            </span>
            <b>1. Основні терміни </b>
            <b>
              <br />
              <br />
            </b>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              До цього документу та всіх відносин сторін, що виникають з цього
              документу, застосовуються такі терміни та визначення:
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.1. Угода означає угоду між командою Planguin, в особі ФОП
              Бондаренко Елла Володимирівна, і Ліцензіатом (Користувачем), яка
              виконується, в порядку і відповідно до умов цієї Угоди про
              користування.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.2. Програма &laquo;Planguin&raquo; є програмним забезпеченням
              для комп'ютера як в цілому, так і її компоненти, представлені в
              об'єктивній формі набором даних і команд, включаючи вихідний
              текст, бази даних, аудіовізуальні роботи, включені Ліцензіаром в
              Програму, а також будь-яка документація про її використання.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.3. Використання програми&nbsp; - використання функціональних
              можливостей та (або) відтворення однієї копії Програми шляхом її
              встановлення та (або) запуску Ліцензіатом у спосіб, визначений
              (технічною) документацією, Ліцензійним договором та цією Угодою.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.4. Ліцензіар - фізична особа-підприємець Бондаренко Елла
              Володимирівна, яка є власником об&rsquo;єкта права інтелектуальної
              власності, а саме - веб-сайту (далі - Програма) який розташований
              за веб-адресою&nbsp;{' '}
            </span>
            <a href="https://www.planguin.co/">
              <span style={{ fontWeight: 400 }}>https://www.planguin.co/</span>
            </a>
            <span style={{ fontWeight: 400 }}>
              , та будь-яких його компонентів, баз даних, вихідних кодів сайту,
              компіляції аудіовізуальних творів, сукупності даних та команд,
              будь-яких похідних сайтів, та вищезазначених їх частин, які
              входять до складу Програми, будь-якої документації по використанню
              Програми, та має виключне право дозволяти використання
              об&rsquo;єкту права інтелектуальної власності (Програми).
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.5. Ліцензіат (надалі - Користувач) - будь-яка фізична або
              юридична особа, яка має право користуватися Програмою Planguin в
              своїх інтересах відповідно до вимог законодавства, Ліцензійного
              договору та цієї Угоди.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.6. Ліцензійний договір&nbsp; - документ, на підставі якого
              Ліцензіар (або інша особа, що має відповідні права) надає
              Ліцензіату Програму для її використання за умовами цієї Угоди.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.7. Тип ліцензії - тарифний план, обраний Ліцензіатом зі списку,
              розміщеного Ліцензіаром на веб-сторінці:{' '}
            </span>
            <a href="https://www.planguin.co">
              <span style={{ fontWeight: 400 }}>https://www.planguin.co</span>
            </a>
            <span style={{ fontWeight: 400 }}> .&nbsp;</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.8. Обліковий запис користувача - запис у масиві даних Програми,
              який зберігає дані, що дозволяють ідентифікувати та авторизувати
              Ліцензіата як користувача Програми.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.9. Реєстрація - дія, спрямована на створення облікового запису
              користувача. При реєстрації в Planguin&nbsp; Користувач
              зобов'язаний забезпечити Ліцензіара необхідною достовірною і
              актуальною інформацією. Користувач несе відповідальність за
              точність, актуальність, повноту і відповідність інформації, що
              надається при реєстрації та її чистоту від претензій третіх осіб.
              Розділ може бути заповнений фізичною особою, фізичною
              особою-підприємцем або юридичною особою.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.10. Авторизація&nbsp; - дозвіл, наданий Ліцензіаром на доступ до
              облікового запису Користувача, який реалізується шляхом аналізу
              інформації про обліковий запис у вигляді унікальної комбінації
              імені Користувача і пароля, введеної Користувачем на сторінці
              авторизації Planguin, і перевірки, що вона відповідає інформації,
              яка міститься в обліковому записі Користувача.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.11. Особистий кабінет користувача -&nbsp; спеціалізований
              приватний розділ Planguin, який доступний Ліцензіату після
              реєстрації та авторизації. Для входу в особистий кабінет
              користувача використовується унікальне імʼя користувача та пароль,
              створений Ліцензіатом під час Реєстрації відповідно до пункту 8.4.
              цієї Угоди.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.12. Персональна сторінка користувача - спеціальний окремий
              статистичний розділ Planguin, що містить інформацію про Ліцензіата
              та його діяльність, включаючи інформацію про послуги та (або) ціни
              на послуги, що надаються Ліцензіатом, фото та відео, контактні
              дані Ліцензіата. Відбір інформації, розміщеної Ліцензіатом на
              Персональній сторінці Користувача, здійснюється Ліцензіатом
              самостійно і не підлягає попередньому узгодженню з Ліцензіаром.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.13. Клієнтська база&nbsp; - структурована інформація про осіб,
              які виявили бажання користуватися послугами, наданими Ліцензіатом,
              а також інформація про споживання послуг, що надаються
              Ліцензіатом, які були внесені в Planguin.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.14. Тарифікація - розділ особистого кабінету користувача, що
              містить інформацію про термін дії ліцензії, тарифи та обсяг
              послуг.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.15. Онлайн-запис - це процес, в якому за допомогою спеціального
              інтерфейсу Planguin, розміщеного на сайті або інших
              онлайн-ресурсах Ліцензіата, будь-яка особа, яка висловлює бажання
              використовувати надані Ліцензіатом послуги, робить заявку про
              намір використовувати ці послуги. Результатом онлайн-запису є
              передача Ліцензіату інформації про час, дату, послугу і контактні
              дані, введені в спеціальний інтерфейс Planguin, розміщеному на
              сайті або інших онлайн-ресурсах Ліцензіата, особі, яка висловила
              бажання користуватися послугами, запропонованими Ліцензіатом.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.16. Програма лояльності&nbsp; - набір знижок, а також бонусів та
              інших привілеїв, наданих Ліцензіаром Ліцензіату, інформація про
              які розміщена Ліцензіаром в мережі Інтернет на сторінці:{' '}
            </span>
            <a href="https://www.planguin.co">
              <span style={{ fontWeight: 400 }}>https://www.planguin.co</span>
            </a>
            <span style={{ fontWeight: 400 }}>&nbsp; .</span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>
          <ul>
            <li aria-level="1">
              <b>2. Загальні правила </b>
              <b>
                <br />
                <br />
              </b>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.1. Ця Угода про користування програмою (далі &ndash; Угода)
              визначає загальні умови та умови використання програмного
              забезпечення Planguin на Інтернет-сайті за адресою:{' '}
            </span>
            <a href="https://www.planguin.co">
              <span style={{ fontWeight: 400 }}>https://www.planguin.co</span>
            </a>
            <span style={{ fontWeight: 400 }}>
              {' '}
              (далі &ndash; Planguin), а також права та обов&rsquo;язки
              Ліцензіата (далі - Користувач) та Ліцензіара. Ця угода також
              стосується прав та інтересів третіх осіб, окрім Користувачів
              Planguin, але на права та інтереси яких можуть впливати дії
              Користувачів Planguin.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.2. Ця угода є домовленістю між Користувачем і Ліцензіаром щодо
              використання Planguin&nbsp; та його послуг і заміняє всі попередні
              угоди, які мали місце або можуть мати місце між Користувачем і
              Ліцензіаром незалежно від форми, де були досягнуті такі
              домовленості.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.3. Planguin надає свої функціональні можливості на платній
              основі. Поточні тарифи можна знайти на сайті:{' '}
            </span>
            <a href="https://www.planguin.co">
              <span style={{ fontWeight: 400 }}>https://www.planguin.co</span>
            </a>
            <span style={{ fontWeight: 400 }}> .&nbsp;</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Використання Planguin на вільній основі допускається у випадках,
              визначених Ліцензіаром. Ліцензіар має право вводити і (або)
              накладати будь-які обмеження на функціональні можливості Planguin,
              що використовується безкоштовно.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.4. Ця угода є юридично обов&rsquo;язковою угодою між
              Користувачем і Ліцензіаром, предметом якої є надання Ліцензіаром
              Користувачу права використання на умовах простих (невиключних)
              ліцензій Planguin&nbsp; відповідно до його функціональних
              можливостей, в тій мірі, що визначається Ліцензіаром, а також
              надання інших пов'язаних послуг та інших послуг (далі - послуги).
              Крім цієї Угоди, Угода між Користувачем і Ліцензіаром включає всі
              інші обов&rsquo;язкові документи, що регламентують надання послуг,
              і розміщені у відповідних розділах Planguin.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.5. Користувач повинен бути повністю ознайомлений з цією Угодою,
              перш ніж зареєструватися в Planguin. Реєстрація Користувача в
              Planguin&nbsp; означає повне і безумовне прийняття Користувачем
              умов Угоди.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.6. Ця Угода може бути змінена і (або) доповнена Ліцензіаром в
              односторонньому порядку без будь-якого спеціального повідомлення
              Користувача. Ця Угода є публічним документом.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.7. Користувач регулярно перевіряє умови цієї Угоди на їх зміну
              та (або) доповнення. Продовження використання Planguin
              Користувачем після внесення змін та (або) доповнень до цієї Угоди
              означає прийняття та згоду Користувача з усіма такими змінами та
              (або) доповненнями.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.8. Жодні положення цієї Угоди не надають Користувачу права на
              використання назви бренду, торгових марок, доменних імен та
              інтелектуальної власності, що використовуються Planguin, за
              винятком випадків, коли таке використання дозволено з письмовою та
              попередньою згодою Ліцензіара.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.9. Під час реєстрації Користувач погоджується з цією Угодою і
              бере на себе зазначені в ній права та обов'язки, пов'язані з
              використанням та експлуатацією Planguin.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.10. Приймаючи цю Угоду, Користувач підтверджує свою згоду на
              обробку Planguin&nbsp; персональних даних Користувача, наданих
              Користувачем на момент реєстрації, а також добровільно розміщених
              Користувачем в Planguin.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.11. Користувач має право укласти з Ліцензіаром окремий
              Ліцензійний договір на використання Planguin без обмежень,
              встановлених для використання Planguin у безкоштовній версії.{' '}
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>

          <li>
            <b>3. Предмет угоди</b>
          </li>

          <p></p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.1. Ліцензіар надає Ліцензіату право користування (простою
              (невиключною) ліцензією) Програмою в межах її функціональних
              можливостей шляхом відтворення Програми (підключення до Програми
              через мережу Інтернет) виключно для власного використання
              Ліцензіатом без права субліцензування третім особам.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.2. Ця Угода укладається до або одразу після початку використання
              Програми і залишається чинною на весь період її законного
              використання Ліцензіатом у терміни дії авторського права на неї,
              за умови дотримання Ліцензіатом цієї Угоди.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.3. Ця Угода і передбачені в ній обов'язкові документи визначають
              основні умови використання програми.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.3.1. Обов'язковою умовою використання програми включно з усіма
              послугами є повне та безумовне прийняття Ліцензіатом умов
              наступних документів (далі - &laquo;обов'язкові документи&raquo;):
            </span>
          </p>
          <ul>
            <li style={{ fontWeight: 400 }} aria-level="1">
              <span style={{ fontWeight: 400 }}>
                Політика конфіденційності, яка розміщена та доступна в мережі
                Інтернет за адресою: https://www.planguin.co/privacy-policy та
                встановлює правила обробки персональної інформації, включаючи
                персональні дані Користувача.
              </span>
            </li>
            <li style={{ fontWeight: 400 }} aria-level="1">
              <span style={{ fontWeight: 400 }}>
                Публічна пропозиція укласти Ліцензійний договір, розміщена
                та/або доступна в мережі Інтернет на сайті:
                https://www.planguin.co/license.
              </span>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.3.2. Ця Угода, включаючи її обов'язкові документи, можуть бути
              змінені Planguin&nbsp; без будь-якого спеціального сповіщення
              Ліцензіата. Нова версія Угоди та/або обов&rsquo;язкових
              документів, зазначених в ній, набудуть чинності з моменту їх
              розміщення на веб-сайті або доведення до Користувача в іншій
              зручній формі, якщо інше не встановлено новою версією Угоди та/або
              обов&rsquo;язковими документами, передбаченими у них.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.3.3. Ліцензіат визнає наступне:
            </span>
          </p>
          <ul>
            <li style={{ fontWeight: 400 }} aria-level="1">
              <span style={{ fontWeight: 400 }}>
                Ви переглянули умови цієї Угоди і обов'язкових документів,
                передбачених ній, в повному обсязі перед використанням Програми.
              </span>
            </li>
            <li style={{ fontWeight: 400 }} aria-level="1">
              <span style={{ fontWeight: 400 }}>
                Ви приймаєте умови Угоди і всіх обов'язкових документів,
                передбачених в ній, в повному обсязі без будь-яких винятків і
                обмежень з вашого боку і зобов'язані дотримуватися їх або
                припинити використання Програми. Якщо ви не погоджуєтеся з цією
                Угодою і обов'язковими документами, передбаченим у ній, або не
                маєте права на їх підставі укладати Угоду, вам слід негайно
                припинити використання програми.
              </span>
            </li>
            <li style={{ fontWeight: 400 }} aria-level="1">
              <span style={{ fontWeight: 400 }}>
                Поточна версія цієї Угоди опублікована за адресою:{' '}
              </span>
              <a href="https://www.planguin.co/license">
                <span style={{ fontWeight: 400 }}>
                  https://www.planguin.co/license
                </span>
              </a>
              <span style={{ fontWeight: 400 }}>.&nbsp;</span>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.4. Ліцензіар надає Ліцензіату право користуватися Програмою без
              обмеження на території в порядку та в умовах, передбачених
              законодавством, Ліцензійним договором та цією Угодою.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.5. Розмір, порядок та умови виплати Ліцензіатом ліцензійного
              платежу за користування Програмою встановлюються Ліцензійним
              договором.
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>

          <li>
            <b>4. Авторські права та товарні знаки </b>
            <b>
              <br />
              <br />
            </b>
          </li>

          <p>
            <span style={{ fontWeight: 400 }}>
              4.1. Програма є результатом інтелектуальної діяльності та об'єктом
              авторського права, які регулюються та охороняються законодавством
              про інтелектуальну власність та нормами міжнародного права.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.2. Алгоритми роботи Програми та її вихідні коди (включаючи їх
              частини) є комерційною таємницею Ліцензіара. Будь-яке їх
              використання або використання програмного забезпечення з
              порушенням умов цієї Угоди та (або) Ліцензійного договору
              розглядається як порушення прав Ліцензіара і має бути достатньою
              підставою для позбавлення Користувача прав, наданих за цією
              Угодою.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.3. Ця Угода не надає Ліцензіату права власності на Програму та
              її складові, а лише право на використання Програми та її складових
              відповідно до умов, визначених цією Угодою та Ліцензійним
              договором.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.4. Ліцензіар гарантує, що він має всі необхідні права на
              Програму для надання її Ліцензіату, включаючи документацію на
              Програму.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.5. Ця Угода не надає Ліцензіату жодних прав на використання
              товарних знаків та знаків обслуговування Ліцензіара та (або) його
              партнерів.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.6. За жодних обставин Ліцензіат не може видаляти або вносити
              незначні відомості про авторські права, права на товарний знак,
              знаки обслуговування, патенти, вказані у Програмі.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.7. Ліцензіат не може копіювати, розповсюджувати Програму та її
              компоненти у будь-якій формі, у тому числі у вигляді вихідного
              коду, у тому числі надавати в оренду, передавати для безоплатного
              користування.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.8. Ліцензіат не може жодним чином використовувати Програму, якщо
              це суперечить або призводить до порушення цієї Угоди, Ліцензійного
              договору або вимог національного та міжнародного законодавства.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.9. Відповідальність за порушення авторських прав Ліцензіат несе
              у відповідності до національного та міжнародного законодавства.
            </span>
          </p>

          <li>
            <b>5. Умови використання програми та обмеження </b>
            <b>
              <br />
              <br />
            </b>
          </li>

          <p>
            <span style={{ fontWeight: 400 }}>
              5.1. Ліцензіат має право Реєстрації в рамках цієї Угоди.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.2. Ліцензіар не надає послуг зв'язку Ліцензіату, не організовує
              для нього можливості доступу до інформаційних систем
              інформаційно-телекомунікаційних мереж, у тому числі мережі
              Інтернет, і не здійснює діяльності з прийому, обробки, зберігання,
              передачі, доставки телекомунікаційних повідомлень.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.3. Ліцензіат має право в рамках цієї Угоди, Ліцензійного
              договору та відповідно до обраного типу ліцензії розмістити в
              Програмі належні йому дані, якщо це не порушує цю Угоду,
              Ліцензійний договір, національне та (або) міжнародне
              законодавство.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.4. Ліцензіат може змінювати, додавати або видаляти файли
              Програми лише у випадках, передбачених законодавством про
              авторське право.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.5. Ліцензіат не може жодним чином використовувати Програму, якщо
              таке використання суперечить або призводить до порушення
              законодавства.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.6. У разі невиконання будь-якого з положень цієї Угоди,
              Ліцензіат втрачає право на оновлення програмного забезпечення без
              будь-якого повідомлення від Ліцензіара.
            </span>
          </p>

          <li>
            <b>6. Уступка (Передача) прав на програму </b>
            <b>
              <br />
              <br />
            </b>
          </li>

          <p>
            <span style={{ fontWeight: 400 }}>
              6.1. Ліцензіат має право уступити (передавати) свої права та
              обов'язки повністю за цією Угодою іншій особі лише один раз за
              попередньою письмовою згодою Ліцензіара. Дане право на уступку
              (передачу) не надається тим Ліцензіатам, які отримали право
              використовувати Програму в результаті аналогічної уступки
              (передачі).
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              6.2. Уступка (передача) прав за цією Угодою не може бути здійснена
              опосередковано або через третю особу.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              6.3. Уступка (передача) прав та обов&rsquo;язків здійснюється лише
              за повної та безумовної згоди нового Ліцензіата з усіма умовами
              цієї Угоди та Ліцензійного договору.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              6.4. Ліцензіат зобов&rsquo;язаний надати Ліцензіару повну
              інформацію про нового Ліцензіата для перереєстрації Програми
              відповідно до цієї Угоди.
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>

          <li>
            <b>7. Типи ліцензій </b>
            <b>
              <br />
              <br />
            </b>
          </li>

          <p>
            <span style={{ fontWeight: 400 }}>
              7.1. Ліцензіат має право використовувати Програму в рамках
              ліцензії для ознайомлення (пробної версії) протягом обмеженого
              періоду часу, визначеного умовами чинними на момент реєстрації,
              встановленими Ліцензіаром та розміщеними в мережі Інтернет на
              веб-сайті:{' '}
            </span>
            <a href="https://www.planguin.co">
              <span style={{ fontWeight: 400 }}>https://www.planguin.co</span>
            </a>
            <span style={{ fontWeight: 400 }}> .&nbsp;</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              7.2. Ліцензіат має право вибрати відповідний тип ліцензії зі
              списку.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              7.3. Тип ліцензії, яку обирає Ліцензіат, визначається Ліцензійним
              договором.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              7.4. Термін дії ліцензії, визначений відповідно до типу ліцензії,
              починає діяти з наступного дня після прийняття Ліцензійного
              договору.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              7.5. Ліцензіат має право у будь-який час змінити тип ліцензії
              протягом строку дії ліцензії, визначений Ліцензійним
              договором.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              7.6. Перерахування вартості та відшкодування ліцензійного платежу,
              сплаченого згідно з Ліцензійним договором, при купівлі поточної
              ліцензії не здійснюється.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              7.7. Після закінчення терміну дії ліцензії Ліцензіат може обмежити
              подальше використання Програми Ліцензіатом, а обліковий запис
              Користувача може бути заблокований.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              7.8. Ліцензіар має право в односторонньому порядку змінювати типи
              ліцензій шляхом розміщення нового їх списку на Інтернет-сторінці:{' '}
            </span>
            <a href="https://www.planguin.co">
              <span style={{ fontWeight: 400 }}>https://www.planguin.co</span>
            </a>
            <span style={{ fontWeight: 400 }}>
              . Типи ліцензій застосовуються в Ліцензійних договорах,
              підписаних, починаючи з дати публікації відповідної
              (модифікованої) інформації про типи ліцензій на вищевказаній
              сторінці в мережі Інтернет. Модифікація типів ліцензій у вказаному
              порядку не є підставою для внесення змін до Ліцензійних договорів,
              укладених до дати публікації Ліцензіаром інформації про зміну
              видів ліцензії.
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>

          <li>
            <b>8. Використання програми </b>
            <b>
              <br />
              <br />
            </b>
          </li>

          <p>
            <span style={{ fontWeight: 400 }}>
              8.1. Загальні умови використання Програми:
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.1.1. Перегляд матеріалів, розміщених на веб-сайті в публічному
              домені, не вимагає реєстрації та/або авторизації Користувача.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.1.2. Будь-яке інше використання функціональних можливостей
              Програми, включаючи надання послуг, дозволяється тільки після
              реєстрації та авторизації Користувача в Програмі відповідно до
              правил, визначених Planguin.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.1.3. Діапазон функціональних можливостей Програми, використання
              якої вимагає попередньої реєстрації та/або авторизації, а також
              виконання окремої угоди та виплати ліцензійного платежу,
              визначається на розсуд Planguin і може час від часу змінюватися з
              попереднім інформуванням Користовача.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>8.2. Реєстрація.</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.2.1. Для того, щоб скористатися Програмою Ліцензіату, необхідно
              виконати процедуру реєстрації, яка створить унікальний обліковий
              запис Користувача для Ліцензіата.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.2.2. Реєстрація здійснюється Ліцензіатом самостійно шляхом
              заповнення реєстраційної форми на сайті&nbsp;{' '}
            </span>
            <a href="https://www.planguin.co/signup">
              <span style={{ fontWeight: 400 }}>
                https://www.planguin.co/signup
              </span>
            </a>
            <span style={{ fontWeight: 400 }}>.&nbsp;</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.2.3. При реєстрації в Planguin Користувач зобов'язаний надати
              Ліцензіару необхідну достовірну та актуальну інформацію.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.2.4. Користувач не має права здійснювати Реєстрацію від імені
              або замість іншої особи, за винятком випадку, коли Користувач має
              необхідні повноваження, надані у порядку, встановленому
              законодавством, щодо надання йому можливості здійснювати
              Реєстрацію від імені третьої особи.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.2.5. Користувач несе відповідальність за точність, актуальність,
              повноту та відповідність законодавству інформації наданої при
              реєстрації та її чистоту від претензій третіх осіб. Якщо
              Користувач надає неправильну інформацію, або якщо Ліцензіар має
              підстави вважати, що інформація, надана Користувачем, є неповною
              або ненадійною, то Ліцензіар має право блокувати або видалити
              обліковий запис Користувача, створений Ліцензіатом, і заборонити
              Ліцензіату використовувати Програму.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.2.6. Planguin&nbsp; залишає за собою право вимагати в будь-який
              час перевірки інформації Користувача, що надається при реєстрації,
              та запиту на надання для перевірки документів (зокрема документів
              особистої ідентифікації); ненадання цих документів може, на розсуд
              інших користувачів, розглядатися як надання недійсної інформації
              та тягнути за собою наслідки передбачені цією Угодою. У випадку,
              якщо інформація про Користувача, вказана в наданих ним документах,
              не відповідає інформації, що надається ним при реєстрації, або
              якщо інформація, надана при реєстрації, не дає можливості
              ідентифікувати Користувача, Planguin залишає за собою право
              відмовити в доступі Користувача до персонального облікового запису
              Користувача та у використанні Програми.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.2.7. Інформація про Користувача, що міститься в особистому
              кабінеті Користувача, зберігається і обробляється Planguin
              відповідно до політики конфіденційності{' '}
            </span>
            <a href="https://www.planguin.co/privacy-policy">
              <span style={{ fontWeight: 400 }}>
                https://www.planguin.co/privacy-policy
              </span>
            </a>
            <span style={{ fontWeight: 400 }}>.&nbsp;</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.2.9. Користувач самостійно визначає процедуру використання
              облікового запису та інші функціональні можливості Програми,
              включаючи послуги, які, однак, ні за яких обставин не можуть
              суперечити цій Угоді.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.2.10. Після реєстрації Користувач має право користуватися
              Програмою та користуватися послугами на умовах простої
              (невиключної) ліцензії в тій мірі, в якій визначається Ліцензіаром
              та Ліцензійним договором.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.3. З міркувань безпеки Користувач зобов'язаний здійснити
              безпечне завершення роботи під своїм обліковим записом Користувача
              (кнопка &laquo;вихід&raquo;) в кінці кожного сеансу роботи з
              Програмою. Ліцензіар не несе відповідальності за можливу втрату
              даних, а також будь-які інші наслідки, які можуть виникнути через
              порушення Користувачем положень цієї частини цієї Угоди.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.4. Користувач повністю відповідає за свої дії, пов'язані з
              використанням Planguin&nbsp; і послуг, відповідно до
              законодавства. Якщо Ліцензіар виявить, що Користувач порушив цю
              Угоду, національне чи міжнародне законодавство, що регулює
              використання Програми, Ліцензійний договір, то Ліцензіар має право
              блокувати доступ Користувача до Planguin.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.5. Під час користування Програмою та послугами Користувач
              повинен:
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.5.1. Дотримуватися положень законодавства, цієї Угоди,
              Ліцензійного договору та інших обов&rsquo;язкових документів,
              розміщених Ліцензіаром в Planguin.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.5.2. Використовувати Програми, послуги тільки з законною метою
              та законною діяльністю, з урахуванням вимог чинного законодавства.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.5.3. Не розміщувати в Planguin інформацію та об'єкти (включаючи
              посилання на них), які можуть порушувати права та інтереси інших
              осіб.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.5.4. Зберігати конфіденційність і не ділитися з іншими
              Користувачами або третіми особами будь-якими особистими даними,
              які їм розголошуються в результаті спілкування з іншими
              Користувачами або іншого використання Planguin&nbsp; (в тому
              числі, але не обмежуючись, адресами в адресній системі, номерами
              телефонів, адресами електронної пошти, даними паспортів,
              банківською інформацією тощо) та інформацію про конфіденційність
              інших Користувачів та третіх осіб без попереднього письмового
              дозволу такого Користувача.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.5.5. Використовуючи Онлайн-запис, розміщувати на сайті або інших
              онлайн-ресурсах, які містять спеціальний інтерфейс Planguin, за
              допомогою якого здійснюється Онлайн-запис, інформацію про правила
              використання цієї служби, включаючи інформацію про процедуру,
              методи і цілі для обробки, використання, передачі, зберігання та
              забезпечення збереження особистих даних третіх осіб, отриманих
              через Онлайн-запис. Ліцензіат відповідає за підготовку та
              розміщення цих правил.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.5.6. При використанні Онлайн-запису забезпечувати рівень захисту
              персональних даних третіх осіб, як це визначено законодавством,
              які отримує Користувач в результаті Онлайн-запису. Не допускати
              використання цих персональних даних в порушення норм, встановлених
              законодавством.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.5.7. Переконатися, що адреса електронної пошти та (або) номер
              телефону, який використовується як ім'я Користувача при
              авторизації, є актуальним.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6. Під час користування Програмою та послугами Користувачу
              забороняється:
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.1. Завантажувати, зберігати, публікувати, розповсюджувати та
              надавати доступ або в іншому випадку використовувати будь-яку
              інформацію, яка містить погрози, дискредитації, образи, порушення
              честі та гідності або ділової репутації, або порушує приватність
              інших Користувачів або третіх осіб.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.2. Скачати, зберігати, публікувати, поширювати і надавати
              доступ або в іншому випадку використовувати будь-яку інформацію,
              що порушує права неповнолітніх.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.3. Завантажувати, зберігати, публікувати, розповсюджувати та
              надавати доступ або в іншому випадку використовувати будь-яку
              інформацію, вульгарного чи непристойного характеру, містить
              нецензурну лексику, містить порнографічні зображення та тексти,
              містить сексуальні сцени за участю неповнолітніх.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.4. Завантажувати, зберігати, публікувати, розповсюджувати та
              надавати доступ або в іншому випадку використовувати будь-яку
              інформацію, яка містить сцени насильства, включаючи нелюдські
              поводження з тваринами.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.5. Завантажувати, зберігати, публікувати, розповсюджувати та
              надавати доступ або в іншому випадку використовувати будь-яку
              інформацію, яка містить опис засобів та методів самогубства,
              будь-яке підбурювання до самогубства.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.6. Завантажувати, зберігати, публікувати, розповсюджувати та
              надавати доступ або в іншому випадку використовувати будь-яку
              інформацію, яка містить пропаганду та (або) сприяє расовій,
              релігійній, етнічній ненависті або ворожнечі, пропагує фашизм або
              ідеологію расової переваги.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.7. Завантажувати, зберігати, публікувати, розповсюджувати та
              надавати доступ або в іншому випадку використовувати будь-яку
              інформацію, яка містить екстремістські матеріали.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.8. Завантажувати, зберігати, публікувати, розповсюджувати та
              надавати доступ або в іншому випадку використовувати будь-яку
              інформацію, яка сприяє злочинній діяльності або містить поради,
              інструкції або вказівки щодо вчинення злочинних дій.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.9. Завантажувати, зберігати, публікувати, розповсюджувати та
              надавати доступ або в іншому випадку використовувати будь-яку
              інформацію, яка містить інформацію з обмеженим доступом,
              включаючи, але не обмежуючись, державну і комерційну таємниці,
              інформацію про приватне життя третіх осіб.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.10. Завантажувати, зберігати, публікувати, розповсюджувати та
              надавати доступ або в іншому випадку використовувати будь-яку
              інформацію, яка містить рекламу або описує привабливість вживання
              наркотиків, включаючи звукові файли, інформацію про розповсюдження
              таких речовин (включаючи звукові файли), рецепти їх виготовлення
              та поради щодо споживання.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.11. Завантажувати, зберігати, публікувати, розповсюджувати та
              надавати доступ або в іншому випадку використовувати будь-яку
              інформацію, що має характер шахрайських дій.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.12. Завантажувати, зберігати, публікувати, розповсюджувати та
              надавати доступ або в іншому випадку використовувати будь-яку
              інформацію, яка порушує інші права та інтереси громадян і
              юридичних осіб або вимоги законодавства.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.13. Незаконно завантажувати, зберігати, публікувати,
              розповсюджувати та надавати доступ до інтелектуальної власності
              Ліцензіара, Користувачів, третіх осіб.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.14. Розміщення будь-якої іншої інформації, яку Ліцензіар
              вважає небажаною, яка не відповідає цілям використання Planguin,
              упередженою до інтересів інших Користувачів, або іншим чином є
              небажаною для розміщення в Planguin.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.15. Використовувати програмне забезпечення та виконувати дії,
              спрямовані на припинення нормального функціонування Planguin, його
              служб, послуг.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.16. Завантажувати, зберігати, публікувати, розповсюджувати та
              надавати доступ або в іншому випадку використовувати віруси,
              трояни та інші шкідливі програми.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.17. Використовувати, без спеціального письмового та
              попереднього дозволу Ліцензіара, автоматизовані скрипти (програми)
              для збору інформації в Planguin&nbsp; та (або) для взаємодії з
              Planguin, його послугами, службами.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.18. У будь-якому випадку, включаючи, але не обмежуючись,
              обманом, зловживанням довірою, зломом, спробами отримати доступ до
              імені Користувача і пароля іншого облікового запису.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.19. Збирати та обробляти персональні дані інших осіб незаконно
              (у тому числі з порушенням правил, встановлених законодавством).
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.20. Доступ (у тому числі спроба доступу) до будь-якої іншої
              послуги, ніж через Planguin&nbsp; (інтерфейс, що надається
              Ліцензіаром), за виключенням випадків, коли такі дії були прямо і
              попередньо дозволені Користувачу відповідно до Ліцензійного
              договору або іншою окремою угодою з Ліцензіаром.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.21. Відтворення, дублювання, копіювання, продаж, торгівля та
              перепродаж послуг з будь-якою метою, за винятком випадків, коли
              такі дії були прямо та попередньо погоджені Користувачем
              відповідно до Ліцензійного договору або іншою окремою угодою з
              Ліцензіаром.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.6.22. Використовувати Програму Planguin, послуги для здійснення
              масової розсилки електронних та інших повідомлень комерційного,
              рекламного та іншого характеру, не узгоджених (не запитаних)
              одержувачем інформації. Ця заборона не поширюється на надсилання
              інформації зі згоди одержувача, якщо можливо відмовитися від
              отримання такої інформації в майбутньому.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.7. Користувач несе відповідальність за будь-яку інформацію, яку
              він розміщує в Програмі, повідомляє іншим Користувачам і третім
              особам, а також за будь-яку взаємодію з іншими Користувачами і
              третіми особами.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.8. Користувач несе відповідальність за власні дії у зв'язку зі
              створенням та розміщенням інформації в Planguin, відповідно до
              законодавства.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.9 Ліцензіар не несе відповідальності за порушення Користувачем
              цієї Угоди і залишає за собою право на власний розсуд, а також при
              отриманні від інших Користувачів або третіх осіб інформації про
              порушення Користувачем цієї Угоди, змінювати (модерувати), та
              (або) видаляти будь-яку інформацію, опубліковану Користувачем, що
              порушує заборони, встановлені цією Угодою, призупинити, обмежити
              або припинити доступ Користувача до всіх або будь-яких розділів
              або служб у Planguin&nbsp; у будь-який час з будь-яких причин та
              без пояснення причин, з або без попереднього повідомлення, не несе
              відповідальності за будь-яку шкоду, яка може бути заподіяна
              Користувачу такими діями.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.10. Ліцензіар має право без пояснення причин призупинити,
              обмежити або припинити доступ Користувача до Planguin&nbsp; або
              будь-якої служби. Ліцензіар не несе відповідальності за тимчасове
              блокування або видалення інформації або видалення облікового
              запису Користувача відповідно до цієї Угоди.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.11. Видалення облікового запису Користувача.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.11.1. Ліцензіар має право блокувати та вилучати обліковий запис
              Користувача Ліцензіата, включаючи весь контент, без пояснення
              причин, у разі порушення умов цієї Угоди. З цього моменту
              неможливо відновити обліковий запис, будь-яку інформацію,
              пов'язану з ним, або отримати доступ до Програми за допомогою
              віддаленого облікового запису.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.11.2. У разі незгоди Користувача з цією Угодою або її поновлення
              відповідно до порядку, передбаченого цією Угодою, Користувач
              зобов'язаний відмовитися від використання Програми, повідомивши
              про це Ліцензіара будь-якими доступними засобами. Після отримання
              зазначеного повідомлення, Ліцензіар має право заблокувати і (або)
              видалити обліковий запис Користувача Ліцензіата, включаючи весь
              опублікований ним контент. З цього моменту неможливо відновити
              обліковий запис, будь-яку інформацію, пов'язану з ним, або
              отримати доступ до Програми за допомогою віддаленого облікового
              запису.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.12. Ліцензіар залишає за собою право в будь-який час змінювати
              дизайн Planguin, його зміст, список послуг і служб, змінювати або
              доповнювати скрипти, програмне забезпечення та інші об'єкти, які
              використовуються або зберігаються в Planguin, будь-які серверні
              Програми, з або без попереднього сповіщення Користувача.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.13. Ліцензіар має право надсилати Користувачу інформацію про
              розвиток Planguin та його послуг, служб, а також рекламувати
              власну діяльність та послуги.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.14. Ліцензіар забезпечує конфіденційність та безпеку
              персональних даних при їх обробці Користувачем за допомогою
              Planguin шляхом:
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.14.1. Надання доступу до Planguin через HTTPS або аналог, що
              забезпечує аналогічний рівень безпеки.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.14.2. Реєстрації фактів авторизації Користувача та вихід з
              облікового запису з Planguin.
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>

          <li>
            <b>9. Обмеження на використання програми</b>
            <b>
              <br />
              <br />
            </b>
          </li>

          <p>
            <span style={{ fontWeight: 400 }}>
              9.1. Користувач не має права виконувати дії, які можуть
              спричинити:
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              9.1.1. Несправність обладнання та мережі Ліцензіара.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              9.1.2. Переривання роботи Програми або обмеження можливості інших
              Користувачів користуватися Програмою.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              9.1.3. Несанкціонований доступ до Програми, а також комп'ютерні та
              мережеві ресурси Ліцензіара.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              9.1.4. Завдавання або загрозу заподіяння шкоди третім сторонам, у
              тому числі шляхом розміщення інформації та посилань на мережеві
              ресурси, зміст яких суперечить законодавству.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              9.2. Користувач не має права змінювати Програму Planguin, її
              дизайн та зовнішній вигляд.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              9.3. Ліцензіат самостійно забезпечує наявність обладнання, яке
              відповідає технічним вимогам щодо використання Програми та доступу
              до мережі Інтернет.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              9.4. Користувач гарантує, що має всі необхідні права над усіма
              даними, комп'ютерними Програмами або послугами, які
              використовуються ним у зв'язку з використанням Програми, і що такі
              дії не порушують прав третіх осіб.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              9.5. Користувач не може використовувати Програму будь-якими
              способами, крім зазначених у цій Угоді, він не може копіювати,
              продавати, перепродавати або отримувати доступ до неї, якщо
              Користувач не отримав попереднього письмового дозволу Ліцензіара.
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>

          <li>
            <b>10. Обмежена гарантія та відповідальність </b>
            <b>
              <br />
              <br />
            </b>
          </li>

          <p>
            <span style={{ fontWeight: 400 }}>
              10.1. Ліцензіар не гарантує, що вся функціональність Програми буде
              відповідати очікуванням Ліцензіата (Користувачів). Ліцензіар також
              відмовляється від будь-якої гарантії, що програма Planguin або
              послуги можуть бути або не можуть бути відповідними для конкретних
              цілей Користувача. Ліцензіар не може і не гарантує ніяких
              конкретних результатів від використання програми Planguin або
              послуг.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              10.2. Ліцензіар не ініціює або не контролює розміщення будь-якої
              інформації Користувачем під час використання Програми Planguin,
              служб і послуг, не впливає на її зміст і цілісність, а також на
              час розміщення зазначених відомостей не знає і не може знати, чи
              порушує вона права та інтереси третіх осіб, що охороняються
              законом, міжнародними договорами та національним законодавством.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              10.3. Ліцензіат безпосередньо відповідає за зміст даних, створених
              і підтримуваних ним. Ліцензіар не здійснює попередній контроль за
              вмістом розміщеної та (або) інформації, що розповсюджується
              Ліцензіатом, але якщо розміщення і поширення такої інформації
              суперечить законодавству, то Ліцензіар має право блокувати або
              видаляти відповідний обліковий запис і дані без попереднього
              повідомлення.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              10.4. Ліцензіар не несе відповідальності перед Користувачем або
              будь-якою третьою стороною за будь-які непрямі, випадкові,
              ненавмисні збитки, включаючи втрату прибутку або даних, порушення
              честі, гідності або діловій репутації, що виникають внаслідок
              використання Програми Planguin, послуг або інші матеріали,
              доступні Користувачу або іншим особам за допомогою Planguin,
              навіть якщо Користувач попереджав або вказував на можливість такої
              шкоди.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              10.5. Ліцензіар не несе відповідальності перед Користувачем за
              будь-яку шкоду, будь-яку втрату доходу, прибутку, інформації чи
              заощаджень, пов'язану з використанням або неможливістю
              використання Програми Planguin або послуг, у тому числі у разі
              попереднього повідомлення Користувачем про можливість такої шкоди,
              або за будь-яким позовом від третьої особи.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              10.6. Ліцензіар забезпечує роботу і працездатність Програми
              Planguin і бере на себе зобов'язання оперативно відновити свою
              працездатність у разі технічних збоїв і розривів. Ліцензіар не
              несе відповідальності за тимчасові несправності та переривання
              Програми Planguin та отриману втрату інформації у зв&rsquo;язку з
              цим.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              10.7. Ліцензіар не несе відповідальності за будь-яку шкоду
              комп&rsquo;ютеру Користувача або іншій особі, мобільним пристроям,
              будь-якому іншому обладнанню або програмному забезпеченню,
              викликаному або пов'язаному з скачуванням та (або) завантаженням
              матеріалів з Planguin або за допомогою посилань, розміщених у
              Planguin.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              10.8. Якщо під час використання Програми буде виявлено помилки, то
              Ліцензіар зробить кроки, щоб виправити їх якомога швидше. Точний
              час усунення конкретних помилок може бути визначений Ліцензіаром з
              урахуванням того, що Програма тісно співпрацює з іншими програмами
              стороннього програмного забезпечення, операційні системи та
              апаратні ресурси комп'ютерів Ліцензіата і продуктивність Програми,
              і час усунення несправностей не залежать виключно від Ліцензіара.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              10.9. У випадку, якщо Ліцензіат виконує дії, заборонені
              положеннями цієї Угоди, Ліцензіар має право, не пояснюючи причини
              та без будь-якого попереднього повідомлення Ліцензіата, вживати
              заходів для виявлення та запобігання цим порушенням.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              10.10. За порушення умов цієї Угоди Ліцензіат несе
              відповідальність, передбачену законодавством.
            </span>
          </p>

          <li>
            <b>11. Умови обробки та використання даних </b>
            <b>
              <br />
              <br />
            </b>
          </li>

          <p>
            <span style={{ fontWeight: 400 }}>
              11.1. Приймаючи умови цієї Угоди, Користувач відповідно до Закону
              України &laquo;Про захист персональних даних&raquo;, діючи вільно,
              за його бажанням і в інтересах, висловлює свою згоду на надання
              своїх персональних даних, включаючи: прізвище, ім'я, по батькові,
              адресу електронної пошти, контактний номер телефону, дату
              народження, регіон, місто, організацію, посаду для їх обробки
              Ліцензіаром.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              11.2. Цілі обробки персональних даних Ліцензіаром:
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              11.2.1. Надання Користувачу права користування Програмою Planguin,
              послугами.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              11.2.2. Надсилання сповіщень Користувачу щодо використання
              Програми Planguin, послуг.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              11.2.3. Підготовка та відправлення відповідей Ліцензіаром на
              запити Користувача, у тому числі, але не обмежуючись, зверненням
              до служби підтримки.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              11.2.4. Направлення інформації про діяльність, яку здійснюють
              Ліцензіар.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              11.2.5. Направлення інформації про продукти та послуги Ліцензіара.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              11.3. Перелік дій з персональними даними, на які погоджується
              Користувач: збір, систематизація, накопичення, зберігання,
              уточнення (оновлення, модифікація), використання, анонімізація,
              передача третім особам для цілей, зазначених в цій Угоді, а також
              будь-які інші дії, передбачені законодавством, як
              неавтоматизовані, так і автоматизовані.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              11.4. Ліцензіар бере на себе зобов'язання вжити всіх необхідних
              заходів, передбачених законодавством для захисту персональних
              даних Користувача від незаконного доступу або розголошення.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              11.5. Згода, зазначена в пунктах 11.1-11.3 цієї Угоди, діє до
              моменту її зняття Користувачем шляхом надсилання відповідного
              повідомлення на електронну адресу Ліцензіара або передачі такого
              повідомлення співробітникам Ліцензіара телефоном, який зазначений
              на сайті{' '}
            </span>
            <a href="https://www.planguin.co">
              <span style={{ fontWeight: 400 }}>https://www.planguin.co</span>
            </a>
            <span style={{ fontWeight: 400 }}>
              . Користувач розуміє і погоджується, що якщо ця згода анульована,
              йому буде відмовлено в можливості використовувати частину або всі
              можливості Програми, Planguin і (або) Служб.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              11.6 Користувач погоджується отримати від Ліцензіара та його
              партнерів за допомогою телефонного номера, електронної адреси, яку
              він вказує під час реєстрації, рекламні та інформаційні
              повідомлення, SMS-повідомлення, Push-повідомлення про продукти,
              послуги та події, пов'язані з продуктами Ліцензіара.&nbsp;{' '}
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>

          <li>
            <b>12. Дія, зміна та припинення цієї угоди </b>
            <b>
              <br />
              <br />
            </b>
          </li>

          <p>
            <span style={{ fontWeight: 400 }}>
              12.1. Ця Угода регулюється і тлумачиться відповідно до
              національного законодавства. Питання, не врегульовані цією Угодою,
              врегульовані відповідно до національного та міжнародного
              законодавства, якщо інший порядок визначення застосовного закону
              не передбачений Ліцензійним договором або нормами міжнародного
              права.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              12.2. Ця Угода набирає чинності для Користувача з дня його
              долучення до неї і залишається в силі на невизначений термін.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              12.3. Ця Угода поширюється на всі оновлення Програми, які
              надаються Ліцензіату, якщо не запропоновано ознайомити вас з новою
              Угодою або доповненням до цієї Угоди.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              12.4. Ліцензіар має право в односторонньому порядку змінити умови
              цієї Угоди, розмістивши змінений текст в мережі Інтернет на
              веб-сайті:{' '}
            </span>
            <a href="https://www.planguin.co">
              <span style={{ fontWeight: 400 }}>https://www.planguin.co</span>
            </a>
            <span style={{ fontWeight: 400 }}> .&nbsp;</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              12.5. Якщо з будь-якої причини один або кілька положень цієї Угоди
              визнані недійсними у встановленому порядку, це не впливає на
              дійсність або достовірність решти положень цієї Угоди.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              12.6. У разі виникнення будь-яких суперечок або розбіжностей, що
              виникають у зв'язку з виконанням цієї Угоди, Користувач і
              Ліцензіар докладають усіх зусиль для їх вирішення шляхом
              переговорів. У разі, якщо суперечки не будуть врегульовані шляхом
              переговорів, спори повинні вирішуватися в порядку, встановленому
              національним та міжнародним законодавством, за місцезнаходженням
              Ліцензіара.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              12.7. Ліцензіар має право в односторонньому порядку розірвати цю
              Угоду, у разі порушення Користувачем умов цієї Угоди про
              використання Програми Planguin, супутних послуг.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              12.8. У разі припинення дії цієї Угоди будь-якою стороною та на
              будь-яких підставах Ліцензіат та Користувач зобов&rsquo;язані
              припинити використання Програми, Planguin та послуг.
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>

          <b>13. Повідомлення </b>
          <b></b>

          <p>
            <span style={{ fontWeight: 400 }}>
              13.1. Planguin має право надсилати електронні інформаційні
              повідомлення (далі - &laquo;сповіщення&raquo;) про важливі події,
              що відбуваються всередині Програми або пов'язані з
              Користувачем&nbsp; на електронну пошту Користувача або за номером
              телефону, вказаному в Програмі.&nbsp;&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              13.2. Planguin також дозволяється використовувати сповіщення для
              інформування Користувача про обмеження доступу до Програми у разі
              профілактичного обслуговування, порушення Користувачем цієї Угоди,
              зміни функціональності Програми, обсягу або умов надання Програми,
              включаючи внесення змін до цієї Угоди і обов'язкових документів.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              13.3. Planguin мають право на власний розсуд використовувати
              Програми сповіщень для розповсюдження реклами власних послуг та
              продуктів (послуг) третіх осіб.{' '}
            </span>
            <b>
              <br />
            </b>
            <b>
              <br />
            </b>
            <b>14. Інформація про Ліцензіара</b>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Сервіс Planguin, в особі фізичної особи-підприємця Бондаренко Елли
              Володимирівни, реєстраційний номер платника податків -
              3503203361.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Запити щодо умов цієї Угоди приймаються за електронною адресою
              Ліцензіара вказаною на сайті{' '}
            </span>
            <a href="https://www.planguin.co">
              <span style={{ fontWeight: 400 }}>https://www.planguin.co</span>
            </a>
            <span style={{ fontWeight: 400 }}>&nbsp; .&nbsp;</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>Дата публікації: 2023</span>
          </p>
        </div>
      </div>
    </>
  );
}

export default Terms;
