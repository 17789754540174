import React from 'react';
import Header from '../Header/Header';
import styles from '../PrivacyPolicy/PrivacyPolicy.module.css';

function PrivacyPolicy() {
  return (
    <>
      <div className={styles.container}>
        {window.innerWidth < 600 ? (
          <Header type={'mobile2'} />
        ) : (
          <Header type={'desktop2'} />
        )}

        <div className={styles.text_container}>
          <p>
            <b style={{ fontSize: '24px', color: 'coral' }}>
              Політика конфеденційності
            </b>
          </p>
          <br />
          <p>
            <span style={{ fontWeight: 400 }}>
              Це Положення розроблено з метою пояснити наші правила, що
              стосуються персональної інформації, отриманої в усній чи письмовій
              формі або наданої при відвідуванні даного веб-сайту або з інших
              джерел.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Згідно Закону України &laquo;Про захист персональних даних&raquo;,
              заповнюючи дані реєстраційної форми та проставляючи відмітку про
              ознайомлення з даною Політикою конфіденційності та обробки
              персональних даних (далі &ndash; Політика конфіденційності) Ви
              даєте згоду на обробку Ваших персональних даних в цілях та в
              умовах, передбачених цією політикою.
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>
          <p>
            <b>Терміни та визначення </b>
            <b>
              <br />
              <br />
            </b>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Автоматизована обробка персональних даних - обробка персональних
              даних за допомогою комп'ютерних засобів;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Блокування персональних даних - тимчасове припинення обробки
              персональних даних (крім випадків, коли обробка необхідна для
              уточнення персональних даних);
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Система персональних даних - набір персональних даних, що
              містяться в базах даних та забезпечують їх обробку інформаційними
              технологіями та технічними засобами;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Обробка персональних даних - будь-яка дія або сукупність дій,
              таких як збирання, реєстрація, накопичення, зберігання,
              адаптування, зміна, поновлення, використання і поширення
              (розповсюдження, реалізація, передача), знеособлення, знищення
              персональних даних, у тому числі з використанням інформаційних
              (автоматизованих) систем;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Володілець персональних даних - фізична або юридична особа, яка
              визначає мету обробки персональних даних, встановлює склад цих
              даних та процедури їх обробки, якщо інше не визначено законом;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Суб&rsquo;єкт персональних даних - фізична особа, персональні дані
              якої обробляються;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Згода суб&rsquo;єкта персональних даних - добровільне
              волевиявлення фізичної особи (за умови її поінформованості) щодо
              надання дозволу на обробку її персональних даних відповідно до
              сформульованої мети їх обробки, висловлене у письмовій формі або у
              формі, що дає змогу зробити висновок про надання згоди. У сфері
              електронної комерції згода суб&rsquo;єкта персональних даних може
              бути надана під час реєстрації в інформаційно-комунікаційній
              системі суб&rsquo;єкта електронної комерції шляхом проставлення
              відмітки про надання дозволу на обробку своїх персональних даних
              відповідно до сформульованої мети їх обробки, за умови, що така
              система не створює можливостей для обробки персональних даних до
              моменту проставлення відмітки;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Персональні дані - відомості чи сукупність відомостей про фізичну
              особу, яка ідентифікована або може бути конкретно ідентифікована;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Одержувач - фізична чи юридична особа, якій надаються персональні
              дані, у тому числі третя особа;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Третя особа - будь-яка особа, за винятком суб&rsquo;єкта
              персональних даних, володільця чи розпорядника персональних даних,
              якій володільцем чи розпорядником персональних даних здійснюється
              передача персональних даних;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Надання персональних даних - дії, спрямовані на розкриття
              персональних даних одержувачу;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Розпорядник персональних даних - фізична чи юридична особа, якій
              володільцем персональних даних або законом надано право обробляти
              ці дані від імені володільця;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Поширення персональних даних - дії, спрямовані на передачу
              відомостей про фізичну особу за згодою суб'єкта персональних
              даних;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Транскордонне передавання персональних даних - передача
              персональних даних на територію іноземної держави у повноваження
              іноземної держави, іноземній фізичній або іноземній юридичній
              особі;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Знищення персональних даних - це дія, яка унеможливлює відновлення
              вмісту персональних даних в інформаційній системі персональних
              даних та (або), що призводить до знищення матеріальними носіями
              персональних даних;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Користувач - означає особу, яка уклала Угоду з сервісом Planguin в
              передбаченому порядку.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Ця політика конфіденційності персональної інформації (далі &mdash;
              Політика) стосується всієї інформації, яка доступна в сервісі
              Planguin, в особі ФОП Бондаренко Елла Володимирівна, що може бути
              отримана про користувача під час його використання будь-якого з
              сайтів Planguin, сервісів, програм і продуктів (далі - послуги).
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Використання служб Planguin означає, що користувач беззастережно
              погоджується на цю політику і на умови обробки його персональної
              інформації, що міститься в ній; у разі незгоди з цими умовами
              користувач повинен утриматися від використання послуг.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Положення цієї політики є основою для організації роботи з обробки
              персональних даних від одержувача, включаючи розробку внутрішніх
              нормативних документів, що регулюють обробку та захист
              персональних даних від одержувача.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Суб'єкти персональних даних можуть подавати свій запит, заявку,
              вимогу із застосуванням даних, що дозволяють ідентифікувати
              суб'єкта або підписуватися кваліфікованим електронним цифровим
              підписом на адресу електронної пошти: planguin.co@gmail.com
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Строк розгляду запиту, заявки, вимоги не перевищує 30 (тридцяти)
              днів з дня подання заяви.
            </span>
          </p>
          <ul>
            <br />
            <li aria-level="1">
              <b>
                1. Інформація про користувача, яку отримує та обробляє Planguin{' '}
              </b>
              <b>
                <br />
                <br />
              </b>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.1. У цій політиці термін &laquo;інформація про
              користувача&raquo; означає:
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.1.1. Інформація, що користувач надає про себе під час реєстрації
              (створення облікового запису), авторизації або під час
              користування послугами, включаючи персональні дані користувача:
              прізвище, ім'я, по батькові, номери контактних телефонів, адреси
              електронної пошти, місце роботи. Інформація, необхідна для надання
              послуг, відзначається особливим чином. Інша інформація, яка надана
              на розсуд користувача.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Діючи від імені компанії, користувач надає інформацію про таку
              компанію: назву, реєстраційні та інші ідентифікаційні номери,
              перелік послуг, що надаються, адреси, номери телефонів та факсів,
              веб-сайт (и), банківські реквізити тощо. Інформація про компанію
              не складає персональних даних.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.1.2. Дані, які автоматично передаються до служб Planguin під час
              їх використання за допомогою програмного забезпечення,
              встановленого на пристрої користувача, включаючи IP-адресу,
              інформацію про cookie, інформацію про браузер користувача (або
              будь-яку іншу програму, що надає доступ до служб), час доступу,
              адресу сторінки.
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
            </span>
            <span style={{ fontWeight: 400 }}>
              Ми також використовуємо сторонні інструменти для покращення
              продуктивності та особливостей нашого веб-сайту, призначені для
              збору неособистої інформації про застосувння нашого веб-сайту
              (сервіси Google та подібні).
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.1.3. Інші відомості про користувача, збір та/або надання яких
              визначено в Регулюючих документах окремих служб Planguin.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.2. Ця політика поширюється лише на послуги Planguin. Planguin не
              контролює або несе відповідальність за будь-які сторонні сайти, на
              які користувач може отримати доступ до посилань, доступних на
              сайтах Planguin, включаючи результати пошуку. На таких сайтах інша
              персональна інформація може бути зібрана або запитана від
              користувача, а також вчинятися інші дії.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.3. Planguin, як правило, не перевіряє достовірність інформації,
              що надається користувачами, а також не стежить за їх юридичною
              спроможністю. Однак, Planguin припускає, що користувач надає
              достовірну і достатню інформацію з питань, що пропонуються в
              реєстраційній формі, і зберігає цю інформацію в теперішньому часі.
              Наслідки надання неправдивої інформації визначені в Угоді
              користувача послуг Planguin{' '}
            </span>
            <a href="https://www.planguin.co/terms">
              <span style={{ fontWeight: 400 }}>
                https://www.planguin.co/terms
              </span>
            </a>
            <span style={{ fontWeight: 400 }}>.&nbsp;</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.4. Приймаючи цю політику, а також застосовуючи певні
              налаштування у своєму програмному забезпеченні, включаючи
              налаштування операційної системи пристрою, користувач погоджується
              з умовами збору та використання даних постачальниками
              вищезазначених програмних послуг.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Cookies - це невеликі текстові файли, що записуються на пристрій
              користувача і містять інформацію про дії користувача в мережі
              Інтернет. Деякі з цих файлів служать для визначення місця, інші -
              для збереження параметрів користувача.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Користувач на свій розсуд налаштовує в браузері користувача
              параметри використання cookie. Однак не рекомендується вимикати
              використання всіх cookies, оскільки в такому випадку користувач не
              зможе використовувати деякі функціональні можливості платформи.
            </span>
          </p>
          <ul>
            <br />
            <li aria-level="1">
              <b>2. Мета збору та обробки інформації про користувача&nbsp;</b>
            </li>
            <br />
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.1. Planguin збирає і зберігає тільки ті дані, які необхідні для
              використання служб і надання послуг (виконання договорів і угод з
              користувачем).
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.2. Персональну інформацію користувача Planguin можна
              використовувати для наступних цілей:
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.2.1. Ідентифікація сторони в рамках договорів і угод з Planguin;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.2.2. Надання користувачу персоналізованих послуг,
              демонстраційний доступ до послуг;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.2.3. Зв'язок з користувачем, включаючи надсилання сповіщень,
              запитів та інформації щодо використання служб, надання послуг, а
              також обробку запитів і заявок від користувача; Відправлення
              рекламних, новинних, інформаційних повідомлень (розсилок), а також
              Push-сповіщень, що стосуються використання служб, надання послуг,
              а також обробки запитів і заявок від користувача;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.2.4. Підвищення якості послуг, зручність їх використання,
              розвиток нових служб і послуг;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.2.5. Таргетування рекламних матеріалів;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.2.6. Статистичні та інші дослідження на основі анонімізованих
              даних.
            </span>
          </p>
          <ul>
            <br />
            <li aria-level="1">
              <b>
                3. Умови обробки інформації користувача та її передачі третім
                особам&nbsp;&nbsp;
              </b>
            </li>
            <br />
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.1. Planguin зберігає інформацію про користувача відповідно до
              Умов користування сервісом.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.2. Інформація користувача зберігається конфіденційно, за
              винятком випадків, коли користувач добровільно надає інформацію
              про себе для публічного доступу для необмеженої кількості осіб.
              При використанні індивідуальних послуг користувач погоджується, що
              певна частина його інформації стає публічною.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.3. Planguin може обмінюватися інформацією про користувача з
              третіми особами у таких випадках:
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.3.1. Користувач висловив свою згоду на такі дії;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.3.2. Передача потрібна в рамках використання користувачем певної
              служби або надання послуги користувачеві;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.3.3. Передача передбачена українським або іншим чинним
              законодавством у порядку, встановленому законом;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.3.4. Ця передача відбувається в рамках продажу або іншої
              передачі бізнесу (повністю або частково), з усіма зобов'язаннями
              дотримуватися умов цієї політики щодо персональної інформації,
              отриманої покупцем;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.3.5. З метою захисту прав та законних інтересів Planguin або
              третіх осіб у випадках, коли користувач порушує Угоду користувача
              Planguin.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.4. При обробці даних користувачів, Planguin керується Законом
              України &laquo;Про захист персональних даних&raquo;:
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.4.1. Під час обробки персональних даних користувача будуть
              здійснені наступні дії: збір, запис, систематизація, накопичення,
              зберігання, уточнення (оновлення, модифікація), вилучення,
              використання, блокування, видалення, утилізація, передача
              (доручення, доступ).
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.4.2. Персональні дані користувача обробляються до тих пір, поки
              не буде досягнуто мети обробки персональних даних або відкликано
              згоду на обробку персональних даних, в залежності від того, яка з
              дій відбудеться раніше.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.4.3. Користувач має право відкликати свою згоду на обробку
              персональних даних, подавши заявку до Planguin відповідно до
              законодавства України. У разі анулювання згоди на обробку
              персональних даних, Planguin має право продовжувати обробку
              персональних даних, якщо є підстави, передбачені Законом України
              &laquo;Про захист персональних даних&raquo;.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.4.4. Згода на обробку персональних даних діє весь час до
              припинення обробки персональних даних, зазначених у 3.4.2. цієї
              Політики.
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>
          <ul>
            <br />
            <li aria-level="1">
              <b>4. Права суб&rsquo;єкта персональних даних </b>
              <b>
                <br />
                <br />
              </b>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.1. Особисті немайнові права на персональні дані, які має кожна
              фізична особа, є невід'ємними і непорушними.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.2. Суб'єкт персональних даних має право отримувати від
              одержувача інформацію, що стосується обробки його персональних
              даних: про джерела збирання, місцезнаходження своїх персональних
              даних, мету їх обробки, місцезнаходження або місце проживання
              (перебування) володільця чи розпорядника персональних даних або
              дати відповідне доручення щодо отримання цієї інформації
              уповноваженим ним особам, крім випадків, встановлених законом та
              якщо таке право не обмежено відповідно до законів України.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.3. Суб'єкт персональних даних має право на доступ до своїх
              персональних даних та отримувати інформацію про умови надання
              доступу до персональних даних, зокрема інформацію про третіх осіб,
              яким передаються його персональні дані.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.4. Суб'єкт персональних даних має право пред'являти вмотивовану
              вимогу одержувачу щодо зміни або знищення своїх персональних даних
              будь-яким володільцем та розпорядником персональних даних, якщо ці
              дані обробляються незаконно чи є недостовірними.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.5. Суб'єкт персональних даних має право на захист своїх
              персональних даних від незаконної обробки та випадкової втрати,
              знищення, пошкодження у зв'язку з умисним приховуванням,
              ненаданням чи несвоєчасним їх наданням, а також на захист від
              надання відомостей, що є недостовірними чи ганьблять честь,
              гідність та ділову репутацію фізичної особи.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.6. Суб'єкт персональних даних має право вносити застереження
              стосовно обмеження права на обробку своїх персональних даних під
              час надання згоди; пред&rsquo;являти вмотивовану вимогу володільцю
              персональних даних із запереченням проти обробки своїх
              персональних даних; відкликати згоду на обробку персональних
              даних; застосовувати засоби правового захисту в разі порушення
              законодавства про захист персональних даних.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.7. Обробка персональних даних з метою просування товарів, робіт,
              послуг на ринку за допомогою прямих контактів з суб'єктом
              персональних даних (потенційним споживачем) за допомогою зв'язку,
              а також в цілях політичної агітації допускається лише за
              попередньої згоди суб'єкта персональних даних.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.8. Одержувач зобов'язаний негайно припинити обробку своїх
              персональних даних для зазначених цілей на вимогу суб'єкта
              персональних даних.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.9. Забороняється приймати рішення на основі виключно
              автоматизованої обробки персональних даних, які мають правові
              наслідки для суб'єкта персональних даних або іншим чином впливають
              на його права та законні інтереси, крім випадків, які передбачені
              законами України, або з письмової згоди суб'єкта персональних
              даних.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.10. Якщо суб'єкт персональних даних вважає, що одержувач
              здійснює обробку його персональних даних з порушенням вимог
              законодавства України або в іншому випадку порушує його права і
              свободи, суб'єкт персональних даних має право оскаржити дії або
              бездіяльність одержувача Уповноваженому органу з захисту прав
              суб'єктів персональних даних або в суді.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.11. Суб'єкт персональних даних має право на захист своїх прав і
              законних інтересів, включаючи відшкодування збитків і (або)
              відшкодування моральної шкоди.
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>
          <ul>
            <br />
            <li aria-level="1">
              <b>5. Зміна користувачем персональної інформації </b>
              <b>
                <br />
                <br />
              </b>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.1. Користувач може в будь-який час змінити (оновити, додати)
              інформацію або її частину, а також її параметри конфіденційності
              за допомогою функції редагування даних в розділі &laquo;особистий
              кабінет&raquo; або в особистому розділі відповідної служби.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.2.&nbsp; Володільці чи розпорядники персональних даних
              зобов'язані вносити зміни до персональних даних на підставі
              вмотивованої письмової вимоги суб'єкта персональних даних, а також
              за зверненням інших суб&rsquo;єктів відносин, пов&rsquo;язаних із
              персональними даними, якщо на це є згода суб&rsquo;єкта
              персональних даних чи відповідна зміна здійснюється згідно з
              приписом Уповноваженого або визначених ним посадових осіб
              секретаріату Уповноваженого чи за рішенням суду, що набрало
              законної сили.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.3. Користувач також може видалити надану ним інформацію в межах
              певного облікового запису, звернувшись до Planguin будь-яким
              зручним способом. Видалення облікового запису може призвести до
              неможливості використання деяких служб Planguin.
            </span>
          </p>
          <ul>
            <br />
            <li aria-level="1">
              <b>
                6. Заходи, що застосовуються для захисту персональних даних
                користувачів{' '}
              </b>
              <b>
                <br />
                <br />
              </b>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              6.1. Planguin вживає необхідних і достатніх
              організаційно-технічних заходів для захисту інформації користувача
              від несанкціонованого або випадкового доступу, знищення, зміни,
              блокування, копіювання, розподілу, а також від інших протиправних
              дій з ними третіми особами.
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>
          <ul>
            <li aria-level="1">
              <b>7. Конфеденційна інформація </b>
              <b>
                <br />
                <br />
              </b>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              7.1. Поняття &laquo;конфіденційна інформація&raquo; означає
              відомості, пов'язані з Вашою расовою приналежністю або
              національністю, політичними поглядами, релігійними або
              філософськими переконаннями, участю в профспілках, станом
              здоров'я, сексуальним життям і орієнтацією, генетикою,
              кримінальним минулим, а також будь-які унікальні біометричні дані,
              що використовуються в цілях ідентифікації. У деяких юрисдикціях
              номера мобільних телефонів, дані про місце розташування та
              інформація на документах, що засвідчують особу, також є
              конфіденційною інформацією. Зазвичай Planguin не збирає
              конфіденційну інформацію, якщо вона не надається Вами добровільно
              або якщо від нас це не потрібно відповідно до чинних законів або
              правил.&nbsp;
            </span>
          </p>
          <ul>
            <li aria-level="1">
              <b>8. Особиста інформація, отримана від дітей.&nbsp;</b>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.1. Planguin не збирає навмисно особисту інформацію про осіб
              молодше 18 років. Якщо Ви є батьками або опікуном, не дозволяйте
              своїм дітям надавати особисту інформацію без Вашого дозволу.
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>
          <ul>
            <li aria-level="1">
              <b>9. Зміна Політики конфеденційності </b>
              <b>
                <br />
                <br />
              </b>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              9.1. Planguin має право вносити зміни до цієї Політики
              конфіденційності. При внесенні змін до поточної версії вказується
              дата останнього оновлення. Нова версія політики набирає чинності з
              моменту її розміщення, якщо інше не передбачено новою версією
              політики. Поточна редакція завжди знаходиться на веб-сайті за
              посиланням{' '}
            </span>
            <a href="https://www.planguin.co/">
              <span style={{ fontWeight: 400 }}>https://www.planguin.co/</span>
            </a>
            <span style={{ fontWeight: 400 }}>.&nbsp;</span>
          </p>
          <ul>
            <li aria-level="1">
              <b>Відгуки, питання та пропозиції&nbsp;</b>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              Будь-які пропозиції або питання щодо цієї політики повинні бути
              повідомлені службі підтримки користувачів Planguin{' '}
            </span>
            <a href="https://www.planguin.co/">
              <span style={{ fontWeight: 400 }}>https://www.planguin.co/</span>
            </a>
            <span style={{ fontWeight: 400 }}>.&nbsp;&nbsp;</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>Дата публікації: 2023</span>
          </p>
          <p></p>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
