import { useState, useRef, useEffect } from 'react';
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from 'react-image-crop';
import canvasPreview from '../utils/canvasPreview';

import 'react-image-crop/dist/ReactCrop.css';
import { Button } from 'Components/BasicComponents/BasicComponents';
import Imgs from 'Imgs/Imgs';
import styles from './ImageCropper.module.css';
import { classNames } from 'utils/lib/classNames/classNames';
import { useTranslation } from 'react-i18next';

const MAX_WIDTH = 1400; 
const MAX_DESTINATION = 200;

export const ImageCropper = ({ loading, avatar, updateAvatar, onClose }) => {
  const { t } = useTranslation();
  const imageRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [image, setImage] = useState('');
  const [fileData, setFileData] = useState(null);
  const [crop, setCrop] = useState({
    unit: '%', // Can be 'px' or '%'
    x: 320,
    y: 820,
    width: 50,
    height: 50,
  });

  const uploadImage = (event) => {
    const file = event.target.files?.[0];

    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      const imageElement = new Image();
      const imageUrl = reader?.result.toString() || '';

      imageElement.src = imageUrl;

      imageElement.onload = (event) => {
        const { width } = imageElement;
        const { naturalWidth, naturalHeight } = event.currentTarget;

        if (width > MAX_WIDTH) {
          alert(`Error!: Photo width must be less than ${MAX_WIDTH}px!`);
          setImage('');
          return 
        }

        if (naturalWidth < MAX_DESTINATION || naturalHeight < MAX_DESTINATION) {
          //test
          alert(`Eror!: Photo must be bigger than ${MAX_DESTINATION}px!`);
          setImage('');
        }
      };

      setFileData(file)
      setImage(imageUrl);
    };
    reader.readAsDataURL(file);
  };

  const imageLoad = (event) => {
    const { width, height } = event.currentTarget;
    const crop = makeAspectCrop(
      {
        unit: '%',
        width: 30,
      },
      1,
      width,
      height,
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const updateImage = () => {
    const dataUrl = previewCanvasRef?.current.toDataURL();
    updateAvatar(dataUrl, fileData);
  };

  useEffect(() => {
    if (imageRef?.current && previewCanvasRef?.current) {
      canvasPreview(
        imageRef.current,
        previewCanvasRef.current,
        convertToPixelCrop(
          crop,
          imageRef?.current?.naturalWidth,
          imageRef?.current?.naturalHeight,
        ),
      );
    }
  }, [imageRef?.current, previewCanvasRef?.current, image, crop]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Imgs
          img={'back_arrow'}
          onClickB={() => {
            onClose();
          }}
        />
        <div className={styles.header_title}>{t('photo_editor')}</div>
      </div>
        <div className={styles.imageCropper_image__container}>
        <label className={styles.imageCropper__title} htmlFor={styles.image_cropper}>{t('upload_photo')}</label>
        <input
          id={styles.image_cropper}
          type="file"
          accept="image/*"
          onChange={(e) => uploadImage(e)}
        />

        {!image
        ? (
          <>
            <p className={styles.preview}>{t('preview')}</p>
          
            <img
              style={{ borderRadius: '50%', marginInline: 10 }}
              src={ avatar }
              alt=""
            />
          </>
          )
        : (
          <>          
            <ReactCrop
              crop={crop}
              onChange={(pixelCrop, percentCrop) => {
                setCrop(pixelCrop)
              } }
              circularCrop
              keepSelection
              aspect={1}
            >
              <img
                ref={imageRef}
                src={image}
                alt="cropped image"
                onLoad={(e) => imageLoad(e)}
              />
            </ReactCrop>

            <Button
              name={t('crop_photo')}
              style={{ marginBlock: 20 }}
              onClick={() => {
                canvasPreview(
                  imageRef?.current,
                  previewCanvasRef?.current,
                  convertToPixelCrop(
                    crop,
                    imageRef?.current?.naturalWidth,
                    imageRef?.current?.naturalHeight,
                  ),
                );
              }}
            />
          </>
        )}
      </div>

      <canvas className={classNames(styles.imageCrop_canvas, { [styles.show]: Boolean(image)}) } ref={previewCanvasRef} />

      <Button 
        disabled={loading}
        style={{ marginBlock: 20 }}
        onClick={() => updateImage()}
        name={t('save')}
      />
    </div>
  );
};

export default ImageCropper;
