import React, { useState, useEffect, useRef } from 'react';
import stylesD from './Scheduler.module.css';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/uk';
import {
  AddAppointment,
  AppointmentDetails,
  CancelAppointment,
} from '../AppointmentsBlock/AppointmentsBlock';
import { CalendarWeekChanger } from '../CalendarWeekChanger/CalendarWeekChanger';
import { useSchedulerStore } from './SchedulerStore';
import { useCalendarWeekChangerStore } from '../CalendarWeekChanger/CalendarWeekChangerStore';
import { checkAccess } from '../../../../access';
import { useAppointmentsStore } from '../AppointmentsBlock/AppointmentsStore';
import 'moment/locale/de';
import 'moment/locale/ru';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

export function Scheduler(props) {
  let { datesOfWeek } = useCalendarWeekChangerStore();
  const { t } = useTranslation();

  let {
    appointments,
    weekends,
    getSpecialistAppointments,
    getSpecialistWeekends,

    calculateLinePosition,
    paddingLine,
    scrollToLine,
  } = useSchedulerStore();

  let {
    showAppointmentDetails,
    showAddAppointmentWindowForCalendar,
    showGroupAppointmentWindow,
  } = useAppointmentsStore();

  useEffect(() => {
    async function af() {
      await getSpecialistAppointments();
      await getSpecialistWeekends();
      calculateLinePosition();
      scrollToLine();
    }
    af();
  }, [useAppointmentsStore().appointments]);

  //!Рендеринг

  //Desktop
  return (
    <>
      <div className={stylesD.calendar_container}>
        <CalendarWeekChanger />

        {/* Хедер */}
        <div className={stylesD.calendar_header}>
          <div className={stylesD.time_slot_header}></div>
          {datesOfWeek.map((el) => {
            return (
              <DayTitle
                number={moment(el).locale(i18n.language).format('DD')}
                weekday={moment(el).locale(i18n.language).format('dddd')}
              />
            );
          })}
        </div>

        <div className={stylesD.weekend_container}>
          <div className={stylesD.weekend_title}>{t('day_off')}</div>

          {datesOfWeek.map((el) => {
            return <Weekend date={el} />;
          })}
        </div>

        <div id='calendar_container_scroll' className={stylesD.calendar_inner_container}>
          <div className={stylesD.line} style={{ top: paddingLine }}></div>

          {/* Блок годин */}

          <div className={stylesD.times_container}>
            {times.map((el) => {
              return <Time time={el} />;
            })}
          </div>

          {/* Блоки днів */}
          {datesOfWeek.map((date) => {
            return (
              <DaySlots
                // Таймслоти дня
                slots={times.map((time) => {
                  return (
                    <TimeSlot
                      weekend={weekends.includes(date) ? true : false}
                      onClick={() => {
                        showAddAppointmentWindowForCalendar(date, time);
                      }}
                      date={date}
                      time={time}
                    />
                  );
                })}
                // Записи
                appointments={appointments.map((el) => {
                  if (el.date == date) {
                    if (el.groupServiceId) {
                      return (
                        <Appointment
                          onClick={() => {
                            showGroupAppointmentWindow(el._id);
                          }}
                          groupAppointment={true}
                          serviceName={el.groupServiceName}
                          timerange={`${el.from}-${el.to} `}
                          members={`${el.participants.length}/${el.maxNumberOfParticipants}`}
                        />
                      );
                    }

                    return (
                      <Appointment
                        onClick={() => {
                          showAppointmentDetails(el._id);
                        }}
                        clientName={el.clientName}
                        serviceName={el.serviceName}
                        timerange={el.timeRange}
                      />
                    );
                  }
                })}
              />
            );
          })}
        </div>
      </div>

      <AddAppointment />
      <AppointmentDetails />
      <CancelAppointment />
    </>
  );
}

// Підкомпоненти

function DayTitle(props) {
  return (
    <>
      <div className={stylesD.day_title_container}>
        <div className={stylesD.number}>{props.number}</div>
        <div className={stylesD.week_day}>{props.weekday}</div>
      </div>
    </>
  );
}

export function Time(props) {
  return (
    <>
      <div className={stylesD.time_container}>{props.time}</div>
    </>
  );
}

function TimeSlot(props) {
  return (
    <>
      <div
        style={{ backgroundColor: props.weekend ? '#FFE0EF' : '' }}
        onClick={props.onClick}
        id={`${props.date}-${props.time}`}
        className={stylesD.time_slot_container}
      ></div>
    </>
  );
}

function DaySlots(props) {
  return (
    <>
      <div className={stylesD.daySlots_container}>
        {props.slots}
        {props.appointments}
      </div>
    </>
  );
}

function Appointment(props) {
  let [position, setPosition] = useState();
  let [duration, setDuration] = useState();
  let [height, setHeight] = useState();

  useEffect(() => {
    let times = props.timerange.split('-');
    let startTime = times[0];
    let parts = startTime.split(':');
    let hours = +parts[0];
    let minutes = +parts[1];

    setPosition(`${hours * 89 + minutes * 1.48}px`);
    setDuration(getDurationInMinutes(props.timerange));
    setHeight(`${+getDurationInMinutes(props.timerange) * 1.48}px`);

    document.getElementById('calendar_container_scroll').scrollTo(0, 700)
  }, []);

  return (
    <>
      <div
        onClick={props.onClick}
        style={{ top: position, height }}
        className={stylesD.appointment_container}
      >
        {duration != 15 ? (
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              textAlign: 'center',
            }}
          >
            {props.clientName}
          </div>
        ) : (
          <></>
        )}

        {duration != 15 ? (
          <>
            <div className={stylesD.time_range}>{props.timerange}</div>
          </>
        ) : (
          <></>
        )}
        {/* {props.groupAppointment&&<div style={{fontSize:'10px'}}>Група</div>} */}
        <div className={stylesD.service_name}>{props.serviceName}</div>
        <div style={{ fontWeight: 'bold', fontSize: '12px' }}>
          {props.members}
        </div>
      </div>
    </>
  );
}

function Weekend(props) {
  let { weekendCheckBoxHandler, weekends } = useSchedulerStore();

  return (
    <>
      <div className={stylesD.checkbox}>
        {' '}
        <input
          onChange={weekendCheckBoxHandler}
          checked={weekends.includes(props.date) ? true : false}
          id={props.date}
          type={'checkbox'}
        />
      </div>
    </>
  );
}

//Допоміжні функції

//Час робочого дня
let times = Array.from({ length: 24 }, (_, i) => {
  let hour = i.toString().padStart(2, '0');
  return `${hour}:00`;
});

//Отримати тривалість послуги в хвилинах з проміжка
function getDurationInMinutes(range) {
  const [start, end] = range.split('-');
  const startTime = new Date('1970-01-01 ' + start);
  const endTime = new Date('1970-01-01 ' + end);
  const duration = endTime - startTime;
  return duration / 1000 / 60;
}
