import React from 'react';
import { BlockTitle, Step } from '../LandingComponents';
import styles from './Block6.module.css';
import stylesM from './Block6M.module.css';
import { useTranslation } from 'react-i18next';

function Block6(props) {
  const { t } = useTranslation();

  if (props.type == 'm') {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '100px',
          }}
        >
          <iframe
            width="360"
            height="215"
            src="https://www.youtube.com/embed/8IaWbmoATOw"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>

        <div id={'howitworks'} className={stylesM.container}>
          <BlockTitle type={'m'} title={t('its_easy')} />

          <div className={stylesM.steps}>
            <Step
              type={'m'}
              number={'01'}
              title={t('register_service')}
            />
            <Step
              type={'m'}
              number={'02'}
              title={t('fill_basic_info')}
            />
            <Step
              type={'m'}
              number={'03'}
              title={t('add_socials_schedule_services')}
            />
            <Step
              type={'m'}
              number={'04'}
              title={t('copy_and_share_link')}
              style={{ height: '100px', border: '2px solid #FF6E05' }}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <iframe
        width="760"
        height="415"
        src="https://www.youtube.com/embed/8IaWbmoATOw"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>

      <div id={'howitworks'} className={styles.container}>
        <BlockTitle
          style={{ marginLeft: '600px', marginTop: '0px' }}
          title={t('its_easy')}
        />
        <Step
          number={'01'}
          title={t('register_service')}
          style={{ marginLeft: '100px', marginTop: '30px' }}
        />
        <Step
          number={'02'}
          title={t('fill_basic_info')}
          style={{ marginLeft: '170px' }}
        />
        <Step
          number={'03'}
          title={t('add_socials_schedule_services')}
          style={{ marginLeft: '240px' }}
        />
        <Step
          number={'04'}
          title={t('copy_and_share_link')}
          style={{ marginLeft: '310px', border: '2px solid #FF6E05' }}
        />
      </div>
    </>
  );
}

export default Block6;
