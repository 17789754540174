import React from 'react';
import styles from './LandingComponents.module.css';
import stylesM from './LandingComponentsM.module.css';

import Images from '../Images';
import notification from '../Images/notification.png';

export function Button(props) {
  if (props.type == 1) {
    return (
      <>
        <button
          onClick={props.onClick}
          style={props.style}
          className={styles.button1}
        >
          Почати роботу
        </button>
      </>
    );
  }

  if (props.type == 2) {
    return (
      <>
        <button
          onClick={props.onClick}
          style={props.style}
          className={styles.button2}
        >
          {props.name}
        </button>
      </>
    );
  }

  if (props.type == 3) {
    return (
      <>
        <button
          onClick={props.onClick}
          style={props.style}
          className={styles.button3}
        >
          Почати роботу
        </button>
      </>
    );
  }

  if (props.type == 4) {
    return (
      <>
        <button
          onClick={props.onClick}
          style={props.style}
          className={styles.button1}
        >
          {props.name}
        </button>
      </>
    );
  }

  if (props.type == 5) {
    return (
      <>
        <button
          onClick={props.onClick}
          style={props.style}
          className={styles.button3}
        >
          {props.name}
        </button>
      </>
    );
  }
}

export function Advantage(props) {
  return (
    <>
      <div className={styles.advantage_container}>
        <div className={styles.advantage_line1}>
          <div className={styles.advantage_icon}>{props.icon}</div>
          <div className={styles.advantage_title}>{props.title}</div>
        </div>
        <div className={styles.advantage_line2}>{props.description}</div>
      </div>
    </>
  );
}

export function BlockTitle(props) {
  if (props.type == 'm') {
    return (
      <>
        <div style={props.style} className={styles.titleM}>
          {props.title}
        </div>
      </>
    );
  }

  return (
    <>
      <div style={props.style} className={styles.block_title}>
        {props.title}
      </div>
    </>
  );
}

export function ForWhom(props) {
  if (props.type == 'm') {
    return (
      <>
        <div className={stylesM.for_whom_container}>
          <div className={stylesM.for_whom_line1}>{props.title}</div>
          <div className={stylesM.for_whom_line2}>{props.description}</div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.for_whom_container}>
        <div className={styles.for_whom_line1}>{props.title}</div>
        <div className={styles.for_whom_line2}>{props.description}</div>
      </div>
    </>
  );
}

export function Function(props) {
  if (props.type == 'm') {
    return (
      <>
        <div className={stylesM.function_container}>
          <div className={stylesM.function_line1}>
            <img width={'45px'} src={props.img} alt="" />
          </div>
          <div className={stylesM.function_line2}>{props.text}</div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.function_container}>
        <div className={styles.function_line1}>
          <img src={props.img} alt="" />
        </div>
        <div className={styles.function_line2}>{props.text}</div>
      </div>
    </>
  );
}

export function Step(props) {
  if (props.type == 'm') {
    return (
      <>
        <div style={props.style} className={stylesM.step_container}>
          <div className={stylesM.step_number}>{props.number}</div>
          <div className={stylesM.step_title}>{props.title}</div>
        </div>
      </>
    );
  }

  return (
    <>
      <div style={props.style} className={styles.step_container}>
        <div className={styles.step_number}>{props.number}</div>
        <div className={styles.step_title}>{props.title}</div>
      </div>
    </>
  );
}

export function Input(props) {
  if (props.type == 'm') {
    return (
      <>
        <input
          value={props.value}
          id={props.id}
          onChange={props.onChange}
          className={stylesM.input}
          placeholder={props.placeholder}
        ></input>
      </>
    );
  }

  return (
    <>
      <input
        value={props.value}
        id={props.id}
        onChange={props.onChange}
        className={styles.input}
        placeholder={props.placeholder}
      ></input>
    </>
  );
}
