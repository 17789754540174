import { create } from 'zustand';

export let useToast2 = create((set, get) => ({
  show: false,
  type: undefined,
  msg: undefined,

  showToast: (t, m) => {
    window.scrollTo(0, 0);
    if (t == 'e') {
      set({ type: 'error' });
    }
    if (t == 's') {
      set({ type: 'success' });
    }
    set({ msg: m });
    set({ show: true });

    setTimeout(() => {
      set({ show: false });
    }, 2000);
  },
}));
