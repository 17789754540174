import React from 'react';
import Header from '../Header/Header';
import styles from './License.module.css';

function License() {
  return (
    <>
      <div className={styles.container}>
        {window.innerWidth < 600 ? (
          <Header type={'mobile2'} />
        ) : (
          <Header type={'desktop2'} />
        )}

        <div className={styles.text_container}>
          <p>
            <b style={{ fontSize: '24px', color: 'coral' }}>
              ЛІЦЕНЗІЙНИЙ ДОГОВІР-ОФЕРТА № 09/02
            </b>
          </p>
          <table>
            <tbody>
              <tr>
                <td>
                  <p>
                    <b>м. Київ 09.02.2023</b>
                  </p>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <span style={{ fontWeight: 400 }}>
              Цей ліцензійний договір-оферта{' '}
            </span>
            <i>
              <span style={{ fontWeight: 400 }}>(далі &ndash; Оферта)</span>
            </i>
            <span style={{ fontWeight: 400 }}>
              {' '}
              за своєю сутністю є ліцензійним договором у розумінні ст. 1109
              Цивільного кодексу України{' '}
            </span>
            <i>
              <span style={{ fontWeight: 400 }}>(далі &ndash; ЦКУ)</span>
            </i>
            <span style={{ fontWeight: 400 }}>
              , та викладений у формі оферти, тобто пропозиції невизначеному
              колу фізичних та юридичних осіб приєднатися до цього договору, а
              саме &ndash; акцептувати його у розумінні ст. 638 та ст. 642 ЦКУ.{' '}
            </span>
            <b>
              Ця ліцензійна угода є офертою Фізичної особи-підприємця
              &laquo;Бондаренко Елли Володимирівни&raquo;, яка зареєстрована як
              Фізична особа-підприємець від 27.06.2019 року, яка за даною
              Офертою є Ліцензіаром. Будь-яка фізична або юридична особа, яка
              приймає цю Оферту є Ліцензіатом, а разом &ndash; Сторони Оферти.
            </b>
            <b>
              <br />
              <br />
            </b>
          </p>
          <ul>
            <li aria-level="1">
              <b>
                1. ПОРЯДОК ПРИЙНЯТТЯ (АКЦЕПТУВАННЯ) ДОГОВОРУ ТА ВИЗНАЧЕННЯ
                ТЕРМІНІВ
              </b>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.1. У цій Оферті використовується ряд термінів, які Сторони
              Оферти розуміють та віддають собі повну раду враховуючи
              користування порталом Ліцензіата та усі юридичні наслідки, які
              виникли з цього факту.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Портал &ndash; веб-сайт який розташований за веб-адресою&nbsp;{' '}
            </span>
            <a href="https://www.planguin.co/">
              <span style={{ fontWeight: 400 }}>https://www.planguin.co/</span>
            </a>
            <span style={{ fontWeight: 400 }}>
              {' '}
              , та будь-які його компоненти, бази даних, вихідний код сайту,
              компіляції аудіовізуальних творів, сукупність даних та команд,
              будь-які похідні сайти, та вищезазначені їх частини, які входять
              до складу Порталу. Будь-яка документація по використанню Порталу;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Ліцензіар &ndash; у даній Оферті, фізична особа-підприємець
              Бондаренко Елла Володимирівна, яка є власником об&rsquo;єкта права
              інтелектуальної власності, а саме - веб-сайту (далі - Портал) який
              розташований за веб-адресою&nbsp;{' '}
            </span>
            <a href="https://www.planguin.co/">
              <span style={{ fontWeight: 400 }}>https://www.planguin.co/</span>
            </a>
            <span style={{ fontWeight: 400 }}>
              {' '}
              , та будь-яких його компонентів, баз даних, вихідних кодів сайту,
              компіляції аудіовізуальних творів, сукупності даних та команд,
              будь-яких похідних сайтів, та вищезазначених їх частин, які
              входять до складу Порталу. Будь-якої документації по використанню
              Порталу, та має виключне право дозволяти використання
              об&rsquo;єкту права інтелектуальної власності (Порталу).
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Ліцензіат &ndash; особа, яка отримує право на використання
              об&rsquo;єкта права інтелектуальної власності, а саме &ndash;
              Порталу у спосіб встановлений Офертою.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Створення особистого кабінету &ndash; дія та/або сукупність дій
              які спрямовані на створення облікового запису та формування
              особистого кабінету Користувача. При реєстрації Ліцензіат
              зобов&rsquo;язаний надати Ліцензіару повну та достовірну
              інформацію про себе. Ліцензіат несе відповідальність за будь-які
              наслідки які настали або можуть настати у випадку надання останнім
              недостовірної/неправомірної/неактуальної інформації. Ліцензіат
              несе відповідальність за достовірність, актуальність та повноту
              наданої при реєстрації інформації. Ліцензіар має право запитати
              документи необхідні для ідентифікації Ліцензіата, а останній
              зобов&rsquo;язаний надати їх у вигляді, у якому їх запитує
              Ліцензіар. У випадку відмови Ліцензіата надати документи,
              Ліцензіар має право протягом 8 (восьми) годин припинити дію цього
              договору та позбавити Ліцензіата доступу до Порталу;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Ідентифікація Ліцензіата &ndash; надання документів, що
              підтверджують особу Ліцензіата, авторизація на веб-сайті
              Ліцензіара;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Обліковий запис &ndash; запис у масиві даних Порталу, який
              зберігає дані, що дозволяють ідентифікувати та авторизувати
              Ліцензіата як користувача Порталу;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Персональні дані &ndash; будь-яка інформація, яка прямо чи
              опосередковано відноситься до певної особи, чи до особи, яка
              визначається. Фактом акцептування цього договору, Ліцензіат надає
              право Ліцензіару збирати/зберігати/обробляти та передавати третім
              особам інформацію, визначену ст. 11 Закону України &laquo;Про
              інформацію&raquo;;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Тип Ліцензії &ndash; тарифний план/сукупність тарифних планів та
              послуг, які надані Ліцензіаром на Порталі з метою придбання з боку
              Ліцензіата;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              E-mail &ndash; електрона пошта.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.2. Зазначена у цьому договорі сукупність інформації, є офіційною
              пропозицією до будь-яких фізичних та/або юридичних осіб укласти
              ліцензійний договір-оферту, предмет якого визначений у Розділі 2
              Оферти.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              1.3. Статтею 642 ЦКУ визначено, що Відповідь особи, якій
              адресована пропозиція укласти договір, про її прийняття (акцепт)
              повинна бути повною і безумовною. Частиною 2 зазначеної статті
              вказано, що Якщо особа, яка{' '}
            </span>
            <span style={{ fontWeight: 400 }}>
              одержала пропозицію укласти договір, у межах строку для відповіді
              вчинила дію відповідно до вказаних у пропозиції умов договору
            </span>
            <span style={{ fontWeight: 400 }}>
              {' '}
              (відвантажила товари, надала послуги, виконала роботи, сплатила
              відповідну суму грошей тощо), яка{' '}
            </span>
            <span style={{ fontWeight: 400 }}>
              засвідчує її бажання укласти договір, ця дія є прийняттям
              пропозиції, якщо інше не вказане в пропозиції укласти договір або
              не встановлено законом.
            </span>
            <span style={{ fontWeight: 400 }}>
              {' '}
              Виходячи з пунктів 1.1. та 1.2., Оферти, Ліцензіар погоджується з
              тим, що будь-який з зазначених фактів та вчинених дій окремо,
              та/або у сукупності свідчить про прийняття (акцептування) цієї
              Оферти у якості Ліцензіата, а саме:
            </span>
          </p>
          <ul>
            <li style={{ fontWeight: 400 }} aria-level="1">
              <span style={{ fontWeight: 400 }}>
                Створення особистого кабінету на сайті Ліцензіара, який
                розташований за веб-адресою{' '}
              </span>
              <a href="https://www.planguin.co/">
                <span style={{ fontWeight: 400 }}>
                  https://www.planguin.co/
                </span>
              </a>
              <span style={{ fontWeight: 400 }}> ;</span>
            </li>
            <li style={{ fontWeight: 400 }} aria-level="1">
              <span style={{ fontWeight: 400 }}>
                Залишення заявки на створення особистого кабінету та/або
                облікового запису на сайті;
              </span>
            </li>
            <li style={{ fontWeight: 400 }} aria-level="1">
              <span style={{ fontWeight: 400 }}>
                Проставлення Ліцензіатом відмітки &laquo;Я погоджуюсь&raquo; при
                реєстрації облікового запису та/або особистого кабінету;
              </span>
            </li>
            <li style={{ fontWeight: 400 }} aria-level="1">
              <span style={{ fontWeight: 400 }}>
                Проведення оплати за той чи інший тип ліцензії та як наслідок,
                отримання Ліцензіаром відповідного фінансового документа, який
                підтверджує факт оплати;
              </span>
            </li>
            <li style={{ fontWeight: 400 }} aria-level="1">
              <span style={{ fontWeight: 400 }}>
                Отримання будь-якого безкоштовного та/або тестового доступу до
                Порталу з метою випробування його можливостей, тощо.
              </span>
              <span style={{ fontWeight: 400 }}>
                <br />
                <br />
              </span>
            </li>
          </ul>
          <ul>
            <li aria-level="1">
              <b>2. ПРЕДМЕТ ОФЕРТИ</b>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.1. Предметом цієї оферти є передача Ліцензіаром невиключних прав
              використання результату інтелектуальної діяльності Порталу -
              Ліцензіату на умовах простої (невиключної) ліцензії, виключно для
              самостійного використання Ліцензіатом без права субліцензування
              та/або передачі третім особам. Право використання надається на
              території всього світу.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.2. Доступ до Порталу, відбувається протягом 2 (двох) робочих
              днів після проведення оплати за замовлення тієї чи іншої ліцензії
              на Порталі. Фактом оплати є зарахування коштів на той чи інший
              рахунок Ліцензіара;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              2.3. Під час надання доступу до Порталу, Ліцензіар створює
              обліковий запис користувача відповідно до наданої Ліцензіатом
              інформації при реєстрації (e-mail: номер телефону, ім&rsquo;я,
              прізвище).
            </span>
          </p>
          <p>
            <b>
              <br />
              <br />
            </b>
          </p>

          <li>
            <b>3. ПОЛОЖЕННЯ ПРО ПЕРСОНАЛЬНІ ДАНІ ТА РОБОТУ З ОСТАННІМИ</b>
            <b>
              <br />
              <br />
            </b>
          </li>

          <p>
            <span style={{ fontWeight: 400 }}>
              3.1. Приймаючи (акцептуючи) цю Оферту та укладаючи цей Ліцензійний
              договір-оферту, Ліцензіат підтверджує, що він ознайомлений та
              згодний з її умовами, а також дає дозвіл на обробку своїх
              персональних даних відповідно до п. 1.1. Оферти;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.2. Метою обробки персональних даних Ліцензіата є можливість
              надання Ліцензіаром Послуг за Офертою, здійснення
              взаєморозрахунків, отримання рахунків, актів та інших документів,
              виявлення та запобігання шахрайським діям, а також вирішення
              проблем безпеки та усунення технічних неполадок, захисту від
              безпосередньої загрози заподіяння шкоди Ліцензіару або його
              клієнтам згідно із діючим законодавством України та міжнародними
              актами визнаними Україною;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.3. Приймаючи (акцептуючи) цю Оферту та укладаючи цей Ліцензійний
              договір-оферту, Ліцензіат підтверджує ряд юридично значущих
              фактів, а саме:
            </span>
          </p>
          <ul>
            <li style={{ fontWeight: 400 }} aria-level="1">
              <span style={{ fontWeight: 400 }}>
                Ліцензіат повідомлений (без додаткових повідомлень) про права
                передбачені законом &laquo;Про захист персональних даних&raquo;
                і про мету обробки даних які Ліцензіат передає Фізичній
                особі-підприємцю &laquo;Бондаренко Еллі Володимирівні&raquo;, як
                Ліцензіару;
              </span>
            </li>
            <li style={{ fontWeight: 400 }} aria-level="1">
              <span style={{ fontWeight: 400 }}>
                Ліцензіат надає дозвіл на збирання, зберігання, обробку
                персональних даних та передачу їх третім особам на весь строк
                дії Оферти та протягом наступних 3 (трьох) років, після його
                закінчення строку дії Оферти;
              </span>
            </li>
            <li style={{ fontWeight: 400 }} aria-level="1">
              <span style={{ fontWeight: 400 }}>
                Ліцензіат гарантує, що він поінформував усіх третіх осіб про
                використання їх персональних даних для надання Ліцензіаром
                Послуг за Договором, мету обробки персональних даних третіх
                осіб, способах такої обробки, та отримав згоду таких третіх осіб
                на обробку їх персональних даних Ліцензіаром;
              </span>
            </li>
            <li style={{ fontWeight: 400 }} aria-level="1">
              <span style={{ fontWeight: 400 }}>
                Ліцензіат зобов&rsquo;язаний на вимогу Ліцензіара надати
                письмову згоду третіх осіб на обробку їх персональних даних;
              </span>
            </li>
            <li style={{ fontWeight: 400 }} aria-level="1">
              <span style={{ fontWeight: 400 }}>
                Ліцензіат погоджується з тим фактом, що його персональні дані
                будуть оброблятися працівниками Ліцензіара. Укладаючи цей
                Договір, Ліцензіат погоджується з тим, що Ліцензіар має право
                без додаткового повідомлення Ліцензіата надавати доступ та
                передавати надані персональні дані третім особам (як в
                знеособленому, так і в відкритому вигляді), не змінюючи при
                цьому мету обробки персональних даних, і що він належним
                способом повідомлений про кожен випадок надання персональних
                даних третім особам в межах мети, вказаної у пункті 3.2.
                Договору. У випадку зміни мети, з якою персональні дані
                Ліцензіата передаються третім особам, Ліцензіар повідомляє
                Ліцензіата про нову мету шляхом надсилання електронного листа на
                електронну адресу, надану Ліцензіатом;
              </span>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.4. Сторони погодили, що персональні дані надані Ліцензіатом є
              особистою конфіденційною інформацією в розумінні політики
              конфіденційності, яка є частиною цієї Оферти та
            </span>{' '}
            <span style={{ fontWeight: 400 }}>доступна за посиланням </span>
            <a href="https://www.planguin.co/privacy-policy">
              <span style={{ fontWeight: 400 }}>
                https://www.planguin.co/privacy-policy
              </span>
            </a>
            <span style={{ fontWeight: 400 }}>&nbsp;</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              3.5. Порядок надання особистої інформації Ліцензіата третім
              особам, а також гарантії захисту такої інформації вказані в
              Політиці конфіденційності, яка є додатком до цієї Оферти.{' '}
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>
          <ul>
            <li aria-level="1">
              <b> 4. ОБСЯГ ПРАВ, ЩО ПЕРЕДАЮТЬСЯ ЛІЦЕНЗІАТУ</b>
              <b>
                <br />
                <br />
              </b>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.1. Портал є результатом інтелектуальної діяльності Ліцензіара та
              весь обсяг майнових та немайнових прав які, як наслідок належать
              Ліцензіару та охороняються Законодавством України про авторське
              право та іншими законами України;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.2. Ліцензіар гарантує наявність в себе повних прав на Портал для
              надання їх ліцензіату. Ліцензіату не надається право власності на
              Портал та його компоненти, а тільки право використання Порталу та
              його компонентів відповідно до умов, які передбачено у цьому
              Ліцензійному договорі;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.3. Алгоритми роботи Порталу та його вихідні коди (в тому числі
              їх частини), як і інші сукупність аудіовізуального контенту та
              компіляцій, кодів, команд, баз даних, інформації, тощо є
              комерційною таємницею Ліцензіара. Будь-яке їх використання або
              використання Порталу в порушення умов цього Ліцензійного договору
              розглядається як порушення прав Ліцензіара і є достатньою
              підставою для направлення повідомлення Ліцензіату з метою
              проведення діалогу та отримання відповідей на питання, які
              цікавлять Ліцензіара. Ліцензіар має право застосувати обмеження у
              доступі до Порталу для Ліцензіата у випадку наявності протиправної
              чи підозрілої активності.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.4. Право використання Порталу надається тільки Ліцензіату, без
              права передачі третім особам на підставі субліцензії, виключно в
              обсязі, обумовленому цим Ліцензійним договором, якщо немає
              письмової згоди Ліцензіара на інше. Ліцензіату не надається жодних
              прав на використання знаків для товарів та послуг, або інших
              об&rsquo;єктів інтелектуальної та/або промислової власності
              Ліцензіара.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              4.5. Ліцензіат не має права копіювати, поширювати Порталу і його
              компоненти в будь-якій формі, в тому числі у вигляді вихідного
              коду, будь-яким способом, в тому числі здавати в оренду,
              безоплатне користування, тощо. Будь-які такі дії будуть
              трактуватися Ліцензіаром як порушення його особистих майнових та
              немайнових прав та стане достатньою підставою для направлення
              повідомлення Ліцензіату з метою проведення діалогу та отримання
              відповідей на питання, які цікавлять Ліцензіара. Ліцензіар має
              право застосувати обмеження у доступі до Порталу для Ліцензіата у
              випадку наявності протиправної чи підозрілої активності.{' '}
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>

          <li>
            <b>5. ПРАВА ТА ОБОВ&rsquo;ЯЗКИ СТОРІН</b>
          </li>

          <p>
            <span style={{ fontWeight: 400 }}>
              5.1. Обов&rsquo;язки Ліцензіата:&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.1.1 Надати повну, правдиву та точну інформацію в кількості,
              необхідній для виконання Ліцензіаром своїх зобов'язань за цією
              Ліцензійною угодою. Інформація, яка надана Ліцензіатом, може
              розкриватися Ліцензіаром у визначених законом випадках, або з
              погодження з Ліцензіатом;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.1.2 Не вживати дій та інших спроб, спрямованих на копіювання,
              модифікування, декомпілювання (перетворення об'єктного коду у
              вихідний текст), деасемблювання (аналіз і дослідження об'єктного
              коду) програм, команд, аудіовізуальних елементів, баз даних,
              систем, вихідного коду, будь-яких дій спрямованих на будь-які дії
              з модифікації вихідного да об&rsquo;єктного коду;&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.1.3 Не чинити щодо Порталу дії, що порушують законодавство
              України та (або) міжнародні норми з авторського права та
              використання програмних засобів.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.1.4. Користуватися придбаною ліцензією та доступом до Порталу в
              межах сплаченого терміну користування. У випадку відсутності факту
              користування з бажанням Ліцензіата, останній позбавлений
              можливості звертатися до Ліцензіара з запитом про повернення
              коштів. Ліцензіат зобов&rsquo;язаний самостійно відстежувати зміни
              у положеннях Оферти та у випадку незгоди з новою редакцією &ndash;
              припинити користування Порталом.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.1.5. Своєчасно, не рідше ніж 1 (один) раз на місяць, але не
              пізніше числа оплати кожного наступного розрахункового місяця,
              після дати активації підписки, яка оплачується, сплачувати
              винагороду за ліцензію з метою наявності доступу до Порталу.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>5.2. Права Ліцензіата&nbsp;</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.2.1 Вимагати від Ліцензіара надання доступу до Порталу
              відповідно до умов цього Договору.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.2.2 Використовувати всі оплачені функціональні можливості
              Програми відповідно до опису обраного Типу ліцензії способами, що
              не порушують умови цього Ліцензійного договору, Правил
              користування, текст якого розміщений Ліцензіаром в мережі Інтернет
              на сторінці https://www.planguin.co, законодавства України та норм
              міжнародного права.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.2.3 Звертатися до Ліцензіара зі скаргами та пропозиціями щодо
              поліпшення якості Порталу.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.3. Обов&rsquo;язки Ліцензіара
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.3.1 Забезпечити цілодобову (за винятком часу проведення
              профілактичних робіт) доступність сервера для використання Порталу
              та сайту на ньому.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.3.2 Надати Ліцензіату доступ до Порталу шляхом реєстрації
              Ліцензіата та ідентифікації його даних.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.3.3 Надати захищений інтерфейс (такий, який потребує пароля та
              логіну) та інші можливості для реєстрації Ліцензіата, обробити
              введені Ліцензіатом дані та створити унікальний обліковий запис на
              підставі введених даних, а також надати необхідні параметри для
              доступу до послуг в спеціальному розділі Порталу.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.3.4 Протягом одного робочого дня з моменту надходження
              винагороди, зазначеного в п. 6.1. цього Ліцензійного договору, на
              банківський рахунок Ліцензіара, надати Ліцензіату можливість
              (відкрити доступ) використання Порталу.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.3.5 Без стягування додаткової винагороди надавати Ліцензіату
              технічну підтримку з питань, пов'язаних з функціональністю
              Порталу, особливостями експлуатації на стандартних конфігураціях
              підтримуваних операційних, поштових та інших систем в порядку і на
              умовах, зазначених у технічній документації до неї.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.3.6 Ліцензіар зобов&rsquo;язаний обмежити доступ до особистих
              даних Ліцензіата, за виключенням тих які опубліковані Ліцензіатом
              у відкритих джерелах включаючи але не обмежуючись, будь-які
              соціальні мережі, публікації, дописи, згадування у новинних
              сюжетах, аудіо та відео матеріалах, тощо.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>5.4. Права Ліцензіара</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.4.1 Тимчасово або повністю припинити доступу до Порталу
              Ліцензіату та вимагати письмових пояснень від Ліцензіата в
              наступних випадках:&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.4.1.1 Не надходження своєчасної оплати.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.4.1.2 Надання Ліцензіатом недостовірної або неточної контактної
              інформації або не надання такої інформації на вимогу
              Ліцензіара.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.4.1.3 Відсутності відповіді Ліцензіата на будь-який запит
              Ліцензіара про підтвердження актуальності контактних даних,
              наданих Ліцензіатом, або їх уточнення протягом 15
              (п&rsquo;ятнадцяти) календарних днів з моменту отримання такого
              запиту Ліцензіаром;&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.4.1.4 Дій, спрямованих на обмеження або перешкоджання в доступі
              інших користувачів до послуг, що надаються Ліцензіаром, а також
              здійснення спроб несанкціонованого доступу до ресурсів Ліцензіара
              та до інших систем, доступних через мережу Інтернет.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.4.1.5 Розсилці через мережу Інтернет будь-якої інформації, що
              суперечить вимогам законодавства України або нормам міжнародного
              права, що містяться в міжнародних договорах і конвенціях,
              учасницею яких є Україна. Під розсиланням розуміється як масове
              розсилання декількох електронних листів великої кількості
              одержувачів, так і множинне розсилання одному одержувачу, а також
              використання реквізитів (веб-сторінок, E-mail) Ліцензіара при
              таких розсиланнях, здійснених через іншого провайдера. Під
              повідомленнями розуміються повідомлення електронної пошти, програм
              обміну повідомленнями (Skype, Viber, WhatsApp, тощо) та інших
              подібних засобів особистого обміну інформацією.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.4.1.6 Опублікування або передачі будь-якої інформації або
              програмного забезпечення, яке містить у собі комп'ютерні віруси
              або інші компоненти, прирівняні до них.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.4.1.7 Дій, спрямованих на те, щоб посилати, публікувати,
              передавати, відтворювати, надавати або в будь-якому вигляді
              використовувати в комерційних цілях інформацію, програмне
              забезпечення або інші матеріали, повністю або частково, отримані
              за допомогою Порталу (якщо це явно не дозволено власником подібної
              інформації, програмного забезпечення або іншої продукції) за умови
              наявності письмової вимоги власника такої інформації про обмеження
              перерахованих дій.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.4.1.8 Дій, спрямованих на те, щоб посилати, публікувати,
              передавати, відтворювати або поширювати будь-яким способом
              отримані за допомогою Порталу програмне забезпечення або інші
              матеріали, повністю або частково, захищені авторськими або іншими
              правами, без дозволу власника, в так само як посилати,
              публікувати, передавати або поширювати будь-яким способом будь-яку
              складову наданого Порталу або створені на її основі роботи, так як
              сам Портал також є об'єктом авторських та інших прав за умови
              наявності письмової вимоги власника таких прав про обмеження
              перерахованих дій.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.4.1.9 Направлення електронних повідомлень комерційного та іншого
              характеру, неузгоджених (не запитаних) попередньо з її одержувачем
              незалежно від того, чи були вони зроблені через поштовий сервер
              Ліцензіара або інший поштовий сервер.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.4.1.10 Публікації і передачі через мережу Інтернет будь-якої
              інформації, що суперечить діючому українському законодавству або
              міжнародним договорам та конвенціям, учасником яких є Україна.
              Зокрема, це відноситься до порнографічних зображень. У зв'язку з
              відсутністю законодавчо встановлених методів визначення того, чи є
              конкретне зображення порнографічним, Ліцензіар залишає за собою
              право такого визначення.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.5. Ліцензіар виконує прохання Ліцензіата, спрямовані тільки з
              контактного E-mail Ліцензіата після успішного створення облікового
              запису та особистого кабінету.
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
            </span>
          </p>

          <li>
            <b>6. ВАРТІСТЬ ЛІЦЕНЗІЇ ТА ПОРЯДОК РОЗРАХУНКІВ</b>
          </li>

          <p>
            <span style={{ fontWeight: 400 }}>
              6.1. Ліцензіат сплачує Ліцензіару ліцензійну винагороду за право
              використання Порталу відповідно до обраного Типу ліцензії в
              розмірі, установленому в рахунку, виставленому Ліцензіату.
              Ліцензіат ознайомлений з цінами на типи ліцензій, які розміщені на
              сайті{' '}
            </span>
            <a href="https://www.planguin.co">
              <span style={{ fontWeight: 400 }}>https://www.planguin.co</span>
            </a>
            <span style={{ fontWeight: 400 }}>
              &nbsp; та погоджується з ними при реєстрації облікового запису та
              в процесі користування.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              6.2 Оплата Послуг здійснюється в національній валюті України у
              відповідності до тарифів, встановлених на момент надання Ліцензії
              на умовах 100% передоплати, яка сплачується у якості
              &laquo;підписки&raquo;, тобто є щомісячною оплатою за право
              користування Порталом.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              6.3 Ліцензіар має право в односторонньому порядку при продовженні
              цієї Ліцензійної угоди змінювати розмір винагороди за право
              використання Порталу відповідно з чинними на момент продовження
              Типами ліцензій з завчасним поінформуванням ліцензіата до
              закінчення терміну користування.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              6.4 Послуги вважаються сплаченими у момент надходження коштів на
              поточний рахунок Ліцензіара.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              6.5 У разі якщо протягом п'яти календарних днів з моменту
              закінчення терміну дії ліцензії. Ліцензіар не отримав претензій
              Ліцензіата, пов'язаних з обсягом наданих прав, то вважається що
              невиключне право використання Програми надано Ліцензіату
              відповідно до цього Ліцензійної угоди в повному обсязі і належним
              чином.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              6.6 Дострокове розірвання цього Ліцензійного договору не є
              підставою для повернення ліцензійної винагороди. Факт відсутності
              фактів користування з боку Ліцензіату також не є підставою для
              повернення ліцензійної винагороди.
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>

          <li>
            <b>7. ВІДПОВІДАЛЬНІСТЬ СТОРІН</b>
            <b>
              <br />
              <br />
            </b>
          </li>

          <p>
            <span style={{ fontWeight: 400 }}>
              7.1 Ліцензіар не гарантує абсолютної безперебійності або
              безпомилковості Порталу і не дає гарантію того, що пропоноване
              програмне забезпечення та сукупність кодів, команд, технік,
              аудіовізуальних творів та компіляцій або будь-які інші матеріали
              не містять системних помилок. Ліцензіар докладає всі розумні
              зусилля і заходи з метою недопущення цього. Ліцензіат в свою чергу
              акцептуючи Оферту погоджується з цим та відмовляється від
              відповідних претензій до Ліцензіара.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              7.2 Ліцензіар не несе відповідальності за прямий або непрямий
              збиток, заподіяний Ліцензіатові в результаті використання або
              неможливості користування Порталу або понесений у результаті
              помилок, пропусків, перерв у роботі, видалення файлів, дефектів,
              затримок в роботі або передачі даних, або зміни функцій і інших
              причин. Ліцензіар не гарантує прийняття пошти Ліцензіара від
              віддалених мереж, функціонування яких призвело до занесення адреси
              такої мережі в списки, по яких програма доставки пошти Ліцензіара
              не здійснює прийом пошти. Ліцензіар не гарантує також і
              безперебійної інтеграції Порталу з сервісами та внутрішніми
              системами обліку робочого часу/записів якими користується
              Ліцензіат.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              7.3 Ліцензіар не несе відповідальності за неможливість
              використання Порталу з причин, незалежних від Ліцензіара.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              7.4 Ліцензіат повністю відповідальний за збереження свого паролю
              та за збитки, які можуть виникнути через несанкціоноване його
              використання. У випадку отримання будь-ким несанкціонованого
              доступу до акаунту Ліцензіата, останній зобов&rsquo;язаний не
              пізніше ніж 3 (три) години з моменту, як йому стало відомо про
              несанкціонований доступ &ndash; повідомити про такий факт
              Ліцензіару.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              7.5 Ліцензіар не є відповідачем або співвідповідачем щодо
              будь-яких зобов&rsquo;язань і витрат Ліцензіата, в тому числі
              пов&rsquo;язаних з &ndash; порушенням положень даного Договору
              Ліцензіатом або іншими особами, які використовують ім&rsquo;я
              користувача і пароль Ліцензіата, або &ndash; розташуванням або
              передачею будь-якого повідомлення, інформації, програмного
              забезпечення або інших матеріалів в мережі Інтернет Ліцензіатом
              або іншими особами, які використовують його логін і пароль.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              7.6 Ліцензіар виконує прохання Ліцензіата, спрямовані тільки з
              контактного E-mail Ліцензіата або зі службової області на сайті
              Ліцензіара після успішного проходження авторизації.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              7.7 Ліцензіат дає свою згоду на отримання повідомлень щодо Порталу
              по електронній пошті (E-mail) і через служби коротких повідомлень
              (SMS).
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              7.8 Сторони погодились вважати електронне цифрове відтворення
              підпису/електронний цифровий підпис та/або факсимільне відтворення
              підпису на рахунках та актах прийому-передачі наданих послуг
              (виконаних робіт), підписаних Сторонами в рамках даного Договору,
              таким, що має таку ж юридичну силу, як і власноручний підпис
              уповноважених представників Сторін.&nbsp;
            </span>
          </p>

          <li>
            <b> 8. ВИРІШЕННЯ СПОРІВ</b>
          </li>

          <p>
            <span style={{ fontWeight: 400 }}>
              8.1. У випадку виникнення будь-яких претензій у будь-якої сторони
              цієї Оферти до іншої сторони. Сторони погодили, що вони
              вирішуються шляхом скерування претензій та перемовин. При чому,
              Претензія має бути скерована не пізніше ніж 3 (три) календарних
              дні з моменту тощо чи іншого порушення стороною прав чи
              невиконання своїх обов&rsquo;язків
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.2. Сторони погодили, що розгляд претензій Ліцензіаром
              провадиться лише за наявності підтвердження від Ліцензіата у
              вигляді фінансового документу про сплату ліцензійної винагороди за
              той чи інший період.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.3. Сторони погодили, що перемовини повинні провадитись у строк
              не більше 30 календарних днів. У випадку, якщо не вдалося досягти
              цілей таких перемовин, то будь-яка сторона в праві звернутися до
              суду за захистом своїх прав.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              8.4. У випадку, якщо питання за цією Офертою не врегульоване нею,
              сторони погодилися застосовувати до цих правовідносин матеріальне
              та процесуальне право України.
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>

          <li>
            <b> 9. ОБСТАВИНИ НЕПЕРЕБОРНОЇ СИЛИ (ФОРС-МАЖОРНІ ОБСТАВИНИ)</b>
          </li>

          <p>
            <span style={{ fontWeight: 400 }}>
              9.1 Сторони не несуть відповідальності за повне або часткове
              невиконання передбачених цим Договором зобов'язань, якщо таке
              невиконання пов'язане з обставинами непереборної сили
              (форс-мажор). До таких обставин відносяться: стихійні лиха,
              аварії, цивільні безлади, страйки, військові дії, протиправні дії
              третіх осіб, епідемії, пожежі, набирання чинності законодавчих
              актів, розпоряджень і постанов державних органів і інші обставини,
              незалежні від волі сторін та такі, які відбуваються безпосередньо
              за місцем діяльності/проживання/перебування будь-якої
              Сторони.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              9.2 Сторона, що посилається на обставини непереборної сили,
              зобов'язана письмово сповістити іншу сторону протягом 1 (одного)
              дня про настання таких обставин. У випадку не направлення або
              несвоєчасного направлення повідомлення про Форс-мажор, Сторона
              втрачає право посилатися на обставини непереборної сили як на
              підставу для звільнення від відповідальності за повне або часткове
              невиконання своїх зобов&rsquo;язань за цим Договором.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              9.3 Якщо обставина непереборної сили безпосередньо вплинула на
              виконання зобов'язань в строк, встановлений в цьому Договорі,
              термін виконання зобов'язань продовжується пропорційно часу дії
              відповідної обставини.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              9.4. Сторони погодили, що сам по собі військовий стан, введений в
              Україні з 24.02.2022 року указом Президента України № 64/2022, не
              є обставиною непереборної сили, оскільки діяв станом на приєднання
              ліцензіата до цієї оферти.
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>

          <li>
            <b>10. СТРОК ДІЇ ОФЕРТИ, ПОРЯДОК ЗМІНИ ТА РОЗІРВАННЯ ОФЕРТИ</b>
            <b>
              <br />
              <br />
            </b>
          </li>

          <p>
            <span style={{ fontWeight: 400 }}>
              10.1. Ця Оферта вступає в силу з моменту прийняття умов цієї
              Оферти, підтвердженого проведенням оплати за той чи інший тариф
              ліцензії відповідно до умов Оферти/початку користування Порталом,
              і діє протягом 1 місяця наступного за днем оплати, та автоматично
              пролонгується зі списанням щомісячної оплати за користування
              Порталом.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              10.2. Ліцензіар, враховуючи дії/бездіяльність Ліцензіата, який
              порушує положення умови Оферти, має право на свій розсуд повністю
              або частково обмежити доступ до Порталу і розірвати з ним договір
              в односторонньому порядку на строк до усунення прогавин та
              недоліків щодо порушення прав Ліцензіара.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              10.3. У разі, якщо Ліцензіар отримає відомості про незаконну
              діяльність Ліцензіата та/або установить факти чи з&rsquo;ясує
              обставини, які вказують на те, що діяльність Ліцензіата має ознаки
              незаконної, з метою забезпечення безпеки Порталу, а також з метою
              запобігання та припинення шахрайських дій на Порталі, має право в
              односторонньому порядку призупинити, обмежити або зупинити доступ
              такого Ліцензіата до Порталу в будь-який час та/або розірвати
              договір в односторонньому порядку без додаткового повідомлення про
              це.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              10.4. Датою укладення цієї Оферти визнається день надходження
              оплати за виставленому Ліцензіаром на адресу Ліцензіата рахунку,
              на банківський рахунок Ліцензіара.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              10.5. Сторони зберігають конфіденційну інформацію, пов'язану з цим
              Договором і зобов'язуються використовувати її виключно в цілях
              виконання своїх договірних зобов'язань та зобов&rsquo;язань
              викладених у цій Оферті
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              10.6. Договір залишається в силі в разі зміни адрес і реквізитів
              Сторін, зміни їх засновницьких документів, включаючи, зміна
              власника, організаційно-правової форми і ін., а також паспортних,
              контактних і інших даних Замовника - фізичної особи.&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              10.7. Ліцензіар про відповідні зміни повідомляє Ліцензіата шляхом
              розміщення повідомлення про це на своєму Порталі.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              10.8. Ліцензіат відповідні зміни проводить за допомогою
              коректування даних в своєму кабінеті клієнта.{' '}
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              10.9. Будь-які зміни до цієї Оферти мають виконуватися у
              письмовому вигляді та є невід&rsquo;ємною частиною цієї Оферти.
              Про зміни Ліцензіар повідомляє всіх шляхом розміщення нової версії
              Оферти на Порталі. Ліцензіати погоджуються з тим, що вони
              ознайомлені з даним текстом Оферти та приєднуються до нього.{' '}
            </span>
            <span style={{ fontWeight: 400 }}>
              <br />
              <br />
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ </span>
            <a href="https://www.planguin.co/privacy-policy">
              <span style={{ fontWeight: 400 }}>
                https://www.planguin.co/privacy-policy
              </span>
            </a>
            <span style={{ fontWeight: 400 }}>&nbsp;&nbsp;</span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              ПРАВИЛА КОРИСТУВАННЯ САЙТОМ{' '}
            </span>
            <a href="https://www.planguin.co/terms">
              <span style={{ fontWeight: 400 }}>
                https://www.planguin.co/terms
              </span>
            </a>
            <span style={{ fontWeight: 400 }}>&nbsp;</span>
          </p>
        </div>
      </div>
    </>
  );
}

export default License;
