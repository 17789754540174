import React, { useState, useEffect } from 'react';
import styles from './ProfileBlock.module.css';
import stylesM from './ProfileBlockM.module.css';
import ProfilePhoto from '../../Components/ProfilePhoto/ProfilePhoto';
import {
  Button,
  DesktopProfileRaw,
  EditableDesktopRaw,
  SocialNetwork,
} from '../BasicComponents/BasicComponents';
import Imgs from '../../Imgs/Imgs';
import axios from 'axios';
import SocialLinksBlock from '../SocialLinksBlock/SocialLinksBlock';
import { useNavigate } from 'react-router-dom';
import { useDB } from 'utils/DB';
import { API } from 'utils/api';
import { useTranslation } from 'react-i18next';

function ProfileBlock(props) {
  const { t } = useTranslation();
  const specialistId = props.specialistId;

  const [editable, setEditable] = useState(false);

  const { specialist, setSpecialist } = useDB();
  const [editLinks, setEditLinks] = useState(false);
  const [update, setUpdate] = useState();
  console.log('specialist', specialist);

  // Зробити вікно для редагування
  function makeEditable() {
    editable ? setEditable(false) : setEditable(true);
  }

  //Зберегти зміни
  async function saveChanges() {
    await API.Specialist.updateProfile(specialist);
    setEditable(false);
  }

  //Обробник інпута
  function inputHandler(e) {
    setSpecialist(e);
  }

  //Открыть окно редактирования соц сетей
  function editSocials() {
    setEditLinks(true);
  }

  //Показати вікно зміни графіку

  useEffect(() => {
    async function af() {}
    af();
  }, [specialist]);

  //!Рендеринг

  //Mobile

  if (props.platform == 'mobile') {
    if (editLinks) {
      return (
        <SocialLinksBlock
          platform={'mobile'}
          specialistId={specialistId}
          show={editLinks}
        />
      );
    }

    //Режим перегляду
    if (!editable) {
      return (
        <>
          <div className={stylesM.mobile_profile_container}>
            <div className={stylesM.edit_button_container}>
              <div></div>
              <Imgs width={'30px'} img={'edit'} onClick_Edit={makeEditable} />
            </div>

            <div className={stylesM.photo_container}>
              <ProfilePhoto t={'test'} imgWidth={'200px'} width={'120px'} />
            </div>

            <div className={stylesM.mobile_profile_data_container}>
              <DesktopProfileRaw
                title={t('name')}
                value={`${specialist.name} ${specialist.surname}`}
              />

              <DesktopProfileRaw title={t('phone')} value={specialist.phone} />
              <DesktopProfileRaw
                title={t('company')}
                value={specialist.visibleName}
              />
              <div className={stylesM.company_hint}>
                {`${t('display_name')} `}
              </div>

              <DesktopProfileRaw
                type={'textarea'}
                title={t('description')}
                value={specialist.about}
              />
            </div>

            <div className={stylesM.mobile_links_container}>
              {specialist.instagram != '' && specialist.instagram ? (
                <SocialNetwork
                  type={'instagram'}
                  value={specialist.instagram}
                />
              ) : (
                <></>
              )}
              {specialist.facebook != '' && specialist.facebook ? (
                <SocialNetwork type={'facebook'} value={specialist.facebook} />
              ) : (
                <></>
              )}
              {specialist.youtube != '' && specialist.youtube ? (
                <SocialNetwork type={'youtube'} value={specialist.youtube} />
              ) : (
                <></>
              )}
              {specialist.tiktok != '' && specialist.tiktok ? (
                <SocialNetwork type={'tiktok'} value={specialist.tiktok} />
              ) : (
                <></>
              )}
              {specialist.whatsapp != '' &&
              specialist.whatsapp &&
              specialist.whatsapp != '+380' ? (
                <SocialNetwork type={'whatsapp'} value={specialist.whatsapp} />
              ) : (
                <></>
              )}
              {specialist.telegram != '' &&
              specialist.telegram &&
              specialist.telegram != '@' ? (
                <SocialNetwork type={'telegram'} value={specialist.telegram} />
              ) : (
                <></>
              )}
              {specialist.viber != '' &&
              specialist.viber &&
              specialist.viber != '380' ? (
                <SocialNetwork type={'viber'} value={specialist.viber} />
              ) : (
                <></>
              )}

              <div onClick={editSocials} className={stylesM.edit_socials}>
                {`${t('add_social_media')} `}
              </div>
            </div>
          </div>
        </>
      );
    }

    //Режим редагування
    if (editable) {
      return (
        <div className={stylesM.mobile_profile_container}>
          {/* <div className={stylesM.photo_container}>
            <ProfilePhoto  t={'test'} imgWidth={'200px'} width={'97px'} />    
        </div> */}
          <div className={stylesM.mobile_profile_data_container}>
            <EditableDesktopRaw
              id={'name'}
              title={t('name')}
              value={`${specialist.name}`}
              onChange={inputHandler}
            />
            <EditableDesktopRaw
              id={'surname'}
              title={t('last_name')}
              value={`${specialist.surname}`}
              onChange={inputHandler}
            />

            <EditableDesktopRaw
              id={'visibleName'}
              title={t('company')}
              value={specialist.visibleName}
              onChange={inputHandler}
              placeholder={t('service_name_placeholder')}
            />
            <EditableDesktopRaw
              id={'phone'}
              title={t('phone')}
              value={specialist.phone}
              onChange={inputHandler}
            />
            <EditableDesktopRaw
              id={'about'}
              textareaStyle={{ height: '100px' }}
              type={'textarea'}
              title={t('phone')}
              value={specialist.about}
              onChange={inputHandler}
            />

            <div className={stylesM.buttons_container}>
              <Button
                name={t('save')}
                style={{ marginTop: '20px' }}
                onClick={saveChanges}
              />
              <Button
                onClick={() => {
                  makeEditable();
                }}
                name={t('back')}
                style={{
                  backgroundColor: '#ffff',
                  color: 'grey',
                  height: '50px',
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    return;
  }

  //Desktop

  //Editable desktop
  if (editable) {
    return (
      <>
        <div className={styles.container}>
          <div className={styles.inner_container}>
            <div className={styles.left_side}>
              <div className={styles.photo_container}>
                <ProfilePhoto t={'test'} imgWidth={'200px'} width={'97px'} />
              </div>
              <div className={styles.socials_container}>
                {specialist.instagram != '' && specialist.instagram ? (
                  <SocialNetwork
                    type={'instagram'}
                    value={specialist.instagram}
                  />
                ) : (
                  <></>
                )}
                {specialist.facebook != '' && specialist.facebook ? (
                  <SocialNetwork
                    type={'facebook'}
                    value={specialist.facebook}
                  />
                ) : (
                  <></>
                )}
                {specialist.youtube != '' && specialist.youtube ? (
                  <SocialNetwork type={'youtube'} value={specialist.youtube} />
                ) : (
                  <></>
                )}
                {specialist.tiktok != '' && specialist.tiktok ? (
                  <SocialNetwork type={'tiktok'} value={specialist.tiktok} />
                ) : (
                  <></>
                )}
                {specialist.whatsapp != '' &&
                specialist.whatsapp &&
                specialist.whatsapp != '+380' ? (
                  <SocialNetwork
                    type={'whatsapp'}
                    value={specialist.whatsapp}
                  />
                ) : (
                  <></>
                )}
                {specialist.telegram != '' &&
                specialist.telegram &&
                specialist.telegram != '@' ? (
                  <SocialNetwork
                    type={'telegram'}
                    value={specialist.telegram}
                  />
                ) : (
                  <></>
                )}
                {specialist.viber != '' &&
                specialist.viber &&
                specialist.viber != '380' ? (
                  <SocialNetwork type={'viber'} value={specialist.viber} />
                ) : (
                  <></>
                )}

                {/* <div onClick={editSocials} className={styles.edit_socials}>+ Додати соціальні мережі </div> */}
              </div>
            </div>
            <div className={styles.right_side}>
              <div className={styles.edit_button_container}>
                <Imgs width={'30px'} img={'edit'} onClick_Edit={makeEditable} />
              </div>
              <div className={styles.fullname}>
                <EditableDesktopRaw
                  id={'name'}
                  title={t('name')}
                  value={`${specialist.name}`}
                  onChange={inputHandler}
                />
                <EditableDesktopRaw
                  id={'surname'}
                  title={t('last_name')}
                  value={`${specialist.surname}`}
                  onChange={inputHandler}
                />
              </div>
              <EditableDesktopRaw
                placeholder={t('service_name_placeholder')}
                id={'visibleName'}
                title={t('company')}
                value={specialist.visibleName}
                onChange={inputHandler}
              />
              <EditableDesktopRaw
                id={'phone'}
                title={t('phone')}
                value={specialist.phone}
                onChange={inputHandler}
              />
              <EditableDesktopRaw
                id={'about'}
                textareaStyle={{ height: '100px' }}
                type={'textarea'}
                title={t('phone')}
                value={specialist.about}
                onChange={inputHandler}
              />

              <div className={styles.button_container}>
                <Button
                  name={t('save')}
                  style={{ width: '160px', height: '40px' }}
                  onClick={saveChanges}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  //View desktop
  return (
    <>
      <div className={styles.container}>
        <div className={styles.inner_container}>
          <div className={styles.left_side}>
            <div className={styles.photo_container}>
              <ProfilePhoto t={'test'} imgWidth={'200px'} width={'97px'} />
            </div>

            <div className={styles.socials_container}>
              {specialist.instagram != '' && specialist.instagram ? (
                <SocialNetwork
                  type={'instagram'}
                  value={specialist.instagram}
                />
              ) : (
                <></>
              )}
              {specialist.facebook != '' && specialist.facebook ? (
                <SocialNetwork type={'facebook'} value={specialist.facebook} />
              ) : (
                <></>
              )}
              {specialist.youtube != '' && specialist.youtube ? (
                <SocialNetwork type={'youtube'} value={specialist.youtube} />
              ) : (
                <></>
              )}
              {specialist.tiktok != '' && specialist.tiktok ? (
                <SocialNetwork type={'tiktok'} value={specialist.tiktok} />
              ) : (
                <></>
              )}
              {specialist.whatsapp != '' &&
              specialist.whatsapp &&
              specialist.whatsapp != '+380' ? (
                <SocialNetwork type={'whatsapp'} value={specialist.whatsapp} />
              ) : (
                <></>
              )}
              {specialist.telegram != '' &&
              specialist.telegram &&
              specialist.telegram != '@' ? (
                <SocialNetwork type={'telegram'} value={specialist.telegram} />
              ) : (
                <></>
              )}
              {specialist.viber != '' &&
              specialist.viber &&
              specialist.viber != '380' ? (
                <SocialNetwork type={'viber'} value={specialist.viber} />
              ) : (
                <></>
              )}

              <div onClick={editSocials} className={styles.edit_socials}>
                {`${t('add_social_media')} `}
              </div>
            </div>
          </div>
          <div className={styles.right_side}>
            <div className={styles.edit_button_container}>
              <Imgs width={'30px'} img={'edit'} onClick_Edit={makeEditable} />
            </div>

            <DesktopProfileRaw
              title={t('name')}
              value={`${specialist.name} ${specialist.surname}`}
            />
            <DesktopProfileRaw title={t('phone')} value={specialist.phone} />
            <DesktopProfileRaw
              title={t('company')}
              value={specialist.visibleName}
            />
            <div className={styles.company_hint}>
              {`${t('display_name')} `}
            </div>

            <DesktopProfileRaw
              type={'textarea'}
              title={t('description')}
              value={specialist.about}
            />

            <div className={styles.button_container}>
              {/* <Button name={'Зберегти'} style={{ width: '160px', height: '40px' }} /> */}
            </div>
          </div>
        </div>
      </div>

      <SocialLinksBlock
        close={() => {
          setEditLinks(false);
          setUpdate(Date.now());
        }}
        onClose={() => {
          setEditLinks(false);
        }}
        platform={'desktop'}
        specialistId={specialistId}
        show={editLinks}
      />
    </>
  );
}

export default ProfileBlock;
