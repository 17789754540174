import React, { useState } from 'react';
import styles from './Block7.module.css';
import stylesM from './Block7M.module.css';
import laptop from '../../Images/laptop.png';
import { Input, Button, BlockTitle } from '../../Components/LandingComponents';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

function Block7(props) {
  const { t } = useTranslation();
  let [formData, setFormData] = useState({ name: '', mail: '', phone: '' });

  //Обробник інпута
  function inputHandler(e) {
    setFormData((ps) => ({ ...ps, [e.target.id]: e.target.value }));
  }

  // Відправка данних на сервер
  async function sendData() {
    if (formData.name == '' || formData.mail == '' || formData.phone == '') {
      toast.error(t('fill_all_fields'), {
        position: 'bottom-right',
        autoClose: 1900,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }

    let response = await axios.post(
      `${process.env.REACT_APP_API}/leads`,
      formData,
      { withCredentials: true },
    );
    if (response.data == 1) {
      setFormData({ name: '', mail: '', phone: '' });
      toast.success(t('data_sent'), {
        position: 'top-center',
        autoClose: 1900,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } else {
      toast.error(t('error_occurred_reload'), {
        position: 'top-center',
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  }

  if (props.type == 'm') {
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />

        <div id={'try'} className={stylesM.container}>
          <div className={stylesM.img}>
            <img src={laptop} width={'220px'} alt="" />
          </div>
          <div className={stylesM.title}>
            {`${t('start_improving_client_service')} `}
          </div>
          <div className={stylesM.subtitle}>
            {`${t('14_days_free_trial')} `}
          </div>
          {/* <div className={stylesM.inputs}>
    <Input type={'m'} value={formData.name} id={'name'} onChange={inputHandler} placeholder={t('first_name')}/>
    <Input type={'m'} value={formData.mail} id={'mail'} onChange={inputHandler} placeholder={'Пошта'}/>
    <Input type={'m'} value={formData.phone}id={'phone'} onChange={inputHandler} placeholder={'+380 (ХХ) ХХХ хХ ХХ'}/>
    </div> */}
          <Button
            onClick={() => {
              window.location.href = '/signup';
            }}
            style={{
              marginTop: '30px',
              backgroundColor: 'white',
              color: 'coral',
            }}
            type={5}
            name={t('registration')}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <ToastContainer
        position="bottom-left"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div id={'try'} className={styles.container}>
        <div className={styles.inner_container}>
          <div className={styles.image}>
            <img src={laptop} width={'550px'} alt="" />
          </div>
          <div className={styles.form}>
            <div className={styles.title}>
              {`${t('start_improving_client_service')} `}
            </div>
            <div className={styles.subtitle}>
              {`${t('14_days_free_trial')} `}
            </div>

            {/* <div className={styles.inputs}>
                    <Input value={formData.name} id={'name'} onChange={inputHandler} placeholder={t('first_name')}/>
                    <Input value={formData.mail} id={'mail'} onChange={inputHandler} placeholder={'Пошта'}/>
                    <Input value={formData.phone}id={'phone'} onChange={inputHandler} placeholder={'+380 (ХХ) ХХХ хХ ХХ'}/>
                </div> */}
            <Button
              onClick={() => {
                window.location.href = '/signup';
              }}
              style={{
                marginTop: '30px',
                backgroundColor: 'white',
                color: 'coral',
              }}
              type={5}
              name={t('registration')}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Block7;
