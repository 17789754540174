import React from 'react';
import Header from '../Header/Header';
import styles from './DeleteAccount.module.css';
import { useTranslation } from 'react-i18next';

function DeleteAccountWeb() {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.container}>
        {window.innerWidth < 600 ? (
          <Header type={'mobile2'} />
        ) : (
          <Header type={'desktop2'} />
        )}

        <h3 className={styles.titleStyle}>
          {t('how_to_delete_account_android')}
        </h3>

        <p>1.{t('go_to_more_tab')}</p>
        <p>2.{t('click_button')} "{t('delete_account')}"</p>
        <p>3.{t('confirm_deletion')}</p>

        <h3 className={styles.titleStyle}>{t('data_to_be_deleted')}</h3>
        <p>{t('client_email')}</p>
        <p>{t('name')}</p>
        <p>{t('phone_number')}</p>
        <p>{t('social_media_addresses')}</p>
        <p>{t('other_data')}</p>

        <h3 className={styles.titleStyle}>{t('support')}:</h3>
        <p style={{ color: 'orange' }}>planguin.co@gmail.com</p>
      </div>
    </>
  );
}

export default DeleteAccountWeb;
