import React from 'react';
import styles from './EditEmail.module.css';
import { Button, Input } from 'Components/BasicComponents/BasicComponents';
import { useSettingsStore } from '../../SettingsStore';
import { useEffect } from 'react';
import { useClientsStore } from 'Pages/Clients/ClientsStore';
import { useTranslation } from 'react-i18next';

function EditEmail() {
  const { t } = useTranslation();
  const {resetEmail, inputHandler, emailResetSubmit, verification, errors } = useSettingsStore()
  const {specialist,  getSpecialistInfo } = useClientsStore()
  
  const onChange = (e) => {
    inputHandler(e, 'email')
  }

  useEffect(() => {

    const customTarget = {
      target: {
        id: "email",
        value: specialist.email
      }
    }
    inputHandler(customTarget, 'email')
  }, [specialist]);
  
  useEffect(() => {
    getSpecialistInfo()
  }, []);
  

  return (
    <div className={styles.container}>

      {
        verification ? (<>
          <span className={styles.input_block__name}>Введіть код підтвердження надісланий на новий email</span>

          <div className={styles.input_block}>
            <Input
              style={{ display: 'flex', width: '185px', margin: '10px auto' }}
              id={'verificationCode'}
              value={resetEmail.verificationCode}
              onChange={onChange}
              placeholder={'CODE'}
              type='number'
            />
          </div>
        </>) : (<>
          <div className={styles.input_block}>
            <span className={styles.input_block__name}>{t('a_current_email')}</span>

            <Input
              style={{ width: '100%' }}
              id={'email'}
              value={resetEmail.email}
              onChange={onChange}
              placeholder={'user@gmail.com'}
              type='email'
            />
          </div>

          <div className={styles.input_block}>
            <span className={styles.input_block__name}>{t('a_new_email')}</span>

            <Input
              style={{ width: '100%' }}
              id={'newEmail'}
              value={resetEmail.newEmail}
              onChange={onChange}
              placeholder={'user@gmail.com'}
              type='email'
            />
          </div>
        </>)
      }

      <div className={styles.errors}>{errors}</div>
      
      <Button color='black' disabled={!!errors} name={t('a_save_new_email')} onClick={emailResetSubmit} />
    </div>
  );
}

export default EditEmail;
