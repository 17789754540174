import React from 'react';
import { Button } from '../../../../Components/BasicComponents/BasicComponents';
import styles from './DeleteConfirmation.module.css';
import Imgs from '../../../../Imgs/Imgs';
import { useServicesStore } from '../../ServicesStore';
import { useTranslation } from 'react-i18next';

export function DeleteConfirmation(props) {
  const { t } = useTranslation();
  let {
    deleteConfirmationWindow,
    deleteService,
    deleteGroupService,
    hideDeleteConfirmationWindow,
  } = useServicesStore();

  if (deleteConfirmationWindow.show) {
    return (
      <>
        <div className={styles.popup_background}>
          <div className={styles.popup_window}>
            <div
              className={styles.close_container}
              onClick={hideDeleteConfirmationWindow}
            >
              <Imgs img={'close'} />
            </div>
            <div className={styles.text}>
              {t('confirm_delete_service')}
            </div>

            {deleteConfirmationWindow.object == 'service' ? (
              <Button
                name={t('confirm')}
                color={'black'}
                onClick={deleteService}
              />
            ) : (
              <Button
                name={t('confirm')}
                color={'black'}
                onClick={deleteGroupService}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default DeleteConfirmation;
