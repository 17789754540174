import React from 'react';
import styles from './Block8.module.css';
import stylesM from './Block8M.module.css';

import planguin3 from '../../Images/planguin3.png';
import { Button } from '../LandingComponents';

function Block8(props) {
  if (props.type == 'm') {
    return (
      <>
        <div id={'price'} className={stylesM.container}>
          {/* <div className={stylesM.title}>Наразі український сервіс Planguin знаходиться на етапі тестування</div> */}
          <div className={stylesM.img}>
            <img width={'220px'} src={planguin3} alt="" />
          </div>
          <div className={stylesM.description}>
            {/* <span style={{fontWeight:'bold'}}>Основний функціонал сервісу вже доступний і БЕЗКОШТОВНИЙ, додаткові функції в розробці
   (CRM-система, сповіщення, інші додаткові інтеграції).</span> */}
            Наша команда покликана створити найзручніший сервіс для вашого
            користування, тому будемо раді будь-якому зворотньому зв’язку та
            допомозі на етапах розробки{' '}
          </div>
          <Button
            onClick={() => {
              window.location.href = 'https://t.me/+I17rvleeqx4yM2Zi';
            }}
            style={{ width: '100%', marginTop: '50px' }}
            type={4}
            name={'Долучитись до спільноти'}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div id={'price'} className={styles.container}>
        <div className={styles.description}>
          Наша команда покликана створити найзручніший сервіс для вашого
          користування, тому будемо раді будь-якому зворотньому зв’язку та
          допомозі на етапах розробки
          <br></br>
          <Button
            onClick={() => {
              window.location.href = 'https://t.me/+I17rvleeqx4yM2Zi';
            }}
            style={{ width: '450px', marginTop: '50px' }}
            type={4}
            name={'Долучитись до спільноти'}
          />
        </div>
        <img width={'557px'} className={styles.img} src={planguin3} alt="" />
        <div className={styles.background}></div>
      </div>
    </>
  );
}

export default Block8;
