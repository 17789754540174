import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { uk } from 'date-fns/locale';

import Header, { MobileHeader } from '../../Components/Header/Header';
import styles from './NotificationsMessages.module.css';
import { useNotificationsStore } from 'store';
import BottomMenu from 'Components/BottomMenu/BottomMenu';
import { classNames } from 'utils/lib/classNames/classNames';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
  
function NotificationsMessages(props) {
  const { t } = useTranslation();
  const location = useLocation()
  const {recordsNotifications, getRecordsNotificationsList, updateNotificationsExists } = useNotificationsStore();
  const [offset, setOffset] = useState(0);

  let isMobile = window.innerWidth < 1024;
  let prevDate = ""; 

  useEffect(() => {
    getRecordsNotificationsList(offset, 20)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, offset])

  useEffect(() => {
    updateNotificationsExists(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  document.body.style.backgroundColor = '#F4F7FE';

  return (
    <>
      {isMobile ? <MobileHeader title={t('notifications_1')} type={2} /> : <Header platform="desktop" />}
      
      <div className={classNames(styles.container, {[styles.container_mobile]: isMobile})}>
       {!isMobile && <h className={styles.title}>{t('notifications_1')}</h>} 

        <div
          id="scrollableNotifications"
          className={styles.list}
        >
          <InfiniteScroll
            scrollableTarget="scrollableNotifications"
            dataLength={recordsNotifications.total} 
            next={() => setOffset(prev => prev + 1)}
            hasMore={recordsNotifications.total > recordsNotifications.notifications.length}
            loader={<p>...</p>}
          >
            {recordsNotifications.notifications.map((record) => {
              const date = parseISO(record.date);
              const formattedDate = format(date, 'd MMMM', { locale: uk });
              const formattedTime = format(date, 'HH:mm');
             
              const formattedDateWithoutTime = format(date, 'yyyy-MM-dd');
              const isNextDay = prevDate !== formattedDateWithoutTime;

              prevDate = formattedDateWithoutTime; 

              return (
                <React.Fragment key={record.id}>
                  {isNextDay && <div className={styles.date}>{formattedDate}</div>}
                  <div className={styles.info}>
                    <div className={styles.time}>{formattedTime}</div>
                    <div className={styles.message}>{record.message}</div>
                  </div>
                </React.Fragment>
              )
            })}
          </InfiniteScroll>
        </div>
      </div>

      {isMobile && <BottomMenu />}
    </>
  );
}

export default NotificationsMessages;
