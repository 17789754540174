import React, { useState, useLayoutEffect } from 'react';
import styles from './Step4.module.css';
import {
  Background,
  Button,
  Input,
  InputsTitle,
} from '../../Components/BasicComponents/BasicComponents';
import Imgs from '../../Imgs/Imgs';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { checkAccess } from '../../access';
import { useTranslation } from 'react-i18next';

function Step4() {
  let navigate = useNavigate();
  const { t } = useTranslation();

  let [data, setData] = useState({
    instagram: '',
    facebook: '',
    youtube: '',
    tiktok: '',
    whatsapp: '+380',
    viber: '380',
    telegram: '',
  });

  let specialistId = checkAccess();

  //Адаптивный дизайн
  let [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function updateWidth() {
    setWindowWidth(window.innerWidth);
  }
  useLayoutEffect(() => {
    window.addEventListener('resize', updateWidth);

    if (windowWidth < 1025) {
      document.body.style.backgroundColor = '#F4F7FE';
    }
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  //Обробник інпута
  function inputHandler(e) {
    setData((ps) => ({ ...ps, [e.target.id]: e.target.value }));
  }

  //Відправити данні
  async function sendData() {
    let response = await axios.put(
      `${process.env.REACT_APP_API}/api/specialists/${specialistId}`,
      data,
      { withCredentials: true },
    );
    navigate('/profile/specialist');
  }

  //! Рендеринг

  //Mobile
  if (windowWidth < 1025) {
    return (
      <>
        <div className={styles.inner_form_container}>
          <InputsTitle title={`${t('add_channels')}👇 `} />
          <Input
            label={true}
            type={'label2'}
            id={'instagram'}
            onChange={inputHandler}
            labelName={'Instagram'}
            placeholder={`name`}
          />
          <Input
            label={true}
            type={'label2'}
            id={'facebook'}
            onChange={inputHandler}
            labelName={`Facebook`}
            placeholder={'name'}
          />
          <Input
            label={true}
            type={'label2'}
            id={'youtube'}
            onChange={inputHandler}
            labelName={`YouTube`}
            placeholder={'name'}
          />
          <Input
            label={true}
            type={'label2'}
            id={'tiktok'}
            onChange={inputHandler}
            labelName={`TikTok`}
            placeholder={'name'}
          />
          <Input
            type={'label2'}
            id={'whatsapp'}
            onChange={inputHandler}
            value={data.whatsup}
            label={true}
            placeholder={`WhatsApp`}
          />
          <Input
            type={'label2'}
            id={'viber'}
            onChange={inputHandler}
            value={data.viber}
            label={true}
            placeholder={`Viber`}
          />
          <Input
            type={'label2'}
            id={'telegram'}
            onChange={inputHandler}
            value={data.telegram}
            label={true}
            placeholder={`name`}
            labelName={'Telegram'}
          />

          <div className={styles.buttons_container}>
            <Button
              name={t('skip')}
              style={{ backgroundColor: '#DADADA' }}
              onClick={sendData}
            />
            <Button name={t('next')} onClick={sendData} />
          </div>
        </div>
      </>
    );
  }

  //WEB
  return (
    <>
      <Background style={{ backgroundColor: '#F4F7FE' }}>
        <div className={styles.outer_container}>
          <div className={styles.form_container}>
            {/* <Imgs img ={'planguinLogo'}/> */}

            <div className={styles.inner_form_container}>
              <InputsTitle title={`${t('add_channels')}👇 `} />
              <Input
                label={true}
                type={'label2'}
                id={'instagram'}
                onChange={inputHandler}
                labelName={'Instagram'}
                placeholder={`name`}
              />
              <Input
                label={true}
                type={'label2'}
                id={'facebook'}
                onChange={inputHandler}
                labelName={`Facebook`}
                placeholder={'name'}
              />
              <Input
                label={true}
                type={'label2'}
                id={'youtube'}
                onChange={inputHandler}
                labelName={`YouTube`}
                placeholder={'name'}
              />
              <Input
                label={true}
                type={'label2'}
                id={'tiktok'}
                onChange={inputHandler}
                labelName={`TikTok`}
                placeholder={'name'}
              />
              <Input
                type={'label2'}
                id={'whatsapp'}
                onChange={inputHandler}
                value={data.whatsup}
                label={true}
                placeholder={`WhatsApp`}
              />
              <Input
                type={'label2'}
                id={'viber'}
                onChange={inputHandler}
                value={data.viber}
                label={true}
                placeholder={`Viber`}
              />
              <Input
                type={'label2'}
                id={'telegram'}
                onChange={inputHandler}
                value={data.telegram}
                label={true}
                placeholder={`name`}
                labelName={'Telegram'}
              />
            </div>

            <div className={styles.buttons_container}>
              <Button
                name={t('skip')}
                style={{ backgroundColor: '#DADADA' }}
                onClick={sendData}
              />
              <Button name={t('next')} onClick={sendData} />
            </div>
          </div>
        </div>
      </Background>
    </>
  );
}

export default Step4;
