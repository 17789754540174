import { useEffect } from 'react';
import styles from './Help.module.css';

import close from './assets/close.png';
import { useHelp } from './useHelp';
import { useDB } from 'utils/DB';
import { useTranslation } from 'react-i18next';

function Help() {
  const { t } = useTranslation();
  let { msg, handleClick, hideMsg, checkMsgStatus } = useHelp();
  let { specialist } = useDB();

  useEffect(() => {
    async function af() {
      await checkMsgStatus();
    }
    af();
  }, [specialist]);

  if (msg) {
    return (
      <>
        <div className={styles.container}>
          {t('unable_to_fill_profile')}
          <button onClick={handleClick} className={styles.button}>
            {t('write_to')}
          </button>
          <img onClick={hideMsg} src={close} width={16} alt="" />
        </div>
      </>
    );
  }
}

export default Help;
