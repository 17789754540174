import React, { useState, useLayoutEffect } from 'react';
import {
  Background,
  Below_Input_Text2,
  Button,
  Input,
  InputsTitle,
} from '../../Components/BasicComponents/BasicComponents';
import Imgs from '../../Imgs/Imgs';
import styles from './ChangePassword.module.css';
import Toast from '../../Components/Toast/Toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ChangePassword() {
  const { t } = useTranslation();
  let navigate = useNavigate();

  let [toast, setToast] = useState({ type: '', show: false, msg: '' });
  let [passwords, setPasswords] = useState({ new: '', repeated: '' });

  //Показати тост
  function showToast(type, msg) {
    if (type == 'e') {
      type = 'error';
    }
    if (type == 's') {
      type = 'success';
    }
    setToast({ type, show: true, msg });
    setTimeout(() => {
      setToast({ type: '', show: false, msg: '' });
    }, 2000);
  }

  //Адаптивный дизайн
  let [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function updateWidth() {
    setWindowWidth(window.innerWidth);
  }
  useLayoutEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  //Парсинг URL
  function getTokenValueFromURL(url) {
    const urlParams = new URLSearchParams(new URL(url).search);
    return urlParams.get('token');
  }

  let token = getTokenValueFromURL(window.location.href);

  function inputHandler(e) {
    setPasswords((ps) => ({ ...ps, [e.target.id]: e.target.value }));
  }

  async function sendData() {
    // if (!token) {
    //   showToast(
    //     "e",
    //     "Посилання не дійсне. Перейдіть за відправленним посиланням."
    //   );
    //   return;
    // }
    if (passwords.new != passwords.repeated) {
      showToast('e', t('passwords_do_not_match'));
      return;
    }

    let { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/specialist/change_password`,
      { token, password: passwords.new, confirmedPassword: passwords.repeated },
      { withCredentials: true },
    );

    if (data == 'Invalid token') {
      showToast('e', t('password_change_expired'));
      return;
    }
    if (data == 'Password changed') {
      window.scroll(0, 0);
      showToast('s', t('password_successfully_changed'));
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    }
  }

  //!Рендеринг

  if (windowWidth < 1025) {
    return (
      <>
        <Toast show={toast.show} type={toast.type} msg={toast.msg} />

        <div className={styles.mobile_form_container}>
          <div className={styles.logo_container}>
            <Imgs img={'planguinLogo'} planguinLogoWidth={'200px'} />
          </div>

          <InputsTitle
            style={{ paddingTop: '50px' }}
            title={t('enter_new_password')}
          />
          <Input
            onChange={inputHandler}
            id={'new'}
            placeholder={t('new_password')}
            type={'password'}
          />
          <Input
            onChange={inputHandler}
            id={'repeated'}
            placeholder={t('confirm_password')}
            type={'password'}
          />

          <Button name={t('save')} onClick={sendData} />
          <Below_Input_Text2
            onClick={() => {
              navigate('/signup');
            }}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <Toast show={toast.show} type={toast.type} msg={toast.msg} />

      <Background>
        <div className={styles.outer_container}>
          <div className={styles.form_container}>
            <Imgs img={'planguinLogo'} />
            <InputsTitle
              style={{ paddingTop: '50px' }}
              title={t('enter_new_password')}
            />
            <Input
              onChange={inputHandler}
              id={'new'}
              placeholder={t('new_password')}
              type={'password'}
            />
            <Input
              onChange={inputHandler}
              id={'repeated'}
              placeholder={t('confirm_password')}
              type={'password'}
            />

            <Button name={t('save')} onClick={sendData} />
            <Below_Input_Text2
              onClick={() => {
                navigate('/signup');
              }}
            />
          </div>
        </div>
      </Background>
    </>
  );
}

export default ChangePassword;
