import React, { useState, useEffect } from 'react';
import styles from './EmailConfirmed.module.css';

import {
  Background,
  Button,
  Input,
  InputsTitle,
} from '../../Components/BasicComponents/BasicComponents';
import Imgs from '../../Imgs/Imgs';
import axios from 'axios';
import { checkAccess } from '../../access';
import PopUp from '../SignUp/Components/SuccessSignUpModal/SuccessSignUpModal';
import Toast from '../../Components/Toast/Toast';
import { useTranslation } from 'react-i18next';

function EmailConfirmed() {
  const { t } = useTranslation();
  let [page, setPage] = useState('empty');
  let [toast, setToast] = useState({ type: '', show: false, msg: '' });

  //Показати тост
  function showToast(type, msg) {
    if (type == 'e') {
      type = 'error';
    }
    if (type == 's') {
      type = 'success';
    }
    setToast({ type, show: true, msg });
    setTimeout(() => {
      setToast({ type: '', show: false, msg: '' });
    }, 2000);
  }

  //Парсинг токена
  function getTokenValueFromURL(url) {
    const urlParams = new URLSearchParams(new URL(url).search);
    return urlParams.get('token');
  }

  function getEmail(url) {
    let parts = url.split('=');
    return parts[2];
  }

  let token = getTokenValueFromURL(window.location.href);
  let email = getEmail(window.location.href);

  useEffect(() => {
    async function af() {
      let { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/specialists/email_confirmation`,
        { token },
        { withCredentials: true },
      );
      console.log(data);
      if (data == 'Invalid token') {
        setPage('error');
        return;
      }
      if (data) {
        if (window.innerWidth < 600) {
          window.location.href = '/registration_successful';
          return;
        }

        setPage('success');
      }
    }
    af();
  }, []);

  async function sendConfirmationLink() {
    let { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/specialist/confirmation_letter`,
      { token, email },
    );

    if (data == 'success') {
      window.location.href('/email_confirmation');
    }
  }

  if (page == 'success') {
    return (
      <>
        <PopUp type={'RegistrationSuccessful'} />
      </>
    );
  }

  if (page == 'empty') {
    return <></>;
  }

  if (page == 'error') {
    return (
      <>
        <Toast show={toast.show} type={toast.type} msg={toast.msg} />

        <Background>
          <div className={styles.outer_container}>
            <div className={styles.form_container}>
              <Imgs img={'planguinLogo'} />
              <InputsTitle
                style={{ paddingTop: '50px' }}
                title={`${t('email_confirmation_error')} ❌`}
              />
              <div style={{ textAlign: 'center' }}>
                {t('need_help')}?{' '}
                <span
                  onClick={() => {
                    window.open('https://t.me/planguin', '_blank');
                  }}
                  style={{ color: 'coral' }}
                >
                  {t('write_to_us')}
                </span>
              </div>
              <Button
                name={t('send_again_1')}
                onClick={sendConfirmationLink}
              />
            </div>
          </div>
        </Background>
      </>
    );
  }
}

export default EmailConfirmed;
