import { useLocation, useNavigate } from "react-router-dom";
import { useNotificationsStore } from "store";
import { classNames } from "utils/lib/classNames/classNames";
import styles from './NotificationButton.module.css';
import notificationsDefault from '../../Imgs/notificationsDefault.png';
import notificationsActive from '../../Imgs/notificationsActive.png';
import { useEffect } from "react";

export function NotificationButton(props) {
    const { notificationsExists, updateNotificationsExists } = useNotificationsStore()
    const location = useLocation()
    const navigate = useNavigate();
  
    useEffect(() => {
        updateNotificationsExists()
    }, [location.pathname])

    return (
        <button 
            type='button' 
            className={classNames(styles.notifications, {[styles.active]: notificationsExists})} 
            onClick={() => navigate('/notifications_messages')}
            >
            <img
                style={{ borderRadius: '50%' }}
                src={notificationsExists ? notificationsActive : notificationsDefault}
                alt=""
                width={'30px'}
            />
        </button>
    )
}