import { getSpecialistId } from 'access';
import { useDB } from 'utils/DB';
import { API } from 'utils/api';
import { create } from 'zustand';

export let useHelp = create((set, get) => ({
  msg: false,

  handleClick: () => {
    const host = window.location.origin;

    window.open(`${host}/support`, '_self');
  },

  checkMsgStatus: () => {
    if (getSpecialistId() == 'Не авторизований користувач') {
      return;
    }

    let specialist = useDB.getState().specialist;
    specialist.helpMessage ? set({ msg: true }) : set({ msg: false });
  },

  hideMsg: async () => {
    let data = await API.Specialist.updateProfile({ helpMessage: false });
    data && set({ msg: false });
  },
}));
