import { create } from 'zustand';

export let useSuccessSignUpModalStore = create((set, get) => ({
  SuccessSignUpModal: false,
  Note: false,

  changeSuccessSignUpModal: (state) => {
    set({ SuccessSignUpModal: state });
  },
  changeNote: (state) => {
    set({ Note: state });
  },
}));
