import React from 'react';
import styles from './СonfirmationModal.module.css';
import { Button } from '../BasicComponents/BasicComponents';
import { useClientsStore } from '../../ClientsStore';
import close from '../Images/Close.svg';
import { useTranslation } from 'react-i18next';

function СonfirmationModal(props) {
  const { t } = useTranslation();
  let { confirmationModal, hideConfirmationModal, deleteClient } =
    useClientsStore();

  if (confirmationModal) {
    return (
      <div className={styles.confirmationModal_container}>
        <div className={styles.confirmationModal_block}>
          <img
            onClick={hideConfirmationModal}
            className={styles.close_img}
            src={close}
            alt=""
          />
          <h2 className={styles.confirmationModal_title}>
            {t('delete_contact_confirmation')}
          </h2>
          <Button
            onClick={deleteClient}
            style={{ width: '200px' }}
            name={t('confirm')}
          />
        </div>
      </div>
    );
  }
}

export default СonfirmationModal;
