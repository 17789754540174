import React from 'react';
import { Button, Input } from './Components/BasicComponents/BasicComponents';
import Contact from './Components/ClientsBlock/ClientsBlock';
import styles from './Clients.module.css';
import { checkAccess } from '../../access';
import Header, { MobileHeader } from '../../Components/Header/Header';
import ClientsBlock from './Components/ClientsBlock/ClientsBlock';
import AddContactForm from './Components/AddContactForm/AddContactForm';
import ConfirmationModal from './Components/ConfirmationModal/СonfirmationModalContact';
import EditContactForm from './Components/EditContactForm/EditContactForm';
import ClientsMobile from './ClientsMobile/ClientsMobile';
import Toast2 from '../../Components/Toast2/Toast2';
import SendMassage from './Components/SendMassage/SendMassage';

function Clients() {
  let specialistId = checkAccess();

  window.innerWidth < 1024
    ? (document.body.style.backgroundColor = `white`)
    : (document.body.style.backgroundColor = '#F4F7FE');

  //Mobile
  if (window.innerWidth < 1024) {
    return (
      <>
        <Toast2 />
        <MobileHeader />
        <ClientsMobile />
      </>
    );
  }

  //Десктоп
  return (
    <>
      <Toast2 />
      <Header platform={'desktop'} specialistId={specialistId} />

      <div className={styles.container}>
        <ClientsBlock />
      </div>
      <SendMassage />
      <AddContactForm />
      <EditContactForm />
      <ConfirmationModal />
    </>
  );
}

export default Clients;
