import React, { useEffect, useState } from 'react';
import styles from './LanguageSwitcher.module.css';
import { useSettingsStore } from 'Pages/Settings/SettingsStore';
import { useTranslation } from 'react-i18next';


const LanguageSwitcher = () => {
  const {i18n} = useTranslation()
  const { inputSettingsHandler } = useSettingsStore()

  const [isOpen, setOpen] = useState(false)
  const [language, setLanguage] = useState('')

  const handleClick = (lang) => {
    inputSettingsHandler(undefined, 'language', lang )
    setLanguage(lang)
    setOpen(false)
  }
  
  useEffect(() => {
    setLanguage(i18n.language)
  }, []);

  return (
    <div className={styles.root}>
      <button className={styles.switcher} type='button' onClick={() => setOpen(prev => !prev)}>
        {language}
      </button>

      {
        isOpen && (
            <div className={styles.dropdown}>
              <button className={styles.dropdown_item} onClick={() => handleClick('uk')}>🇺🇦 </button>
              <button className={styles.dropdown_item} onClick={() => handleClick('en')}>🇬🇧 </button>
              <button className={styles.dropdown_item} onClick={() => handleClick('de')}>🇩🇪 </button>
              <button className={styles.dropdown_item} onClick={() => handleClick('ru')}>🇷🇺 </button>
            </div>
        )
      }
    </div>
  );
};

export default LanguageSwitcher;
