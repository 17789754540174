import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie';
import translationEN from './locales/en/translation.json';
import translationUA from './locales/ua/translation.json';
import translationDE from './locales/de/translation.json';
import translationRU from './locales/ru/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  uk: {
    translation: translationUA,
  },
  de: {
    translation: translationDE,
  },
  ru: {
    translation: translationRU,
  },
};

const getBrowserLanguage = () => {
  const language = navigator.language || navigator.languages[0];
  const langCode = language.split('-')[0]; 
  return resources[langCode] ? langCode : 'uk'; 
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: Cookies.get('language') ?? getBrowserLanguage(),
    fallbackLng: 'uk',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
