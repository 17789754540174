import React from 'react';
import styles from './EditPassword.module.css';
import { Button, Input } from 'Components/BasicComponents/BasicComponents';
import { useSettingsStore } from '../../SettingsStore';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function EditPassword() {
  const { t } = useTranslation();
  const {resetPassword, inputHandler, passwordResetSubmit, errors } = useSettingsStore()
  const navigate = useNavigate()

  
  return (
    <div className={styles.container}>
      <div className={styles.input_block}>
        <span className={styles.input_block__name}>{t('a_old_password')}</span>

        <Input
          style={{ width: '100%' }}
          id={'oldPassword'}
          value={resetPassword.oldPassword}
          onChange={inputHandler}
          placeholder={''}
          type='password'
        />
      </div>

      <div className={styles.link_reset}>{`${t('a_forgot_password')} `}
        <span className={styles.link} onClick={() => navigate('/reset_password')}>{t('a_reset_password')}</span> 
      </div>

      <div className={styles.input_block}>
        <span className={styles.input_block__name}>{t('a_new_password')}</span>

        <Input
          style={{ width: '100%' }}
          id={'newPassword'}
          value={resetPassword.newPassword}
          onChange={inputHandler}
          placeholder={''}
          type='password'
        />
      </div>

      <div className={styles.input_block}>
        <span className={styles.input_block__name}>{t('a_new_password')}</span>

        <Input
          style={{ width: '100%' }}
          id={'newPasswordRepeat'}
          value={resetPassword.newPasswordRepeat}
          onChange={inputHandler}
          placeholder={''}
          type='password'
        />
      </div>

      <div className={styles.errors}>{errors}</div>
      
      <Button color='black' disabled={!!errors} name={t('a_save_new_password')} onClick={passwordResetSubmit} />
    </div>
  );
}

export default EditPassword;
