import React, { useState, useEffect } from 'react';
import styles from './WeekSelector.module.css';
import Imgs from '../../../../Imgs/Imgs';
import moment from 'moment';
import { useWeekSelectorStore } from './WeekSelectorStore';

export function WeekSelector2(props) {
  let {
    currentWeek,
    getCurrentWeek,
    goToNextWeek,
    goToPreviousWeek,
    getDatesOfWeek,
  } = useWeekSelectorStore();

  useEffect(() => {
    getCurrentWeek();
    getDatesOfWeek();
  }, []);

  return (
    <>
      <div style={props.style} className={styles.week_selector_container}>
        <div onClick={goToPreviousWeek} className={styles.back}>
          <Imgs img={'back'} />
        </div>
        <div className={styles.week}>{convertDateRange(currentWeek)}</div>
        <div onClick={goToNextWeek} className={styles.next}>
          <Imgs img={'next'} />
        </div>
      </div>
    </>
  );
}

function convertDateRange(dateRange) {
  if (!dateRange) {
    return;
  }

  var dateRangeArr = dateRange.split(' - ');
  var startDateArr = dateRangeArr[0].split('/');
  var endDateArr = dateRangeArr[1].split('/');
  var startDate =
    startDateArr[2] + '/' + startDateArr[1] + '/' + startDateArr[0];
  var endDate = endDateArr[2] + '/' + endDateArr[1] + '/' + endDateArr[0];
  return startDate + ' - ' + endDate;
}
