import React, { useState } from 'react';

import { InputPicker, Toggle } from 'rsuite';
import styles from './ClientNotification.module.css';
import {
  Button,
  Selector,
  TextArea,
} from 'Components/BasicComponents/BasicComponents';
import { useTranslation } from 'react-i18next';

const timeOptions = [
  { label: '30 хв', value: 30 },
  { label: '45 хв', value: 45 },
  { label: '1 год', value: 60 },
  { label: '1 год 30 хв', value: 90 },
  { label: '2 год', value: 120 },
  { label: '2 год 30 хв', value: 150 },
  { label: '3 год', value: 180 },
  { label: '4 год', value: 240 },
];

function ClientNotification({
  title,
  value,
  onChange,
  picker,
  pickerValue,
  onSave,
  pickerText,
}) {
  const { t } = useTranslation();
  const [changes, setChanges] = useState(false);

  const handler = (k, v) => {
    setChanges(true);
    value[k] = v;
    onChange(value);
  };

  const remindClientTime = (
    <>
      <div className={styles.flex_container}>
        <div>{pickerText}</div>
        <InputPicker
          cleanable={false}
          onChange={(v) => {
            handler('minutes', v);
          }}
          value={pickerValue}
          data={timeOptions}
          style={{ width: 100 }}
        />
      </div>
    </>
  );

  const saveButonStyle = {
    isActive: {
      color: 'white',
      cursor: 'pointer',
      backgroundColor: 'white',
      border: '1px solid orange',
      borderRadius: '5px',
      padding: '5px 5px',
      backgroundColor: 'orange',
    },
    isInactive: {
      color: 'grey',
      cursor: 'default',
      display: 'none',
    },
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title_container}>
          <div className={styles.title}>{title}</div>
          <Toggle
            className={styles.toggle}
            checked={value?.status}
            onChange={(v) => {
              handler('status', v);
            }}
          />
        </div>

        {picker && remindClientTime}

        <div className={styles.flex_container}>
          <div>{t('sms_template')}</div>
          <div
            onClick={() => {
              onSave();
              setChanges(false);
            }}
            style={
              changes ? saveButonStyle.isActive : saveButonStyle.isInactive
            }
          >
            {t('save')}
          </div>
        </div>
        <TextArea
          onChange={(e) => {
            handler('template', e.target.value);
          }}
          value={value?.template}
        />
        <div style={{ color: 'blue' }}>
          {value?.template.length} {t('sms_characters')} /{' '}
          {Math.ceil(value?.template.length / 70)} SMS
        </div>

        <div>
          <p>
            <strong>{`{customer_name}`}</strong> - {t('customer_name_placeholder')}
          </p>
          <p>
            <strong>{`{date}`}</strong> – {t('date_placeholder')}
          </p>
          <p>
            <strong>{`{time}`}</strong> – {t('time_placeholder')}
          </p>
          <p>
            <strong>{`{company_name}`}</strong> – {t('company_name_placeholder')}
          </p>
          <p>
            <strong>{`{url}`}</strong> – {t('url_placeholder')}
          </p>
          <p>
            <strong>{`{service_name}`}</strong> - {t('service_name_variable_placeholder')}
          </p>
        </div>
      </div>
    </>
  );
}

export default ClientNotification;
