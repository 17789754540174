import React, { useEffect } from 'react';
import styles from './ServiceEditBlock.module.css';
import Imgs from '../../../../Imgs/Imgs';
import {
  Button,
  Input,
  Selector,
  TextArea,
} from '../../../../Components/BasicComponents/BasicComponents';
import { useServicesStore } from '../../ServicesStore';
import planguin from './Images/planguin.png';
import { fn } from 'moment/moment';
import Select from 'react-select';
import { useMobileClientsStore } from 'Pages/Clients/ClientsMobile/MobileClientsStore';
import { useClientsStore } from 'Pages/Clients/ClientsStore';
import { useTranslation } from 'react-i18next';

function ServiceEditBlock(props) {
  const { t } = useTranslation();
  let {
    service,
    categories,
    serviceInputHandler,
    editServiceWindow,
    addServiceWindow,
    addService,
    updateService,
    zaglushka,
    showDeleteConfirmationWindow,
    groupService,
    addGroupServiceWindow,
    editGroupServiceWindow,
    groupServiceInputHandler,
    addGroupService,
    updateGroupService,
    sectionOfgroupWindow,
  } = useServicesStore();

  if (zaglushka) {
    return (
      <>
        <div className={styles.zaglushka_container}>
          <img style={{ width: '350px' }} src={planguin} alt="" />
          <div className={styles.zaglushka_title}>
            {t('create_or_select_service')}
          </div>
        </div>
      </>
    );
  }

  if (addServiceWindow) {
    return (
      <div className={styles.container}>
        <div className={styles.inner_container}>
          <div className={styles.block_header}>
            <div className={styles.title}>{service.name}</div>
            <div onClick={props.onClick_Delete} className={styles.delete}>
              <Imgs img={'delete'} />
            </div>
          </div>

          <div className={styles.inputs_container}>
            <div className={styles.line1}>
              <div className={styles.name_label}>{t('category')}</div>
              <Selector
                style={{ color: 'coral' }}
                id={'category'}
                value={service.category}
                options={[t('no_category'), ...categories]}
                onChange={serviceInputHandler}
              />
            </div>

            <div className={styles.line1}>
              <div className={styles.name_label}>{t('service_name_placeholder')}</div>
              <Input
                id={'name'}
                onChange={serviceInputHandler}
                value={service.name}
              />
            </div>

            <div className={styles.line2}>
              <div className={styles.price}>
                <div className={styles.price_label}>{t('cost')}</div>
                <Input
                  id={'price'}
                  onChange={serviceInputHandler}
                  type={'withQ'}
                  value={service.price}
                />
              </div>

              <div className={styles.duration}>
                <div className={styles.duration_label}>
                  {t('duration')}
                </div>
                <Selector
                  value={service.hours}
                  id={'hours'}
                  onChange={serviceInputHandler}
                  style={{ width: '120px' }}
                  options={[0, 1, 2, 3, 4, 5, 6]}
                  label={t('hours')}
                />
                <Selector
                  value={service.minutes}
                  id={'minutes'}
                  onChange={serviceInputHandler}
                  style={{ width: '120px' }}
                  options={[0, 15, 30, 45]}
                  label={t('minutes')}
                />
              </div>
            </div>

            <div className={styles.line3}>
              <div className={styles.description_label}>{t('description')}</div>
              <TextArea
                id={'description'}
                onChange={serviceInputHandler}
                value={service.description}
              />
            </div>
          </div>

          <div className={styles.button_container}>
            {addServiceWindow ? (
              <Button
                name={t('add_service')}
                style={{ width: '190px', height: '50px' }}
                onClick={addService}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (editServiceWindow) {
    return (
      <div className={styles.container}>
        <div className={styles.inner_container}>
          <div className={styles.block_header}>
            <div className={styles.title}>{service.name}</div>

            <div className={styles.delete}>
              <Imgs
                onClick={() => {
                  showDeleteConfirmationWindow('service');
                }}
                img={'delete'}
              />
            </div>
          </div>

          <div className={styles.inputs_container}>
            <div className={styles.line1}>
              <div className={styles.name_label}>{t('category')}</div>
              <Selector
                style={{ color: 'coral' }}
                id={'category'}
                value={service.category}
                options={[t('no_category'), ...categories]}
                onChange={serviceInputHandler}
              />
            </div>

            <div className={styles.line1}>
              <div className={styles.name_label}>{t('service_name_placeholder')}</div>
              <Input
                id={'name'}
                onChange={serviceInputHandler}
                value={service.name}
              />
            </div>

            <div className={styles.line2}>
              <div className={styles.price}>
                <div className={styles.price_label}>{t('cost')}</div>
                <Input
                  id={'price'}
                  onChange={serviceInputHandler}
                  type={'withQ'}
                  value={service.price}
                />
              </div>

              <div className={styles.duration}>
                <div className={styles.duration_label}>
                  {t('duration')}
                </div>
                <Selector
                  value={service.hours}
                  id={'hours'}
                  onChange={serviceInputHandler}
                  style={{ width: '120px' }}
                  options={[0, 1, 2, 3, 4, 5, 6]}
                  label={t('hours')}
                />
                <Selector
                  value={service.minutes}
                  id={'minutes'}
                  onChange={serviceInputHandler}
                  style={{ width: '120px' }}
                  options={[0, 15, 30, 45]}
                  label={t('minutes')}
                />
              </div>
            </div>

            <div className={styles.line3}>
              <div className={styles.description_label}>{t('description')}</div>
              <TextArea
                id={'description'}
                onChange={serviceInputHandler}
                value={service.description}
              />
            </div>
          </div>

          <div className={styles.button_container}>
            {editServiceWindow ? (
              <Button
                color={'black'}
                name={t('save_changes')}
                style={{ width: '190px', height: '50px' }}
                onClick={updateService}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  }

  //Групові

  if (addGroupServiceWindow) {
    return (
      <div style={{ height: '100%' }} className={styles.container}>
        <div className={styles.inner_container}>
          <div className={styles.block_header}>
            <div className={styles.title}> 👥 {groupService.name}</div>

            <div
              style={{
                display: 'flex',
                columnGap: '30px',
                alignItems: 'center',
              }}
            >
              <SectionHandler />
            </div>
          </div>

          {sectionOfgroupWindow == 'інформація' ? (
            <>
              <div className={styles.inputs_container}>
                <div className={styles.line1}>
                  <div className={styles.name_label}>{t('service_name_placeholder')}</div>
                  <Input
                    id={'name'}
                    onChange={groupServiceInputHandler}
                    value={groupService.name}
                  />
                </div>

                <div className={styles.line2}>
                  <div className={styles.price}>
                    <div className={styles.price_label}>{t('cost')}</div>
                    <Input
                      id={'price'}
                      onChange={groupServiceInputHandler}
                      type={'withQ'}
                      value={groupService.price}
                    />
                  </div>

                  <div className={styles.duration}>
                    <div className={styles.duration_label}>
                      {t('duration')}
                    </div>
                    <Selector
                      value={groupService.hours}
                      id={'hours'}
                      onChange={groupServiceInputHandler}
                      style={{ width: '120px' }}
                      options={[0, 1, 2, 3, 4, 5, 6]}
                      label={t('hours')}
                    />
                    <Selector
                      value={groupService.minutes}
                      id={'minutes'}
                      onChange={groupServiceInputHandler}
                      style={{ width: '120px' }}
                      options={[0, 15, 30, 45]}
                      label={t('minutes')}
                    />
                  </div>
                </div>

                <div className={styles.line3}>
                  <div className={styles.description_label}>{t('description')}</div>
                  <TextArea
                    id={'description'}
                    onChange={groupServiceInputHandler}
                    value={groupService.description}
                  />
                </div>

                <div className={styles.line1}>
                  <div className={styles.name_label}>{t('participants')}</div>
                  <Input
                    id={'maxNumberOfParticipants'}
                    onChange={groupServiceInputHandler}
                    value={groupService.maxNumberOfParticipants}
                    style={{ width: '100px' }}
                    type={'number'}
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {sectionOfgroupWindow == 'розклад' ? (
            <>
              <div
                style={{ height: '450px' }}
                className={styles.inputs_container}
              >
                <div style={{}} className={styles.line1}>
                  <div className={styles.name_label}>{t('group_schedule')}</div>
                  <div></div>
                </div>

                <div
                  style={{ position: 'relative', top: '-55px' }}
                  className={styles.line1}
                >
                  <div style={{}} className={styles.name_label}></div>

                  <GroupSchedule />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {sectionOfgroupWindow == 'учасники' ? (
            <>
              <div
                style={{ minHeight: '450px' }}
                className={styles.inputs_container}
              >
                <SearchClient />
              </div>
            </>
          ) : (
            <></>
          )}

          <div className={styles.button_container}>
            <Button
              name={t('add_group_service')}
              style={{
                width: '250px',
                height: '50px',
                marginTop: '-70px',
                zIndex: 1,
              }}
              onClick={addGroupService}
            />

            <></>
          </div>
        </div>
      </div>
    );
  }

  if (editGroupServiceWindow) {
    return (
      <div style={{ height: '100%' }} className={styles.container}>
        <div className={styles.inner_container}>
          <div className={styles.block_header}>
            <div className={styles.title}> 👥 {groupService.name}</div>

            <div
              style={{
                display: 'flex',
                columnGap: '30px',
                alignItems: 'center',
              }}
            >
              <SectionHandler />
              <div className={styles.delete}>
                <Imgs
                  onClick={() => {
                    showDeleteConfirmationWindow('groupService');
                  }}
                  img={'delete'}
                />
              </div>
            </div>
          </div>

          {sectionOfgroupWindow == 'інформація' ? (
            <>
              <div className={styles.inputs_container}>
                <div className={styles.line1}>
                  <div className={styles.name_label}>{t('service_name_placeholder')}</div>
                  <Input
                    id={'name'}
                    onChange={groupServiceInputHandler}
                    value={groupService.name}
                  />
                </div>

                <div className={styles.line2}>
                  <div className={styles.price}>
                    <div className={styles.price_label}>{t('cost')}</div>
                    <Input
                      id={'price'}
                      onChange={groupServiceInputHandler}
                      type={'withQ'}
                      value={groupService.price}
                    />
                  </div>

                  <div className={styles.duration}>
                    <div className={styles.duration_label}>
                      {t('duration')}
                    </div>
                    <Selector
                      value={groupService.hours}
                      id={'hours'}
                      onChange={groupServiceInputHandler}
                      style={{ width: '120px' }}
                      options={[0, 1, 2, 3, 4, 5, 6]}
                      label={t('hours')}
                    />
                    <Selector
                      value={groupService.minutes}
                      id={'minutes'}
                      onChange={groupServiceInputHandler}
                      style={{ width: '120px' }}
                      options={[0, 15, 30, 45]}
                      label={t('minutes')}
                    />
                  </div>
                </div>

                <div className={styles.line3}>
                  <div className={styles.description_label}>{t('description')}</div>
                  <TextArea
                    id={'description'}
                    onChange={groupServiceInputHandler}
                    value={groupService.description}
                  />
                </div>

                <div className={styles.line1}>
                  <div className={styles.name_label}>{t('participants')}</div>
                  <Input
                    id={'maxNumberOfParticipants'}
                    onChange={groupServiceInputHandler}
                    value={groupService.maxNumberOfParticipants}
                    style={{ width: '100px' }}
                    type={'number'}
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {sectionOfgroupWindow == 'розклад' ? (
            <>
              <div
                style={{ height: '450px' }}
                className={styles.inputs_container}
              >
                <div style={{}} className={styles.line1}>
                  <div className={styles.name_label}>{t('group_schedule')}</div>
                  <div></div>
                </div>

                <div
                  style={{ position: 'relative', top: '-55px' }}
                  className={styles.line1}
                >
                  <div style={{}} className={styles.name_label}></div>

                  <GroupSchedule />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {sectionOfgroupWindow == 'учасники' ? (
            <>
              <div
                style={{ minHeight: '450px' }}
                className={styles.inputs_container}
              >
                <SearchClient />
              </div>
            </>
          ) : (
            <></>
          )}

          <div className={styles.button_container}>
            <Button
              color={'black'}
              name={t('save_changes')}
              style={{ width: '250px', height: '50px', marginTop: '-20px' }}
              onClick={updateGroupService}
            />

            <></>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceEditBlock;

//!Допоміжні компоненти

export function Client(props) {
  return (
    <>
      <div className={styles.client_container}>
        <div onClick={props.onClick} className={styles.client_block}>
          <div className={styles.client_image}>{props.initials}</div>
          <div className={styles.client_name}>
            {props.name} {props.surname}
          </div>
          {props.phone == '+380' || !props.phone ? (
            <></>
          ) : (
            <div className={styles.client_tel}>{props.phone}</div>
          )}
        </div>
        <div className={styles.channels}>
          <Imgs
            style={{ marginRight: '15px' }}
            img={'deleteInterval'}
            onClick={props.onClick_Delete}
          />
          {/* {props.phone =='+380'|| !props.phone ? <></>: 
    <><img src={telegram} style={{width:'30px'}} onClick={()=>{window.open(`https://t.me/${props.phone}`,'_blank')}} alt=""  />
    <img src={viber} style={{width:'30px'}} onClick={()=>{window.open(`viber://chat/?number=%2B${props.phone.replace(/\D/g, "")}`)}} alt="" />
    </>
    } */}
          {/* <img src={mail} style={{width:'30px'}} onClick={props.onClickMsg} alt="" /> */}
        </div>
      </div>
    </>
  );
}

export function SearchClient() {
  const { t } = useTranslation();
  let {
    clientSearchInputHandler,
    clientSearchInputValue,
    clientsOptions,
    getSpecialistClients,
    persistentGroupMembers,
    deletePersistentGroupMember,
  } = useServicesStore();

  let { showAddContactForm } = useClientsStore();

  const colorStyles = {
    option: (styles, { data, isSelected }) => {
      return {
        ...styles,
        color: isSelected ? 'white' : 'green',
        color: data.color,
      };
    },
  };

  useEffect(() => {
    async function af() {
      await getSpecialistClients();
    }
    af();
  }, []);

  return (
    <>
      <div className={styles.search_client_container}>
        <div style={{ width: '600px' }}>
          <Select
            placeholder={t('select_existing_client')}
            options={clientsOptions}
            inputValue={undefined}
            value={clientSearchInputValue}
            onChange={clientSearchInputHandler}
            noOptionsMessage={() => t('no_contacts_found')}
            styles={colorStyles}
          />
        </div>
        {window.innerWidth > 1025 ? (
          <div style={{ color: 'grey' }}>
            {' '}
            {t('or')} &nbsp;{' '}
            <span
              id={'groupService'}
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={showAddContactForm}
            >
              {t('add_new')}
            </span>{' '}
          </div>
        ) : (
          <div style={{ color: 'grey' }}>
            {' '}
            <span
              id={'groupService'}
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={showAddContactForm}
            >
              {t('add_new')}
            </span>{' '}
          </div>
        )}
      </div>
      <div className={styles.clients_container}>
        {persistentGroupMembers?.map((el, index) => {
          return (
            <>
              <Client
                onClick_Delete={() => {
                  deletePersistentGroupMember(index);
                }}
                initials={el.name[0]}
                name={el.name}
                surname={el.surname}
                phone={el.phone}
              />
            </>
          );
        })}
      </div>
    </>
  );
}

export function SectionHandler() {
  const { t } = useTranslation();
  let { sectionOfgroupWindow, setSectionOfgroupWindow } = useServicesStore();

  return (
    <>
      <div className={styles.sections}>
        <div
          onClick={() => {
            setSectionOfgroupWindow('інформація');
          }}
          style={
            sectionOfgroupWindow == 'інформація' ? { color: '#FF8A35' } : {}
          }
        >
          {t('info')}
        </div>

        <div
          onClick={() => {
            setSectionOfgroupWindow('розклад');
          }}
          style={sectionOfgroupWindow == 'розклад' ? { color: '#FF8A35' } : {}}
        >
          {t('schedule')}
        </div>

        <div
          onClick={() => {
            setSectionOfgroupWindow('учасники');
          }}
          style={sectionOfgroupWindow == 'учасники' ? { color: '#FF8A35' } : {}}
        >
          {' '}
          {t('regular_participants')}
        </div>
      </div>
    </>
  );
}

function GroupTimeSlot(props) {
  const { t } = useTranslation();
  const daysOfWeek = [
    {value: 'Понеділок', label: t('monday') },
    {value: 'Вівторок', label: t('tuesday') },
    {value: 'Середа', label: t('wednesday') },
    {value: 'Четвер', label: t('thursday') },
    {value: 'Пятниця', label: t('friday') },
    {value: 'Субота', label: t('saturday') },
    {value: 'Неділя', label: t('sunday') }
  ];

  return (
    <>
      <div className={styles.group_time_slot_container}>
        <Selector
          onChange={props.onChange}
          id={'day'}
          style={{ width: '150px' }}
          value={props.day_value}
          options={daysOfWeek}
        />

        <div>{t('start_time')}</div>
        <Selector
          onChange={props.onChange}
          id={'start'}
          style={{ width: '150px' }}
          value={props.start_value}
          options={times}
        />

        <Imgs onClick={props.onClickDelete} img={'deleteInterval'} />
      </div>
    </>
  );
}

export function GroupSchedule() {
  const { t } = useTranslation();
  let {
    groupTimeSlots,
    addGroupTimeSlot,
    deleteGroupTimeSlot,
    groupTimeslotInputHandler,
  } = useServicesStore();

  return (
    <>
      <div className={styles.group_time_slots_container}>
        {groupTimeSlots.map((el, i) => {
          return (
            <GroupTimeSlot
              onClickDelete={() => {
                deleteGroupTimeSlot(i);
              }}
              day_value={el.day}
              start_value={el.start}
              onChange={(e) => {
                groupTimeslotInputHandler(e, i);
              }}
            />
          );
        })}
        <div onClick={addGroupTimeSlot} style={{ color: '#1166E8' }}>
          {t('add_time')}
        </div>
      </div>
    </>
  );
}

const generateTimes = (intervalMinutes) => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += intervalMinutes) {
      const formattedHour = String(hour).padStart(2, '0');
      const formattedMinute = String(minute).padStart(2, '0');
      times.push(`${formattedHour}:${formattedMinute}`);
    }
  }
  return times;
};

const times = generateTimes(15);

