import { Selector } from 'Components/BasicComponents/BasicComponents';
import styles from './StatsBlock.module.css';
import { useStatsBlock } from './useStatsBlock';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function StatsBlock() {
  const { t } = useTranslation();
  let {
    statsCategory,
    serviceTypes,
    serviceType,
    serviceTypeHandler,
    getStats,
    totalIndividulAppointments,
    individualServicesStats,
    clientsAppointmentsCountForIndividualService,
    totalGroupAppointments,
    groupServicesStats,
    clientsStatsOfGroupServices,
  } = useStatsBlock();

  useEffect(() => {
    async function af() {
      await getStats();
    }
    af();
  }, []);

  const clientCount = useCallback(
    () => {
      return serviceType === t('serviceTypes.1') ? clientsStatsOfGroupServices.length : clientsAppointmentsCountForIndividualService.length
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serviceType, clientsStatsOfGroupServices, clientsAppointmentsCountForIndividualService],
  )
  
  let innerStyles = {
    selector: {
      backgroundColor: 'transparent',
      width: '190px',
      backgroundColor: '#F4F5F7',
      padding: '5px',
      width: 'fit-content',
    },
    total_appointments: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
  };

  let individualAppointments = (
    <>
      {window.innerWidth < 1024 && <div style={{ marginTop: '20px' }}></div>}

      <div className={styles.stats1}>
        {t('entries_created')}:
        <span style={innerStyles.total_appointments}>
          &nbsp; {totalIndividulAppointments}
        </span>
      </div>

      <div className={styles.table_header}>
        <div>{t('service_name')}</div>
        <div>{t('number_of_entries')}</div>
      </div>

      {individualServicesStats.map((service) => (
        <Service
          serviceName={service?.serviceName}
          appointmentsNumber={service?.appointmentsNumber}
        />
      ))}
    </>
  );

  let groupAppointments = (
    <>
      {window.innerWidth < 1024 && <div style={{ marginTop: '20px' }}></div>}

      <div className={styles.stats1}>
        {t('entries_created')}:
        <span style={innerStyles.total_appointments}>
          &nbsp; {totalGroupAppointments}
        </span>
      </div>

      <div className={styles.table_header}>
        <div>{t('service_name')}</div>
        <div>{t('number_of_entries')}</div>
      </div>
      {groupServicesStats.map((service) => (
        <Service
          serviceName={service?.groupServiceName}
          appointmentsNumber={service?.appointments}
        />
      ))}
    </>
  );

  let clientsOfIndividualAppointments = (
    <>
      {window.innerWidth < 1024 && <div style={{ marginTop: '20px' }}></div>}

      <div className={styles.stats1}>
        {t('total_clients')}:
        <span style={innerStyles.total_appointments}>&nbsp; {clientCount()}</span>
      </div>

      {/* <div className={styles.stats1}>
          Нові клієнти: 
          <span style={innerStyles.total_appointments}>&nbsp; {newClients}</span>
        </div> */}

      <div className={styles.table_header}>
        <div>{t('client')}</div>
        <div>{t('number_of_entries')}</div>
      </div>

      {clientsAppointmentsCountForIndividualService.map((client) => (
        <Client
          clientName={`${client.clientName} `}
          appointmentsNumber={client.appointments}
        />
      ))}
    </>
  );

  let clientsOfGroupAppointments = (
    <>
      {window.innerWidth < 1024 && <div style={{ marginTop: '20px' }}></div>}

      <div className={styles.stats1}>
        {t('total_clients')}:
        <span style={innerStyles.total_appointments}>&nbsp; {clientCount()}</span>
      </div>

      {/* <div className={styles.stats1}>
          Нові клієнти: 
          <span style={innerStyles.total_appointments}>&nbsp; {newClients}</span>
        </div> */}

      <div className={styles.table_header}>
        <div>{t('client')}</div>
        <div>{t('number_of_entries')}</div>
      </div>

      {clientsStatsOfGroupServices.map((client) => (
        <Client
          clientName={`${client.clientName} ${client.surname}`}
          appointmentsNumber={client.appointments}
        />
      ))}
    </>
  );

  let statsOfIndividualServices = (
    <>
      {statsCategory === t('statsCategory.0') && individualAppointments}
      {statsCategory === t('statsCategory.1') && clientsOfIndividualAppointments}
    </>
  );

  let statsOfGroupServices = (
    <>
      {statsCategory === t('statsCategory.0') && groupAppointments}
      {statsCategory === t('statsCategory.1') && clientsOfGroupAppointments}
    </>
  );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container_header}>
          <StatsCategorySelector />
          <Selector
            value={serviceType}
            onChange={serviceTypeHandler}
            style={innerStyles.selector}
            options={serviceTypes}
          />
        </div>

        {serviceType === t('serviceTypes.0') && statsOfIndividualServices}
        {serviceType === t('serviceTypes.1') && statsOfGroupServices}
      </div>
    </>
  );
}

export default StatsBlock;

//Підкомпоненти

function StatsCategorySelector() {
  const { t } = useTranslation();
  let { statsCategory, selectCategory } = useStatsBlock();

  let fontStyles = {
    nonActive: {
      fontSize: '20px',
      color: '#979797',
      cursor: 'pointer',
    },

    active: {
      fontSize: '20px',
      color: '#FF8A35',
      cursor: 'pointer',
    },
  };

  return (
    <>
      <div className={styles.category_selector_container}>
        <span
          id={t('statsCategory.0')}
          onClick={selectCategory}
          style={fontStyles[statsCategory === t('statsCategory.0') ? 'active' : 'nonActive']}
        >
          {t('bookings')}
        </span>

        <span
          id={t('statsCategory.1')}
          onClick={selectCategory}
          style={
            fontStyles[statsCategory === t('statsCategory.1') ? 'active' : 'nonActive']
          }
        >
          {t('clients')}
        </span>
      </div>
    </>
  );
}

function Service({ serviceName, appointmentsNumber }) {
  return (
    <>
      <div className={styles.service_container}>
        <div className={styles.serviceName}>{serviceName} </div>
        <div style={{ fontSize: '18px' }}>{appointmentsNumber}</div>
      </div>
    </>
  );
}

function Client({ clientName, appointmentsNumber }) {
  return (
    <>
      <div className={styles.service_container}>
        <div className={styles.serviceName}>{clientName} </div>
        <div style={{ fontSize: '18px' }}>{appointmentsNumber}</div>
      </div>
    </>
  );
}
