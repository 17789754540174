import React, { useEffect, useState } from 'react';
import styles from './SocialLinksBlock.module.css';
import { Button, Input } from '../BasicComponents/BasicComponents';
import { InputsTitle } from '../BasicComponents/BasicComponents';
import axios from 'axios';
import Imgs from '../../Imgs/Imgs';
import { useNavigate } from 'react-router-dom';
import { useDB } from 'utils/DB';
import { API } from 'utils/api';
import { useTranslation } from 'react-i18next';

function SocialLinksBlock(props) {
  const { t } = useTranslation();
  let navigate = useNavigate();

  let DB = useDB();

  let specialistId = props.specialistId;
  let [specialist, setSpecialist] = useState({});

  //Обробник інпута
  function inputHandler(e) {
    setSpecialist((ps) => ({ ...ps, [e.target.id]: e.target.value }));
  }

  //Зберегти зміни
  async function saveLinks() {
    let data = await API.Specialist.updateProfile(specialist);

    if (data) {
      window.location.href = '/profile/specialist';
    }
  }

  useEffect(() => {
    async function af() {
      setSpecialist(DB.specialist);
    }
    af();
  }, []);

  //! Рендеринг

  if (props.show) {
    if (props.platform == 'mobile') {
      return (
        <>
          <div className={styles.mobile_container}>
            <div className={styles.title}>{t('communication_channels')}</div>
            <Input
              type={'label2'}
              id={'instagram'}
              label={true}
              onChange={inputHandler}
              placeholder={`name`}
              labelName={'Instagram'}
              value={specialist.instagram}
            />
            <Input
              type={'label2'}
              id={'facebook'}
              label={true}
              onChange={inputHandler}
              placeholder={`name`}
              labelName={'Facebook'}
              value={specialist.facebook}
            />
            <Input
              type={'label2'}
              id={'tiktok'}
              label={true}
              onChange={inputHandler}
              placeholder={`name`}
              labelName={'TikTok'}
              value={specialist.tiktok}
            />
            <Input
              type={'label2'}
              id={'whatsapp'}
              label={true}
              onChange={inputHandler}
              placeholder={`WhatsApp`}
              value={specialist.whatsapp}
            />
            <Input
              type={'label2'}
              id={'viber'}
              label={true}
              onChange={inputHandler}
              placeholder={`+380`}
              value={specialist.viber}
            />
            <Input
              type={'label2'}
              id={'telegram'}
              label={true}
              onChange={inputHandler}
              placeholder={`@nickname`}
              labelName={'Telegram'}
              value={specialist.telegram}
            />

            <div className={styles.buttons_container}>
              <Button name={t('save')} onClick={saveLinks} />
              <Button
                onClick={() => {
                  window.location.href = '/profile/specialist';
                }}
                name={t('back')}
                style={{
                  backgroundColor: '#ffff',
                  color: 'grey',
                  height: '50px',
                }}
              />
            </div>
          </div>
        </>
      );
    }

    if (props.platform == 'desktop') {
      return (
        <>
          <div className={styles.popup_background}>
            <div className={styles.popup_window}>
              <div className={styles.title_container}>
                <InputsTitle title={`${t('communication_channels')}👇`} />
                <Imgs img={'close'} onClick={props.onClose} />
              </div>
              <Input
                type={'label2'}
                id={'instagram'}
                label={true}
                onChange={inputHandler}
                placeholder={`name`}
                labelName={'Instagram'}
                value={specialist.instagram}
              />
              <Input
                type={'label2'}
                id={'facebook'}
                label={true}
                onChange={inputHandler}
                placeholder={`name`}
                labelName={'Facebook'}
                value={specialist.facebook}
              />
              <Input
                type={'label2'}
                id={'tiktok'}
                label={true}
                onChange={inputHandler}
                placeholder={`name`}
                labelName={'TikTok'}
                value={specialist.tiktok}
              />
              <Input
                type={'label2'}
                id={'whatsapp'}
                label={true}
                onChange={inputHandler}
                placeholder={`WhatsApp`}
                value={specialist.whatsapp}
              />
              <Input
                type={'label2'}
                id={'viber'}
                label={true}
                onChange={inputHandler}
                placeholder={`+380`}
                value={specialist.viber}
              />
              <Input
                type={'label2'}
                id={'telegram'}
                label={true}
                onChange={inputHandler}
                placeholder={`@nickname`}
                labelName={'Telegram'}
                value={specialist.telegram}
              />

              <Button name={t('save')} onClick={saveLinks} />
            </div>
          </div>
        </>
      );
    }
  }
}

export default SocialLinksBlock;
