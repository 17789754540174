import React, { useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import moment from 'moment';
import 'react-day-picker/dist/style.css';
import '../DayPickerForCalendar/day-picker.css';
import { useWeekSelectorStore } from '../WeekSelector/WeekSelectorStore';
import { useTranslation } from 'react-i18next';
import { enUS, uk, de, ru } from 'date-fns/locale'; // Імпортуємо локалі з date-fns


function DayPickerForCalendar() {
  const { t, i18n } = useTranslation(); 
  let { setCurrentWeekByDate } = useWeekSelectorStore();
  const today = new Date();

  let [selectedDay, setSelectedDay] = useState(today);
  const localeMap = {
    en: enUS,
    uk: uk,
    de: de,
    ru: ru,
  };

  const [currentLocale, setCurrentLocale] = useState(localeMap[i18n.language] || enUS);

  useEffect(() => {
    setCurrentLocale(localeMap[i18n.language] || enUS);
  }, [i18n.language]);

  return (
    <>
      <DayPicker
        onDayClick={(date) => {
          setCurrentWeekByDate(date);
        }}
        mode="single"
        selected={selectedDay}
        onSelect={setSelectedDay}
        locale={currentLocale}
      />
    </>
  );
}

export default DayPickerForCalendar;
