import React from 'react';
import styles from './ClientsMobile.module.css';
import AllClients from './AllClients/AllClients';
import AddClient from './AddClient/AddClient';
import EditClient from './EditClient/EditClient';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import { useMobileClientsStore } from './MobileClientsStore';
import SendMessage from './SendMessage/SendMessage';

function ClientsMobile() {
  let { page } = useMobileClientsStore();

  if (page == 'AllClients') {
    return <AllClients />;
  }
  if (page == 'AddClient') {
    return <AddClient />;
  }
  if (page == 'EditClient') {
    return <EditClient />;
  }
  if (page == 'Send Message') {
    return <SendMessage />;
  }
}

export default ClientsMobile;
