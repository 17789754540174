import Cookies from "js-cookie";
import { CurrencyEnum, LanguageEnum } from "utils/enums";

export const useCurrency = () => {
  const currencyId = Cookies.get('currencyId')

  const language = navigator.language || navigator.languages[0];
  const langCode = language.split('-')[0]; 

  const currency = CurrencyEnum[currencyId] ?? CurrencyEnum[langCode] ?? CurrencyEnum[LanguageEnum.En]

  return { currency }
};