import React from 'react';
import { BlockTitle, Function } from '../LandingComponents';
import styles from './Block5.module.css';
import stylesM from './Block5M.module.css';

import img1 from '../../Images/group-icon.png';
import img2 from '../../Images/img2.png';
import img3 from '../../Images/img3.png';
import img4 from '../../Images/img4.png';
import { useTranslation } from 'react-i18next';

function Block5(props) {
  const { t } = useTranslation();

  if (props.type == 'm') {
    return (
      <>
        <div className={stylesM.container}>
          <BlockTitle title={t('additional_functionality_in_development')} type={'m'} />

          <div className={stylesM.functions}>
            <Function
              type={'m'}
              img={img1}
              text={t('interface_design_customization')}
            />
            <Function
              type={'m'}
              img={img2}
              text={t('specialist_income_calculation')}
            />
            <Function type={'m'} img={img3} text={t('online_payment_for_services')} />
            <Function
              type={'m'}
              img={img4}
              text={t('and_much_more_for_improving_work')}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.inner_container}>
          <div className={styles.title}>{t('additional_functionality_in_development')}</div>
          <div className={styles.functions}>
            <Function img={img1} text={t('interface_design_customization')} />
            <Function img={img2} text={t('specialist_income_calculation')} />
            <Function img={img3} text={t('online_payment_for_services')} />
            <Function
              img={img4}
              text={t('and_much_more_for_improving_work')}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Block5;
