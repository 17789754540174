import { API } from 'utils/api';
import { create } from 'zustand';
import { useAppointmentsStore } from '../AppointmentsBlock/AppointmentsStore';
import { useClientsStore } from 'Pages/Clients/ClientsStore';

export let useAddGroupMembersStore = create((set, get) => ({
  state: 'button',
  clients: [],
  clientsOptions: [],
  clientSearchInputValue: undefined,

  changeState: (state) => {
    set({ state });
  },

  getSpecialistClients: async () => {
    let data = await API.Client.getAll();
    let clientsOptions = data.map((client) => {
      return {
        value: client._id,
        label: `${client.name} ${client.surname}`,
        phone: client.phone,
      };
    });
    clientsOptions = [
      ...clientsOptions,
      { value: 'addNew', label: '+ Додати нового', color: '#FF8A35' },
    ];
    set({ clients: data, clientsOptions: clientsOptions });
  },

  clientSearchInputHandler: (data) => {
    set({
      clientSearchInputValue: {
        value: undefined,
        label: 'Оберіть клієнта або створіть нового ',
        color: '#F4F5F7',
      },
    });

    if (data.value == 'addNew') {
      set({ state: 'button' });

      useAppointmentsStore.getState().changeGroupAppointmentWindowState(false);
      useClientsStore.getState().showAddContactForm();

      return;
    }

    let [newMember] = get().clients.filter((client) => {
      return client._id == data.value;
    });

    let groupMembers = useAppointmentsStore.getState().groupMembers;
    useAppointmentsStore.setState({
      groupMembers: [...groupMembers, newMember],
    });
    set({ state: 'button' });
  },
}));
