import React, { useEffect } from 'react';
import styles from './SendMessage.module.css';
import backImg from '../Images/Vector.svg';
import { useMobileClientsStore } from '../MobileClientsStore';
import {
  InputWidthLabel,
  TextArea,
  Button,
} from '../../Components/BasicComponents/BasicComponents';
import { SelectWidthLabel } from '../../Components/BasicComponents/BasicComponents';

const options = [
  { value: 'sms', label: 'SMS' },
  { value: 'email', label: 'Email' },
];

function SendMessage() {
  let {
    goToPage,
    message,
    msgModalInputHandler,
    sms,
    sendMessageToClient,
    specialist,
    getSpecialistInfo,
  } = useMobileClientsStore();

  useEffect(() => {
    async function af() {
      await getSpecialistInfo();
    }
    af();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.sendMessage_header}>
          <img
            onClick={() => {
              goToPage('AllClients');
            }}
            src={backImg}
            alt="back"
          />
          <h1 className={styles.sendMessage_title}>Надіслати повідомлення</h1>
        </div>

        <InputWidthLabel
          disabled
          style={{
            marginBottom: '0px',
            backgroundColor: 'white',
            paddingTop: '0px',
            paddingLeft: '10px',
            fontSize: '16px',
            fontWeight: 'bold',
            caret: 'none',
          }}
          id={''}
          value={message.clientName}
          label="Кому:"
          name="send"
          type="text"
        />

        <SelectWidthLabel
          onChange={msgModalInputHandler}
          options={options}
          id={'channel'}
          select={styles.sendMassage_input}
          label="Метод:"
          name="select"
        />
        <TextArea
          value={message.msg}
          onChange={msgModalInputHandler}
          name="Повідомлення:"
          id="msg"
        />

        {message.channel == 'sms' ? (
          <div className={styles.sms_data}>
            <div>
              Cимволів:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {sms.symbols} / {sms.numberOfsms} SMS
              </span>{' '}
            </div>
            <div>"{specialist?.visibleName}"</div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className={styles.button_container}>
        <Button
          style={{ marginTop: '25px' }}
          onClick={sendMessageToClient}
          name="Надіслати повідомлення"
        />
      </div>
    </>
  );
}

export default SendMessage;
