import React from 'react';
import Images from '../../Images';
import { Advantage, BlockTitle } from '../LandingComponents';
import styles from './Block2.module.css';
import stylesM from './Block2M.module.css';
import planguin2 from '../../Images/planguin2.png';
import { useTranslation } from 'react-i18next';

function Block2(props) {
  const { t } = useTranslation();
  if (props.type == 'm') {
    return (
      <>
        <div className={stylesM.container}>
          <BlockTitle type={'m'} title={t('planguin_info')} />

          <div className={stylesM.advantages}>
            <Advantage
              icon={<Images img={'icon1'} />}
              title={t('simple_setup')}
              description={t('quick_start_without_details')}
            />

            <Advantage
              icon={<Images img={'icon2'} />}
              title={t('increased_loyalty')}
              description={t('client_info_link')}
            />

            <Advantage
              icon={<Images img={'icon3'} />}
              title={t('schedule_systematization')}
              description={t('set_convenient_schedule')}
            />

            <Advantage
              icon={<Images img={'icon4'} />}
              title={t('time_saving')}
              description={t('reduce_time_spent_on_communication')}
            />

            <Advantage
              icon={<Images img={'icon5'} />}
              title={t('no_admin_needed')}
              description={t('reduce_time_spent_on_communication')}
            />

            <img src={planguin2} alt="" width={'153px'} />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.inner_container}>
        <BlockTitle title={t('planguin_info')} />
        <div className={styles.line1}>
          <Advantage
            icon={<Images img={'icon1'} />}
            title={t('simple_setup')}
            description={t('quick_start_without_details')}
          />

          <Advantage
            icon={<Images img={'icon2'} />}
            title={t('increased_loyalty')}
            description={t('client_info_link')}
          />

          <Advantage
            icon={<Images img={'icon3'} />}
            title={t('schedule_systematization')}
            description={t('set_convenient_schedule')}
          />
        </div>
        <div className={styles.line2}>
          <Advantage
            icon={<Images img={'icon4'} />}
            title={t('time_saving')}
            description={t('reduce_time_spent_on_communication')}
          />

          <Advantage
            icon={<Images img={'icon5'} />}
            title={t('no_admin_needed')}
            description={t('clients_book_themselves')}
          />
        </div>

        <img className={styles.img} src={planguin2} alt="" width={'400px'} />
      </div>
    </>
  );
}

export default Block2;
