import React from 'react';
import styles from './PopUpPhone.module.css';
import { Button } from 'Components/BasicComponents/BasicComponents';
import { usePopUpPhoneStore } from './PopUpPhoneStore';
import { useProfileBlockViewStore } from '../ProfileBlockView/ProfileBlockViewStore';
import { useTranslation } from 'react-i18next';

export function PopUpPhone(props) {
  const { t } = useTranslation();
  let { popUpPhoneWindow, changePopUpPhoneState } = usePopUpPhoneStore();
  let { specialist } = useProfileBlockViewStore();

  if (popUpPhoneWindow) {
    return (
      <>
        <div className={styles.popup_background}>
          <div className={styles.popup_window1}>
            <div className={styles.popup_title}>{t('phone_number')}</div>
            <div className={styles.popup_phone}>{specialist.phone}</div>
            <Button
              onClick={() => {
                changePopUpPhoneState(false);
              }}
              style={{ marginTop: '20px' }}
              color={'black'}
              name={'Закрити'}
            />
          </div>
        </div>
      </>
    );
  }
}
