import i18n from 'i18n';
import { API } from 'utils/api';
import { create } from 'zustand';

export const useStatsBlock = create((set, get) => ({
    statsCategory: i18n.t('statsCategory.0'),
    serviceType: i18n.t('serviceType'),
    serviceTypes: [i18n.t('serviceTypes.0'), i18n.t('serviceTypes.1')],
    period: [new Date('2022-01-01'), new Date('2024-01-01')],
  
    totalIndividulAppointments: 0,
    totalGroupAppointments: 0,
  
    individualServicesStats: [],
    groupServicesStats: [],
  
    allClients: 0,
    newClients: 0,
  
    clientsAppointmentsCountForIndividualService: [],
    clientsStatsOfGroupServices: [],

    updateLanguage: () => {
      set({
        statsCategory: i18n.t('statsCategory'),
        serviceType: i18n.t('serviceType'),
        serviceTypes: [i18n.t('serviceTypes.0'), i18n.t('serviceTypes.1')],
      });
    },
  
    selectCategory: (e) => {
      set({ statsCategory: e.target.id });
    },
  
    serviceTypeHandler: (e) => {
      set({
        serviceType: e.target.value,
      });
    },
  
    calendarHandler: (value) => {
      set({ period: value });
      get().getStats();
    },
  
    getStats: async () => {
      const period = get().period;
  
      const requests = [
        //Індивідуальні
        API.Stats.getSpecialistAppointmentsCount(period[0], period[1]),
        API.Stats.getServiceAppointmentsCount(period[0], period[1]),
        API.Stats.getClientsCount(),
        API.Stats.getNewClientsCount(period[0], period[1]),
        API.Stats.getClientsAppointmentCount(period[0], period[1]),
        //Групові
        API.Stats.getSpecialistGroupAppointmentsCount(period[0], period[1]),
        API.Stats.getGroupServiceAppointmentsCount(period[0], period[1]),
        API.Stats.getClientsGroupAppointmentCount(period[0], period[1]),
      ];
  
      let responses = await Promise.all(requests);
  
      let [
        totalIndividulAppointments,
        individualServicesStats,
        allClients,
        newClients,
        clientsAppointmentsCountForIndividualService,
        totalGroupAppointments,
        groupServicesStats,
        clientsStatsOfGroupServices,
      ] = responses;
  
      set({
        totalIndividulAppointments,
        individualServicesStats,
        allClients,
        newClients,
        clientsAppointmentsCountForIndividualService,
        totalGroupAppointments,
        groupServicesStats,
        clientsStatsOfGroupServices,
      });
    },
  }
));

i18n.on('languageChanged', () => {
  const { updateLanguage } = useStatsBlock.getState();
  updateLanguage();
});