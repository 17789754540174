import React, { useEffect, useState } from 'react';
import styles from './LinkBlock.module.css';
import stylesM from './LinkBlockM.module.css';

import {
  Button,
  Input,
} from '../../Components/BasicComponents/BasicComponents';
import Imgs from '../../Imgs/Imgs';
import axios from 'axios';
import Toast from '../../Components/Toast/Toast';
import { useDB } from 'utils/DB';
import { API } from 'utils/api';
import { useTranslation } from 'react-i18next';

function LinkBlock(props) {
  const { t } = useTranslation();
  let { specialist } = useDB();
  let [link, setLink] = useState(`https://www.planguin.co/`);
  let [input, setInput] = useState(false);
  let [toast, setToast] = useState({ type: '', show: false, msg: '' });

  //Показати тост
  function showToast(type, msg) {
    if (type == 'e') {
      type = 'error';
    }
    if (type == 's') {
      type = 'success';
    }
    setToast({ type, show: true, msg });
    setTimeout(() => {
      setToast({ type: '', show: false, msg: '' });
    }, 2000);
  }

  useEffect(() => {
    async function af() {
      setLink(`https://www.planguin.co/${specialist.login}`);
      console.log(specialist);
    }
    af();
  }, [specialist]);

  function inputHandler(e) {
    if (e.target.value.length < 24) {
      console.log('not allowed');
      return;
    }
    setLink(e.target.value);
  }

  function editLink() {
    input ? setInput(false) : setInput(true);
  }

  async function saveLink() {
    let parts = link.split('/');
    let login = parts[3];

    let response = await API.Specialist.changeLogin(login);

    if (response.data == 'логін зайнятий') {
      showToast('e', t('login_taken'));
      return;
    }

    if (response.data) {
      showToast('s', t('link_changed'));
      setInput(false);
    } else {
      showToast('e', t('error_occurred'));
    }
  }

  if (props.platform == 'mobile') {
    return (
      <>
        <Toast show={toast.show} type={toast.type} msg={toast.msg} />

        <div className={stylesM.mobile_container}>
          <div className={stylesM.title}>
            {t('calendar_link')}
            <span onClick={editLink} className={styles.edit}>
              <Imgs width={'20px'} img={'edit'} />
            </span>{' '}
          </div>
          <div className={stylesM.link}>
            <div className={stylesM.link_inner_container}>
              {input
                ? [
                    <Input
                      type={'m'}
                      value={link}
                      onChange={inputHandler}
                      style={{ height: '40px' }}
                    />,
                    <Button
                      onClick={saveLink}
                      style={{
                        height: '35px',
                        width: '120px',
                        fontSize: '14px',
                      }}
                      name={t('save')}
                    />,
                  ]
                : [
                    <div
                      style={{ height: '40px' }}
                      className={styles.link_container}
                    >
                      {link}
                    </div>,
                    <Imgs
                      width={'35px'}
                      img={'copy'}
                      onClickCopy={() => {
                        navigator.clipboard.writeText(link);
                        showToast('s', t('link_copied'));
                      }}
                    />,
                  ]}
            </div>
          </div>
          <div className={stylesM.description}>
            {t('copy_link')}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Toast show={toast.show} type={toast.type} msg={toast.msg} />

      <div className={styles.container}>
        <div className={styles.title}>
          {`${t('calendar_link')} `}
          <span onClick={editLink} className={styles.edit}>
            <Imgs width={'30px'} img={'edit'} />
          </span>
        </div>
        <div className={styles.link}>
          <div className={styles.link_inner_container}>
            {input
              ? [
                  <Input
                    value={link}
                    onChange={inputHandler}
                    style={{ height: '40px', width: '463px' }}
                  />,
                  <Button
                    onClick={saveLink}
                    style={{ height: '40px', width: '100px', fontSize: '14px' }}
                    name={t('save')}
                  />,
                ]
              : [
                  <div className={styles.link_container}>{link}</div>,
                  <Imgs
                    img={'copy'}
                    onClickCopy={() => {
                      navigator.clipboard.writeText(link);
                      showToast('s', t('link_copied'));
                    }}
                  />,
                ]}
          </div>
        </div>
        <div className={styles.description}>
          {t('copy_link')}
        </div>
      </div>
    </>
  );
}

export default LinkBlock;
