import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Background,
  Below_Input_Text1,
  Below_Input_Text2,
  Below_Input_Text3,
  Button,
  Input,
  InputsTitle,
  Logo,
  ProfileRaw,
  WhiteBlock,
} from '../../Components/BasicComponents/BasicComponents';

import Imgs from '../../Imgs/Imgs';
import styles from './Login.module.css';
import { useNavigate } from 'react-router-dom';
import Toast from '../../Components/Toast/Toast';
import axios from 'axios';
import Cookies from 'js-cookie';
import a from '../Landing/Images/a.png';
import g from '../Landing/Images/g.png';
import { API } from 'utils/api';

function Login() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  let [data, setData] = useState({ email: '', password: '' });
  let [toast, setToast] = useState({ type: '', show: false, msg: '' });

  //Android
  document.addEventListener('message', (message) => {});

  //IOS
  window.addEventListener('message', (message) => {
    if (typeof message.data == 'string') {
      const expirationDate = new Date();
      expirationDate.setMonth(expirationDate.getMonth() + 1);

      document.cookie =
        'sToken=' +
        message.data +
        '; expires=' +
        expirationDate.toUTCString() +
        '; path=/;';
    }

    if (document.cookie.includes('sToken')) {
      window.location.href = '/calendar';
    }
  });

  //Показати тост
  function showToast(type, msg) {
    if (type == 'e') {
      type = 'error';
    }
    if (type == 's') {
      type = 'success';
    }
    setToast({ type, show: true, msg });
    setTimeout(() => {
      setToast({ type: '', show: false, msg: '' });
    }, 2000);
  }

  //Адаптивный дизайн
  let [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function updateWidth() {
    setWindowWidth(window.innerWidth);
  }
  useLayoutEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  //Обробник інпута
  function inputHandler(e) {
    if (e.target.id == 'email') {
      e.target.value = e.target.value.toLowerCase();
    }
    setData((ps) => ({ ...ps, [e.target.id]: e.target.value }));
  }

  //Відправити данні на сервер
  async function sendData() {
    if (!checkData()) {
      return;
    }

    let response = await API.Specialist.login(data);

    if (response.data == 'Successful authorization') {
      navigate('/profile/specialist');
      return;
    }
    if (response.data == 'unconfirmed email') {
      navigate('/email_confirmation');
    }

    if (response.data != 'Successful authorization') {
      showToast('e', t('check_and_try_again'));
      return;
    }
  }

  // Перевірка даанних
  function checkData() {
    // Перевірка емейл
    if (!data.email.match('@') && !data.email.match('@')) {
      showToast('e', t('invalid_email'));
      return false;
    }

    // Перевірка пароля
    if (data.password.length == 0) {
      showToast('e', t('incorrect_password'));
      return false;
    }

    return true;
  }

  //! Рендеринг

  // Mobile
  if (windowWidth < 1025) {
    return (
      <>
        <Toast show={toast.show} type={toast.type} msg={toast.msg} />

        <div className={styles.mobile_form_container}>
          <div className={styles.logo_container}>
            <Imgs img={'planguinLogo'} planguinLogoWidth={'200px'} />
          </div>

          <InputsTitle
            style={{ paddingTop: '70px' }}
            title={t('login')}
          />
          <Input id={'email'} onChange={inputHandler} placeholder={t('email')} />
          <Input
            id={'password'}
            onChange={inputHandler}
            placeholder={t('password')}
            type={'password'}
          />
          <Below_Input_Text1
            onClick={() => {
              navigate('/reset_password');
            }}
          />
          <Button name={t('sign_in')} onClick={sendData} />
          <Below_Input_Text2
            style={{ marginTop: '10px', textAlign: 'center' }}
            onClick={() => {
              navigate('/signup');
            }}
          />
        </div>
      </>
    );
  }

  // WEB

  return (
    <>
      <Toast show={toast.show} type={toast.type} msg={toast.msg} />

      <Background>
        <div className={styles.outer_container}>
          <div className={styles.form_container}>
            <Imgs img={'planguinLogo'} />
            <InputsTitle
              style={{ paddingTop: '50px' }}
              title={t('login')}
            />
            <Input id={'email'} onChange={inputHandler} placeholder={t('email')} />
            <Input
              id={'password'}
              onChange={inputHandler}
              placeholder={t('password')}
              type={'password'}
            />
            <Below_Input_Text1
              onClick={() => {
                navigate('/reset_password');
              }}
            />
            <Button name={t('sign_in')} onClick={sendData} />
            <Below_Input_Text2
              onClick={() => {
                navigate('/signup');
              }}
            />
          </div>
        </div>
      </Background>
    </>
  );
}

export default Login;
