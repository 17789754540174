import { checkAccess } from 'access';
import axios from 'axios';
import React, { useState, useEffect, useMemo } from 'react';
import { Input } from 'Pages/Clients/Components/BasicComponents/BasicComponents';
import { Button } from 'Components/BasicComponents/BasicComponents';
import SelectClient from 'Pages/Calendar/Components/SelectClient/SelectClient';
import { API } from 'utils/api';

function SandBox() {
  let sendRequest = async () => {
    let data = await API.Stats.getClientsCount();
    console.log(data);
  };

  return (
    <>
      <button onClick={sendRequest}>Send request</button>
    </>
  );
}
export default SandBox;
