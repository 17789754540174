import React, { useCallback, useMemo } from 'react';
import { Button } from '../../../../Components/BasicComponents/BasicComponents';
import styles from './Appoint.module.css';
import { useChooseServiceStore } from '../ChooseService/ChooseServiceStore';
import { useDatePickerBlockStore } from '../DatePickerBlock/DatePickerBlockStore';
import { useTimePickerBlockStore } from '../TimePickerBlock/TimePickerBlockStore';
import moment from 'moment';
import { useAppointmentConfirmationStore } from '../AppointmentConfirmation/AppointmentConfirmationStore';
import { addMinutes, adjustPrice } from 'utils/lib/helpers/helpers';
import { useTranslation } from 'react-i18next';
import { useCurrency } from 'hooks/useCurrency';

function Appoint(props) {
  const { t } = useTranslation();
  const {currency} = useCurrency()

  let { service } = useChooseServiceStore();
  let { date } = useDatePickerBlockStore();
  let { timeSlotStartTime, groupTimeSlot } = useTimePickerBlockStore();
  let { showAppointmentConfirmationWindow } = useAppointmentConfirmationStore();
 
  const services = useMemo(() => {
    if (!service)  return [];
    return service.slice().sort(({ hours: hoursA, minutes: minutesA }, { hours: hoursB, minutes: minutesB }) => {
      const durationA = hoursA * 60 + minutesA;
      const durationB = hoursB * 60 + minutesB;
      return durationA - durationB;
    });
  }, [service]);

  const totalTimes = useCallback(() => {
    const countTimes = services.reduce((acc, nextService) => {
      return acc += nextService.hours * 60 + nextService.minutes
    }, 0);

    return addMinutes(timeSlotStartTime, countTimes)
  }, [timeSlotStartTime])

  const totalPrice = useCallback(() => {
    return services.reduce((acc, service) => {
      return acc = adjustPrice(acc, service.price)
    }, '');
  }, [services])

  return (
    <div className={styles.container}>
      {services.map((item, index, serviceArray) => {
        const servicePrev = serviceArray[index - 1]
        const sliceTimes = serviceArray.slice(0, index);
        const startTimeTotal = sliceTimes.reduce((acc, service) => {
          return acc += service.hours * 60 + service.minutes
        }, 0);
        const startTime = servicePrev ? addMinutes( timeSlotStartTime, startTimeTotal) : timeSlotStartTime

        return (
          <div key={item._id} className={styles.fragment}>
            <div className={styles.block}>
              <div className={styles.title}>{t('service')}</div>
              <div
                style={{
                  width: '200px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                className={styles.value}
              >
                {item?.name || '-'}
              </div>
            </div>
            <div className={styles.block}>
              <div className={styles.title}>{t('time')}</div>
              <div className={styles.value}>
                {moment(date).format('DD.MM')} / {/* //Якщо послуга не групова */}
                {!item?.schedule
                  ? timeSlotStartTime
                    ? startTime 
                    : '-'
                  : groupTimeSlot
                    ? groupTimeSlot?.from
                    : '-'}
              </div>
            </div>
            <div className={styles.block}>
              <div className={styles.title}>{t('cost')}</div>
              <div
                style={{ fontWeight: 700, fontSize: '18px' }}
                className={styles.value}
              >
                {item.price || '-'} {currency}
              </div>
            </div>
              {
                service.length === 1 
                  ? (
                    <div className={styles.block}>
                      <Button
                        name={t('book')}
                        color={'black'}
                        style={{ height: '65px', maxWidth: '220px' }}
                        onClick={() => {
                          showAppointmentConfirmationWindow();
                        }}
                      />
                    </div>
                  ) : null
              }
          </div>
        )
      })}

      {
        service && service.length > 1 
          ? (
            <div className={styles.fragment}>
              <div className={styles.block}>
                <div
                  style={{ width: '200px'}}
                  className={styles.value}
                />
              </div>
              <div className={styles.block}>
                <div className={styles.title}>{t('time')}</div>
                <div className={styles.value}>
                  {moment(date).format('DD.MM')} / 
                  {timeSlotStartTime ? `${timeSlotStartTime} - ${totalTimes()}` : '-'}
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.title}>{t('cost')}</div>
                <div
                  style={{ fontWeight: 700, fontSize: '18px' }}
                  className={styles.value}
                >
                  {totalPrice()} {currency}
                </div>
              </div>
               
              <div className={styles.block}>
                <Button
                  name={t('book')}
                  color={'black'}
                  style={{ height: '65px', maxWidth: '215px' }}
                  onClick={() => {
                    showAppointmentConfirmationWindow();
                  }}
                />
              </div>
            </div>
            ) 
          : null
      }
      
    </div>
  );
}

export default Appoint;
