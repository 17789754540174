import React, { useEffect, useState, useLayoutEffect } from 'react';
import styles from './SpecialistProfile.module.css';
import Header, { MobileHeader } from '../../Components/Header/Header';
import ProfileBlock from '../../Components/ProfileBlock/ProfileBlock';
import LinkBlock from '../LinkBlock/LinkBlock';
import { checkAccess } from '../../access';
import SheduleBlock from '../../Components/SheduleBlock/SheduleBlock';
import BottomMenu from '../../Components/BottomMenu/BottomMenu';
import { useNavigate } from 'react-router-dom';
import { useDB } from 'utils/DB';
import { useTranslation } from 'react-i18next';

function SpecialistProfile() {
  const { t } = useTranslation();
  let navigate = useNavigate();

  let specialistId = checkAccess();

  let { updateDB } = useDB();

  //Адаптивный дизайн
  let [windowWidth, setWindowWidth] = useState(window.innerWidth);
  let [page, setPage] = useState('mainMenu');

  function updateWidth() {
    setWindowWidth(window.innerWidth);
  }
  useLayoutEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  function pageHandler(page) {
    page == 'profile' ? setPage('profile') : console.log();
    page == 'shedule' ? setPage('shedule') : console.log();
    page == 'mainMenu' ? setPage('mainMenu') : console.log();
  }

  useEffect(() => {
    let url = new URL(window.location.href);
    let p = url.searchParams.get('page');
    if (p) {
      if (p.replace(/\"/g, '') == 'shedule') {
        setPage('shedule');
        return;
      }
      if (p.replace(/\"/g, '') == 'profile') {
        setPage('profile');
        return;
      }
    }

    async function af() {
      updateDB('specialist');
    }
    af();
  }, []);

  document.body.style.backgroundColor = '#F4F7FE';

  //! Рендеринг

  if (windowWidth < 1025) {
    //Mobile

    if (page == 'mainMenu') {
      navigate('/calendar');
      return <></>;
    }

    if (page == 'shedule') {
      return (
        <>
          <MobileHeader />
          <SheduleBlock goToPage={pageHandler} platform={'mobile'} />
          <BottomMenu />
        </>
      );
    }

    if (page == 'profile') {
      return (
        <>
          {window.scroll(0, 0)}
          <MobileHeader title={t('my_profile')} type={2} />
          <ProfileBlock
            goToPage={pageHandler}
            platform={'mobile'}
            specialistId={specialistId}
          />
        </>
      );
    }
  }

  // Desktop

  return (
    <>
      <Header platform={'desktop'} specialistId={specialistId} />

      <div className={styles.outer_container}>
        <div className={styles.main_container}>
          <div className={styles.left_side}>
            <ProfileBlock specialistId={specialistId} />
            <LinkBlock specialistId={specialistId} />
          </div>
          <div className={styles.right_side}>
            <SheduleBlock />
          </div>
        </div>
      </div>
    </>
  );
}

export default SpecialistProfile;
