import { create } from 'zustand';
import axios from 'axios';
import { useBookingStore } from '../../BookingStore';
import moment from 'moment';
import { API } from 'utils/api';
import { useChooseServiceStore } from '../ChooseService/ChooseServiceStore';

export let useTimePickerBlockStore = create((set, get) => ({
  timeSlot: undefined,
  timeSlotStartTime: undefined,
  timeSlots: [],

  groupTimeslots: [], // by date
  groupTimeSlot: undefined,

  selectTimeSlot: (timeSlot) => {
    set({ timeSlot: timeSlot });

    let parts = timeSlot.split('-');
    set({ timeSlotStartTime: parts[0] });
  },

  getTimeSlots: async ({ services, date }) => {
    if (!services.length || !date) {
      return;
    }

    const serviceIds = services.map((service) => service._id) 
    let { specialistId } = useBookingStore.getState();

    let { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/timeslots`,
      { specialistId, serviceIds, date },
    );

    let dateNow = moment().format('YYYY/MM/DD');
    let timeNow = moment().format('HH:mm');

    //Відфільтрувати час який вже пройшов
    if (date == dateNow) {
      let filteredTimeslots = data.filter((el) => {
        let [startTime, endTime] = el.split('-');
        if (startTime > timeNow) {
          return el;
        }
      });
      set({ timeSlots: filteredTimeslots });
      return;
    }
    set({ timeSlots: data });
  },

  getGroupTimeSlots: async ({ service, date }) => {
    if (!service || !date) {
      return;
    }
    let { specialistId } = useBookingStore.getState();

    let groupTimeslots = await API.GroupAppointment.getTimeSlots({
      specialistId,
      groupServiceId: service._id,
      date,
    });

    if (groupTimeslots.length == 0) {
      set({ groupTimeslots: [] });
      return;
    }
    // Не показуємо записи , час який пройшов
    let timeNow = moment().format('HH:mm');

    let filteredGroupTimeslots = groupTimeslots.filter((groupTimeslot) => {
      if (date == moment().format('YYYY/MM/DD')) {
        const now = moment(timeNow, 'HH:mm');
        const groupTime = moment(groupTimeslot.from, 'HH:mm');

        if (groupTime.isAfter(now)) {
          return groupTimeslot;
        }
      } else {
        return groupTimeslot;
      }
    });

    filteredGroupTimeslots = filteredGroupTimeslots.filter(
      (groupTimeslot) =>
        groupTimeslot.maxNumberOfParticipants -
          groupTimeslot.participantsNumber >
        0,
    );
    
    filteredGroupTimeslots = filteredGroupTimeslots.sort((a, b) => {
      const [aHours, aMinutes] = a.from.split(':').map(Number);
      const [bHours, bMinutes] = b.from.split(':').map(Number);
    
      const aTotalMinutes = aHours * 60 + aMinutes;
      const bTotalMinutes = bHours * 60 + bMinutes;
    
      return aTotalMinutes - bTotalMinutes;
    });

    set({ groupTimeslots: filteredGroupTimeslots });
  },

  selectGroupTimeSlot: (groupTimeSlot) => {
    set({ groupTimeSlot });
  },
}));
