import React from 'react';
import stylesM from './DeleteAccountM.module.css';
import planguin from '../../Imgs/planguin.png';
import correct from '../../Imgs/correct.png';
import { useDeleteAccountStore } from './DeleteAccountStore';
import { Button } from '../../Components/BasicComponents/BasicComponents';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

function DeleteAccount() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  let { page, deleteAccount } = useDeleteAccountStore();

  if (page == 'confirmation') {
    return (
      <>
        <div className={stylesM.main_container}>
          <div className={stylesM.content_container}>
            <img src={planguin} width={'200px'} alt="" />
            <div className={stylesM.title}>{t('delete_account')}?</div>
            <div className={stylesM.subtitle}>
              {' '}
              {t('data_cannot_be_restored')}
            </div>
          </div>

          <div className={stylesM.buttons_container}>
            <Button
              onClick={deleteAccount}
              style={{ width: '100%' }}
              name={t('yes')}
            />
            <Button
              onClick={() => {
                navigate(-1);
              }}
              style={{ width: '100%', backgroundColor: '#B5B5B5' }}
              name={t('no')}
            />
          </div>
        </div>
      </>
    );
  }

  if (page == 'confirmed') {
    return (
      <>
        <div className={stylesM.main_container}>
          <div className={stylesM.content_container}>
            <img src={correct} width={'64px'} alt="" />
            <div style={{ marginTop: '20px' }} className={stylesM.title}>
              {t('account_deletion_request_sent')}
            </div>
            <div className={stylesM.subtitle}>
              {' '}
              {t('account_deletion_within_24_hours')}
            </div>
          </div>

          <div className={stylesM.buttons_container}>
            <Button
              onClick={() => {
                window.ReactNativeWebView.postMessage('exit');
                Cookies.remove('sToken', {
                  domain: process.env.REACT_COOKIE_DOMAIN,
                });
                window.location.href = '/login';
              }}
              style={{ width: '100%' }}
              name={t('understood')}
            />
          </div>
        </div>
      </>
    );
  }
}

export default DeleteAccount;
