import { create } from 'zustand';
import axios from 'axios';
import moment from 'moment';
import { checkAccess } from '../../../../access';
import { API } from 'utils/api';

export let useSchedulerStore = create((set, get) => ({
  dates: [],
  appointments: [],
  weekends: [],

  paddingLine: undefined,

  appointmentDetailsWindow: false,
  confirmDeleteWindow: false,
  addAppointmentWindow: false,

  getSpecialistAppointments: async () => {
    //Групові записи
    let groupAppointments = await API.GroupAppointment.getAll();

    // Звичайні записи
    let { data } = await axios.get(
      `${
        process.env.REACT_APP_API
      }/api/specialists/${checkAccess()}/appointments`,
    );

    // З'єднаємо групові та звичайні записи
    data = [...groupAppointments, ...data];

    set({ appointments: data });
  },

  getSpecialistWeekends: async () => {
    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialists/${checkAccess()}`,
    );
    set({ weekends: data.weekends });
  },

  calculateLinePosition: () => {
    let timeNow = moment().format('HH:mm');
    let parts = timeNow.split(':');
    let hours = +parts[0];
    let minutes = +parts[1];

    set({ linePosition: (hours) * 90 + minutes * 1.48 });
    set({ paddingLine: `${(hours) * 90 + minutes * 1.48}px` });
  },

  scrollToLine: () => {
    document.getElementById('calendar')?.scrollTo({
      top: get().linePosition - 226,
      behavior: 'smooth',
    });
  },

  weekendCheckBoxHandler: async (e) => {
    if (!get().weekends.includes(e.target.id)) {
      let { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/weekend`,
        { specialistId: checkAccess(), action: 'add', date: e.target.id },
      );
      get().getSpecialistWeekends();
      return;
    }

    if (get().weekends.includes(e.target.id)) {
      let { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/weekend`,
        { specialistId: checkAccess(), action: 'delete', date: e.target.id },
      );
      get().getSpecialistWeekends();

      return;
    }
  },
}));
