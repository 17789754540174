import React, { useEffect, useState } from 'react';
import styles from './TimePickerBlock.module.css';
import stylesM from './TimePickerBlockM.module.css';

import nodata from '../../../../Imgs/nodata.png';
import { useTimePickerBlockStore } from './TimePickerBlockStore';
import { useChooseServiceStore } from '../ChooseService/ChooseServiceStore';
import { useDatePickerBlockStore } from '../DatePickerBlock/DatePickerBlockStore';
import { useBookingStore } from '../../BookingStore';
import moment from 'moment';
import 'moment/locale/uk';
import 'moment/locale/ru';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import { useTranslation } from 'react-i18next';

function TimeSlot(props) {
  if (props.t == 'm') {
    if (props.type == 'selected') {
      return (
        <div
          onClick={props.onClick}
          style={{ backgroundColor: '#FF8A35', color: '#ffff' }}
          className={stylesM.timeslot_container}
        >
          {props.value}
        </div>
      );
    }

    return (
      <div onClick={props.onClick} className={styles.timeslot_container}>
        <div style={{ color: '#FF8A35', fontSize: '10px' }}>
          {props.members}
        </div>
        {props.value}
      </div>
    );
  }

  if (props.type == 'selected') {
    return (
      <div
        onClick={props.onClick}
        style={{ backgroundColor: '#FF8A35', color: '#ffff' }}
        className={styles.timeslot_container}
      >
        {props.value}
      </div>
    );
  }

  return (
    <div onClick={props.onClick} className={styles.timeslot_container}>
      <div style={{ color: '#FF8A35', fontSize: '10px' }}>{props.members}</div>
      {props.value}
    </div>
  );
}

function TimePickerBlock(props) {
  const { t, i18n  } = useTranslation();
  let { specialistId } = useBookingStore();
  let { service } = useChooseServiceStore();
  let { date } = useDatePickerBlockStore();
  let {
    timeSlot,
    timeSlots,
    getTimeSlots,
    selectTimeSlot,
    getGroupTimeSlots,
    groupTimeslots,
    groupTimeSlot,
    selectGroupTimeSlot,
  } = useTimePickerBlockStore();

  moment.locale(i18n.language);

  useEffect(() => {
    async function af() {
      if (!service?.length) {
        return;
      }

      // Якщо послуга індивідуальна
      if ('category' in service[0]) {
        await getTimeSlots({ services: service, date });
        return;
      }
      // Якщо послуга групова
      if ('schedule' in service[0]) {
        await getGroupTimeSlots({ service: service[0], date });
        return;
      }
    }
    af();
  }, [service, date, specialistId]);

  let zaglushka = (
    <div className={stylesM.img_container}>
      <img src={nodata} width={'220px'} alt="" />
      {service ? (
        <div className={stylesM.nodata_title}>{t('free_not')}</div>
      ) : (
        <div className={stylesM.nodata_title}>{t('choose_service')}</div>
      )}
    </div>
  );

  if (props.platform == 'mobile') {
    return (
      <>
        <div className={stylesM.container_mobile}>
          <div className={stylesM.timeslots_mobile}>
            {!service?.length ? (
              zaglushka
            ) : (
              <>
                {'category' in service[0] && timeSlots.length == 0 ? (
                  zaglushka
                ) : (
                  <></>
                )}
                {'schedule' in service[0] && groupTimeslots.length == 0 ? (
                  zaglushka
                ) : (
                  <></>
                )}
              </>
            )}

            {service?.length && service[0].category && timeSlots ? (
              timeSlots.map((el) => {
                return (
                  <TimeSlot
                    t="m"
                    type={timeSlot == el ? 'selected' : ''}
                    onClick={() => {
                      selectTimeSlot(el);
                    }}
                    value={el}
                  />
                );
              })
            ) : (
              <></>
            )}
            {service?.length && service[0].schedule && groupTimeslots ? (
              groupTimeslots.map((el) => {
                return (
                  <TimeSlot
                    t="m"
                    members={`${t('a_remaining_spots')}: ${el.maxNumberOfParticipants - el.participantsNumber}`}
                    type={groupTimeSlot == el ? 'selected' : ''}
                    onClick={() => {
                      selectGroupTimeSlot(el);
                    }}
                    value={`${el.from}-${el.to}`}
                  />
                );
              })
            ) : (
              <></>
            )}

            <div className={stylesM.emptyblock}></div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.line1}>
          <div className={styles.title}>
            {t('choose_the_slot')} {moment(date).format('D MMMM')}:
          </div>
          <div className={styles.filter}></div>
        </div>
        <div className={styles.timeslots}>
          {!service?.length  ? (
            zaglushka
          ) : (
            <>
              {'category' in service[0] && timeSlots.length == 0 ? (
                zaglushka
              ) : (
                <></>
              )}
              {'schedule' in service[0] && groupTimeslots.length == 0 ? (
                zaglushka
              ) : (
                <></>
              )}
            </>
          )}

          {service?.length && service[0].category && timeSlots ? (
            timeSlots.map((el) => {
              return (
                <TimeSlot
                  key={Math.random()}
                  type={timeSlot == el ? 'selected' : ''}
                  onClick={() => {
                    selectTimeSlot(el);
                  }}
                  value={el}
                />
              );
            })
          ) : (
            <></>
          )}
          {service?.length && service[0].schedule && groupTimeslots ? (
            groupTimeslots.map((el) => {
              return (
                <TimeSlot
                  key={Math.random()}
                  members={`${t('a_remaining_spots')}: ${el.maxNumberOfParticipants - el.participantsNumber}`}
                  type={groupTimeSlot == el ? 'selected' : ''}
                  onClick={() => {
                    selectGroupTimeSlot(el);
                  }}
                  value={`${el.from}-${el.to}`}
                />
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default TimePickerBlock;
