import axios from 'axios';
import { create } from 'zustand';
import { checkAccess } from '../../access';

export let useDeleteAccountStore = create((set, get) => ({
  page: 'confirmation',

  //Видалити акаунт
  deleteAccount: async () => {
    let { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/specialist/delete_account`,
      { specialistId: checkAccess() },
    );

    if (data) {
      set({ page: 'confirmed' });
    }
  },
}));
