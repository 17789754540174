import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { get } from 'react-scroll/modules/mixins/scroller';

// Доступ
export function checkAccess() {
  const sTokenDev = process.env.REACT_APP_DEV_TOKEN;
  const sToken = Cookies.get('sToken');
  const currentToken = process.env.REACT_APP_DEV === 'true' ? sTokenDev : sToken;

  if(currentToken){
      let payload = jwtDecode(currentToken)
      let specialistId = payload.data.specialistId
      return specialistId
  } else{
      window.location.href='/access_denied'
  }
}

export let getSpecialistId = () => {
  if (Cookies.get('sToken')) {
    let payload = jwtDecode(Cookies.get('sToken'));
    let specialistId = payload.data.specialistId;

    return specialistId;
  } else {
    return 'Не авторизований користувач';
  }
};
