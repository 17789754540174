import { create } from 'zustand';
import axios from 'axios';
import { useBookingStore } from 'Pages/Booking/BookingStore';

export let useProfileBlockViewStore = create((set, get) => ({
  specialist: {},

  getSpecialistInfo: async () => {
    let { specialistId } = useBookingStore.getState();

    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialists/${specialistId}`,
    );
    set({ specialist: data });
  },
}));
