import React, { useEffect, useState } from 'react';
import styles from './ChooseService.module.css';
import Imgs from 'Imgs/Imgs';
import {
  Button,
  Selector,
  ServiceBlock,
} from 'Components/BasicComponents/BasicComponents';
import { useChooseServiceStore } from './ChooseServiceStore';
import { useBookingStore } from 'Pages/Booking/BookingStore';
import { useTranslation } from 'react-i18next';
import { useCurrency } from 'hooks/useCurrency';

function ChooseService(props) {
  const { t } = useTranslation();
  const {currency} = useCurrency()
  const [serviceId, setServiceId] = useState('')

  let { specialistId } = useBookingStore();

  let {
    service,
    windowSection,
    changeWindowSection,
    categories,
    category,
    categoryHandler,
    filteredServices,
    selectService,
    changeChooseServiceWindowState,
    goNext,
    chooseServiceWindow,
    getSpecialistInfo,
    getSpecialistServices,
    getSpecialistGroupServices,
    getAllGroupTimeSlots,
  } = useChooseServiceStore();

  const handleServiceSelection = (el) => {
    if (el.schedule) {
      selectService([el]);
    } else {
      const isServiceSelected = service?.some((service) => service._id === el._id);
      const newService = isServiceSelected
        ? service.filter((service) => service._id !== el._id)
        : [...service ?? [], el].filter((service) => !service.schedule);
  
      selectService(newService);
    }
    setServiceId(el._id);
  };

  useEffect(() => {
    async function af() {
      await getSpecialistInfo();
      await getSpecialistServices();
      await getSpecialistGroupServices();
      await getAllGroupTimeSlots();
    }
    af();
  }, [specialistId, service]);

  if (chooseServiceWindow) {
    if (windowSection == 'serviceInfo') {
      const serviceSelected = filteredServices.find(({_id}) => _id === serviceId);
      
      return (
        <>
          <div className={styles.popup_background}>
            <div className={styles.popup_window}>
              <div className={styles.title}>
                {' '}
                <Imgs
                  onClick={() => {
                    changeWindowSection('allServices');
                  }}
                  width={'30px'}
                  img={'back'}
                />{' '}
                <span
                  className={styles.close}
                  onClick={() => {
                    changeWindowSection('allServices');
                  }}
                >
                  <Imgs img={'close'} />
                </span>
              </div>

              <div className={styles.services_container}>
                <div className={styles.service_name}>{serviceSelected.name}</div>
                <div>
                  {`${t('service_duration')} `}
                  <span className={styles.property}>
                    {serviceSelected.hours} {t('hours')} {serviceSelected.minutes} {t('minutes')}
                  </span>
                </div>
                <div>
                  {`${t('cost')}: `}
                  <span className={styles.property}>{serviceSelected.price} {currency}</span>{' '}
                </div>
                <textarea readOnly className={styles.service_description}>
                  {serviceSelected.description}
                </textarea>
              </div>

              <Button
                style={{
                  backgroundColor: 'grey',
                  position: 'relative',
                  top: '-15px',
                }}
                name={t('back')}
                onClick={() => {
                  changeWindowSection('allServices');
                }}
              />
            </div>
          </div>
        </>
      );
    }

    if (windowSection == 'allServices') {
      return (
        <>
          <div className={styles.popup_background}>
            <div className={styles.popup_window}>
              <div className={styles.title}>
                {t('choose_service')}
                <span
                  className={styles.close}
                  onClick={() => {
                    changeChooseServiceWindowState(false);
                  }}
                >
                  <Imgs img={'close'} />
                </span>
              </div>

              <div className={styles.categories_container}>
                {categories?.length !== 0 ? (
                  <Selector
                    value={category}
                    style={{
                      backgroundColor: 'transparent',
                      paddingLeft: '0px',
                      width: 'fit-content',
                      color: 'coral',
                    }}
                    onChange={categoryHandler}
                    options={[t('all_categories'), ...categories]}
                  />
                ) : (
                  <></>
                )}
              </div>

              <div className={styles.services_container}>
                {filteredServices.map((el) => {
                  const isSelected = service?.some((service) => service._id === el._id);

                  return (
                    <div key={el._id}>
                      <ServiceBlock
                        type={'withInfo'}
                        onClick_info={() => {
                          selectService('serviceInfo');
                          changeWindowSection('serviceInfo');
                          setServiceId(el._id);
                        }}
                        description={el.description}
                        onClick={() => {
                          handleServiceSelection(el);
                        }}
                        style={{
                          border: isSelected ? '2px solid #FF8A35' : '',
                        }}
                        name={`${el.name} ${el.schedule ? `(${t('group')})` : ''}`}
                        duration={`${el.hours} ${t('hours')} ${el.minutes} ${t('minutes')}`}
                        price={el.price}
                      />
                    </div>
                  );
                })}
                <div style={{ height: '80px' }}></div>
              </div>

              <Button name={t('next')} onClick={goNext} />
            </div>
          </div>
        </>
      );
    }
  }
}

export default ChooseService;
