import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Title1,
} from '../../../../Components/BasicComponents/BasicComponents';
import styles from './SuccessSignUpModal.module.css';
import planguin from 'Imgs/planguin.png';
import Imgs from 'Imgs/Imgs';
import { useSuccessSignUpModalStore } from './SuccessSignUpModalStore';
import { useTranslation } from 'react-i18next';

function SuccessSignUpModal(props) {
  let navigate = useNavigate();
  const { t } = useTranslation();

  let { SuccessSignUpModal, Note, changeSuccessSignUpModal, changeNote } =
    useSuccessSignUpModalStore();

  if (SuccessSignUpModal) {
    return (
      <>
        <div className={styles.popup_background}>
          <div className={styles.popup_window1}>
            <div className={styles.popup_title1}>{t('welcome')} </div>
            <div className={styles.popup_img1}>
              <img src={planguin} alt="" />
            </div>
            <Title1 />
            <Button
              style={{ marginTop: '35px' }}
              color={'black'}
              name={t('start_working')}
              onClick={() => {
                // changeSuccessSignUpModal(false)
                // changeNote(true)
                navigate('/step1');
              }}
            />
          </div>
        </div>
      </>
    );
  }
  if (Note) {
    return (
      <>
        <div className={styles.popup_background}>
          <div
            style={{ width: '400px', height: '530px' }}
            className={styles.popup_window1}
          >
            <div className={styles.popup_title1}>📎 {t('note')}</div>

            <div style={{ marginTop: '30px' }}>
              3 {t('steps_to_start_using_service')}: <br />
              <br />
              1️⃣ {t('add_personal_information.0')} <b>{t('add_personal_information.1')} </b> <br />
              &nbsp;&nbsp;&nbsp;&nbsp; ({t('profile_tab')}). <br />
              <br />
              2️⃣ {t('add_basic_schedule_work.0')} <b>{t('add_basic_schedule_work.1')}</b> {t('add_basic_schedule_work.2')} <br />
              &nbsp;&nbsp;&nbsp;&nbsp; ({t('schedule_menu')}). <br />
              <br />
              3️⃣ {t('add_at_least_one_service.0')} <b>{t('add_at_least_one_service.1')}</b> <br /> &nbsp;&nbsp;&nbsp;&nbsp;
              ({t('services_menu')}). <br />
              <br />
              {t('add_current_bookings')}
              <br />
              <br />
              <a href="https://bit.ly/planguin-setting">
                👉{t('detailed_instruction')}
              </a>
            </div>

            <Button
              style={{ marginTop: '35px' }}
              color={'black'}
              name={t('ok')}
              onClick={() => {
                // navigate("/profile/specialist");
                navigate('/step1');
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default SuccessSignUpModal;
