import React from 'react';
import styles from './EditClient.module.css';
import backImg from '../Images/Vector.svg';
import deleteImg from '../Images/Shape.svg';
import {
  Button,
  InputWidthLabel,
  TextArea,
} from '../BasicComponents/BasicComponents';
import { useMobileClientsStore } from '../MobileClientsStore';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { useTranslation } from 'react-i18next';

function EditClient() {
  const { t } = useTranslation();
  let { goToPage, client, inputHandler, updateClient, showConfirmationModal } =
    useMobileClientsStore();

  return (
    <>
      <ConfirmationModal />

      <div className={styles.editClient_container}>
        <div className={styles.editClient_header}>
          <img
            onClick={() => {
              goToPage('AllClients');
            }}
            src={backImg}
            alt="back"
          />
          <h1 className={styles.editClient_title}>{t('edit_contact')}</h1>
          <img
            onClick={showConfirmationModal}
            style={{ width: '23px' }}
            src={deleteImg}
            alt="delete"
          />
        </div>
        <div className={styles.editClient_form}>
          <InputWidthLabel
            id={'name'}
            value={client.name}
            onChange={inputHandler}
            name="name"
            label={t('name')}
            type="name"
          />
          <InputWidthLabel
            id={'surname'}
            value={client.surname}
            onChange={inputHandler}
            name="surname"
            label={t('last_name')}
            type="name"
          />
          <InputWidthLabel
            id={'phone'}
            value={client.phone}
            onChange={inputHandler}
            name="tel"
            label={t('phone')}
            type="tel"
          />
          <InputWidthLabel
            id={'email'}
            value={client.email}
            onChange={inputHandler}
            name="email"
            label={t('client_email')}
            type="email"
          />
          <TextArea
            id={'comment'}
            value={client.comment}
            label={t('comment')}
            onChange={inputHandler}
            classNameTextArea={styles.editClient_textarea}
          />
          <div style={{ height: '80px' }}></div>
          <Button
            onClick={updateClient}
            className={styles.editClient_button}
            label={t('save')}
          />
        </div>
      </div>
    </>
  );
}

export default EditClient;
