import Header from 'Components/Header/Header';
import Toast2 from 'Components/Toast2/Toast2';
import StatsBlock from './Components/StatsBlock/StatsBlock';
import styles from './Stats.module.css';
import { StatsPeriodSelector } from './Components/StatsPeriodSelector/StatsPeriodSelector';

function Stats() {
  document.body.style.backgroundColor = '#F4F7FE';

  return (
    <>
      <Toast2 />
      <Header platform={'desktop'} />

      <div className={styles.container}>
        <StatsPeriodSelector />
        <StatsBlock />
      </div>
    </>
  );
}

export default Stats;
