import React from 'react';
import { Button } from '../../../../Components/BasicComponents/BasicComponents';
import styles from './SuccessAppointment.module.css';
import planguin from '../../../../Imgs/planguin.png';
import Imgs from '../../../../Imgs/Imgs';
import { useAppointmentConfirmationStore } from '../AppointmentConfirmation/AppointmentConfirmationStore';
import { useTranslation } from 'react-i18next';

function SuccessAppointment(props) {
  const { t } = useTranslation();
  let {
    appointmentConfirmationSuccesWindow,
    changeAppointmentConfirmationSuccesWindowState,
    isAppointmentUpdate
  } = useAppointmentConfirmationStore();

  if (appointmentConfirmationSuccesWindow) {
    return (
      <>
        <div className={styles.popup_background}>
          <div className={styles.popup_window}>
            <div className={styles.title}>
              {isAppointmentUpdate 
                ? t('already_booked')
                : t('booked')
              }
              {' '}
              <span
                onClick={() => {
                  changeAppointmentConfirmationSuccesWindowState(false);
                  window.location.reload();
                }}
                className={styles.close}
              >
                <Imgs img={'close'} />
              </span>
            </div>

            <div className={styles.img}>
              <img src={planguin} alt="" />
            </div>
            <div className={styles.img_title}>{t('booking_confirmed')} ✅</div>
            <Button
              name={t('ok_thanks')}
              color={'black'}
              onClick={() => {
                changeAppointmentConfirmationSuccesWindowState(false);
                window.location.reload();
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default SuccessAppointment;
