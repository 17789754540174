import { API } from 'utils/api';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import Cookies from 'js-cookie';

const serialize = (state) => JSON.stringify(state);
const deserialize = (serializedState) => JSON.parse(serializedState);

//! FrontendDB

export let useDB = create(
  persist(
    (set, get) => ({
      specialist: [],
      specialistServices: [],
      specialistGroupServices: [],
      specialistAppointments: [],
      specialistClients: [],
      specialistCategories: [],

      updatedTime: undefined,

      updateDB: async (data) => {
        //Якщо користувач немає токена, тоді базу не оновлювати
        if (!Cookies.get('sToken')) {
          console.log('Немає токена');
          return;
        }

        //Якщо останнє оновлення було не пізніше 1хв
        // if( Date.now()-get().updatedTime<60000 && data=='*'){
        //         console.log('Данні актуальні');
        //         return
        // }

        let start = Date.now();

        if (data == 'specialist' || data == '*') {
          let specialist = await API.Specialist.getInfo();
          if (specialist) {
            console.log('Данні спеціаліста оновлені');
          }
          set({ specialist });
        }

        if (data == 'specialistServices' || data == '*') {
          let specialistServices = await API.Service.getAll();
          if (specialistServices) {
            console.log('Послуги спеціаліста оновлені');
          }
          set({ specialistServices });
        }

        if (data == 'specialistGroupServices' || data == '*') {
          let specialistGroupServices = await API.GroupService.getAll();
          if (specialistGroupServices) {
            console.log('Групові послуги спеціаліста оновлені');
          }
          set({ specialistGroupServices });
        }

        if (data == 'specialistAppointments' || data == '*') {
          let specialistAppointments = await API.Appointment.getAll();
          if (specialistAppointments) {
            console.log('Записи спеціаліста оновлені');
          }
          set({ specialistAppointments });
        }

        if (data == 'specialistClients' || data == '*') {
          let specialistClients = await API.Client.getAll();
          if (specialistClients) {
            console.log('Клієнти спеціаліста оновлені');
          }
          set({ specialistClients });
        }

        set({ updatedTime: Date.now() });

        let end = Date.now();
        console.log(`Оновлення бази завершено. Час ${end - start} мс`);
        return true;
      },

      //Вихід з аккаунта
      deleteDB: () => {
        set({
          specialist: [],
          specialistServices: [],
          specialistGroupServices: [],
          specialistAppointments: [],
          specialistClients: [],
          specialistCategories: [],
          updatedTime: undefined,
        });
      },

      setSpecialist: (e) => {
        set({
          specialist: { ...get().specialist, [e.target.id]: e.target.value },
        });
      },

      getSpecialistService: (serviceId) => {
        let [service] = get().specialistServices.filter(
          (service) => service._id == serviceId,
        );
        return service;
      },
    }),
    {
      name: 'DB',
      storage: localStorage,
      serialize,
      deserialize,
    },
  ),
);
