import React, { useEffect } from 'react';
import styles from './ClientsBlock.module.css';
import { Button, Client, Input } from '../BasicComponents/BasicComponents';
import { useClientsStore } from '../../ClientsStore';
import { useTranslation } from 'react-i18next';

function ClientsBlock() {
  const { t } = useTranslation();
  let {
    searchValue,
    clients,
    filteredClients,
    getSpecialistsClients,
    showAddContactForm,
    showEditContactForm,
    filterClients,
    showSendMessageModal,
  } = useClientsStore();

  useEffect(() => {
    async function af() {
      await getSpecialistsClients();
    }
    af();
  }, []);

  return (
    <>
      <div className={styles.clientsBlock_container}>
        <div className={styles.clientsBlock_container_header}>
          <Input
            onChange={filterClients}
            style={{ width: '400px', margin: '0px' }}
            placeholder={t('search')}
          />
          <Button
            onClick={showAddContactForm}
            name={t('add_client_button')}
            style={{ width: '200px' }}
          />
        </div>

        <div className={styles.clients_container}>
          {(searchValue ? filteredClients : clients).map((client) => {
            return (
              <Client
                onClick={() => {
                  showEditContactForm(client._id);
                }}
                onClickMsg={() => {
                  showSendMessageModal({
                    clientName: client.name,
                    clientId: client._id,
                  });
                }}
                name={client?.name}
                surname={client?.surname}
                phone={client?.phone}
                initials={`${client?.name[0]}`}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ClientsBlock;
