import React from 'react';
import { Button } from '../LandingComponents';
import styles from './Block1.module.css';
import stylesM from './Block1M.module.css';
import planguin1 from '../../Images/planguin1.png';
import a from '../../Images/a.png';
import g from '../../Images/g.png';
import mobileapp from '../../Images/mobileapp.png';
import { useTranslation } from 'react-i18next';

function Block1(props) {
  const { t } = useTranslation();

  if (props.type == 'm') {
    return (
      <>
        <dir className={stylesM.container}>
          <div className={stylesM.title}>{t('client_booking_program')}</div>
          <div className={stylesM.subtitle}>
            {t('online_booking_web_page')}
          </div>

          <div className={stylesM.img_container}>
            <img src={mobileapp} width={'200px'} />
            <img
              className={stylesM.planguinimg}
              src={planguin1}
              width={'250px'}
            />
          </div>
          <div className={stylesM.description}>
            {t('help_clients_book_in_2_clicks')}
          </div>

          <Button
            style={{ width: '100%' }}
            name={t('registration')}
            type={2}
            onClick={() => {
              window.location.href = '/signup';
            }}
          />
          {/* <Button style={{border:'1px solid black',color:'black',backgroundColor:'transparent',width:'100%'}} name={'Всі функції'} type={2} onClick={()=>{window.location = (""+window.location).replace(/#[A-Za-z0-9_]*$/,'')+"#functions"}}/> */}

          <div className={stylesM.circle}></div>

          <div className={stylesM.stores_container}>
            <div className={stylesM.stores_title}>
              {`${t('download_mobile_app.0')} ${t('download_mobile_app.1')}`}
            </div>

            <div className={stylesM.stores}>
              <img
                src={a}
                width={'150px'}
                height={'46px'}
                alt=""
                onClick={() => {
                  window.open(
                    `https://apps.apple.com/ua/app/planguin/id6446116686?l=uk`,
                  );
                }}
              />
              <img
                src={g}
                width={'170px'}
                height={'46px'}
                alt=""
                onClick={() => {
                  window.open(
                    'https://play.google.com/store/apps/details?id=com.rboy0509.planguin',
                  );
                }}
              />
            </div>
          </div>
        </dir>
      </>
    );
  }

  return (
    <>
      <div className={styles.outer_container}>
        <div className={styles.inner_container}>
          <div className={styles.title}>{t('client_booking_program')}</div>
          <div className={styles.sub_title}>
            {t('online_booking_web_page')}
          </div>
          <div className={styles.description}>
            {t('help_clients_book_in_2_clicks')}
          </div>
          <div className={styles.buttons}>
            <Button
              name={t('registration')}
              type={2}
              onClick={() => {
                window.location.href = '/signup';
              }}
            />
            {/* <Button style={{border:'1px solid black',color:'black',backgroundColor:'transparent'}} name={'Всі функції'} type={2} onClick={()=>{window.location = (""+window.location).replace(/#[A-Za-z0-9_]*$/,'')+"#functions"}}/> */}
          </div>
        </div>
        <img src={mobileapp} alt="" />
        <img className={styles.img} src={planguin1} width={'350px'} />
        <div className={styles.circle}></div>
      </div>

      <div className={styles.stores_container}>
        <div className={styles.stores_title}>
          {t('download_mobile_app.0')} <br></br> {t('download_mobile_app.1')}
        </div>

        <div className={styles.stores}>
          <img
            src={a}
            width={'160px'}
            height={'56px'}
            alt=""
            onClick={() => {
              window.open(
                `https://apps.apple.com/ua/app/planguin/id6446116686?l=uk`,
              );
            }}
          />
          <img
            src={g}
            width={'200px'}
            height={'56px'}
            alt=""
            onClick={() => {
              window.open(
                'https://play.google.com/store/apps/details?id=com.rboy0509.planguin',
              );
            }}
          />
        </div>
      </div>
    </>
  );
}

export default Block1;
