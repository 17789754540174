import Select from 'react-select';
import { useSelectClient } from './useSelectClient';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function SelectClient(props) {
  const { t } = useTranslation();
  let {
    getSpecialistClients,
    clientsOptions,
    clientSearchInputValue,
    clientSearchInputHandler,
    setInitialClient,
    setSource,
  } = useSelectClient();

  let [fnExecuted, setFnExecuted] = useState(false);

  useEffect(() => {
    async function af() {
      await getSpecialistClients();
      setSource(props.source);

      if (fnExecuted) return;
      await setInitialClient(props.clientPhone);
      setFnExecuted(true);
    }
    af();
  }, []);

  let colorStyles = {
    option: (styles, { data, isSelected }) => {
      return {
        ...styles,
        color: isSelected ? 'white' : 'green',
        color: data.color,
      };
    },
  };

  let { option } = colorStyles;

  return (
    <>
      <Select
        placeholder={t('select_client_or_create')}
        options={clientsOptions}
        inputValue={undefined}
        value={clientSearchInputValue}
        onChange={clientSearchInputHandler}
        noOptionsMessage={() => t('no_contacts_found')}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), option }}
      />
    </>
  );
}

export default SelectClient;
