import React, { useState, useLayoutEffect, useEffect } from 'react';
import Imgs from '../../Imgs/Imgs';
import Block1 from './Components/Block1/Block1';
import Block2 from './Components/Block2/Block2';
import Block3 from './Components/Block3/Block3';
import Block4 from './Components/Block4/Block4';
import Block5 from './Components/Block5/Block5';
import Block6 from './Components/Block6/Block6';
import Block7 from './Components/Block7/Block7';
import Block8 from './Components/Block8/Block8';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import {
  Advantage,
  BlockTitle,
  Button,
  ForWhom,
  Function,
  Input,
  Step,
} from './Components/LandingComponents';
import styles from './Landing.module.css';
import { Helmet } from 'react-helmet';
import { Prices } from './Components/Prices/Prices';

function Landing() {
  //Адаптивный дизайн
  let [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function updateWidth() {
    setWindowWidth(window.innerWidth);
  }
  useLayoutEffect(() => {
    window.addEventListener('resize', updateWidth);

    if (windowWidth < 1025) {
      document.body.style.backgroundColor = '#F4F7FE';
    }
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  window.document.body.style.fontFamily = 'Montserrat';

  //Мобілні пристрої

  if (windowWidth <= 1024) {
    return (
      <>
        <div className={styles.containerM}>
          <Header type="mobile" />
          <Block1 type="m" />
          <Block2 type="m" />
          <Block3 type="m" />
          <Block4 type="m" />
          <Block5 type="m" />
          <Prices type="m" />
          <Block6 type="m" />
          <Block7 type="m" />
          {/* <Block8 type="m" /> */}
        </div>

        <Footer type={'m'} />
      </>
    );
  }

  //Десктоп

  return (
    <>
      <div className={styles.container}>
        <Header type={'desktop'} />
        <Block1 />
        <Block2 />
        <Block3 />
        <Block4 />
        <Block5 />
        <Prices />
        <Block6 />
        <Block7 />
        {/* <Block8 /> */}
      </div>
      <Footer />
    </>
  );
}

export default Landing;
