import React, { useEffect, useState } from 'react';
import styles from './Toast2.module.css';
import { useToast2 } from './ToastStore';

function Toast2(props) {
  let { show, type, msg } = useToast2();

  if (show) {
    if (type == 'error')
      return (
        <div style={props.style} className={styles.container1}>
          {msg}
        </div>
      );

    if (type == 'success')
      return (
        <div style={props.style} className={styles.container2}>
          {msg}
        </div>
      );
  }
}

export default Toast2;
