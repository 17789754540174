import React, { useEffect } from 'react';
import styles from './SendMassage.module.css';
import closeImage from '../Images/Close.svg';
import {
  Button,
  InputWidthLabel,
  SelectWidthLabel,
  TextArea,
} from '../BasicComponents/BasicComponents';
import { useClientsStore } from '../../ClientsStore';

const options = [
  { value: 'sms', label: 'SMS' },
  { value: 'email', label: 'Email' },
];

function SendMassage(props) {
  let {
    sendMessageModal,
    hideSendMessageModal,
    message,
    msgModalInputHandler,
    sendMessageToClient,
    sms,
    getSpecialistInfo,
    specialist,
  } = useClientsStore();

  useEffect(() => {
    async function af() {
      await getSpecialistInfo();
    }
    af();
  }, []);

  if (sendMessageModal) {
    return (
      <div className={styles.sendMassage_container}>
        <div className={styles.sendMassage_block}>
          <h1 className={styles.sendMassage_title}>Надіслати повідомлення</h1>
          <img
            onClick={hideSendMessageModal}
            className={styles.sendMassage_close}
            src={closeImage}
            alt="close"
          />
          <InputWidthLabel
            disabled
            style={{
              marginBottom: '0px',
              backgroundColor: 'white',
              paddingTop: '0px',
              paddingLeft: '10px',
              color: 'coral',
              fontSize: '16px',
              fontWeight: 'bold',
              caret: 'none',
            }}
            id={props.id}
            value={message.clientName}
            label="Кому:"
            name="send"
            type="text"
          />

          <SelectWidthLabel
            onChange={msgModalInputHandler}
            options={options}
            id={'channel'}
            select={styles.sendMassage_input}
            label="Метод:"
            name="select"
          />
          <TextArea
            value={message.msg}
            onChange={msgModalInputHandler}
            name="Повідомлення:"
            id="msg"
          />

          {message.channel == 'sms' ? (
            <div className={styles.sms_data}>
              <div>
                Cимволів:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {sms.symbols} / {sms.numberOfsms} SMS
                </span>{' '}
              </div>
              <div>"{specialist.visibleName}"</div>
            </div>
          ) : (
            <></>
          )}

          <Button
            style={{ marginTop: '25px' }}
            onClick={sendMessageToClient}
            name="Надіслати повідомлення"
          />
        </div>
      </div>
    );
  }
}

export default SendMassage;
