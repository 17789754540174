import React from 'react';
import styles from './InactiveAccount.module.css';
import nodata from '../../Imgs/nodata.png';
import { useTranslation } from 'react-i18next';

function InactiveAccount() {
  const { t } = useTranslation()
  
  return (
    <>
      <div className={styles.container}>
        <img src={nodata} alt="" />
        <div className={styles.title}>{t('profile_inactive')}</div>
      </div>
    </>
  );
}

export default InactiveAccount;
