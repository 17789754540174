import { fn } from 'moment/moment';
import React from 'react';
import { BlockTitle, Button } from '../LandingComponents';
import styles from './Prices.module.css';
import stylesM from './PricesM.module.css';
import { useTranslation } from 'react-i18next';

function Plan1(props) {
  const { t } = useTranslation();
  if (props.type == 'm') {
    return (
      <div className={stylesM.plan_container}>
        <div className={stylesM.plan_title}>{t('basic_license')}</div>
        <div
          className={stylesM.options}
          style={{ fonSize: '18px', color: 'grey' }}
        >
          {' '}
          <span style={{ fontSize: '35px', fontWeight: 500, color: 'black' }}>
            170
          </span>
          {` ${t('uah_per_month')}`}
        </div>
        <div className={stylesM.options}>
          · {t('basic_functionality')} <br />
          <br />
          · {t('online_link_booking')} <br />
          <br />
          · {t('calendar_journal')} <br />
          <br />
          · {t('telegram_email_notifications')} <br />
          <br />
          · {t('client_sms_reminders')} <br />
          <br />
          · {t('technical_support')} &nbsp;&nbsp;Telegram/Email {t('available_24_7')}
          <br />
          <br />
          · {t('client_database_and_work')} <br />
          &nbsp;&nbsp;{t('customers')} <br />
          <br />
          · {t('group_services')} <br />
          <br />
          · {t('statistics_and_analytics.0')} {t('statistics_and_analytics.1')} <br />
        </div>
        <Button
          type={4}
          name={t('try')}
          style={{
            width: '280px',
            height: '60px',
            marginTop: '10px',
            position: 'relative',
          }}
          onClick={() => {
            window.location.href = '/signup';
          }}
        />
      </div>
    );
  }

  return (
    <div className={styles.plan_container}>
      <div className={styles.plan_title}>{t('basic_license')}</div>
      <div
        className={styles.options}
        style={{ fonSize: '18px', color: 'grey' }}
      >
        {' '}
        <span style={{ fontSize: '35px', fontWeight: 500, color: 'black' }}>
          170
        </span>
        {` ${t('uah_per_month')}`}
      </div>
      <div className={styles.options}>
        · {t('basic_functionality')} <br />
        <br />
        · {t('online_link_booking')} <br />
        <br />
        · {t('calendar_journal')} <br />
        <br />
        · {t('telegram_email_notifications')} <br />
        <br />
        · {t('client_sms_reminders')} <br />
        <br />
        · {t('technical_support')} Telegram/ <br />
        &nbsp; Email {t('available_24_7')}
        <br />
        <br />
        · {t('client_database_and_work')} <br />
        &nbsp; {t('customers')} <br />
        <br />
        · {t('group_services')} <br />
        <br />
        · {t('statistics_and_analytics.0')} {t('statistics_and_analytics.1')} <br />
      </div>

      <Button
        type={4}
        name={t('try')}
        style={{ width: '370px', height: '60px', marginTop: '25px' }}
        onClick={() => {
          window.location.href = '/signup';
        }}
      />
    </div>
  );
}

function Plan2(props) {
  const { t } = useTranslation();

  if (props.type == 'm') {
    return (
      <div className={stylesM.plan_container}>
        <div className={stylesM.plan_title}>{t('extended_license')}</div>
        <div
          className={stylesM.options}
          style={{ fonSize: '18px', color: 'grey' }}
        >
          {' '}
          <span style={{ fontSize: '35px', fontWeight: 500, color: 'black' }}>
            ~ 200
          </span>
          {` ${t('uah_per_month')}`}
        </div>
        <div className={stylesM.options}>
          · {t('all_features_basic_license')} <br />
          <br />
          · {t('profile_style_change')} <br />
          <br />
          <span>
            {' '}
            · {t('client_database_and_work')} <br />
            &nbsp; {`${t('customers')} `}
          </span>{' '}
          <br />
          <br />
          · {t('notification_customization')} <br />
          <br />
          · {t('online_client_payment')} <br />
          <br />
          · {t('bonus_system')} <br />
          <br />
          · {t('statistics_and_analytics.0')} <br />
          &nbsp; {t('statistics_and_analytics.1')} <br />
          <br />
          · {t('latest_tools_for_working')}<br />
          &nbsp; {t('clients_and_more')}
          <br />
          &nbsp; {t('interesting')} <br />
        </div>
        <Button
          type={5}
          name={t('in_development')}
          style={{
            width: '280px',
            height: '60px',
            marginTop: '0px',
            position: 'relative',
          }}
        />
      </div>
    );
  }

  return (
    <div className={styles.plan_container}>
      <div className={styles.plan_title}>{t('extended_license')}</div>
      <div
        className={styles.options}
        style={{ fonSize: '18px', color: 'grey' }}
      >
        {' '}
        <span style={{ fontSize: '35px', fontWeight: 500, color: 'black' }}>
          ~ 200
        </span>
        {` ${t('uah_per_month')}`}
      </div>
      <div className={styles.options}>
        · {t('all_features_basic_license')} <br />
        <br />
        · {t('profile_style_change')} <br />
        <br />
        <span>
          {' '}
          · {t('client_database_and_work')} <br />
          &nbsp; {`${t('customers')}, `}
        </span>{' '}
        <br />
        <br />
        · {t('notification_customization')} <br />
        <br />
        · {t('online_client_payment')} <br />
        <br />
        · {t('bonus_system')} <br />
        <br />
        · {t('statistics_and_analytics')}
        <br />
        &nbsp; {t('visit_statistics')} <br />
        <br />
        · {t('latest_tools_for_working')} <br />
        &nbsp; {t('clients_and_more')} {t('interesting')} <br />
      </div>
      <Button
        type={5}
        name={t('in_development')}
        style={{ width: '370px', height: '60px', marginTop: '0px' }}
      />
    </div>
  );
}

export function Prices(props) {
  const { t } = useTranslation();

  if (props.type == 'm') {
    return (
      <>
        {/* <div className={styles.title_container}><BlockTitle type={'m'} title={'Тарифи'} /></div> */}
        <div className={stylesM.container}>
          <BlockTitle title={t('tariffs')} type={'m'} />
          <Plan1 type="m" />
          <Plan2 type="m" />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.title_container}>
        <BlockTitle title={t('tariffs')} />
      </div>
      <div className={styles.container}>
        <Plan1 />
        <Plan2 />
      </div>
    </>
  );
}
