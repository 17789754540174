import React from 'react';
import styles from './BasicComponents.module.css';
import mail from '../Images/mail.png';
import telegram from '../Images/telegram.png';
import viber from '../Images/viber.png';

export function InputWidthLabel(props) {
  return (
    <div className={`${styles.form_contact} ${props.className || ''}`}>
      <label className={styles.label} for={props.name}>
        {props.label}
      </label>
      <input
        disabled={props.disabled}
        style={props.style}
        className={styles.input}
        value={props.value}
        onChange={props.onChange}
        type={props.type}
        id={props.id}
      />
    </div>
  );
}
export function SelectWidthLabel(props) {
  return (
    <div className={`${styles.form_contact} ${props.className || ''}`} >
      {
        props.label && (
          <label
            className={`${styles.label} ${styles.selectLabel}`}
            for={props.name}
          >
            {props.label}
          </label>
        )
      }
      
      <select className={`${styles.select} ${props.select}`} onChange={props.onChange} value={props.value} id={props.id}>
        {props.options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
export function TextArea(props) {
  return (
    <>
      <label className={styles.label} for="textarea">
        {props.name}
      </label>
      <textarea
        onChange={props.onChange}
        value={props.value}
        style={props.style}
        className={styles.textarea}
        id={props.id}
      ></textarea>
    </>
  );
}

export function Button(props) {
  return (
    <button
      style={props.style}
      onClick={props.onClick}
      className={styles.button}
    >
      {props.name}
    </button>
  );
}

export function Input(props) {
  return (
    <input
      onChange={props.onChange}
      value={props.value}
      style={props.style}
      className={styles.input}
      placeholder={props.placeholder}
      id={props.id}
    />
  );
}

export function Client(props) {
  return (
    <div className={styles.client_container}>
      <div onClick={props.onClick} className={styles.client_block}>
        <div className={styles.client_image}>{props.initials}</div>
        <div className={styles.client_name}>
          {props.name} {props.surname}
        </div>
        <div className={styles.client_tel}>{props.phone}</div>
      </div>
      <div className={styles.channels}>
        {props.phone == '+380' || !props.phone ? (
          <></>
        ) : (
          <>
            <img
              src={telegram}
              style={{ width: '30px' }}
              onClick={() => {
                window.open(`https://t.me/${props.phone}`, '_blank');
              }}
              alt=""
            />
            {/* <img src={viber} style={{width:'30px'}} onClick={()=>{window.open(`viber://chat/?number=%2B${props.phone.replace(/\D/g, "")}`)}} alt="" /> */}
          </>
        )}
        <img
          src={mail}
          style={{ width: '30px' }}
          onClick={props.onClickMsg}
          alt=""
        />
      </div>
    </div>
  );
}
