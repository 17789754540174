import React, { useEffect, useState } from 'react';
import styles from './Toast.module.css';

function Toast(props) {
  if (!props.show) {
    return;
  }

  if (props.type == 'error')
    return (
      <div style={props.style} className={styles.container1}>
        {props.msg}
      </div>
    );

  if (props.type == 'success')
    return (
      <div style={props.style} className={styles.container2}>
        {props.msg}
      </div>
    );
}

export default Toast;
