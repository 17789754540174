export function dataURLToFile(dataURL, fileName) {
  const arr = dataURL.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
}

export function addMinutes(time, minutes) {
  const date = new Date(`1970-01-01T${time}:00`);

  date.setMinutes(date.getMinutes() + minutes);

  return date.toTimeString().substring(0, 5);
}

export const adjustPrice = (price, adjustment) => {
  // Перевіряємо, чи є коригування в форматі діапазону
  const isAdjustmentRange = adjustment.includes('-');
  let adjustmentMin, adjustmentMax;

  if (isAdjustmentRange) {
    // Розділяємо діапазон коригування на мінімальне і максимальне значення
    [adjustmentMin, adjustmentMax] = adjustment.split('-').map(Number);
  } else {
    // Якщо коригування є фіксованим значенням, використовуємо його як мінімальне і максимальне
    adjustmentMin = adjustmentMax = Number(adjustment);
  }

  if (price.includes('-')) {
    // Розділяємо діапазон цін на мінімальну і максимальну ціну
    let [minPrice, maxPrice] = price.split('-').map(Number);

    // Збільшуємо мінімальну і максимальну ціну
    let newMinPriceMin = minPrice + adjustmentMin;
    let newMinPriceMax = minPrice + adjustmentMax;
    let newMaxPriceMin = maxPrice + adjustmentMin;
    let newMaxPriceMax = maxPrice + adjustmentMax;

    // Формуємо новий діапазон
    return `${Math.min(newMinPriceMin, newMinPriceMax)}-${Math.max(newMaxPriceMin, newMaxPriceMax)}`;
  } else {
    // Якщо ціна не в форматі діапазону, просто збільшуємо її
    let minPrice = Number(price) + adjustmentMin;
    let maxPrice = Number(price) + adjustmentMax;

    // Повертаємо значення як є, якщо діапазон складається з одного значення
    if (minPrice === maxPrice) {
      return `${maxPrice}`;
    }
    return `${minPrice}-${maxPrice}`;
  }
};