import React, { useState, useLayoutEffect, useEffect } from 'react';
import Header from '../../Components/Header/Header';
import styles from './Calendar.module.css';
import { checkAccess } from '../../access';
import { AppointmentsBlock } from 'Pages/Calendar/Components/AppointmentsBlock/AppointmentsBlock';
import MobileCalendar from '../../Mobile/MobileCalendar/MobileCalendar';
import uk from 'date-fns/locale/uk';
import moment from 'moment';
import Cookies from 'js-cookie';
import Toast2 from '../../Components/Toast2/Toast2';
import { Scheduler } from './Components/Scheduler/Scheduler';
import DayPickerForCalendar from './Components/DayPickerForCalendar/DayPickerForCalendar';
import AddContactForm from 'Pages/Clients/Components/AddContactForm/AddContactForm';

function Calendar() {
  let specialistId = checkAccess();

  document.body.style.backgroundColor = '#F4F7FE';

  useEffect(() => {
    async function af() {
      if (Cookies.get('sToken')) {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ sToken: Cookies.get('sToken') }),
          );
        }
      }
    }
    af();
  }, []);

  //! Рендеринг

  // Mobile
  if (window.innerWidth < 1025) {
    return (
      <>
        <Toast2 />
        <MobileCalendar />
      </>
    );
  }

  // Desktop

  return (
    <>
      <AddContactForm />
      <Toast2 />
      <Header platform={'desktop'} specialistId={specialistId} />

      <div className={styles.container}>
        <div className={styles.left_side}>
          <div className={styles.calendar}>
            <DayPickerForCalendar />
          </div>
          <AppointmentsBlock />
        </div>

        <div className={styles.right_side}>
          <div className={styles.shedule}>
            <Scheduler />
          </div>
        </div>
      </div>
    </>
  );
}

export default Calendar;
