import React, { useState } from 'react';
import {
  Background,
  Button,
  Input,
  InputsTitle,
} from '../../Components/BasicComponents/BasicComponents';
import Imgs from '../../Imgs/Imgs';
import styles from './ConfirmEmail.module.css';
import Toast from '../../Components/Toast/Toast';
import { checkAccess } from '../../access';
import { useTranslation } from 'react-i18next';

function ConfirmEmail() {
  const { t } = useTranslation();
  let [toast, setToast] = useState({ type: '', show: false, msg: '' });

  //Показати тост
  function showToast(type, msg) {
    if (type == 'e') {
      type = 'error';
    }
    if (type == 's') {
      type = 'success';
    }
    setToast({ type, show: true, msg });
    setTimeout(() => {
      setToast({ type: '', show: false, msg: '' });
    }, 2000);
  }

  let searchParams = new URLSearchParams(window.location.search);
  let email = searchParams.get('email');
  console.log(email);

  return (
    <>
      <Toast show={toast.show} type={toast.type} msg={toast.msg} />

      <Background>
        <div className={styles.outer_container}>
          <div className={styles.form_container}>
            <Imgs img={'planguinLogo'} />
            <InputsTitle
              style={{ paddingTop: '50px' }}
              title={`${t('email_confirmation_sent')} ✉️`}
            />
            <div style={{ textAlign: 'center' }}>
              {t('did_not_receive_email')}{' '}
              <span
                onClick={() => {
                  window.open('https://t.me/planguin', '_blank');
                }}
                style={{ color: 'coral' }}
              >
                {t('write_to_us')}
              </span>
            </div>
          </div>
        </div>
      </Background>
    </>
  );
}

export default ConfirmEmail;
