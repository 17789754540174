import React from 'react';
import { BlockTitle } from '../LandingComponents';
import styles from './Block4.module.css';
import stylesM from './Block4M.module.css';
import phone1 from '../../Images/phone1.png';
import phone1M from '../../Images/phone1M.png';
import { useTranslation } from 'react-i18next';

function Item(props) {
  if (props.type == 'm') {
    return (
      <>
        <div className={stylesM.item}>
          <div className={stylesM.circle}></div>
          <div className={stylesM.title}>{props.title}</div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.item}>
        <div className={styles.circle}></div>
        <div className={styles.title}>{props.title}</div>
      </div>
    </>
  );
}



function Block4(props) {
  const { t } = useTranslation();

  if (props.type == 'm') {
    return (
      <>
        <div id={'functions'} className={stylesM.container}>
          <div className={stylesM.img_container}>
            <img
              className={stylesM.img}
              width={'250px'}
              height={'360pxpx'}
              src={phone1}
            />
          </div>

          <div className={stylesM.functions}>
            <BlockTitle
              style={{ color: 'white' }}
              type={'m'}
              title={t('functionality')}
            />
            <Item type={'m'} title={t('client_record_calendar_journal')} />
            <Item type={'m'} title={t('work_schedule_formation')} />
            <Item type={'m'} title={t('convenient_list_of_services_and_cost')} />
            <Item type={'m'} title={t('personal_online_page')} />
            <Item
              type={'m'}
              title={t('client_database_and_management_system')}
            />
            <Item
              type={'m'}
              title={t('notifications_for_new_bookings')}
            />
            <Item type={'m'} title={t('sms_reminders_before_booking')} />
            <Item type={'m'} title={t('mobile_app_ios_and_android')} />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div id={'functions'} className={styles.container}>
        <div className={styles.inner_container}>
          <BlockTitle style={{ color: '#ffff' }} title={t('functionality')} />
          <Item title={t('client_record_calendar_journal')} />
          <Item title={t('work_schedule_formation')} />
          <Item title={t('convenient_list_of_services_and_cost')} />
          <Item title={t('personal_online_page')} />
          <Item title={t('client_database_and_management_system')} />
          <Item
            title={t('notifications_for_new_bookings')}
          />
          <Item title={t('sms_reminders_before_booking')} />
          <Item title={t('mobile_app_ios_and_android')} />

          <img width={'650px'} className={styles.img} src={phone1} />
        </div>
      </div>
    </>
  );
}

export default Block4;
