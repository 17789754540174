import React, { useState, useLayoutEffect } from 'react';
import {
  Background,
  Button,
  Input,
  InputsTitle,
} from '../../Components/BasicComponents/BasicComponents';
import Imgs from '../../Imgs/Imgs';
import styles from './Step1.module.css';
import axios from 'axios';
import Toast from '../../Components/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import { checkAccess } from '../../access';
import shortId from 'shortid';
import { useTranslation } from 'react-i18next';

function Step1() {
  const { t } = useTranslation();
  let specialistId = checkAccess();

  let navigate = useNavigate();

  let [data, setData] = useState({
    visibleName: '',
    phone: '+380',
    // login: "",
  });
  let [toast, setToast] = useState({ type: '', show: false, msg: '' });

  //Показати тост
  function showToast(type, msg) {
    if (type == 'e') {
      type = 'error';
    }
    if (type == 's') {
      type = 'success';
    }
    setToast({ type, show: true, msg });
    setTimeout(() => {
      setToast({ type: '', show: false, msg: '' });
    }, 2000);
  }

  //Адаптивный дизайн
  let [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function updateWidth() {
    setWindowWidth(window.innerWidth);
  }
  useLayoutEffect(() => {
    window.addEventListener('resize', updateWidth);

    if (windowWidth < 1025) {
      document.body.style.backgroundColor = '#F4F7FE';
    }
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  //Обробник інпута
  function inputHandler(e) {
    if (e.target.id == 'phone' && e.target.value.length < 4) {
      return;
    }
    if (e.target.value.length > 13) {
      return;
    }

    setData((ps) => ({ ...ps, [e.target.id]: e.target.value }));
  }

  //Відправити данні
  async function sendData() {
    if (!checkData()) {
      return;
    }

    let response = await axios.put(
      `${process.env.REACT_APP_API}/api/specialists/${specialistId}`,
      data,
      { withCredentials: true },
    );

    navigate('/step2');
  }

  // Перевірка даанних
  function checkData() {
    // Перевірка номеру
    //    if(data.phone.length!=13){showToast('e','Не вірний формат номеру');return false}

    return true;
  }

  //! Рендеринг

  //Mobile
  if (windowWidth < 1025) {
    return (
      <>
        <Toast show={toast.show} type={toast.type} msg={toast.msg} />

        <div className={styles.mobile_form_container}>
          <div className={styles.logo_container}>
            <Imgs img={'planguinLogo'} planguinLogoWidth={'200px'} />
          </div>

          <InputsTitle
            style={{ marginTop: '20px' }}
            title={`${t('add_basic_info')} 📝`}
          />

          <div className={styles.inner_form_container_mobile}>
            {/* <div className={styles.title}>Додай базову інфу 📝</div> */}
            <Input
              id={'visibleName'}
              placeholder={t('name_or_title_visible_to_clients')}
              onChange={inputHandler}
            />
            <Input
              id={'phone'}
              placeholder={t('phone')}
              value={data.phone}
              onChange={inputHandler}
            />
          </div>

          <Button name={t('next')} onClick={sendData} />
          <Button
            onClick={() => {
              navigate('/step2');
            }}
            name={t('skip')}
            style={{ backgroundColor: '#DADADA', marginTop: '-15px' }}
          />
        </div>
      </>
    );
  }

  //Web
  return (
    <>
      <Toast show={toast.show} type={toast.type} msg={toast.msg} />

      <Background style={{ backgroundColor: '#F4F7FE' }}>
        <div className={styles.outer_container}>
          <div className={styles.form_container}>
            <Imgs img={'planguinLogo'} />

            <div className={styles.inner_form_container}>
              {/* <div className={styles.title}>Додай базову інфу 📝</div> */}
              <InputsTitle title={`${t('add_basic_info')} 📝`} />

              <Input
                id={'visibleName'}
                onChange={inputHandler}
                placeholder={t('name_or_title_visible_to_clients')}
              />
              <Input
                id={'phone'}
                onChange={inputHandler}
                placeholder={t('phone')}
                value={data.phone}
                label={true}
              />
            </div>

            <Button name={t('next')} onClick={sendData} />
            <Button
              name={t('skip')}
              style={{ backgroundColor: '#DADADA', marginTop: '-15px' }}
              onClick={() => {
                navigate('/step2');
              }}
            />
          </div>
        </div>
      </Background>
    </>
  );
}

export default Step1;
