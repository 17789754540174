import React from 'react';
import stylesM from './BottomMenuM.module.css';
import BottomNavigation from 'reactjs-bottom-navigation';
import 'reactjs-bottom-navigation/dist/index.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Icons(props) {
  if (props.icon == 'services') {
    return (
      <>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.9909 15.7373C22.2188 15.5105 22.2866 15.168 22.1637 14.8712C22.0407 14.5744 21.7503 14.3804 21.4291 14.3804H18.2488C16.0533 14.3804 14.2734 16.1602 14.2734 18.3557V21.536C14.2734 21.8572 14.4674 22.1476 14.7643 22.2706C15.0611 22.3936 15.4035 22.3257 15.6304 22.0978L21.9909 15.7373Z"
            fill="#979797"
          />
          <mask
            id="mask0_1940_82"
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="22"
            height="22"
          >
            <path
              d="M1.60547 1.65918H22.2511V22.3407H1.60547V1.65918Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_1940_82)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.3929 22.3309C13.2773 22.0871 13.2137 21.8168 13.2137 21.5359V18.3556C13.2137 15.5749 15.4686 13.3202 18.2491 13.3202H21.4295C21.7104 13.3202 21.9806 13.3837 22.2245 13.4993V5.63453C22.2245 3.43904 20.4446 1.65918 18.2491 1.65918H5.52808C3.3326 1.65918 1.55273 3.43904 1.55273 5.63453V18.3556C1.55273 20.5511 3.3326 22.3309 5.52808 22.3309H13.3929ZM6.58815 15.9704H12.9487C13.3876 15.9704 13.7438 15.6142 13.7438 15.1753C13.7438 14.7365 13.3876 14.3802 12.9487 14.3802H6.58815C6.14926 14.3802 5.79313 14.7365 5.79313 15.1753C5.79313 15.6142 6.14926 15.9704 6.58815 15.9704ZM6.58815 11.73H17.1891C17.628 11.73 17.9841 11.3739 17.9841 10.935C17.9841 10.4961 17.628 10.1399 17.1891 10.1399H6.58815C6.14926 10.1399 5.79313 10.4961 5.79313 10.935C5.79313 11.3739 6.14926 11.73 6.58815 11.73ZM6.58815 7.48962H17.1891C17.628 7.48962 17.9841 7.13349 17.9841 6.6946C17.9841 6.25571 17.628 5.89957 17.1891 5.89957H6.58815C6.14926 5.89957 5.79313 6.25571 5.79313 6.6946C5.79313 7.13349 6.14926 7.48962 6.58815 7.48962Z"
              fill="#979797"
            />
          </g>
        </svg>
      </>
    );
  }

  if (props.icon == 'servicesA') {
    return (
      <>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.9909 15.7373C22.2188 15.5105 22.2866 15.168 22.1637 14.8712C22.0407 14.5744 21.7503 14.3804 21.4291 14.3804H18.2488C16.0533 14.3804 14.2734 16.1602 14.2734 18.3557V21.536C14.2734 21.8572 14.4674 22.1476 14.7643 22.2706C15.0611 22.3936 15.4035 22.3257 15.6304 22.0978L21.9909 15.7373Z"
            fill="#FF8A35"
          />
          <mask
            id="mask0_1940_82"
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="22"
            height="22"
          >
            <path
              d="M1.60547 1.65918H22.2511V22.3407H1.60547V1.65918Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_1940_82)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.3929 22.3309C13.2773 22.0871 13.2137 21.8168 13.2137 21.5359V18.3556C13.2137 15.5749 15.4686 13.3202 18.2491 13.3202H21.4295C21.7104 13.3202 21.9806 13.3837 22.2245 13.4993V5.63453C22.2245 3.43904 20.4446 1.65918 18.2491 1.65918H5.52808C3.3326 1.65918 1.55273 3.43904 1.55273 5.63453V18.3556C1.55273 20.5511 3.3326 22.3309 5.52808 22.3309H13.3929ZM6.58815 15.9704H12.9487C13.3876 15.9704 13.7438 15.6142 13.7438 15.1753C13.7438 14.7365 13.3876 14.3802 12.9487 14.3802H6.58815C6.14926 14.3802 5.79313 14.7365 5.79313 15.1753C5.79313 15.6142 6.14926 15.9704 6.58815 15.9704ZM6.58815 11.73H17.1891C17.628 11.73 17.9841 11.3739 17.9841 10.935C17.9841 10.4961 17.628 10.1399 17.1891 10.1399H6.58815C6.14926 10.1399 5.79313 10.4961 5.79313 10.935C5.79313 11.3739 6.14926 11.73 6.58815 11.73ZM6.58815 7.48962H17.1891C17.628 7.48962 17.9841 7.13349 17.9841 6.6946C17.9841 6.25571 17.628 5.89957 17.1891 5.89957H6.58815C6.14926 5.89957 5.79313 6.25571 5.79313 6.6946C5.79313 7.13349 6.14926 7.48962 6.58815 7.48962Z"
              fill="#FF8A35"
            />
          </g>
        </svg>
      </>
    );
  }

  if (props.icon == 'shedule') {
    return (
      <>
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.1254 0.935059C5.49492 0.935059 0.935547 5.50462 0.935547 11.1351C0.935547 16.7655 5.49492 21.3351 11.1254 21.3351C16.766 21.3351 21.3355 16.7655 21.3355 11.1351C21.3355 5.50462 16.766 0.935059 11.1254 0.935059ZM11.1355 19.2951C6.62721 19.2951 2.97559 15.6435 2.97559 11.1351C2.97559 6.62673 6.62721 2.9751 11.1355 2.9751C15.644 2.9751 19.2956 6.62673 19.2956 11.1351C19.2956 15.6435 15.644 19.2951 11.1355 19.2951ZM11.6456 6.03506H10.1155V12.1551L15.4706 15.368L16.2355 14.1134L11.6456 11.39V6.03506Z"
            fill="#979797"
          />
        </svg>
      </>
    );
  }

  if (props.icon == 'sheduleA') {
    return (
      <>
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.1254 0.935059C5.49492 0.935059 0.935547 5.50462 0.935547 11.1351C0.935547 16.7655 5.49492 21.3351 11.1254 21.3351C16.766 21.3351 21.3355 16.7655 21.3355 11.1351C21.3355 5.50462 16.766 0.935059 11.1254 0.935059ZM11.1355 19.2951C6.62721 19.2951 2.97559 15.6435 2.97559 11.1351C2.97559 6.62673 6.62721 2.9751 11.1355 2.9751C15.644 2.9751 19.2956 6.62673 19.2956 11.1351C19.2956 15.6435 15.644 19.2951 11.1355 19.2951ZM11.6456 6.03506H10.1155V12.1551L15.4706 15.368L16.2355 14.1134L11.6456 11.39V6.03506Z"
            fill="#FF8A35"
          />
        </svg>
      </>
    );
  }

  if (props.icon == 'menu') {
    return (
      <>
        <svg
          style={{ paddingTop: '5px' }}
          width="22"
          height="22"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 3.42857H22.5C23.3284 3.42857 24 2.66106 24 1.71429C24 0.767512 23.3284 0 22.5 0H1.5C0.671573 0 0 0.767512 0 1.71429C0 2.66106 0.671573 3.42857 1.5 3.42857Z"
            fill="#979797"
          />
          <path
            d="M1.5 24.0003H22.5C23.3284 24.0003 24 23.2328 24 22.2861C24 21.3393 23.3284 20.5718 22.5 20.5718H1.5C0.671573 20.5718 0 21.3393 0 22.2861C0 23.2328 0.671573 24.0003 1.5 24.0003Z"
            fill="#979797"
          />
          <path
            d="M1.5 13.7142H22.5C23.3284 13.7142 24 12.9467 24 11.9999C24 11.0532 23.3284 10.2856 22.5 10.2856H1.5C0.671573 10.2856 0 11.0532 0 11.9999C0 12.9467 0.671573 13.7142 1.5 13.7142Z"
            fill="#979797"
          />
        </svg>
      </>
    );
  }

  if (props.icon == 'menuA') {
    return (
      <>
        <svg
          style={{ paddingTop: '5px' }}
          width="22"
          height="22"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 3.42857H22.5C23.3284 3.42857 24 2.66106 24 1.71429C24 0.767512 23.3284 0 22.5 0H1.5C0.671573 0 0 0.767512 0 1.71429C0 2.66106 0.671573 3.42857 1.5 3.42857Z"
            fill="#FF8A35"
          />
          <path
            d="M1.5 24.0003H22.5C23.3284 24.0003 24 23.2328 24 22.2861C24 21.3393 23.3284 20.5718 22.5 20.5718H1.5C0.671573 20.5718 0 21.3393 0 22.2861C0 23.2328 0.671573 24.0003 1.5 24.0003Z"
            fill="#FF8A35"
          />
          <path
            d="M1.5 13.7142H22.5C23.3284 13.7142 24 12.9467 24 11.9999C24 11.0532 23.3284 10.2856 22.5 10.2856H1.5C0.671573 10.2856 0 11.0532 0 11.9999C0 12.9467 0.671573 13.7142 1.5 13.7142Z"
            fill="#FF8A35"
          />
        </svg>
      </>
    );
  }

  if (props.icon == 'calendar') {
    return (
      <>
        <svg
          width="22"
          height="22"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 4.57149C0 2.67806 1.46534 1.14307 3.27279 1.14307H20.7273C22.5348 1.14307 24 2.67806 24 4.57149V20.5715C24 22.465 22.5348 24 20.7273 24H3.27279C1.46534 24 0 22.465 0 20.5715V4.57149ZM3.27279 3.42863C2.67031 3.42863 2.18191 3.94035 2.18191 4.57149V20.5715C2.18191 21.2026 2.67031 21.7143 3.27279 21.7143H20.7273C21.3298 21.7143 21.8182 21.2026 21.8182 20.5715V4.57149C21.8182 3.94035 21.3298 3.42863 20.7273 3.42863H3.27279Z"
            fill="#979797"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 4.57149C0 2.67806 1.46534 1.14307 3.27279 1.14307H20.7273C22.5348 1.14307 24 2.67806 24 4.57149V8.00006C24 8.63134 23.5116 9.14291 22.9091 9.14291H1.09102C0.488537 9.14291 0 8.63134 0 8.00006V4.57149ZM3.27279 3.42863C2.67031 3.42863 2.18191 3.94035 2.18191 4.57149V6.8572H21.8182V4.57149C21.8182 3.94035 21.3298 3.42863 20.7273 3.42863H3.27279Z"
            fill="#979797"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.27344 0H5.45521V3.99985H3.27344V0Z"
            fill="#979797"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9082 0H13.0901V3.99985H10.9082V0Z"
            fill="#979797"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.5449 0H20.7268V3.99985H18.5449V0Z"
            fill="#979797"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.9082 11.4287H7.09011V13.7144H4.9082V11.4287Z"
            fill="#979797"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.9082 16H7.09011V18.2857H4.9082V16Z"
            fill="#979797"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9082 11.4287H13.0901V13.7144H10.9082V11.4287Z"
            fill="#979797"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9082 16H13.0901V18.2857H10.9082V16Z"
            fill="#979797"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9082 11.4287H19.0901V13.7144H16.9082V11.4287Z"
            fill="#979797"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9082 16H19.0901V18.2857H16.9082V16Z"
            fill="#979797"
          />
        </svg>
      </>
    );
  }

  if (props.icon == 'calendarA') {
    return (
      <>
        <svg
          width="22"
          height="22"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 4.57149C0 2.67806 1.46534 1.14307 3.27279 1.14307H20.7273C22.5348 1.14307 24 2.67806 24 4.57149V20.5715C24 22.465 22.5348 24 20.7273 24H3.27279C1.46534 24 0 22.465 0 20.5715V4.57149ZM3.27279 3.42863C2.67031 3.42863 2.18191 3.94035 2.18191 4.57149V20.5715C2.18191 21.2026 2.67031 21.7143 3.27279 21.7143H20.7273C21.3298 21.7143 21.8182 21.2026 21.8182 20.5715V4.57149C21.8182 3.94035 21.3298 3.42863 20.7273 3.42863H3.27279Z"
            fill="#FF8A35"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 4.57149C0 2.67806 1.46534 1.14307 3.27279 1.14307H20.7273C22.5348 1.14307 24 2.67806 24 4.57149V8.00006C24 8.63134 23.5116 9.14291 22.9091 9.14291H1.09102C0.488537 9.14291 0 8.63134 0 8.00006V4.57149ZM3.27279 3.42863C2.67031 3.42863 2.18191 3.94035 2.18191 4.57149V6.8572H21.8182V4.57149C21.8182 3.94035 21.3298 3.42863 20.7273 3.42863H3.27279Z"
            fill="#FF8A35"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.27344 0H5.45521V3.99985H3.27344V0Z"
            fill="#FF8A35"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9082 0H13.0901V3.99985H10.9082V0Z"
            fill="#FF8A35"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.5449 0H20.7268V3.99985H18.5449V0Z"
            fill="#FF8A35"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.9082 11.4287H7.09011V13.7144H4.9082V11.4287Z"
            fill="#FF8A35"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.9082 16H7.09011V18.2857H4.9082V16Z"
            fill="#FF8A35"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9082 11.4287H13.0901V13.7144H10.9082V11.4287Z"
            fill="#FF8A35"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9082 16H13.0901V18.2857H10.9082V16Z"
            fill="#FF8A35"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9082 11.4287H19.0901V13.7144H16.9082V11.4287Z"
            fill="#FF8A35"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9082 16H19.0901V18.2857H16.9082V16Z"
            fill="#FF8A35"
          />
        </svg>
      </>
    );
  }

  if (props.icon == 'clients') {
    return (
      <svg
        style={{ marginTop: '3px' }}
        width="22"
        height="22"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.22656 4.36343C5.22656 6.76991 7.03999 10.1476 9.2886 10.1476C11.5372 10.1476 13.3506 6.76991 13.3506 4.36343C13.3506 1.95706 11.5372 0 9.2886 0C7.03999 0 5.22656 1.95706 5.22656 4.36343Z"
          fill="#979797"
        />
        <path
          d="M17.4549 18.5547C18.1078 18.5547 18.6156 18.0328 18.572 17.395C18.4704 15.9163 18.1803 13.4519 17.3969 12.7126C16.657 12.0167 12.2613 10.7266 12.2613 10.7266L9.28732 12.901L6.31337 10.7266C6.31337 10.7266 1.91765 12.0167 1.17772 12.7126C0.394385 13.4519 0.104222 15.9018 0.00260211 17.395C-0.0409164 18.0328 0.466838 18.5547 1.11973 18.5547H17.4549Z"
          fill="#979797"
        />
      </svg>
    );
  }

  if (props.icon == 'clientsA') {
    return (
      <svg
        style={{ marginTop: '3px' }}
        width="22"
        height="22"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.22656 4.36343C5.22656 6.76991 7.03999 10.1476 9.2886 10.1476C11.5372 10.1476 13.3506 6.76991 13.3506 4.36343C13.3506 1.95706 11.5372 0 9.2886 0C7.03999 0 5.22656 1.95706 5.22656 4.36343Z"
          fill="#FF8A35"
        />
        <path
          d="M17.4549 18.5547C18.1078 18.5547 18.6156 18.0328 18.572 17.395C18.4704 15.9163 18.1803 13.4519 17.3969 12.7126C16.657 12.0167 12.2613 10.7266 12.2613 10.7266L9.28732 12.901L6.31337 10.7266C6.31337 10.7266 1.91765 12.0167 1.17772 12.7126C0.394385 13.4519 0.104222 15.9018 0.00260211 17.395C-0.0409164 18.0328 0.466838 18.5547 1.11973 18.5547H17.4549Z"
          fill="#FF8A35"
        />
      </svg>
    );
  }
}

function Link(props) {
  return (
    <>
      <div onClick={props.onClick} className={stylesM.link_container}>
        <Icons icon={props.icon} />
        <div
          style={{ color: props.active ? '#FF8A35' : <></> }}
          className={stylesM.link_title}
        >
          {props.name}
        </div>
      </div>
    </>
  );
}

function BottomMenu() {
  let navigate = useNavigate();
  const { t } = useTranslation();

  let path = new URL(window.location.href).pathname;

  return (
    <>
      <div className={stylesM.bottom_menu_container}>
        <Link
          active={path == '/calendar' ? true : false}
          icon={path == '/calendar' ? 'calendarA' : 'calendar'}
          onClick={() => {
            navigate('/calendar');
          }}
          name={t('bookings')}
        />
        <Link
          active={path == '/profile/specialist/' ? true : false}
          icon={path == '/profile/specialist/' ? 'sheduleA' : 'shedule'}
          onClick={() => {
            navigate('/profile/specialist/?page="shedule');
          }}
          name={t('my_schedule')}
        />
        <Link
          active={path == '/specialist/services' ? true : false}
          icon={path == '/specialist/services' ? 'servicesA' : 'services'}
          onClick={() => {
            navigate('/specialist/services');
          }}
          name={t('services')}
        />
        <Link
          active={path == '/clients' ? true : false}
          icon={path == '/clients' ? 'clientsA' : 'clients'}
          onClick={() => {
            navigate('/clients');
          }}
          name={t('clients')}
        />
        <Link
          active={path == '/more' ? true : false}
          icon={path == '/more' ? 'menuA' : 'menu'}
          onClick={() => {
            navigate('/more');
          }}
          name={'Більше'}
        />
      </div>
    </>
  );
}

export default BottomMenu;
