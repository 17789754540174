import { LanguageEnum } from "./enums";
import { useTranslation } from 'react-i18next';

export const useCountryOptions = () => {
  const { t } = useTranslation();

  return [
    { value: LanguageEnum.Uk, label: t('countries.Ukraine') },
    { value: LanguageEnum.En, label: t('countries.USA') },
    { value: LanguageEnum.De, label: t('countries.Europe') },
    { value: LanguageEnum.Us, label: t('countries.GreatBritain') },
    { value: LanguageEnum.As, label: t('countries.Asia') },
    { value: LanguageEnum.Ca, label: t('countries.Canada') },
    { value: LanguageEnum.Au, label: t('countries.Australia') },
  ];
}

export const currencyOptions = [
  { value: LanguageEnum.Uk, label: 'Ukrainian Hryvnia - UAH' },
  { value: LanguageEnum.En, label: 'United States Dollar - USD' },
  { value: LanguageEnum.De, label: 'Euro - EUR' },
  { value: LanguageEnum.Ru, label: 'Russian Ruble - RUB' },
  { value: LanguageEnum.Us, label: 'British Pound - GBP' },
  { value: LanguageEnum.Jp, label: 'Japanese Yen - JPY' },
  { value: LanguageEnum.Cn, label: 'Chinese Yuan - CNY' },
];

export const languageOptions = [
  { value: LanguageEnum.Uk, label: "🇺🇦 Українська"  },
  { value: LanguageEnum.En, label: "🇬🇧 English"  },
  { value: LanguageEnum.De, label: "🇩🇪 Deutsch"  },
  { value: LanguageEnum.Ru, label: "🇷🇺 Російська"  },
]