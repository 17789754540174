import { create } from 'zustand';
import axios from 'axios';
import { fn } from 'moment';

export let useBookingStore = create((set, get) => ({
  specialist: undefined,
  specialistId: undefined,
  page: undefined,

  getSpecialistId: async () => {
    let specialistLogin = getLoginFromUrl();
    let { data } = await axios.post(
      `${process.env.REACT_APP_API}/api/specialists/ids`,
      { login: specialistLogin },
    );

    if (data.length == 24) {
      set({ specialistId: data });
    } else {
      set({ page: 'noSpecialist' });
    }
  },

  checkSpecialistAccountStatus: async () => {
    if (!get().specialistId) {
      return;
    }

    let { data } = await axios.get(
      `${process.env.REACT_APP_API}/api/specialists/${get().specialistId}`,
    );
    set({ specialist: data });
    if (data.plan.expiresIn < Date.now()) {
      window.location.href = '/inactive_account';
      return;
    }
  },
}));

//Допоміжні функції

function getLoginFromUrl() {
  let pathname = new URL(window.location.href).pathname;
  let parts = pathname.split('/');
  let login = parts[1];
  return login;
}
