import React from 'react';
import stylesM from './AboutM.module.css';
import stylesD from './AboutD.module.css';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import { Button } from '../Components/LandingComponents';
import phone from './imgs/phone.png';
import img1 from './imgs/1.png';
import img2 from './imgs/2.png';
import img3 from './imgs/3.png';
import img4 from './imgs/4.png';
import img5 from './imgs/5.png';
import img6 from './imgs/6.png';
import img7 from './imgs/7.png';
import img8 from './imgs/8.png';
import img9 from './imgs/9.png';
import Block7 from '../Components/Block7/Block7';
import { useTranslation } from 'react-i18next';

function MainBlock(props) {
  const { t } = useTranslation();

  if (props.type == 'm') {
    return (
      <>
        <div className={stylesM.main_block_container}>
          <div className={stylesM.img_container}></div>
          <div className={stylesM.main_block_title}>
            {t('main_features')}
          </div>
          <div className={stylesM.main_block_button_container}>
            <Button
              style={{ width: '100%' }}
              type={5}
              name={t('registration')}
              onClick={() => {
                window.location.href = '/signup';
              }}
            />
          </div>
          <img className={stylesM.phone} src={phone} alt="" />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={stylesD.main_block_container}>
        <div className={stylesD.main_block_title}>
          {t('main_features')}
        </div>
        <div className={stylesD.main_block_button_container}>
          <Button
            style={{ width: '411px' }}
            type={5}
            name={t('registration')}
            onClick={() => {
              window.location.href = '/signup';
            }}
          />
        </div>
        <img className={stylesD.phone} src={phone} alt="" />
      </div>
    </>
  );
}

function Item(props) {
  if (props.type == 'm') {
    return (
      <>
        <div className={stylesM.item_container}>
          <div className={stylesM.item_number}>{props.number}</div>
          <div className={stylesM.item_title}>{props.title}</div>
        </div>

        <div className={stylesM.item_text}>{props.text}</div>
      </>
    );
  }

  return (
    <>
      <div className={stylesD.item_container}>
        <div className={stylesD.item_number}>{props.number}</div>
        <div className={stylesD.item_title}>{props.title}</div>
        <div></div>
        <div className={stylesD.item_text}>{props.text}</div>
      </div>
    </>
  );
}

function About() {
  //Мобільні пристрої
  const { t } = useTranslation();

  if (window.innerWidth <= 768) {
    return (
      <>
        <div className={stylesM.containerM}>
          <Header type="mobile2" />
          <MainBlock type={'m'} />

          <div className={stylesM.functions_container}>
            <div className={stylesD.text}>
              <Item
                type={'m'}
                number={'01'}
                title={t('online_booking')}
                text={t('personal_web_link')}
              />
            </div>
            <div
              style={{ display: 'flex', justifyContent: 'center' }}
              className={stylesD.functions_img}
            >
              <img src={img1} alt="" />
            </div>

            <div className={stylesD.text}>
              <Item
                type={'m'}
                number={'02'}
                title={t('client_calendar_journal')}
                text={t('client_journal_description')}
              />
            </div>
            <div
              style={{ display: 'flex', justifyContent: 'center' }}
              className={stylesD.functions_img}
            >
              <img src={img2} alt="" />
            </div>

            <div className={stylesD.text}>
              <Item
                type={'m'}
                number={'03'}
                title={t('work_schedule_1')}
                text={t('systematize_work_schedule')}
              />
            </div>
            <div
              style={{ display: 'flex', justifyContent: 'center' }}
              className={stylesD.functions_img}
            >
              <img src={img3} alt="" />
            </div>

            <div className={stylesD.text}>
              <Item
                type={'m'}
                number={'04'}
                title={t('service_list_and_cost')}
                text={t('add_service_info')}
              />
            </div>
            <div
              style={{ display: 'flex', justifyContent: 'center' }}
              className={stylesD.functions_img}
            >
              <img src={img4} alt="" />
            </div>

            <div className={stylesD.text}>
              <Item
                type={'m'}
                number={'05'}
                title={t('notifications')}
                text={t('receive_notifications')}
              />
            </div>
            <div
              style={{ display: 'flex', justifyContent: 'center' }}
              className={stylesD.functions_img}
            >
              <img src={img5} alt="" />
            </div>

            <div className={stylesD.text}>
              <Item
                type={'m'}
                number={'06'}
                title={t('client_reminders')}
                text={t('sms_reminders')}
              />
            </div>
            <div
              style={{ display: 'flex', justifyContent: 'center' }}
              className={stylesD.functions_img}
            >
              <img src={img6} alt="" />
            </div>

            <div className={stylesD.text}>
              <Item
                type={'m'}
                number={'07'}
                title={t('client_management_system')}
                text={t('client_database')}
              />
            </div>
            <div
              style={{ display: 'flex', justifyContent: 'center' }}
              className={stylesD.functions_img}
            >
              <img src={img7} alt="" />
            </div>

            <div className={stylesD.text}>
              <Item
                type={'m'}
                number={'08'}
                title={t('mobile_apps')}
                text={t('mobile_apps_description')}
              />
            </div>
            <div
              style={{ display: 'flex', justifyContent: 'center' }}
              className={stylesD.functions_img}
            >
              <img src={img8} alt="" />
            </div>

            <div className={stylesD.text}>
              <Item
                type={'m'}
                number={'09'}
                title={t('online_payment')}
                text={t('payment_description')}
              />
            </div>
            <div
              style={{ display: 'flex', justifyContent: 'center' }}
              className={stylesD.functions_img}
            >
              <img src={img9} alt="" />
            </div>
          </div>

          <div className={stylesD.block_title}>
            {t('more_features_in_development')}
          </div>

          <Block7 type={'m'} />
        </div>

        <Footer type={'m'} />
      </>
    );
  }

  //Десктоп

  return (
    <>
      <div className={stylesD.container}>
        <Header type={'desktop2'} />

        <MainBlock />

        <div className={stylesD.functions_container}>
          <div
            style={{ display: 'flex', justifyContent: 'center' }}
            className={stylesD.functions_img}
          >
            <img src={img1} alt="" />
          </div>
          <div className={stylesD.text}>
            <Item
              number={'01'}
              title={t('online_booking')}
              text={t('personal_web_link')}
            />
          </div>

          <div className={stylesD.text}>
            <Item
              number={'02'}
              title={t('client_calendar_journal')}
              text={t('client_journal_description')}
            />
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center' }}
            className={stylesD.functions_img}
          >
            <img src={img2} alt="" />
          </div>

          <div
            style={{ display: 'flex', justifyContent: 'center' }}
            className={stylesD.functions_img}
          >
            <img src={img3} alt="" />
          </div>
          <div className={stylesD.text}>
            <Item
              number={'03'}
              title={t('work_schedule_1')}
              text={t('systematize_work_schedule')}
            />
          </div>

          <div className={stylesD.text}>
            <Item
              number={'04'}
              title={t('service_list_and_cost')}
              text={t('add_service_info')}
            />
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center' }}
            className={stylesD.functions_img}
          >
            <img src={img4} alt="" />
          </div>

          <div
            style={{ display: 'flex', justifyContent: 'center' }}
            className={stylesD.functions_img}
          >
            <img src={img5} alt="" />
          </div>
          <div className={stylesD.text}>
            <Item
              number={'05'}
              title={t('notifications')}
              text={t('receive_notifications')}
            />
          </div>

          <div className={stylesD.text}>
            <Item
              number={'06'}
              title={t('client_reminders')}
              text={t('sms_reminders')}
            />
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center' }}
            className={stylesD.functions_img}
          >
            <img src={img6} alt="" />
          </div>

          <div
            style={{ display: 'flex', justifyContent: 'center' }}
            className={stylesD.functions_img}
          >
            <img src={img7} alt="" />
          </div>
          <div className={stylesD.text}>
            <Item
              number={'07'}
              title={t('client_management_system')}
              text={t('client_database')}
            />
          </div>

          <div className={stylesD.text}>
            <Item
              number={'08'}
              title={t('mobile_apps')}
              text={t('mobile_apps_description')}
            />
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center' }}
            className={stylesD.functions_img}
          >
            <img src={img8} alt="" />
          </div>

          <div
            style={{ display: 'flex', justifyContent: 'center' }}
            className={stylesD.functions_img}
          >
            <img src={img9} alt="" />
          </div>
          <div className={stylesD.text}>
            <Item
              number={'09'}
              title={t('online_payment')}
              text={t('payment_description')}
            />
          </div>
        </div>

        <div className={stylesD.block_title}>
          {t('more_features_in_development')}
        </div>

        <Block7 />
      </div>
      <div style={{ height: '100px' }}></div>
      <Footer />
    </>
  );
}

export default About;
