import React from 'react';
import { BlockTitle, ForWhom } from '../LandingComponents';
import styles from './Block3.module.css';
import stylesM from './Block3M.module.css';
import { useTranslation } from 'react-i18next';

function Block3(props) {
  const { t } = useTranslation();

  if (props.type == 'm') {
    return (
      <>
        <div className={stylesM.container}>
          <BlockTitle type={'m'} title={t('who_will_find_it_useful')} />
          <div className={stylesM.forWhom}>
            <ForWhom
              type={'m'}
              title={`💇 ${t('beauty_professionals')}`}
              description={t('barbers_hairdressers_professionals')}
            />

            <ForWhom
              type={'m'}
              title={`👨‍💼 ${t('consultants')}`}
              description={t('tutors_trainers_notaries_lawyers_service_centers')}
            />

            <ForWhom
              type={'m'}
              title={`👩‍⚕️ ${t('doctors')}`}
              description={t('private_practice_doctors_dentists_psychologists_and_other_specialists')}
            />

            <ForWhom
              type={'m'}
              title={`🧑‍🔧 ${t('automotive_service')}`}
              description={t('car_service_stations_tire_shops_car_washes_and_others')}
            />

            <ForWhom
              type={'m'}
              title={`‍👩‍🏭 ${t('automotive_service_equipment')}`}
              description={t('laser_hair_removal_saloons')}
            />

            <ForWhom
              type={'m'}
              title={`‍🏫 ${t('hourly_rental')}`}
              description={t('photo_studios_coworking_workspaces_and_more')}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          <BlockTitle title={t('who_will_find_it_useful')} />
        </div>
        <div className={styles.line1}>
          <ForWhom
            title={`💇 ${t('beauty_professionals')}`}
            description={t('barbers_hairdressers_professionals')}
          />

          <ForWhom
            title={`👨‍💼 ${t('consultants')}`}
            description={t('tutors_trainers_notaries_lawyers_service_centers')}
          />

          <ForWhom
            title={`👩‍⚕️ ${t('doctors')}`}
            description={t('private_practice_doctors_dentists_psychologists_and_other_specialists')}
          />
        </div>
        <div className={styles.line2}>
          <ForWhom
            title={`🧑‍🔧 ${t('automotive_service')}`}
            description={t('car_service_stations_tire_shops_car_washes_and_others')}
          />

          <ForWhom
            title={`‍👩‍🏭 ${t('automotive_service_equipment')}`}
            description={t('laser_hair_removal_saloons')}
          />

          <ForWhom
            title={`‍🏫 ${t('hourly_rental')}`}
            description={t('photo_studios_coworking_workspaces_and_more')}
          />
        </div>
      </div>
    </>
  );
}

export default Block3;
