import React from 'react';
import styles from './ConfirmationModal.module.css';
import { Button } from '../BasicComponents/BasicComponents';
import { useMobileClientsStore } from '../MobileClientsStore';
import { useTranslation } from 'react-i18next';

function ConfirmationModal() {
  const { t } = useTranslation();
  let { confirmationModal, hideConfirmationModal, deleteClient } =
    useMobileClientsStore();

  if (confirmationModal) {
    return (
      <>
        <div className={styles.confirmationModal_background}>
          <div className={styles.confirmationModal_block}>
            <h1 className={styles.confirmationModal_title}>
              {' '}
              Видалити контакт?
            </h1>
            <Button
              onClick={deleteClient}
              className={styles.confirmationModal_button__true}
              label={t('yes')}
            />
            <Button
              onClick={hideConfirmationModal}
              className={styles.confirmationModal_button__false}
              label={t('no')}
            />
          </div>
        </div>
      </>
    );
  }
}

export default ConfirmationModal;
