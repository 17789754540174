import React, { useEffect, useState } from 'react';
import styles from './AppointmentsBlock.module.css';
import Imgs from 'Imgs/Imgs';
import { ServiceInfo } from '../../../Booking/Components/AppointmentConfirmation/AppointmentConfirmation';
import {
  Button,
  Input,
  Selector,
  TextArea,
} from 'Components/BasicComponents/BasicComponents';
import moment, { fn } from 'moment';
import nodata from 'Imgs/nodata.png';
import { useAppointmentsStore } from './AppointmentsStore';
import AddGroupMembers from '../AddGroupMembers/AddGroupMembers';
import { Client } from 'Pages/Services/Components/ServiceEditBlock/ServiceEditBlock';
import SelectClient from '../SelectClient/SelectClient';
import { useTranslation } from 'react-i18next';

//! Desktop

export function AppointmentsBlock() {
  const { t, i18n } = useTranslation();

  moment.locale(i18n.language);

  let {
    appointments,
    getSpecialistAppointments,
    showAppointmentDetails,
    showAddAppointmentWindow,
    showGroupAppointmentWindow,
  } = useAppointmentsStore();

  useEffect(() => {
    async function af() {
      await getSpecialistAppointments();
    }
    af();
  }, []);

  let zaglushka = (
    <div className={styles.img_container}>
      <img src={nodata} width={'150px'} alt="" />
      <div className={styles.nodata_title}>{t('no_records')}</div>
    </div>
  );

  return (
    <>
      <AddAppointment />
      <EditAppointmentWindow />
      <AppointmentDetails />
      <CancelAppointment />
      <GroupAppointmentWindow />

      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>{t('current_records')}</div>
          <div
            onClick={() => {
              showAddAppointmentWindow();
            }}
            className={styles.add_button}
          >
            +
          </div>
        </div>
        <div className={styles.line}></div>

        <div className={styles.appointments_container}>
          {appointments.length == 0 ? zaglushka : <></>}

          {appointments.map((el) => {
            return [
              <div>
                {el.date == moment().format('YYYY/MM/DD')
                  ? t('today')
                  : moment(el.date).format('DD.MM.YYYY')}
              </div>,
              el.records.map((el) => {
                //Якщо груповий запис
                if (el.groupServiceId) {
                  return (
                    <AppointmentBlock
                      onClick={() => {
                        showGroupAppointmentWindow(el._id);
                      }}
                      serviceName={`${t('group')}: ${el.groupServiceName} `}
                      timeRange={`${el.from}-${el.to} `}
                      members={`${el.participants.length}/${el.maxNumberOfParticipants}`}
                    />
                  );
                }
                //Якщо звичайний запис

                return (
                  <AppointmentBlock
                    onClick={() => {
                      showAppointmentDetails(el._id);
                    }}
                    serviceName={el.clientName}
                    timeRange={el.timeRange}
                  />
                );
              }),
            ];
          })}
        </div>
      </div>
    </>
  );
}

//Модульні вікна

export function AddAppointment(props) {
  const { t } = useTranslation();

  let {
    appointment,
    getSpecialistData,
    getSpecialistServices,
    addAppointmentWindow,
    hideAddAppointmentWindow,
    inputHandler,
    specialistServices,
    addAppointment,
  } = useAppointmentsStore();

  useEffect(() => {
    async function af() {
      await getSpecialistData();
      await getSpecialistServices();
    }
    af();
  }, []);

  if (addAppointmentWindow) {
    return (
      <>
        <div className={styles.popup_background}>
          <div className={styles.popup_window}>
            <div className={styles.popup_title}>
              {t('create_record')}
              <span onClick={hideAddAppointmentWindow} className={styles.close}>
                <Imgs img={'close'} />
              </span>
            </div>

            <div className={styles.inner_container}>
              <div className={styles.service}>
                <div className={styles.input_title}>{t('service')}</div>
                <Selector
                  id={'serviceId'}
                  value={appointment?.serviceId}
                  onChange={inputHandler}
                  options={[]}
                  type={'custom'}
                  customOptions={specialistServices.map((el) => {
                    return <option value={el._id}>{el.name}</option>;
                  })}
                />
              </div>

              <div className={styles.service_info}>
                <label style={{ width: '250px' }}>
                  {t('date')}
                  <input
                    id={'date'}
                    value={moment(appointment?.date).format('YYYY-MM-DD')}
                    onChange={inputHandler}
                    type={'date'}
                    className={styles.input}
                  />
                </label>
                <label style={{ width: '170px' }}>
                  {t('time')}
                  <Selector
                    id={'time'}
                    value={appointment.time}
                    onChange={inputHandler}
                    options={times}
                  />
                </label>
              </div>

              <SelectClient source={'addAppointment'} />

              {/* <Input
                id={"clientName"}
                onChange={inputHandler}
                placeholder={"Імя"}
              />
              <Input
                id={"phone"}
                value={appointment.phone}
                onChange={inputHandler}
                placeholder={"Телефон"}
              /> */}

              <TextArea
                id={'comment'}
                onChange={inputHandler}
                style={{ height: '90px' }}
                placeholder={t('comment')}
              />
              <Button
                name={t('confirm')}
                color={'black'}
                onClick={addAppointment}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export function EditAppointmentWindow(props) {
  const { t, i18n } = useTranslation();

  moment.locale(i18n.language);
  
  let {
    appointment,
    getSpecialistData,
    getSpecialistServices,
    editAppointmentWindow,
    hideEditAppointmentWindow,
    inputHandler,
    specialistServices,
    updateAppointment,
  } = useAppointmentsStore();

  useEffect(() => {
    async function af() {
      await getSpecialistData();
      await getSpecialistServices();
    }
    af();
  }, []);

  if (editAppointmentWindow) {
    return (
      <>
        <div className={styles.popup_background}>
          <div className={styles.popup_window}>
            <div className={styles.popup_title}>
              {t('edit_booking')}
              <span
                onClick={hideEditAppointmentWindow}
                className={styles.close}
              >
                <Imgs img={'close'} />
              </span>
            </div>

            <div className={styles.inner_container}>
              <div className={styles.service}>
                <div className={styles.input_title}>{t('service')}</div>
                <Selector
                  id={'serviceId'}
                  value={appointment?.serviceId}
                  onChange={inputHandler}
                  options={[]}
                  type={'custom'}
                  customOptions={specialistServices.map((el) => {
                    return <option value={el._id}>{el.name}</option>;
                  })}
                />
              </div>

              <div className={styles.service_info}>
                <label style={{ width: '250px' }}>
                  {t('date')}
                  <input
                    id={'date'}
                    value={moment(appointment?.date).format('YYYY-MM-DD')}
                    onChange={inputHandler}
                    type={'date'}
                    className={styles.input}
                  ></input>
                </label>
                <label style={{ width: '170px' }}>
                  {t('time')}
                  <Selector
                    id={'time'}
                    value={appointment.time}
                    onChange={inputHandler}
                    options={times}
                  />
                </label>
              </div>

              <SelectClient
                source={'editAppointment'}
                clientPhone={appointment?.phone}
              />

              {/* <Input
                id={"clientName"}
                value={appointment.clientName}
                onChange={inputHandler}
                placeholder={"Імя"}
              />
              <Input
                id={"phone"}
                value={appointment.phone}
                onChange={inputHandler}
                placeholder={"Телефон"}
              /> */}
              <TextArea
                id={'comment'}
                value={appointment.comment}
                onChange={inputHandler}
                style={{ height: '90px' }}
                placeholder={t('comment')}
              />
              <Button
                name={t('update_changes')}
                color={'black'}
                onClick={updateAppointment}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export function AppointmentDetails(props) {
  const { t } = useTranslation();
  let {
    appointmentDetailsWindow,
    appointment,
    hideAppointmentDetailsWindow,
    showEditAppointmentWindow,
    showConfirmDeleteWindow,
  } = useAppointmentsStore();

  if (appointmentDetailsWindow) {
    return (
      <>
        <div className={styles.popup_background}>
          <div className={styles.popup_window}>
            <div className={styles.popup_title}>
              {appointment.serviceName}
              <span
                onClick={hideAppointmentDetailsWindow}
                className={styles.close}
              >
                <div className={styles.modal_buttons}>
                  <Imgs
                    width={'25px'}
                    img={'edit'}
                    onClick_Edit={showEditAppointmentWindow}
                  />
                  <Imgs img={'close'} />
                </div>
              </span>
            </div>

            <div className={styles.inner_details_container}>
              <div className={styles.service_info}>
                <ServiceInfo
                  type={'time'}
                  value={` ${moment(appointment.date).format(`DD.MM.YYYY`)} о ${
                    appointment.time
                  }`}
                />
                <ServiceInfo type={'price'} value={appointment.price} />
              </div>

              <div className={styles.fullname}>{appointment.clientName}</div>
              <div className={styles.phone}>{appointment.phone}</div>
              <div className={styles.comment}>{appointment.comment}</div>

              <Button
                color={'black'}
                name={t('cancel_booking')}
                onClick={showConfirmDeleteWindow}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export function CancelAppointment(props) {
  const { t } = useTranslation();
  let { confirmDeleteWindow, hideConfirmDeleteWindow, cancelAppointment } =
    useAppointmentsStore();

  if (confirmDeleteWindow) {
    return (
      <>
        <div className={styles.popup_background}>
          <div style={{ width: '350px' }} className={styles.popup_window}>
            <div
              className={styles.close_container}
              onClick={hideConfirmDeleteWindow}
            >
              <Imgs img={'close'} />
            </div>
            <div className={styles.text}>
              {t('cancel_visit_confirmation')}
            </div>
            <Button
              onClick={cancelAppointment}
              name={t('confirm')}
              color={'black'}
              style={{ marginTop: '30px' }}
            />
          </div>
        </div>
      </>
    );
  }
}

export function GroupAppointmentWindow(props) {
  const { t } = useTranslation();
  let {
    groupAppointment,
    groupAppointmentWindow,
    changeGroupAppointmentWindowState,
    groupMembers,
    deleteGroupMember,
    saveGroupMembers,
    deleteGroup
  } = useAppointmentsStore();

  const [isSendSMS, setSendSMS] = useState(false)
  const [appointmentModal, setAppointmentModal] =  useState({
    isOpen: false,
    groupAppointmentId: null
  })

  const onGroupDelete = async (groupAppointmentId, isSendSMS) => {
    deleteGroup(groupAppointmentId, isSendSMS)
    onGroupClose()
  }
  
  const onGroupClose = () => {
    setAppointmentModal({isOpen: false, groupAppointmentId: null})
    setSendSMS(false)
  }

  if (groupAppointmentWindow) {
    return (
      <>
        <div className={styles.popup_background}>
          <div style={{ height: '650px' }} className={styles.popup_window}>
            <div className={styles.popup_title}>
              {groupAppointment?.groupServiceName}
              <button 
                className={styles.group_appointment_group__delete} 
                type='button' 
                onClick={() => setAppointmentModal({isOpen: true, groupAppointmentId: groupAppointment?._id})} 
              >
                <Imgs img={'delete'} />
              </button>
              <span
                onClick={() => {
                  changeGroupAppointmentWindowState(false);
                }}
                className={styles.close}
              >
                <Imgs img={'close'} />
              </span>
              <div className={styles.group_appointment_time}>
                {moment(groupAppointment.date).format('dddd')}{' '}
                {groupAppointment?.from}
              </div>
            </div>

            <hr style={{ marginTop: '20px', borderTop: '#F1F1F1' }} />
              <AddGroupMembers />
            <div className={styles.group_members}>
              {groupMembers.map((el) => (
                <Client
                  onClick_Delete={() => {
                    deleteGroupMember(el._id);
                  }}
                  initials={el.name[0]}
                  name={el.name}
                  surname={el.surname}
                  phone={el.phone}
                />
              ))}
            </div>

            <Button
              onClick={saveGroupMembers}
              name={t('save')}
              style={{ marginTop: '20px', height: '50px' }}
            />
          </div>
        </div>
        {
          appointmentModal.isOpen && (
            <div className={styles.popup_background}>
              <div style={{ width: '350px' }} className={styles.popup_window}>
                <div
                  className={styles.close_container}
                  onClick={() => onGroupClose()}
                >
                  <Imgs img={'close'} />
                </div>
                <div className={styles.text}>
                  {t('delete_training')}
                </div>
                <div className={styles.modal_buttons}>
                  <Button
                    onClick={() => setSendSMS(true)}
                    name={t('yes')}
                    color={'black'}
                    style={{ marginTop: 20, height: '40px' }}
                  />
                  <Button
                    onClick={onGroupClose}
                    name={t('no')}
                    color={'black'}
                    style={{ marginTop: 20, height: '40px' }}
                  />
                </div>
              </div>
            </div>
          )
        }
        {
          isSendSMS && (
            <div className={styles.popup_background}>
              <div style={{ width: '350px' }} className={styles.popup_window}>
                <div
                  className={styles.close_container}
                  onClick={() => onGroupClose()}
                >
                  <Imgs img={'close'} />
                </div>
                <div className={styles.text}>
                  {t('notify_participants_sms')}
                </div>
                <div className={styles.modal_buttons}>
                  <Button
                    onClick={() => onGroupDelete(appointmentModal.groupAppointmentId, true)}
                    name={t('yes')}
                    color={'black'}
                    style={{ marginTop: 20, height: '40px' }}
                  />
                  <Button
                    onClick={() => onGroupDelete(appointmentModal.groupAppointmentId, false)}
                    name={t('no')}
                    color={'black'}
                    style={{ marginTop: 20, height: '40px' }}
                  />
                </div>
              </div>
            </div>
          )
        }
      </>
    );
  }
}

// Підкомпоненти

export function AppointmentBlock(props) {
  return (
    <>
      <div
        className={styles.appointment_block_container}
        onClick={props.onClick}
      >
        <div className={styles.color}></div>
        <div className={styles.content}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              color: 'grey',
              marginRight: '10px',
            }}
          >
            <div className={styles.timerange}>{props.timeRange}</div>
            <div>{props.members}</div>
          </div>
          <div className={styles.serviceName}>{props.serviceName}</div>
        </div>
      </div>
    </>
  );
}

let times = [
  '00:00',
  '00:15',
  '00:30',
  '00:45',
  '01:00',
  '01:15',
  '01:30',
  '01:45',
  '02:00',
  '02:15',
  '02:30',
  '02:45',
  '03:00',
  '03:15',
  '03:30',
  '03:45',
  '04:00',
  '04:15',
  '04:30',
  '04:45',
  '05:00',
  '05:15',
  '05:30',
  '05:45',
  '06:00',
  '06:15',
  '06:30',
  '06:45',
  '07:00',
  '07:15',
  '07:30',
  '07:45',
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00',
  '21:15',
  '21:30',
  '21:45',
  '22:00',
  '22:15',
  '22:30',
  '22:45',
  '23:00',
  '23:15',
  '23:30',
  '23:45',
  '24:00',
];

//Допоміжні

//Час для запису
// let times = [
//   "08:00",
//   "08:15",
//   "08:30",
//   "08:45",
//   "09:00",
//   "09:15",
//   "09:30",
//   "09:45",
//   "10:00",
//   "10:15",
//   "10:30",
//   "10:45",
//   "11:00",
//   "11:15",
//   "11:30",
//   "11:45",
//   "12:00",
//   "12:15",
//   "12:30",
//   "12:45",
//   "13:00",
//   "13:15",
//   "13:30",
//   "13:45",
//   "14:00",
//   "14:15",
//   "14:30",
//   "14:45",
//   "15:00",
//   "15:15",
//   "15:30",
//   "15:45",
//   "16:00",
//   "16:15",
//   "16:30",
//   "16:45",
//   "17:00",
//   "17:15",
//   "17:30",
//   "17:45",
//   "18:00",
//   "18:15",
//   "18:30",
//   "18:45",
//   "19:00",
//   "19:15",
//   "19:30",
//   "19:45",
//   "20:00",
//   "20:15",
//   "20:30",
//   "20:45",
//   "21:00",
//   "21:15",
//   "21:30",
//   "21:45",
//   "22:00",
//   "22:15",
//   "22:30",
//   "22:45",
// ];
