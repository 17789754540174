import React from 'react';
import { checkAccess } from '../../access';
import BottomMenu from '../../Components/BottomMenu/BottomMenu';
import { MobileHeader } from '../../Components/Header/Header';
import LinkBlock from '../LinkBlock/LinkBlock';
import stylesM from './MoreM.module.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import settings from '../../Imgs/settings.png';


function More() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  let specialistId = checkAccess();

  return (
    <>
      <MobileHeader />
      <div className={stylesM.container}>
        <div
          className={stylesM.link_container}
          onClick={() => {
            navigate('/profile/specialist/?page="profile"');
          }}
        >
          👤 {t('my_profile')}
        </div>

        <div
          className={stylesM.link_container}
          onClick={() => {
            navigate('/notifications');
          }}
        >
          📲 {t('notifications')}
        </div>

        <div
          className={stylesM.link_container}
          onClick={() => {
            navigate('#');
          }}
        >
          <div className={stylesM.inDevelopment}>
            в розробці
          </div>
          🗂️
          {t('casa')}
        </div>

        <div
          className={stylesM.link_container}
          onClick={() => {
            navigate('/support');
          }}
        >
          👩‍💻 {t('support')}
        </div>

        <div
          className={stylesM.link_container}
          onClick={() => {
            navigate('/payments');
          }}
        >
          💰 {t('payment')}
        </div>

        <div
          className={stylesM.link_container}
          onClick={() => {
            navigate('/profile/settings');
          }}
        >
          <img src={settings} alt="settings" /> {" "}
          {t('settings')}
        </div>

        <div
          style={{ borderColor: 'red', color: 'red' }}
          className={stylesM.link_container}
          onClick={() => {
            window.ReactNativeWebView.postMessage('exit');
            Cookies.remove('sToken', {
              domain: process.env.REACT_COOKIE_DOMAIN,
            });

            window.location.href = '/login';
          }}
        >
          🚪 {t('logout')}
        </div>
      </div>

      <LinkBlock specialistId={specialistId} platform={'mobile'} />
      <div
        style={{
          color: 'grey',
          fontSize: '10px',
          paddingTop: '100px',
          marginLeft: '48%',
          opacity: '50%',
        }}
      >
        v.1.01
      </div>
      <BottomMenu />
    </>
  );
}

export default More;
