import React, { useCallback, useState } from 'react';
import {
  Button,
  Input,
  Selector,
  TextArea,
} from '../../../../Components/BasicComponents/BasicComponents';
import styles from './AppointmentConfirmation.module.css';
import stylesM from './AppointmentConfirmationM.module.css';
import clock from '../../../../Imgs/Others/clock.png';
import price from '../../../../Imgs/Others/price.png';
import Imgs from '../../../../Imgs/Imgs';
import { useAppointmentConfirmationStore } from './AppointmentConfirmationStore';
import { useChooseServiceStore } from '../ChooseService/ChooseServiceStore';
import { useDatePickerBlockStore } from '../DatePickerBlock/DatePickerBlockStore';
import { useTimePickerBlockStore } from '../TimePickerBlock/TimePickerBlockStore';
import moment from 'moment';
import { addMinutes, adjustPrice } from 'utils/lib/helpers/helpers';
import { useTranslation } from 'react-i18next';
import { useCurrency } from 'hooks/useCurrency';
import PhoneNumberInput from 'Components/PhoneNumberInput/PhoneNumberInput';

export function ServiceInfo(props) {
  const { t } = useTranslation();
  const {currency} = useCurrency()

  if (props.t == 'm') {
    if (props.type == 'time') {
      return (
        <>
          <div className={stylesM.service_info_container}>
            <div className={stylesM.icon}>
              <img width={'50px'} src={clock} alt="" />
            </div>
            <div className={stylesM.service_info}>
              <div className={stylesM.service_info_title}>{t('time')}</div>
              <div className={stylesM.service_info_value}>{props.value}</div>
            </div>
          </div>
        </>
      );
    }

    if (props.type == 'price') {
      return (
        <>
          <div className={styles.service_info_container}>
            <div className={stylesM.icon}>
              <img width={'50px'} src={price} alt="" />
            </div>
            <div className={stylesM.service_info}>
              <div className={stylesM.service_info_title}>{t('cost')}</div>
              <div className={stylesM.service_info_value}>
                {props.value} {currency}
              </div>
            </div>
          </div>
        </>
      );
    }
  }

  if (props.type == 'time') {
    return (
      <>
        <div className={styles.service_info_container}>
          <div className={styles.icon}>
            <img src={clock} alt="" />
          </div>
          <div className={styles.service_info}>
            <div className={styles.service_info_title}>{t('time')}</div>
            <div className={styles.service_info_value}>{props.value}</div>
          </div>
        </div>
      </>
    );
  }

  if (props.type == 'price') {
    return (
      <>
        <div className={styles.service_info_container}>
          <div className={styles.icon}>
            <img src={price} alt="" />
          </div>
          <div className={styles.service_info}>
            <div className={styles.service_info_title}>{t('cost')}</div>
            <div className={styles.service_info_value}>{props.value} {t('currency')}</div>
          </div>
        </div>
      </>
    );
  }
}

function AppointmentConfirmation(props) {
  const { t } = useTranslation();
  let {
    appointmentConfirmationWindow,
    changeAppointmentConfirmationWindowState,
    inputHandler,
    appointment,
    confirmAppointment,
  } = useAppointmentConfirmationStore();

  let { service } = useChooseServiceStore();

  let { date } = useDatePickerBlockStore();
  let { timeSlotStartTime, groupTimeSlot } = useTimePickerBlockStore();

  const totalPrice = useCallback(() => {
    if (!service?.length) return ''
    
    return service.reduce((acc, nextService) => {
      return acc = adjustPrice(acc, nextService.price)
    }, '');
  }, [service])

  const totalTimes = useCallback(() => {
    const hasFavoriteSchedule = service?.some((s) => s.schedule)

    if (!service?.length || hasFavoriteSchedule) return null

    const countTimes = service.reduce((acc, nextService) => {
      return acc += nextService.hours * 60 + nextService.minutes
    }, 0);

    return `${timeSlotStartTime} - ${addMinutes(timeSlotStartTime, countTimes)}`
  }, [timeSlotStartTime])

  //! Рендеринг

  if (appointmentConfirmationWindow) {
    
    const serviceGroupsNames = service
      .filter((s) => Boolean(s.schedule))  
      .map((s) => `${s.name} (${t('group')})`)                 
      .join(' / ');    
                      
    const serviceNames = service
      .filter((s) => !Boolean(s.schedule))  
      .map((s) => s.name)                
      .join(' / ');                      

    return (
      <>
        <div className={styles.popup_background}>
          <div
            style={!timeSlotStartTime ? { height: '475px' } : {}}
            className={styles.popup_window}
          >
            <div className={styles.title}>
              {` ${t('booking_confirmation')} `}
              <span
                onClick={() => {
                  changeAppointmentConfirmationWindowState(false);
                }}
                className={styles.close}
              >
                <Imgs img={'close'} />
              </span>
            </div>
            <div className={styles.service}>
              <div className={styles.input_title}>{t('service')}</div>
              <div className={styles.service_name}>
                  {serviceNames}
                  {serviceGroupsNames}
              </div>
            </div>

            <div className={styles.service_info2}>
              <ServiceInfo
                type={'time'}
                value={`${moment(date).format('DD.MM')} / ${totalTimes() ?? groupTimeSlot?.from}`}
              />
              <ServiceInfo type={'price'} value={totalPrice()} />
            </div>

            <Input id={'name'} onChange={inputHandler} placeholder={t('name')} />

            <PhoneNumberInput
              id={'phone'} 
              handleChange={(value, country, event) => inputHandler(value, 'phone')} 
            />

            {/* Якщо запис на індивідуальну послугу */}
            {timeSlotStartTime ? (
              <TextArea
                id={'comment'}
                onChange={inputHandler}
                style={{ height: '90px' }}
                placeholder={t('comment')}
              />
            ) : (
              <></>
            )}

            <Button
              name={t('book')}
              color={'black'}
              onClick={confirmAppointment}
            />
          </div>
        </div>
      </>
    );
  }
}

export default AppointmentConfirmation;
