import React from 'react';
import Imgs from '../../Imgs/Imgs';
import styles from './RegistrationSuccessful.module.css';
import planguin from '../../Imgs/planguin.png';
import {
  Button,
  InputsTitle,
} from '../../Components/BasicComponents/BasicComponents';
import { useNavigate } from 'react-router-dom';
import { useRegistrationSuccessfulStore } from './RegistrationSuccessfulStore';
import { useTranslation } from 'react-i18next';

function RegistrationSuccessful() {
  let navigate = useNavigate();
  const { t } = useTranslation();

  let {
    RegistrationSuccessfulWindow,
    Note,
    changeRegistrationSuccessfulWindowState,
    changeNoteState,
  } = useRegistrationSuccessfulStore();

  //! Рендеринг
  if (RegistrationSuccessfulWindow) {
    return (
      <>
        <div className={styles.mobile_form_container}>
          <div className={styles.logo_container}>
            <Imgs img={'planguinLogo'} planguinLogoWidth={'200px'} />
          </div>

          <div className={styles.title}>{t('welcome')}</div>
          <div className={styles.img}>
            <img style={{ width: '300px' }} src={planguin} alt="" />
          </div>

          <InputsTitle title={`${t('registration_successful')} ✅`} />
          <div className={styles.button_container}>
            <Button
              name={t('start_working')}
              color={'black'}
              syle={{ marginTop: '200px' }}
              onClick={() => {
                // changeRegistrationSuccessfulWindowState(false);changeNoteState(true)
                navigate('/step1');
              }}
            />
          </div>
        </div>
      </>
    );
  }

  if (Note) {
    return (
      <>
        <div
          style={{ padding: '30px' }}
          className={styles.mobile_form_container}
        >
          <div style={{ position: 'static' }} className={styles.title}>
            📎 {t('note')}
          </div>
          <div style={{ marginTop: '30px' }}>
            3 {t('steps_to_start_using_service')}: <br />
            <br />
            1️⃣ {t('add_personal_information.0')} <b>{t('add_personal_information.1')} </b> <br />
            &nbsp;&nbsp;&nbsp;&nbsp; ({t('profile_tab')}). <br />
            <br />
            2️⃣ {t('add_basic_schedule_work.0')} <b>{t('add_basic_schedule_work.1')}</b> {t('add_basic_schedule_work.2')} <br />
            &nbsp;&nbsp;&nbsp;&nbsp; ({t('schedule_menu')}). <br />
            <br />
            3️⃣ {t('add_at_least_one_service.0')} <b>{t('add_at_least_one_service.1')}</b> <br /> &nbsp;&nbsp;&nbsp;&nbsp;
            ({t('services_menu')}). <br />
            <br />
            {t('add_current_bookings')}
            <br />
            <br />
            <a href="https://bit.ly/planguin-setting">👉{t('detailed_instruction')}</a>
          </div>
          <div className={styles.button_container}>
            <Button
              name={t('ok')}
              color={'black'}
              syle={{ marginTop: '200px' }}
              onClick={() => {
                navigate('/profile/specialist');
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default RegistrationSuccessful;
