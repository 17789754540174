import React, { useState, useEffect, useCallback } from 'react';
import styles from './MobileBooking.module.css';
import {
  Button,
  Input,
  ServiceBlock,
  SocialNetwork,
  TextArea,
} from '../../Components/BasicComponents/BasicComponents';
import call from '../../Imgs/Others/call.png';
import Header from '../../Components/Header/Header';
import DatePickerBlock from 'Pages/Booking/Components/DatePickerBlock/DatePickerBlock';
import TimePickerBlock from 'Pages/Booking/Components/TimePickerBlock/TimePickerBlock';
import { ServiceInfo } from 'Pages/Booking/Components/AppointmentConfirmation/AppointmentConfirmation';
import planguin from '../../Imgs/planguin.png';
import moment from 'moment';
import nodata from '../../Imgs/nodata.png';
import { useMobileBookingStore } from './MobileBookingStore';
import { useChooseServiceStore } from 'Pages/Booking/Components/ChooseService/ChooseServiceStore';
import { useDatePickerBlockStore } from 'Pages/Booking/Components/DatePickerBlock/DatePickerBlockStore';
import { useAppointmentConfirmationStore } from 'Pages/Booking/Components/AppointmentConfirmation/AppointmentConfirmationStore';
import { useTimePickerBlockStore } from 'Pages/Booking/Components/TimePickerBlock/TimePickerBlockStore';
import { useBookingStore } from 'Pages/Booking/BookingStore';
import { addMinutes, adjustPrice } from 'utils/lib/helpers/helpers';
import { useTranslation } from 'react-i18next';
import { useCurrency } from 'hooks/useCurrency';
import PhoneNumberInput from 'Components/PhoneNumberInput/PhoneNumberInput';

function MobileBooking(props) {
  const { t } = useTranslation();
  const {currency} = useCurrency()
  const [serviceId, setServiceId] = useState('')

  let { specialistId } = useBookingStore();
  let { page, setPage } = useMobileBookingStore();
  let {
    specialist,
    getSpecialistInfo,
    service,
    filteredServices,
    categories,
    category,
    getSpecialistServices,
    selectService,
    categoryHandler,
    getSpecialistGroupServices,
    getAllGroupTimeSlots,
  } = useChooseServiceStore();

  let { appointment, inputHandler, confirmAppointment } =
    useAppointmentConfirmationStore();
  let { date } = useDatePickerBlockStore();
  let { timeSlots, timeSlotStartTime, groupTimeSlot } =
    useTimePickerBlockStore();

  const handleServiceSelection = (el) => {
    if (el.schedule) {
      selectService([el]);
    } else {
      const isServiceSelected = service?.some((service) => service._id === el._id);
      const newService = isServiceSelected
        ? service.filter((service) => service._id !== el._id)
        : [...service ?? [], el].filter((service) => !service.schedule);
  
      selectService(newService);
    }
    setServiceId(el._id);
  };

  const totalTimes = useCallback(() => {
    const hasFavoriteSchedule = service?.some((s) => s.schedule)
    if (!service?.length || hasFavoriteSchedule) return null

    const countTimes = service.reduce((acc, nextService) => {
      return acc += nextService.hours * 60 + nextService.minutes
    }, 0);

    return `${timeSlotStartTime} - ${addMinutes(timeSlotStartTime, countTimes)}`
  }, [timeSlotStartTime])

  const totalPrice = useCallback(() => {
    if (!service?.length) return ''
    
    return service.reduce((acc, nextService) => {
      return acc = adjustPrice(acc, nextService.price)
    }, '');
  }, [service])

  useEffect(() => {
    async function af() {
      await getSpecialistInfo();
      await getSpecialistServices();
      await getSpecialistGroupServices();
      await getAllGroupTimeSlots();
    }
    af();
  }, [specialistId, service]);

  let zaglushka = (
    <div className={styles.img_container}>
      <img src={nodata} width={'220px'} alt="" />
      <div className={styles.nodata_title}>
        {t('no_free_slots')}
      </div>
    </div>
  );

  if (page == 'profile') {
    return (
      <>
        {window.scroll(0, 0)}
        <Header platform={'mobile'} user={'client'} />
        <div className={styles.container}>
          <div className={styles.call_button}>
            <a href={`tel:${specialist?.phone}`}>
              <img src={call} alt="" width={'60px'} />
            </a>
          </div>
          <div className={styles.avatar_container}>
            <div className={styles.inner_container}>
              <img className={styles.avatar} src={specialist?.photo} alt="" />
            </div>
          </div>
          {/* <div className={styles.name}>{specialist?.name} ⭐ </div> */}
          <div className={styles.name}>{specialist?.visibleName} ⭐ </div>

          <div className={styles.about}>{specialist?.about}</div>
          <div className={styles.social_links}>
            {specialist?.instagram != '' && specialist?.instagram ? (
              <SocialNetwork
                width={'33px'}
                show={'view'}
                type={'instagram'}
                value={specialist?.instagram}
                link={`https://instagram.com/${specialist?.instagram}`}
              />
            ) : (
              <></>
            )}
            {specialist?.facebook != '' && specialist?.facebook ? (
              <SocialNetwork
                width={'33px'}
                show={'view'}
                type={'facebook'}
                value={specialist?.facebook}
                link={`https://www.facebook.com/${specialist?.facebook}`}
              />
            ) : (
              <></>
            )}
            {specialist?.youtube != '' && specialist?.youtube ? (
              <SocialNetwork
                width={'33px'}
                show={'view'}
                type={'youtube'}
                value={specialist?.youtube}
                link={`https://www.youtube.com/${specialist?.youtube}`}
              />
            ) : (
              <></>
            )}
            {specialist?.tiktok != '' && specialist?.tiktok ? (
              <SocialNetwork
                width={'33px'}
                show={'view'}
                type={'tiktok'}
                value={specialist?.tiktok}
                link={`https://www.tiktok.com/@${specialist?.tiktok}`}
              />
            ) : (
              <></>
            )}
            {specialist?.whatsapp != '' &&
            specialist?.whatsapp != '+380' &&
            specialist?.whatsapp ? (
              <SocialNetwork
                width={'33px'}
                show={'view'}
                type={'whatsapp'}
                value={specialist?.whatsapp}
                link={`https://wa.me/${specialist?.whatsapp}`}
              />
            ) : (
              <></>
            )}
            {specialist?.telegram != '' &&
            specialist?.telegram != '@' &&
            specialist?.telegram ? (
              <SocialNetwork
                width={'33px'}
                show={'view'}
                type={'telegram'}
                value={specialist?.telegram}
                link={`https://t.me/${specialist?.telegram}`}
              />
            ) : (
              <></>
            )}
            {specialist?.viber != '' &&
            specialist?.viber != '380' &&
            specialist?.viber ? (
              <SocialNetwork
                width={'33px'}
                show={'view'}
                type={'viber'}
                value={specialist?.viber}
                link={`viber://chat/?number=%2B${specialist?.viber}`}
              />
            ) : (
              <></>
            )}
          </div>
          <div className={styles.hint}>
            {`${t('select_service_to_book')} `}
          </div>
          <div className={styles.button_container}>
            <Button
              name={t('choose_service_1')}
              color={'black'}
              style={{ width: '288px' }}
              onClick={async () => {
                setPage('services');
              }}
            />
          </div>
        </div>
      </>
    );
  }

  if (page == 'services') {
    return (
      <>
        <Header platform={'mobile'} user={'client'} />
        <div className={styles.container}>
          <div className={styles.services_title}>{t('choose_service')} 👇</div>
          {categories.length > 0 ? (
            <div className={styles.categories_container}>
              {[t('all_categories'), ...categories].map((el) => {
                return (
                  <Category
                    style={{
                      backgroundColor: el == category ? '#FF8A35' : '#EFEFEF',
                    }}
                    onClick={categoryHandler}
                    id={el}
                    value={el}
                  />
                );
              })}
            </div>
          ) : (
            <></>
          )}
          <div className={styles.services_container}>
            {filteredServices.map((el) => {
              const isSelected = service?.some((service) => service._id === el._id);

              return (
                <div key={el._id}>
                  <ServiceBlock
                    type={'withInfo'}
                    onClick_info={() => {
                      selectService('serviceInfo');
                      setPage('serviceInfo');
                      setServiceId(el._id);
                    }}
                    description={el.description}
                    onClick={() => {
                      handleServiceSelection(el);
                    }}
                    style={{
                      border: isSelected ? '2px solid #FF8A35' : '',
                    }}
                    name={`${el.name} ${el.schedule ? `(${t('group')})` : ''}`}
                    hasGroup={true}
                    duration={`${el.hours} ${t('hours')} ${el.minutes} ${t('minutes')}`}
                    price={el.price}
                  />
                </div>
              );
            })}

            <div className={styles.empty_block}></div>
          </div>

          <div className={styles.buttons_container}>
            <Button
              style={{ width: '100%' }}
              name={t('next')}
              onClick={() => {
                setPage('chooseTime');
              }}
            />
            <Button
              onClick={() => {
                setPage('profile');
              }}
              name={t('back')}
              style={{
                backgroundColor: '#ffff',
                color: 'grey',
                height: '30px',
              }}
            />
          </div>
        </div>
      </>
    );
  }

  if (page == 'serviceInfo') {
    const serviceSelected = filteredServices.find(({_id}) => _id === serviceId);

    return (
      <>
        <Header platform={'mobile'} user={'client'} />
        <div className={styles.container}>
          <div className={styles.services_title}>{serviceSelected.name}</div>

          <div className={styles.services_container}>
            {/* <div className={styles.service_name}>{service.name}</div> */}
            <div>
              {`${t('service_duration')} `}
              <span className={styles.property}>
                {serviceSelected.hours} {t('hours')} {serviceSelected.minutes} {t('minutes')}
              </span>
            </div>
            <div>
              {`${t('cost')}: `}
              <span className={styles.property}>{serviceSelected.price} {currency}</span>{' '}
            </div>
            <textarea readOnly className={styles.service_description}>
              {serviceSelected.description}
            </textarea>

            <div className={styles.empty_block}></div>
          </div>

          <div className={styles.buttons_container}>
            <Button
              onClick={() => {
                setPage('services');
              }}
              name={t('back')}
              style={{ backgroundColor: 'grey', height: '60px' }}
            />
          </div>
        </div>
      </>
    );
  }

  if (page == 'chooseTime') {
    return (
      <>
        <Header platform={'mobile'} user={'client'} />

        <div className={styles.container}>
          <div className={styles.services_title}>
            {t('select_date_and_time')} 👇
          </div>
          <DatePickerBlock platform={'mobile'} />

          {/* {timeSlots.length==0?zaglushka:<></>} */}

          <TimePickerBlock platform={'mobile'} />
          <div className={styles.buttons_container}>
            <Button
              style={{ width: '100%' }}
              name={t('next')}
              onClick={() => {
                setPage('confirmation');
              }}
            />
            <Button
              onClick={() => {
                setPage('services');
              }}
              name={t('back')}
              style={{
                backgroundColor: '#ffff',
                color: 'grey',
                height: '30px',
              }}
            />
          </div>
        </div>
      </>
    );
  }

  if (page == 'confirmation') {
    const serviceGroupsNames = service
      .filter((s) => Boolean(s.schedule))  
      .map((s) => `${s.name} (${t('group')})`)                 
      .join(' / ');    
                      
    const serviceNames = service
      .filter((s) => !Boolean(s.schedule))  
      .map((s) => s.name)                
      .join(' / ');    

    return (
      <>
        <Header platform={'mobile'} user={'client'} />

        <div className={styles.confirmation_container}>
          <div className={styles.confirmation_title}>{t('confirmation')}</div>

          <div className={styles.service}>
            <div className={styles.input_title}>{t('service')}</div>
            <div className={styles.service_value}>
              {serviceNames}
              {serviceGroupsNames}
            </div>
          </div>

          <div className={styles.service_info2}>
            <ServiceInfo
              t={'m'}
              type={'time'}
              value={`${moment(date).format('DD.MM')} / ${totalTimes() ?? groupTimeSlot?.from}`}
            />
            <ServiceInfo t={'m'} type={'price'} value={`${totalPrice()} `} />
          </div>

          <Input
            style={{ width: '100%' }}
            id={'name'}
            value={appointment?.name}
            onChange={inputHandler}
            placeholder={t('name')}
          />

          <PhoneNumberInput
            id={'phone'} 
            handleChange={(value, country, event) => inputHandler(value, 'phone')} 
          />

          {timeSlotStartTime && (
            <TextArea
              style={{ width: '100%' }}
              id={'comment'}
              onChange={inputHandler}
              placeholder={t('comment')}
            />
          )}
          <Button
            style={{ width: '100%' }}
            name={t('book')}
            color={'black'}
            onClick={confirmAppointment}
          />
          <Button
            onClick={() => {
              setPage('chooseTime');
            }}
            name={t('back')}
            style={{ backgroundColor: '#ffff', color: 'grey', height: '30px' }}
          />
        </div>
      </>
    );
  }

  if (page == 'success') {
    return (
      <>
        {document.body.scrollIntoView()}

        <div className={styles.success_container}>
          <div className={styles.title}>{t('booked')}</div>
          <div className={styles.img_title}>{t('booking_confirmed')} ✅</div>

          <div className={styles.img}>
            <img src={planguin} alt="" />
          </div>
          <Button
            name={t('ok_thanks')}
            color={'black'}
            onClick={() => {
              window.location.reload();
            }}
          />
        </div>
      </>
    );
  }
}

export default MobileBooking;

//Допоміжні

export function Category(props) {
  return (
    <>
      <div
        style={props.style}
        id={props.value}
        onClick={props.onClick}
        className={styles.category}
      >
        {props.value}
      </div>
    </>
  );
}
