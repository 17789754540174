import React, { useEffect, useState, useLayoutEffect } from 'react';
import styles from './Services.module.css';
import Header from '../../Components/Header/Header';
import ServicesBlock from './Components/ServicesBlock/ServicesBlock';
import ServiceEditBlock from './Components/ServiceEditBlock/ServiceEditBlock';
import axios from 'axios';
import { checkAccess } from '../../access';
import Toast2 from '../../Components/Toast2/Toast2';
import DeleteConfirmation from './Components/DeleteConfirmation/DeleteConfirmation';
import MobileServices from '../../Mobile/MobileServices/MobileServices';
import nodata from '../../Imgs/nodata.png';
import { useServicesStore } from './ServicesStore';
import AddContactForm from 'Pages/Clients/Components/AddContactForm/AddContactForm';

function Services() {
  let { getSpecialistServices, getSpecialistCategories } = useServicesStore();

  useEffect(() => {
    async function af() {
      await getSpecialistServices();
      await getSpecialistCategories();
    }
    af();
  }, []);

  document.body.style.backgroundColor = '#F4F7FE';

  //! Рендеринг

  //Mobile
  if (window.innerWidth < 1025) {
    return (
      <>
        <Toast2 />
        <MobileServices specialistId={checkAccess()} />;
      </>
    );
  }

  //Desktop
  return (
    <>
      <Toast2 />

      <Header platform={'desktop'} specialistId={checkAccess()} />

      <div className={styles.container}>
        <div className={styles.inner_container}>
          <ServicesBlock />
          <ServiceEditBlock />
        </div>
      </div>

      <DeleteConfirmation />
      <AddContactForm />
    </>
  );
}

export default Services;
