import React from 'react';
import styles from './Settings.module.css';
import Header, { MobileHeader } from '../../Components/Header/Header';
import { checkAccess } from '../../access';
import { SelectWidthLabel } from 'Pages/Clients/Components/BasicComponents/BasicComponents';
import { Modal } from 'Components/Modal/Modal';
import EditPassword from './Components/EditPassword/EditPassword';
import Imgs from 'Imgs/Imgs';
import EditEmail from './Components/EditEmail/EditEmail';
import BottomMenu from 'Components/BottomMenu/BottomMenu';
import Toast2 from 'Components/Toast2/Toast2';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import moment from 'moment-timezone';
import { useSettingsStore } from './SettingsStore';
import { useEffect } from 'react';
import { currencyOptions, languageOptions, useCountryOptions } from 'utils/vars';
import Cookies from 'js-cookie';
import { LanguageEnum } from 'utils/enums';

function Settings() {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const countryOptions = useCountryOptions()
  const { settings, modal, inputSettingsHandler, handleOpenModal } = useSettingsStore()

  let isMobile = window.innerWidth < 1024;

  document.body.style.backgroundColor = '#F4F7FE';

  

  useEffect(() => {
    if (settings.country) {
      const language = languageOptions.find((option) => option.value === settings.country)
       
      inputSettingsHandler(undefined, 'language', language?.value ?? LanguageEnum.En )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.country]);

  useEffect(() => {
    if (settings.language) {
      const currency = currencyOptions.find((option) => option.value === settings.language)
      if (currency)  inputSettingsHandler(undefined, 'currency', currency.value )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.language]);

  useEffect(() => {
    const country = Cookies.get('country')
    if (country)  inputSettingsHandler(undefined, 'country', country )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Toast2 />

      {isMobile ? (
        <MobileHeader />
      ): (
        <Header platform={'desktop'} specialistId={checkAccess()} />
      )}

      <main className={styles.main}>
        <div className={styles.container}>
          <div className={styles.content}>
            <h3 className={styles.title}>{t('settings')}</h3>
            <div className={styles.setting}>
              {t('country')}

              <SelectWidthLabel
                className={styles.select_block}
                value={settings.country}
                onChange={inputSettingsHandler}
                options={countryOptions}
                id={'country'}
                select={styles.select}
                name="select"
              />
            </div>
            {/* <div className={styles.setting}>
              {t('time_zone')}

              <SelectWidthLabel
                className={styles.select_block}
                value={settings.timeZone}
                onChange={inputSettingsHandler}
                options={timeZoneOptions}
                id={'timeZone'}
                select={styles.select}
                name="select"
              />
            </div> */}
            <div className={styles.setting}>
              {t('language_1')}

              <SelectWidthLabel
                className={styles.select_block}
                value={settings.language}
                onChange={inputSettingsHandler}
                options={languageOptions}
                id={'language'}
                select={styles.select}
                name="select"
              />
            </div>
          
            <div className={styles.setting}>
              {t('currency_1')}

              <SelectWidthLabel
                className={styles.select_block}
                value={settings.currency}
                onChange={inputSettingsHandler}
                options={currencyOptions}
                id={'currency'}
                select={styles.select}
                name="select"
              />
            </div>
          </div>
           
        </div>

        <div className={styles.container}>
          <button className={styles.edit_button} onClick={() => handleOpenModal("isOpenEmail", true)} >{t('change_email')}</button>
          <button className={styles.edit_button} onClick={() => handleOpenModal("isOpenPassword", true)} >{t('change_password')}</button>
        </div>

        <div className={styles.container}>
          <div
            className={styles.link_container}
            onClick={() => {
              navigate('/delete_account');
            }}
          >
            ❌ {t('delete_account')}
          </div>
        </div>
      </main>

      
       {isMobile && (
        <BottomMenu />
      )}

      <Modal className={styles.modal} onClose={() => handleOpenModal("isOpenPassword", false)} isOpen={modal.isOpenPassword}>
        <div className={styles.head}>
          <h3 className={styles.title}>{t('change_password')}</h3>
          <span className={styles.close} onClick={() => handleOpenModal("isOpenPassword", false)}> <Imgs img={'close'} /> </span>
        </div>
        <EditPassword />
      </Modal>

      <Modal className={styles.modal} onClose={() => handleOpenModal("isOpenEmail", false)} isOpen={modal.isOpenEmail}>
        <div className={styles.head}>
          <h3 className={styles.title}>{t('change_email')}</h3>
          <span className={styles.close} onClick={() => handleOpenModal("isOpenEmail", false)}> <Imgs img={'close'} /> </span>
        </div>
        <EditEmail />
      </Modal>
    </>
  );
}

export default Settings;
