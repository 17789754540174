import { create } from 'zustand';
import axios from 'axios';
import { useBookingStore } from '../../Pages/Booking/BookingStore';
import { useChooseServiceStore } from 'Pages/Booking/Components/ChooseService/ChooseServiceStore';
import { useToast2 } from '../../Components/Toast2/ToastStore';
import { useDatePickerBlockStore } from 'Pages/Booking/Components/DatePickerBlock/DatePickerBlockStore';
import { useTimePickerBlockStore } from 'Pages/Booking/Components/TimePickerBlock/TimePickerBlockStore';
import moment from 'moment';
let { showToast } = useToast2.getState();

export let useMobileBookingStore = create((set, get) => ({
  page: 'profile',

  setPage: (page) => {
    if (page == 'chooseTime') {
      let { service } = useChooseServiceStore.getState();
      if (!service) {
        showToast('e', 'Оберіть послугу');
        return;
      }
    }

    if (page == 'confirmation') {
      let { timeSlot, timeSlotStartTime, groupTimeSlot } =
        useTimePickerBlockStore.getState();
      let { service } = useChooseServiceStore.getState();

      let { date } = useDatePickerBlockStore.getState();

      //Якщо вибрана послуга індивідуальна
      const hasFavoriteSchedule = service.some((s) => s.schedule)

      if (!hasFavoriteSchedule) {
        if (!timeSlotStartTime) {
          showToast('e', 'Оберіть час візиту');
          return;
        }
      }
      //Якщо вибрана послуга групова
      if (hasFavoriteSchedule) {
        if (!groupTimeSlot) {
          showToast('e', 'Оберіть час візиту');
          return;
        }
      }

      if (moment(date).add(1, 'days').format('x') < moment().format('x')) {
        showToast('e', 'Обрана дата вже пройшла');
        return;
      }
      if (
        moment(`${date} ${timeSlotStartTime}`).format('x') <
        moment().format('x')
      ) {
        showToast('e', 'Обраний час вже пройшов');
        return;
      }
    }

    set({ page });
  },
}));
