import React, { useState, useEffect } from 'react';
import Appoint from './Components/Appoint/Appoint';
import DatePickerBlock from './Components/DatePickerBlock/DatePickerBlock';
import Header from '../../Components/Header/Header';
import ProfileBlockView from './Components/ProfileBlockView/ProfileBlockView';
import TimePickerBlock from './Components/TimePickerBlock/TimePickerBlock';
import styles from './Booking.module.css';
import AppointmentConfirmation from './Components/AppointmentConfirmation/AppointmentConfirmation';
import Toast2 from '../../Components/Toast2/Toast2';
import nodata from '../../Imgs/nodata.png';
import SuccessAppointment from './Components/SuccessAppointment/SuccessAppointment';
import { useBookingStore } from './BookingStore';
import MobileBooking from '../../Mobile/MobileBooking/MobileBooking';
import { PopUpPhone } from './Components/PopUpPhone/PopUpPhone';
import { useTranslation } from 'react-i18next';

function Booking() {
  const { t } = useTranslation();
  let { getSpecialistId, checkSpecialistAccountStatus, page } =
    useBookingStore();

  useEffect(() => {
    async function af() {
      await getSpecialistId();
      await checkSpecialistAccountStatus();
    }
    af();
  }, []);

  let zaglushka = (
    <div className={styles.img_container}>
      <img src={nodata} width={'220px'} alt="" />
      <div style={{ fontWeight: 'bold' }} className={styles.nodata_title}>
        {t('invalid_link')}
      </div>
      <div
        onClick={() => {
          window.location.href = 'https://www.planguin.co/';
        }}
        style={{ color: 'coral', cursor: 'pointer' }}
        className={styles.nodata_title}
      >
        {t('home')}
      </div>
    </div>
  );

  document.body.style.backgroundColor = '#F4F7FE';

  //! Рендеринг

  // Якщо такого логіну не існує
  if (page == 'noSpecialist') {
    return <>{zaglushka}</>;
  }

  // //Mobile
  if (window.innerWidth < 1025) {
    return (
      <>
        <Toast2 />
        <MobileBooking />
      </>
    );
  }

  //Desktop
  return (
    <>
      <Toast2 />

      <Header user={'client'} />

      <div className={styles.container}>
        <div className={styles.left_side}>
          <ProfileBlockView />
        </div>
        <div className={styles.right_side}>
          <div className={styles.choose_date}>
            <DatePickerBlock />
          </div>
          <div className={styles.choose_time}>
            <TimePickerBlock />
          </div>
          <div className={styles.appointment}>
            <Appoint />
          </div>
        </div>
      </div>

      <AppointmentConfirmation />
      <SuccessAppointment />
      <PopUpPhone />
    </>
  );
}

export default Booking;
