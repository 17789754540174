import React, { useState, useLayoutEffect, useEffect } from 'react';
import {
  Background,
  Button,
  InputsTitle,
} from '../../Components/BasicComponents/BasicComponents';
import Imgs from '../../Imgs/Imgs';
import styles from './Step2.module.css';
import Toast from '../../Components/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SelectWidthLabel } from 'Pages/Clients/Components/BasicComponents/BasicComponents';
import { useSettingsStore } from 'Pages/Settings/SettingsStore';
import { currencyOptions, languageOptions, useCountryOptions } from 'utils/vars';
import { LanguageEnum } from 'utils/enums';

function Step2() {
  const { t } = useTranslation();
  const countryOptions = useCountryOptions()
  let navigate = useNavigate();
  const { settings, inputSettingsHandler } = useSettingsStore();

  let [toast, setToast] = useState({ type: '', show: false, msg: '' });

  //Адаптивный дизайн
  let [windowWidth, setWindowWidth] = useState(window.innerWidth);

 //Відправити данні
  async function sendData() {
    navigate('/step3');
  }

  function updateWidth() {
    setWindowWidth(window.innerWidth);
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updateWidth);

    if (windowWidth < 1025) {
      document.body.style.backgroundColor = '#F4F7FE';
    }
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
      if (settings.country) {
        const language = languageOptions.find((option) => option.value === settings.country)
        inputSettingsHandler(undefined, 'language', language?.value ?? LanguageEnum.En )
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings.country]);

    useEffect(() => {
      if (settings.language) {
        const currency = currencyOptions.find((option) => option.value === settings.language)
        if (currency)  inputSettingsHandler(undefined, 'currency', currency.value )
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings.language]);

  //! Рендеринг

  //Mobile
  if (windowWidth < 1025) {
    return (
      <>
        <Toast show={toast.show} type={toast.type} msg={toast.msg} />

        <div className={styles.mobile_form_container}>
          <div className={styles.logo_container}>
            <Imgs img={'planguinLogo'} planguinLogoWidth={'200px'} />
          </div>

          <InputsTitle style={{ marginTop: '20px', fontSize: '20px' }} title={`${t('choose_settings_profile')} 📝`} />

          <div className={styles.inner_form_container_mobile}>
            <div className={styles.setting}>
              <p className={styles.setting_name}>{t('country')}</p>

              <SelectWidthLabel
                className={styles.select_block}
                value={settings.country}
                onChange={inputSettingsHandler}
                options={countryOptions}
                id={'country'}
                select={styles.select}
                name="select"
              />
            </div>


            <div className={styles.setting}>
              <p className={styles.setting_name}>{t('language_1')}</p>

              <SelectWidthLabel
                className={styles.select_block}
                value={settings.language}
                onChange={inputSettingsHandler}
                options={languageOptions}
                id={'language'}
                select={styles.select}
                name="select"
              />
            </div>

            <div className={styles.setting}>
              {t('')}
              <p className={styles.setting_name}>{t('currency_1')}</p>

              <SelectWidthLabel
                className={styles.select_block}
                value={settings.currency}
                onChange={inputSettingsHandler}
                options={currencyOptions}
                id={'currency'}
                select={styles.select}
                name="select"
              />
            </div>
          </div>

          <Button name={t('next')} onClick={sendData} />
          <Button
            onClick={() => {
              navigate('/step2');
            }}
            name={t('skip')}
            style={{ backgroundColor: '#DADADA', marginTop: '-15px' }}
          />
        </div>
      </>
    );
  }

  //Web
  return (
    <>
      <Toast show={toast.show} type={toast.type} msg={toast.msg} />

      <Background style={{ backgroundColor: '#F4F7FE' }}>
        <div className={styles.outer_container}>
          <div className={styles.form_container}>
            <Imgs img={'planguinLogo'} />

            <div className={styles.inner_form_container}>
              <InputsTitle style={{fontSize: '20px'}} title={`${t('choose_settings_profile')} 📝`} />

              <div className={styles.setting}>
                <p className={styles.setting_name}>{t('country')}</p>

                <SelectWidthLabel
                  className={styles.select_block}
                  value={settings.country}
                  onChange={inputSettingsHandler}
                  options={countryOptions}
                  id={'country'}
                  select={styles.select}
                  name="select"
                />
              </div>


              <div className={styles.setting}>
                <p className={styles.setting_name}>{t('language_1')}</p>

                <SelectWidthLabel
                  className={styles.select_block}
                  value={settings.language}
                  onChange={inputSettingsHandler}
                  options={languageOptions}
                  id={'language'}
                  select={styles.select}
                  name="select"
                />
              </div>

              <div className={styles.setting}>
                {t('')}
                <p className={styles.setting_name}>{t('currency_1')}</p>

                <SelectWidthLabel
                  className={styles.select_block}
                  value={settings.currency}
                  onChange={inputSettingsHandler}
                  options={currencyOptions}
                  id={'currency'}
                  select={styles.select}
                  name="select"
                />
              </div>
            </div>

            <Button name={t('next')} onClick={sendData} />
            <Button
              name={t('skip')}
              style={{ backgroundColor: '#DADADA', marginTop: '-15px' }}
              onClick={() => {
                navigate('/step3');
              }}
            />
          </div>
        </div>
      </Background>
    </>
  );
}

export default Step2;
