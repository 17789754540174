import React from 'react';
import styles from './BasicComponents.module.css';
import mail from '../../Components/Images/mail.png';
import telegram from '../../Components/Images/telegram.png';
import viber from '../../Components/Images/viber.png';
import call from '../Images/call.png';

export function InputWidthLabel(props) {
  return (
    <div className={`${styles.form_contact} ${props.className || ''}`}>
      <label htmlFor={props.name}>{props.label}</label>
      <input
        className={styles.input}
        value={props.value}
        onChange={props.onChange}
        id={props.id}
      />
    </div>
  );
}
export function TextArea(props) {
  return (
    <>
      <label htmlFor="textarea">{props.label}</label>
      <textarea
        className={styles.textarea}
        value={props.value}
        onChange={props.onChange}
        id={props.id}
      ></textarea>
    </>
  );
}

export function Button(props) {
  return (
    <button onClick={props.onClick} type="button" className={props.className}>
      {props.label}
    </button>
  );
}

export function Input(props) {
  return (
    <div className={styles.input_block}>
      <input
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        className={styles.input}
        placeholder={props.placeholder}
      />
    </div>
  );
}

export function Contact(props) {
  return (
    <div className={styles.contact_container}>
      <div onClick={props.onClick} className={styles.right_side}>
        <div className={styles.contact_image}>{props.initials}</div>
        <div className={styles.contactGroups}>
          <div className={styles.contactName}>
            {props.name} {props.surname}
          </div>
          <div className={styles.contact_tel}>{props.tel}</div>
        </div>
      </div>
      <div className={styles.channels}>
        <a href={`tel:${props.tel}`}>
          <img
            onClick={() => {}}
            src={call}
            style={{ width: '32px', height: 'auto' }}
            alt=""
          />
        </a>
        {props.tel == '+380' || !props.tel ? (
          <></>
        ) : (
          <>
            <img
              src={telegram}
              style={{ width: '30px', height: '30px' }}
              onClick={() => {
                window.open(`https://t.me/${props.tel}`, '_self');
              }}
              alt=""
            />
            {/* <img src={viber} style={{width:'30px'}} onClick={()=>{window.open(`viber://chat/?number=%2B${props.tel.replace(/\D/g, "")}`,'_self')}} alt="" /> */}
          </>
        )}
        <img
          src={mail}
          style={{ width: '30px', height: '30px' }}
          onClick={props.onClickMsg}
          alt=""
        />
      </div>
    </div>
  );
}
