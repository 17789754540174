import { create } from 'zustand';

export let useRegistrationSuccessfulStore = create((set, get) => ({
  RegistrationSuccessfulWindow: true,
  Note: false,

  changeRegistrationSuccessfulWindowState: (state) => {
    set({ RegistrationSuccessfulWindow: state });
  },
  changeNoteState: (state) => {
    set({ Note: state });
  },
}));
