import React, { useState } from 'react';
import Imgs from '../../Imgs/Imgs';
import styles from './BasicComponents.module.css';
import stylesM from './BasicComponentsM.module.css';
import info from '../../Imgs/info.png';
import { useTranslation } from 'react-i18next';
import { useCurrency } from 'hooks/useCurrency';

export function Logo(props) {
  return (
    <>
      <Imgs img={'planguinLogo'} />{' '}
    </>
  );
}

export function InputsTitle(props) {
  return (
    <div style={props.style} className={styles.inputs_title_container}>
      {props.title}
    </div>
  );
}

export function Input(props) {
  const { t } = useTranslation()
  const {currency} = useCurrency()
  let [label, setLabel] = useState(false);

  function showLabel() {
    setLabel(true);
  }

  if (props.type == 'm') {
    return (
      <>
        <input
          onFocus={props.onFocus}
          style={props.style}
          id={props.id}
          type={props.type}
          value={props.value}
          onChange={(e) => {
            showLabel(e);
            props.onChange(e);
          }}
          placeholder={props.placeholder}
          className={stylesM.input}
        />
      </>
    );
  }

  if (props.type == 'withQ') {
    return (
      <>
        <div style={props.style} className={styles.input_container}>
          <div className={styles.q}>{props.q} {currency}</div>
          <input
            id={props.id}
            type={props.type}
            value={props.value}
            onChange={(e) => {
              showLabel(e);
              props.onChange(e);
            }}
            placeholder={props.placeholder}
            className={styles.input}
          />
        </div>
      </>
    );
  }

  if (props.label) {
    label = true;
  }

  if (props.type == 'label2') {
    return (
      <>
        <div className={styles.input_container}>
          {label ? (
            <div className={styles.input_label}>{props.labelName}</div>
          ) : (
            <></>
          )}
          <input
            style={props.style}
            id={props.id}
            type={props.type}
            value={props.value}
            onChange={(e) => {
              showLabel(e);
              props.onChange(e);
            }}
            placeholder={props.placeholder}
            className={styles.input}
          />
          {props.show_input_description ? (
            <div className={styles.input_description}>
              {t('email_note')}
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.input_container}>
        <input
          style={props.style}
          id={props.id}
          type={props.type}
          value={props.value}
          onChange={(e) => {
            showLabel(e);
            props.onChange(e);
          }}
          placeholder={props.placeholder}
          className={styles.input}
          {...props}
        />
        {props.show_input_description ? (
          <div className={styles.input_description}>
            {t('email_note')}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export function Below_Input_Text1(props) {
  const { t } = useTranslation();

  return (
    <div className={styles.below_input_text}>
      {t('forgot_password')}{' '}
      <span className={styles.selected_word} onClick={props.onClick}>
        {' '}
        {t('reset_password')}
      </span>
    </div>
  );
}

export function Below_Input_Text2(props) {
  const { t } = useTranslation();

  return (
    <div style={props.style} className={styles.below_input_text}>
      {t('no_account')}
      <span onClick={props.onClick} className={styles.selected_word}>
        {t('create_account')}
      </span>
    </div>
  );
}

export function Below_Input_Text3(props) {
  const { t } = useTranslation();

  return (
    <div className={styles.below_input_text}>
      {t('already_have_account')} {t('you_can')}
      <span onClick={props.onClick} className={styles.selected_word}>
        {t('log_in')}
      </span>{' '}
      &nbsp; {`${t('to_your_account')} `}
    </div>
  );
}

export function Title1() {
  const { t } = useTranslation();

  return (
    <div className={styles.inputs_title_container}>
      {t('registration_successful')} ✅
    </div>
  );
}

export function Button(props) {
  if (props.color == 'black') {
    return (
      <button
        style={props.style}
        className={styles.button_black}
        onClick={props.onClick}
        {...props}
      >
        {props.name}
      </button>
    );
  }

  return (
    <button
      style={props.style}
      className={styles.button}
      onClick={props.onClick}
      {...props}
    >
      {props.name}
    </button>
  );
}

export function Background(props) {
  return (
    <div style={props.style} className={styles.background_container}>
      <div className={styles.circle1}></div>
      <div className={styles.circle2}></div>
      <div className={styles.circle3}></div>
      <div className={styles.circle4}></div>

      {props.children}
    </div>
  );
}

export function AgreeWithRules(props) {
  const { t } = useTranslation();
  return (
    <div className={styles.agree_with_rules_container}>
      <div onClick={props.onClick} className={styles.checkbox}>
        {props.value ? (
          <Imgs img={'checkbox_true'} />
        ) : (
          <Imgs img={'checkbox_false'} />
        )}
      </div>
      <span className={styles.agree_with_rules_string}>
        {`${t('i_agree_with')} `}
        <span
          onClick={() => {
            window.open('/terms', '_blank');
          }}
          className={styles.selected_word}
        >
          {t('terms_of_service')}
        </span>{' '}
        &nbsp; {`${t('and')} `}
        <span
          onClick={() => {
            window.open('/privacy-policy', '_blank');
          }}
          className={styles.selected_word}
        >
          {t('privacy_policy_repeated.0')} <br /> {t('privacy_policy_repeated.1')}
        </span>{' '}
      </span>
    </div>
  );
}

export function WhiteBlock() {
  return <div className={styles.white_block}></div>;
}

export function ProfileRaw(props) {
  return (
    <>
      <div className={styles.profile_raw_container}>
        <div className={styles.raw_title}>{props.title}</div>
        <div className={styles.raw_value}>{props.value}</div>
      </div>
    </>
  );
}

export function Selector(props) {
  let options = props.options.map((el) => {
    
    if ( typeof el === 'object' && 'value' in el && 'label' in el) {
      return (
        <option value={el.value}>
          {el.label}
        </option>
      )
    }

    return (
      <option value={el}>
        {el} {props.label}
      </option>
    );
  });

  if (props.type == 'custom') {
    return (
      <>
        <select
          value={props.value}
          id={props.id}
          onChange={props.onChange}
          style={props.style}
          className={styles.selector}
        >
          {props.customOptions}
        </select>
      </>
    );
  }

  return (
    <select
      value={props.value}
      id={props.id}
      onChange={props.onChange}
      style={props.style}
      className={styles.selector}
    >
      {options}
    </select>
  );
}

export function ServiceBlock(props) {
  const {currency} = useCurrency()

  if (props.type == 'withInfo') {
    return (
      <>
        <div
          id={props.id}
          className={styles.service_container_withInfo}
          onClick={(e) => {
            props.onClick(e);
          }}
          style={props.style}
        >
          <div id={props.id} className={styles.service_line1}>
            {props.name}{' '}
          </div>
          <div id={props.id} className={styles.service_line2}>
            {props.duration} / {props.price} {currency}
          </div>
          <img
            onClick={props.onClick_info}
            className={styles.service_info_img}
            src={info}
            alt=""
          />
        </div>
      </>
    );
  }
  if (true) {
    return (
      <>
        <div
          id={props.id}
          className={styles.service_container}
          onClick={(e) => {
            props.onClick(e);
          }}
          style={props.style}
        >
          <div id={props.id} className={styles.service_line1}>
            {props.name}{' '}
          </div>
          <div id={props.id} className={styles.service_line2}>
            {props.duration} {!props.hasGroup && `/ ${props.price} ${currency}` }
          </div>
        </div>
      </>
    );
  }
}

export function TextArea(props) {
  return (
    <textarea
      onFocus={props.onFocus}
      placeholder={props.placeholder}
      style={props.style}
      value={props.value}
      id={props.id}
      onChange={props.onChange}
      className={styles.textarea}
    ></textarea>
  );
}

SocialNetwork.defaultProps = { width: '23px' };

export function SocialNetwork(props) {
  if (props.show == 'view') {
    return (
      <div>
        <a href={props.link} target={'_blank'} rel="noreferrer">
          <Imgs width={props.width} img={props.type} />
        </a>
      </div>
    );
  }

  return (
    <div on className={styles.social_network_container}>
      <div className={styles.social_network_icon_container}>
        <Imgs width={props.width} img={props.type} />
      </div>
      <div className={styles.social_network_name}>{props.value}</div>
    </div>
  );
}

export function DesktopProfileRaw(props) {
  if (props.hide) {
    return <></>;
  }

  if (props.type == 'textarea') {
    return (
      <div className={styles.desktop_profile_raw_container}>
        <div className={styles.desktop_profile_raw_container_title_textarea}>
          {props.title}
        </div>
        <div className={styles.desktop_profile_raw_value_textarea}>
          {props.value}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.desktop_profile_raw_container}>
      <div className={styles.desktop_profile_raw_container_title}>
        {props.title}
      </div>
      <div className={styles.desktop_profile_raw_value}>{props.value}</div>
    </div>
  );
}

export function EditableDesktopRaw(props) {
  const { t } = useTranslation();
  
  if (props.type == 'textarea') {
    return (
      <>
        <div className={styles.editable_desktop_raw_container}>
          <div className={styles.editable_desktop_raw_description_label}>
            {t('description')}
          </div>
          <TextArea
            id={props.id}
            style={props.textareaStyle}
            onChange={props.onChange}
            value={props.value}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.editable_desktop_raw_container}>
        <div className={styles.editable_desktop_raw_label}>{props.title}</div>
        <Input
          style={{ height: '40px' }}
          id={props.id}
          onChange={props.onChange}
          value={props.value}
          placeholder={props.placeholder}
        />
      </div>
    </>
  );
}
