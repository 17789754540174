import React, { useEffect } from 'react';
import styles from './AllClients.module.css';
import { Button, Contact, Input } from '../BasicComponents/BasicComponents';
import { useMobileClientsStore } from '../MobileClientsStore';
import BottomMenu from '../../../../Components/BottomMenu/BottomMenu';
import { useTranslation } from 'react-i18next';

function AllClients() {
  const { t } = useTranslation();
  let {
    clients,
    getSpecialistsClients,
    goToPage,
    showEditPage,
    filterClients,
    searchValue,
    filteredClients,
  } = useMobileClientsStore();

  useEffect(() => {
    async function af() {
      await getSpecialistsClients();
    }
    af();
  }, []);

  return (
    <>
      <div className={styles.allClients_container}>
        <div className={styles.allClients_header}>
          <h1 className={styles.allClients_title}>{t('clients')} </h1>
          <Button
            onClick={() => {
              goToPage('AddClient');
            }}
            className={styles.allClients_button}
            label={`+ ${t('add_contact')}`}
          />
        </div>
        <Input onChange={filterClients} placeholder={t('search')} />

        <div style={{ marginTop: '30px' }}></div>
        {(searchValue ? filteredClients : clients).map((client) => {
          return (
            <Contact
              onClick={() => {
                showEditPage(client._id);
              }}
              onClickMsg={() => {
                goToPage('Send Message', client._id, client?.name);
              }}
              name={client?.name}
              surname={client?.surname}
              tel={client?.phone}
              initials={`${client?.name[0]}`}
            />
          );
        })}

        <div style={{ height: '70px' }}></div>
      </div>

      <BottomMenu />
    </>
  );
}

export default AllClients;
